import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import CerrarModal from "../controles/CerrarModal";
import Tabla from "../controles/Tabla";
import TrInfo from "../controles/TrInfo";
import {formatoMoneda} from "../controles/formatoMoneda";
import {useGsBackend} from "../funcionesApi";

type Props = {
    onCerrar: () => void
}

type InfoMediosDePago = {
    medio_de_pago: string,
    cant_pendientes: number,
    importe_pendientes: number,
    cant_cerrados: number,
    importe_cerrados: number,
    cant_total: number,
    importe_total: number,
}

type InfoDelivery = {
    id_repartidor: number,
    repartidor: string,
    cant_pendientes: number,
    importe_pendientes: number,
    cant_cerrados: number,
    importe_cerrados: number,
    cant_total: number,
    importe_total: number,
    medios_de_pago: Array<InfoMediosDePago>
}

type Informacion = {
    info_delivery: Array<InfoDelivery>
}

const SeguimientoDelivery: React.FC<Props> = ({onCerrar}) => {
    const [datos, setDatos] = useState<Informacion | undefined>(undefined)
    const {pedidoJson} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'pedidos/seguimientodelivery', ok: setDatos})
    }, [pedidoJson])

    if (datos === undefined) return <React.Fragment/>

    return (
        <Modal colorBorde='border-info'>
            <h4>Seguimiento de Delivery</h4>
            <h5>Repartidores</h5>
            <Tabla espacio>
                <thead>
                <tr>
                    <th style={{width: '25%'}} rowSpan={2} className='border-end'>Repartidor</th>
                    <th colSpan={2} className='border-end'>Abiertos</th>
                    <th colSpan={2} className='border-end'>Cerrados</th>
                    <th colSpan={2}>Total</th>
                </tr>
                <tr className='headerTabla'>
                    <th style={{width: '12.5%'}}>Cantidad</th>
                    <th style={{width: '12.5%'}} className='border-end'>Importe</th>
                    <th style={{width: '12.5%'}}>Cantidad</th>
                    <th style={{width: '12.5%'}} className='border-end'>Importe</th>
                    <th style={{width: '12.5%'}}>Cantidad</th>
                    <th style={{width: '12.5%'}}>Importe</th>
                </tr>
                </thead>
                <tbody>
                {datos.info_delivery.length ? datos.info_delivery.map((i, k) => (
                    <React.Fragment key={k}>
                        <tr>
                            <td className='border-end'>
                                {i.repartidor !== '' ? i.repartidor : (
                                    <span className='text-muted'>(Sin Repartidor)</span>
                                )}
                            </td>
                            {i.cant_pendientes > 0 || i.importe_pendientes > 0 ? (
                                <>
                                    <td className='text-end text-nowrap'>{i.cant_pendientes}</td>
                                    <td className='text-end text-nowrap border-end'>
                                        {formatoMoneda(i.importe_pendientes)}
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td className='text-end text-muted'>-</td>
                                    <td className='text-end text-muted border-end'>-</td>
                                </>
                            )}
                            {i.cant_cerrados > 0 || i.importe_cerrados > 0 ? (
                                <>
                                    <td className='text-end text-nowrap'>{i.cant_cerrados}</td>
                                    <td className='text-end text-nowrap border-end'>
                                        {formatoMoneda(i.importe_cerrados)}
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td className='text-end text-muted'>-</td>
                                    <td className='text-end text-muted border-end'>-</td>
                                </>
                            )}
                            {i.cant_total > 0 || i.importe_total > 0 ? (
                                <>
                                    <td className='text-end text-nowrap'>{i.cant_total}</td>
                                    <td className='text-end text-nowrap border-end'>
                                        {formatoMoneda(i.importe_total)}
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td className='text-end text-muted'>-</td>
                                    <td className='text-end text-muted'>-</td>
                                </>
                            )}
                        </tr>
                        {i.medios_de_pago.map((m, k) => (
                            <tr className='bg-light small' key={k}>
                                <td className='ps-3 border-end'>
                                    {m.medio_de_pago !== '' ? m.medio_de_pago : (
                                        <span className='text-muted'>(Otros medios de Pago)</span>
                                    )}
                                </td>
                                {m.cant_pendientes > 0 || m.importe_pendientes > 0 ? (
                                    <>
                                        <td className='text-end text-nowrap'>{m.cant_pendientes}</td>
                                        <td className='text-end text-nowrap border-end'>
                                            {formatoMoneda(m.importe_pendientes)}
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td className='text-end text-muted'>-</td>
                                        <td className='text-end text-muted border-end'>-</td>
                                    </>
                                )}
                                {m.cant_cerrados > 0 || m.importe_cerrados > 0 ? (
                                    <>
                                        <td className='text-end text-nowrap'>{m.cant_cerrados}</td>
                                        <td className='text-end text-nowrap border-end'>
                                            {formatoMoneda(m.importe_cerrados)}
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td className='text-end text-muted'>-</td>
                                        <td className='text-end text-muted border-end'>-</td>
                                    </>
                                )}
                                {m.cant_total > 0 || m.importe_total > 0 ? (
                                    <>
                                        <td className='text-end text-nowrap'>{m.cant_total}</td>
                                        <td className='text-end text-nowrap border-end'>
                                            {formatoMoneda(m.importe_total)}
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td className='text-end text-muted'>-</td>
                                        <td className='text-end text-muted'>-</td>
                                    </>
                                )}
                            </tr>
                        ))}
                    </React.Fragment>
                )) : (
                    <TrInfo colSpan={9} texto='Sin información para mostrar'/>
                )}
                </tbody>
            </Tabla>
            <CerrarModal onCerrar={onCerrar}/>
        </Modal>
    )
}

export default SeguimientoDelivery