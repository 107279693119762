import React from "react";

type TextoNDProps = {
    texto?: string,
    guion?: boolean
}

const TextoND: React.FC<TextoNDProps> = ({texto, guion}) => {
    if (!texto || texto === '') {
        return <span className='text-muted'>{guion ? '-' : '(N/D)'}</span>
    } else {
        return <>{texto}</>
    }
}

export default TextoND
