import React from "react";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import Boton from "./Boton";
import {setAlertaSistema} from "../redux/estadoReducer";

const AlertaAplicacion: React.FC = () => {
    const info = useAppSelector(state => state.estado.alerta_sistema)
    const dispatch = useAppDispatch()

    if (info === undefined) {
        return <React.Fragment/>
    } else {
        return (
            <div className='py-2 px-4 bg-warning text-dark'>
                <div className='row g-0'>
                    <div className='col'>
                        <div className='py-1 d-inline-block'>
                            <i className='fas fa-exclamation-triangle me-2 fa-beat-fade'/>
                            {info.mensaje}
                        </div>
                        <div className='d-inline-block'>
                            {info.botonActualizar && (
                                <Boton chico titulo='Actualizar' icono='fas fa-sync' className='btn-dark ms-3'
                                       onClick={() => window.location.reload()}/>
                            )}
                        </div>
                    </div>
                    {info.cerrable && (
                        <div className='col-auto'>
                            <div className='py-1'>
                                <i className='fas fa-times' style={{cursor: 'pointer'}}
                                   onClick={() => dispatch(setAlertaSistema(undefined))}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default AlertaAplicacion