import React from "react";
import {seccionesApp} from "../modulos";

type Props = {
    modulo: number
}

const VistaModulos: React.FC<Props> = ({modulo}) => {
    if (modulo === 0) {
        return <><i className='fas fa-compact-disc me-1 text-muted'/>Sistema</>
    } else {
        const mod = seccionesApp.filter(s => s.id === modulo)
        if (!mod.length) return <></>

        return <><i className={mod[0]?.icono + ' me-1 text-primary'}/>{mod[0]?.titulo}</>
    }
}

export default VistaModulos