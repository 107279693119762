import React, {useEffect, useState} from "react";
import Tarjeta from "../controles/Tarjeta";
import CheckEstado from "../controles/CheckEstado";
import ToolbarBtn from "../controles/ToolbarBtn";
import Boton from "../controles/Boton";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import InputTexto from "../controles/InputTexto";
import InputSwitch from "../controles/InputSwitch";
import Tabla from "../controles/Tabla";
import ControlPaginas, {paginado} from "../controles/ControlPaginas";
import {useGsBackend} from "../funcionesApi";

type Categoria = {
    id: number,
    descripcion: string,
    cant_productos: number,
    stock: boolean,
    elaborados: boolean,
    insumos: boolean,
    mantenimiento: boolean
}

type InfoCategorias = {
    categorias: Array<Categoria>
}

const AdminCategorias: React.FC = () => {
    const [datos, setDatos] = useState<InfoCategorias>({categorias: []})
    const [pagina, setPagina] = useState<number>(0)
    const [editando, setEditando] = useState<number | undefined>(undefined)
    const [editDescripcion, setEditDescripcion] = useState<string>('')
    const [editStock, setEditStock] = useState<boolean>(false)
    const [editElaborados, setEditElaborados] = useState<boolean>(false)
    const [editInsumos, setEditInsumos] = useState<boolean>(false)
    const [editMantenimiento, setEditMantenimiento] = useState<boolean>(false)
    const [validaDescripcion, setValidaDescripcion] = useState<boolean>(false)
    const [permitirEliminar, setPermitirEliminar] = useState<boolean>(false)

    const {pedidoJson, actualizar, guardando} = useGsBackend()

    useEffect(() => {
        setPagina(1)
        pedidoJson({url: 'admin/categorias', ok: setDatos})
    }, [pedidoJson])

    const nuevaHandler = (): void => {
        setValidaDescripcion(true)
        setEditDescripcion('')
        setEditStock(false)
        setEditElaborados(false)
        setEditInsumos(false)
        setEditMantenimiento(false)
        setEditando(0)
    }

    const editarHandler = (c: Categoria): void => {
        setValidaDescripcion(true)
        setEditDescripcion(c.descripcion)
        setEditStock(c.stock)
        setEditElaborados(c.elaborados)
        setEditInsumos(c.insumos)
        setEditMantenimiento(c.mantenimiento)
        setPermitirEliminar(c.cant_productos === 0)
        setEditando(c.id)
    }

    const guardarHandler = async (eliminar: boolean): Promise<void> => {
        if (editando === undefined) return
        if (!eliminar) {
            setValidaDescripcion(editDescripcion !== '')
            if (editDescripcion === '') return
        }

        const fd = new FormData()
        fd.append('accion', editando === 0 ? 'nuevo' : eliminar ? 'eliminar' : 'editar')
        fd.append('id', editando.toString())
        fd.append('descripcion', editDescripcion)
        fd.append('stock', editStock ? '1' : '0')
        fd.append('elaborados', editElaborados ? '1' : '0')
        fd.append('insumos', editInsumos ? '1' : '0')
        fd.append('mantenimiento', editMantenimiento ? '1' : '0')

        pedidoJson({
            url: 'admin/categorias', method: 'post', body: fd,
            ok: () => {
                setEditando(undefined)
                actualizar()
            }
        })
    }

    return (
        <Tarjeta>
            <ToolbarBtn margen>
                <Boton toolbar titulo='Nueva categoría' className='btn-light' icono='fas fa-plus'
                       onClick={nuevaHandler}/>
            </ToolbarBtn>
            <Tabla>
                <thead>
                <tr>
                    <th colSpan={6}>
                        <ControlPaginas registros={datos.categorias.length} pagina={pagina} onChange={setPagina}/>
                    </th>
                </tr>
                <tr>
                    <th colSpan={2}>Categorías de productos</th>
                    <th colSpan={4}>Disponibilidad de las categorías</th>
                </tr>
                <tr className='headerTabla'>
                    <th style={{width: '25%'}}>Descripción</th>
                    <th style={{width: '15%'}}>Cantidad de Productos</th>
                    <th style={{width: '15%'}}>Stock</th>
                    <th style={{width: '15%'}}>Elaborados</th>
                    <th style={{width: '15%'}}>Insumos</th>
                    <th style={{width: '15%'}}>Mantenimiento</th>
                </tr>
                </thead>
                <tbody>
                {datos.categorias.length ? paginado(datos.categorias, pagina).map((c, k) => (
                    <tr key={k} className='trhover' onClick={() => editarHandler(c)}>
                        <td>{c.descripcion}</td>
                        <td>{c.cant_productos}</td>
                        <td><CheckEstado estado={c.stock} textosino times/></td>
                        <td><CheckEstado estado={c.elaborados} textosino times/></td>
                        <td><CheckEstado estado={c.insumos} textosino times/></td>
                        <td><CheckEstado estado={c.mantenimiento} textosino times/></td>
                    </tr>
                )) : (
                    <tr>
                        <td colSpan={6}>
                            <i className='fas fa-info-circle me-2 text-info'/>
                            Sin resultados para mostrar
                        </td>
                    </tr>
                )}
                </tbody>
            </Tabla>
            {editando !== undefined && (
                <Modal>
                    <h4>{editando === 0 ? 'Nueva categoría' : 'Editar categoría'}</h4>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Descripción</div>
                        <div className='col-md-9'>
                            <InputTexto value={editDescripcion} onChange={setEditDescripcion}
                                        invalido={!validaDescripcion} autoFocus/>
                        </div>
                    </div>
                    <h5>Disponibilidad de la categoría</h5>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Stock</div>
                        <div className='col-md-9 pt-2'>
                            <InputSwitch checked={editStock} onChange={setEditStock}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Elaborados</div>
                        <div className='col-md-9 pt-2'>
                            <InputSwitch checked={editElaborados} onChange={setEditElaborados}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Insumos</div>
                        <div className='col-md-9 pt-2'>
                            <InputSwitch checked={editInsumos} onChange={setEditInsumos}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Mantenimiento</div>
                        <div className='col-md-9 pt-2'>
                            <InputSwitch checked={editMantenimiento} onChange={setEditMantenimiento}/>
                        </div>
                    </div>
                    <ModalBotones onCancelar={() => setEditando(undefined)} guardando={guardando}
                                  onEliminar={() => guardarHandler(true)}
                                  onGuardar={() => guardarHandler(false)}
                                  editando={editando !== 0} soloEditar={editando !== 0 && !permitirEliminar}/>
                </Modal>
            )}
        </Tarjeta>
    )
}

export default AdminCategorias