import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface InfoAlertaSistema {
    mensaje: string,
    botonActualizar?: boolean,
    cerrable: boolean
}

export interface InfoNotificacion {
    mensaje: string,
    tipo: 'success' | 'error' | 'warning' | 'info'
}

interface EstadoState {
    cargando: boolean,
    error_sistema: string | undefined,
    alerta_sistema: InfoAlertaSistema | undefined,
    notificacion: InfoNotificacion | undefined
    modo_oscuro: boolean
}

export const estadoSlice = createSlice({
    name: 'estado',
    initialState: {
        cargando: false,
        error_sistema: undefined,
        alerta_sistema: undefined,
        notificacion: undefined,
        modo_oscuro: false
    } as EstadoState,
    reducers: {
        setCargando: (state, action: PayloadAction<boolean>) => {
            state.cargando = action.payload
        },
        setErrorSistema: (state, action: PayloadAction<string | undefined>) => {
            state.error_sistema = action.payload
        },
        setAlertaSistema: (state, action: PayloadAction<InfoAlertaSistema | undefined>) => {
            state.alerta_sistema = action.payload
        },
        setNotificacion: (state, action: PayloadAction<InfoNotificacion | undefined>) => {
            state.notificacion = action.payload
        },
        setModoOscuro: (state, action: PayloadAction<boolean>) => {
            state.modo_oscuro = action.payload
        }
    }
})

export const {setCargando, setErrorSistema, setAlertaSistema, setNotificacion, setModoOscuro} = estadoSlice.actions

export default estadoSlice.reducer
