import React from "react";
import Tarjeta from "../controles/Tarjeta";

export interface TotalIngresosGastos {
    ingresos: number,
    ingresos_fmt: string,
    gastos: number,
    gastos_fmt: string,
    saldo: number,
    saldo_fmt: string
}

type Props = {
    datos: TotalIngresosGastos
}

const EstadisticasTotalIngresosGastos: React.FC<Props> = ({datos}) => {
    return (
        <Tarjeta bordeArriba espacio>
            <h5>Total de Ingresos y Gastos</h5>
            <hr/>
            <div className='row g-0 text-nowrap'>
                <div className='col-md-4 text-center'>
                    <div style={{fontSize: '1.15em'}}>Ingresos</div>
                    <div style={{fontSize: '1.35em'}} className='text-success'>
                        {datos.ingresos_fmt}
                    </div>
                </div>
                <div className='col-md-4 text-center'>
                    <div style={{fontSize: '1.15em'}}>Egresos</div>
                    <div style={{fontSize: '1.35em'}} className='text-danger'>
                        {datos.gastos_fmt}
                    </div>
                </div>
                <div className='col-md-4 text-center'>
                    <div style={{fontSize: '1.15em'}}>Saldo</div>
                    <div style={{fontSize: '1.35em'}} className='fw-bold'>
                        {datos.saldo_fmt}
                    </div>
                </div>
            </div>
        </Tarjeta>
    )
}

export default EstadisticasTotalIngresosGastos