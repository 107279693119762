import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import BarraTarjeta from "../controles/BarraTarjeta";
import Boton from "../controles/Boton";
import TituloPagina from "../controles/TituloPagina";
import Tarjeta from "../controles/Tarjeta";
import {nivelAcceso} from "../autorizacion";
import TablaMovimientos from "./TablaMovimientos";
import TablaDatosCierre, {DatosCierre} from "./TablaDatosCierre";
import EstadisticasCierre from "./EstadisticasCierre";
import {NivelesAcceso} from "../redux/authReducer";
import ToolbarBtn from "../controles/ToolbarBtn";
import {funcionDescarga} from "../funcionDescarga";
import {useGsBackend} from "../funcionesApi";

type Movimiento = {
    tipo_movimiento: number,
    origen_movimiento: number,
    id_detalle: number,
    fechayhora: string,
    descripcion: string,
    importe: number,
    importe_fmt: string,
    mediodepago: string,
    saldo_parcial: number,
    saldo_parcial_fmt: string,
    usuario: string
}

type PantallaConsultaCierre = 'detalle' | 'estadisticas'

type InfoCierre = {
    info_cierre: DatosCierre,
    movimientos: Array<Movimiento>
}

const ConsultarCierre: React.FC = () => {
    const [params] = useSearchParams()
    const [datos, setDatos] = useState<InfoCierre>({
        info_cierre: {
            nro_cierre: 0, fechayhora: '', saldo_fmt: '', movimientos_cantidad: 0, movimientos_ingresos_fmt: '',
            movimientos_egresos_fmt: '', movimientos_saldo_fmt: '', comentarios: '', usuario: ''
        }, movimientos: []
    })
    const [pantalla, setPantalla] = useState<PantallaConsultaCierre>('detalle')
    const [mailEnviado, setMailEnviado] = useState<boolean>(false)
    const navigate = useNavigate()
    const accesoEstadisticas = nivelAcceso('estadisticas')
    const {pedidoJson, pedidoBlob} = useGsBackend()

    useEffect(() => {
        const idCierre = params.get("id") || '0'
        pedidoJson({
            url: `caja/consultarcierre?id=${idCierre}`, ok: setDatos,
            err: () => navigate('/caja')
        })
    }, [navigate, params, pedidoJson])

    const mailHandler = (): void => {
        const fd = new FormData()
        fd.append('id', params.get('id') || '0')

        setMailEnviado(true) // Que no pidan dos veces
        pedidoJson({url: 'caja/mailcierre', method: 'post', body: fd})
    }

    const excelHandler = async (): Promise<void> => {
        const idCierre = params.get("id") || '0'

        pedidoBlob({
            url: `caja/excelcierre?id=${idCierre}`,
            ok: res => funcionDescarga(res, 'movimientos.xlsx')
        })
    }

    const vistaPantalla = (): JSX.Element => {
        switch (pantalla) {
            case 'detalle':
                return (
                    <>
                        <ToolbarBtn margen>
                            {mailEnviado ? (
                                <Boton toolbar titulo='E-Mail solicitado correctamente' icono='fas fa-check'
                                       disabled/>
                            ) : (
                                <Boton toolbar titulo='Enviar por E-Mail' icono='fas fa-envelope'
                                       onClick={mailHandler}/>
                            )}
                            <Boton toolbar titulo='Descargar Excel' icono='fas fa-file-excel'
                                   onClick={excelHandler}/>
                        </ToolbarBtn>
                        <TablaMovimientos movimientos={datos.movimientos}/>
                    </>
                )
            case 'estadisticas':
                return <EstadisticasCierre idCierre={parseInt(params.get('id') || '0')}/>
        }
    }

    return (
        <>
            <TituloPagina icono='fas fa-history' titulo='Consultar Cierre de Caja'/>
            <BarraTarjeta>
                <Boton toolbar titulo='Volver' icono='fas fa-chevron-left' className='btn-light'
                       onClick={() => navigate('../')}/>
                <Boton toolbar titulo='Detalle de Movimientos' icono='fas fa-list'
                       className={pantalla === 'detalle' ? 'btn-primary' : 'btn-light'}
                       onClick={() => setPantalla('detalle')}/>
                {accesoEstadisticas >= NivelesAcceso.NivelConsulta && (
                    <Boton toolbar titulo='Estadísticas' icono='fas fa-chart-pie'
                           className={pantalla === 'estadisticas' ? 'btn-primary' : 'btn-light'}
                           onClick={() => setPantalla('estadisticas')}/>
                )}
            </BarraTarjeta>
            <Tarjeta>
                <TablaDatosCierre datos={datos.info_cierre}/>
                {vistaPantalla()}
            </Tarjeta>
        </>
    )
}

export default ConsultarCierre