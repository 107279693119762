import React, {useEffect, useState} from "react";
import TituloPagina from "../controles/TituloPagina";
import BarraTarjeta from "../controles/BarraTarjeta";
import Boton from "../controles/Boton";
import {useNavigate, useSearchParams} from "react-router-dom";
import Tarjeta from "../controles/Tarjeta";
import TrInfo from "../controles/TrInfo";
import BotonIcono from "../controles/BotonIcono";
import ProveedoresHistorialDetalle from "../proveedores/ProveedoresHistorialDetalle";
import OrigenMovimiento from "./OrigenMovimiento";
import ConsultarPedido from "../pedidos/ConsultarPedido";
import TextoND from "../controles/TextoND";
import Tabla from "../controles/Tabla";
import {useGsBackend} from "../funcionesApi";

type Movimiento = {
    tipo: number,
    origen: number,
    proveedor: string,
    id_proveedor_compra: number,
    pedido: string,
    id_pedidocerrado: number,
    cantidad_movimiento: string,
    cantidad_parcial: string,
    importe: string,
    comentarios: string,
    fecha_y_hora: string,
    usuario: string
}

type InfoMovimientos = {
    producto: string,
    movimientos: Array<Movimiento>
}

const StockMovimientosProducto: React.FC = () => {
    const [params] = useSearchParams()
    const [datos, setDatos] = useState<InfoMovimientos>({producto: '', movimientos: []})
    const [detalleCompra, setDetalleCompra] = useState<number | undefined>(undefined)
    const [detallePedido, setDetallePedido] = useState<number | undefined>(undefined)
    const navigate = useNavigate()
    const idProducto = parseInt(params.get('id') || '0') || 0

    const {pedidoJson} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: `stock/movimientos/producto?id=${idProducto}`, ok: setDatos})
    }, [pedidoJson, idProducto])

    return (
        <>
            <TituloPagina icono='fas fa-magnifying-glass-plus' titulo={'Movimientos - ' + datos.producto}/>
            <BarraTarjeta>
                <Boton toolbar titulo='Volver' icono='fas fa-chevron-left' className='btn-light'
                       onClick={() => navigate('../')}/>
            </BarraTarjeta>
            <Tarjeta>
                <Tabla>
                    <thead>
                    <tr className='headerTabla'>
                        <th>Tipo</th>
                        <th>Origen</th>
                        <th>Fecha y Hora</th>
                        <th>Detalle</th>
                        <th>Cantidad</th>
                        <th>Stock Parcial</th>
                        <th>Importe</th>
                        <th>Comentarios</th>
                        <th>Usuario</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.movimientos.length ? datos.movimientos.map((m, k) => (
                        <tr key={k}>
                            {m.tipo === 1 ? (
                                <td className='text-success'><i className='fas fa-sign-in me-1'/>Ingreso</td>
                            ) : (
                                <td className='text-danger'><i className='fas fa-sign-out me-1'/>Baja</td>
                            )}
                            <td><OrigenMovimiento origen={m.origen}/></td>
                            <td>{m.fecha_y_hora}</td>
                            <td className='text-nowrap'>
                                {m.id_proveedor_compra !== 0 ? (
                                    <div className='row g-2'>
                                        <div className='col-auto'>
                                            <BotonIcono icono='fas fa-magnifying-glass-plus'
                                                        onClick={() => setDetalleCompra(m.id_proveedor_compra)}/>
                                        </div>
                                        <div className='col'>
                                            {m.proveedor}<br/><span className='small text-muted'>Proveedor</span>
                                        </div>
                                    </div>
                                ) : m.id_pedidocerrado !== 0 ? (
                                    <div className='row g-2'>
                                        <div className='col-auto'>
                                            <BotonIcono icono='fas fa-magnifying-glass-plus'
                                                        onClick={() => setDetallePedido(m.id_pedidocerrado)}/>
                                        </div>
                                        <div className='col'>
                                            {m.pedido}<br/><span className='small text-muted'>Pedido</span>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </td>
                            <td className='text-end border-end'>{m.cantidad_movimiento}</td>
                            <td className='text-end border-end'>{m.cantidad_parcial}</td>
                            <td className='text-end border-end'><TextoND texto={m.importe}/></td>
                            <td>{m.comentarios}</td>
                            <td>{m.usuario}</td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={9} texto='No hay movimientos para mostrar'/>
                    )}
                    </tbody>
                </Tabla>
            </Tarjeta>
            {detalleCompra !== undefined && (
                <ProveedoresHistorialDetalle idCompra={detalleCompra}
                                             onCerrar={() => setDetalleCompra(undefined)}/>
            )}
            {detallePedido !== undefined && (
                <ConsultarPedido idPedido={detallePedido}
                                 onCerrar={() => setDetallePedido(undefined)}/>
            )}
        </>
    )
}

export default StockMovimientosProducto