import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import CerrarModal from "../controles/CerrarModal";
import TextoND from "../controles/TextoND";
import {useGsBackend} from "../funcionesApi";

type DatosPedido = {
    tipo: number,
    nombre: string,
    telefono: string,
    direccion: string,
    repartidor: string,
    cierre: string,
    observaciones: string,
    cant_cubierto: number,
    valor_cubierto: string,
    valor_envio: string,
    importe: string,
    descuento: string,
    total_sin_descuento: string
}

type ItemPedido = {
    descripcion: string,
    cantidad: number,
    precio_unitario: string,
    importe_total: string
}

type InfoPedido = {
    datos_pedido: DatosPedido,
    items_pedido: Array<ItemPedido>
}

type Props = {
    idPedido: number,
    onCerrar: () => void
}

const ConsultarPedido: React.FC<Props> = ({idPedido, onCerrar}) => {
    const [datos, setDatos] = useState<InfoPedido | undefined>(undefined)
    const {pedidoJson} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: `pedidos/consultar?id=${idPedido}`, ok: setDatos})
    }, [pedidoJson, idPedido])

    if (datos === undefined) return <></>

    return (
        <Modal>
            <h4>Detalle del Pedido</h4>
            <div className='row mb-2'>
                <div className='col-md-3 fw-bold'>Tipo</div>
                <div className='col-md-9'>
                    {datos.datos_pedido.tipo === 1 ? (
                        <div className='text-primary fw-bold'>
                            <i className='fas fa-house me-2'/>Local
                        </div>
                    ) : (
                        <div className='text-info fw-bold'>
                            <i className='fas fa-motorcycle me-2'/>Delivery
                        </div>
                    )}
                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-md-3 fw-bold'>{datos.datos_pedido.tipo === 1 ? 'Descripción' : 'Nombre'}</div>
                <div className='col-md-9'><TextoND texto={datos.datos_pedido.nombre}/></div>
            </div>
            {datos.datos_pedido.tipo === 2 && (
                <>
                    <div className='row mb-2'>
                        <div className='col-md-3 fw-bold'>Teléfono</div>
                        <div className='col-md-9'><TextoND texto={datos.datos_pedido.telefono}/></div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 fw-bold'>Dirección</div>
                        <div className='col-md-9'><TextoND texto={datos.datos_pedido.direccion}/></div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 fw-bold'>Repartidor</div>
                        <div className='col-md-9'><TextoND texto={datos.datos_pedido.repartidor}/></div>
                    </div>
                </>
            )}
            <div className='row mb-2'>
                <div className='col-md-3 fw-bold'>Cierre</div>
                <div className='col-md-9'>{datos.datos_pedido.cierre}</div>
            </div>
            <table className='table table-sm border small mb-2'>
                <thead>
                <tr>
                    <th>Cant.</th>
                    <th>Descripción</th>
                    <th>Precio Unitario</th>
                    <th>Precio Total</th>
                </tr>
                </thead>
                <tbody>
                {datos.items_pedido.map((i, k) => (
                    <tr key={k}>
                        <td className='text-end'>{i.cantidad}</td>
                        <td>{i.descripcion}</td>
                        <td className='text-end'>{i.precio_unitario}</td>
                        <td className='text-end'>{i.importe_total}</td>
                    </tr>
                ))}
                {datos.datos_pedido.cant_cubierto > 0 && (
                    <tr>
                        <td className='text-end'>{datos.datos_pedido.cant_cubierto}</td>
                        <td>Cubierto</td>
                        <td className='text-end'>&nbsp;</td>
                        <td className='text-end'>{datos.datos_pedido.valor_cubierto}</td>
                    </tr>
                )}
                {datos.datos_pedido.valor_envio !== '' && (
                    <tr>
                        <td className='text-end'>
                            <i className='fas fa-motorcycle text-muted'/>
                        </td>
                        <td>Envío</td>
                        <td className='text-end'>&nbsp;</td>
                        <td className='text-end'>{datos.datos_pedido.valor_envio}</td>
                    </tr>
                )}
                </tbody>
            </table>
            {datos.datos_pedido.descuento !== '' && (
                <div className='row mb-2'>
                    <div className='col-md-3 fw-bold'>Descuento</div>
                    <div className='col-md-9'>{datos.datos_pedido.descuento}</div>
                </div>
            )}
            <div className='row mb-2'>
                <div className='col-md-3 fw-bold'>Importe Total</div>
                {datos.datos_pedido.descuento === '' ? (
                    <div className='col-md-9 fw-bold'>{datos.datos_pedido.importe}</div>
                ) : (
                    <div className='col-md-9 fw-bold'>
                        {datos.datos_pedido.importe}
                        <span className='text-decoration-line-through text-muted ms-2 fw-normal'>
                            {datos.datos_pedido.total_sin_descuento}
                        </span>
                    </div>
                )}
            </div>
            {datos.datos_pedido.observaciones !== '' && (
                <div className='row mb-2'>
                    <div className='col-md-3 fw-bold'>Observaciones</div>
                    <div className='col-md-9'>{datos.datos_pedido.observaciones}</div>
                </div>
            )}
            <CerrarModal onCerrar={onCerrar}/>
        </Modal>
    )
}

export default ConsultarPedido