export interface Config {
    api: string,
    basename: string,
    link_empresa: string,
    link_sistema: string,
    link_privacidad: string
}

const config: Config = require('./config.json')

export default config
