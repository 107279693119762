import React from "react";
import config from "../config";

type Props = {
    chico?: boolean,
    grande?: boolean,
    blanco?: boolean,
    tamanioCustom?: string
}

const LogoSistemas: React.FC<Props> = (
    {chico, grande, blanco, tamanioCustom}
) => {
    const tamanio = tamanioCustom ? tamanioCustom : chico ? '0.9em' : grande ? '1.25em' : '1em'
    const azul = blanco ? '#ffffff' : '#2c3e50'
    const gris = blanco ? '#ffffff' : '#95a5a6'

    return (
        <div className='fuentetitulo' style={{marginTop: '-8px', fontSize: tamanio, cursor: 'pointer'}}
             onClick={() => window.open(config.link_empresa, '_blank')}>
            <div style={{color: azul}}>
                <div className='d-inline-block' style={{fontSize: '2em'}}>glavic</div>
                <div className='d-inline-block' style={{fontSize: '1em', verticalAlign: 'top', paddingTop: '0.5em'}}>
                    &#174;
                </div>
            </div>
            <div style={{
                color: gris,
                fontSize: '0.95em',
                position: 'relative',
                marginTop: '-0.85em',
                marginLeft: '1.6em'
            }}>sistemas
            </div>
        </div>
    )
}

export default LogoSistemas