import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export enum ModoServidor {
    Desa,
    Test,
    Prod
}

export interface ModoState {
    modoServidor?: ModoServidor,
    codigo_vf?: string
}

export const modoSlice = createSlice({
    name: 'estado',
    initialState: {
        modo: undefined,
        codigo_vf: undefined
    } as ModoState,
    reducers: {
        setModo: (state, action: PayloadAction<ModoState>) => {
            state.modoServidor = action.payload.modoServidor
            state.codigo_vf = action.payload.codigo_vf
        }
    }
})

export const {setModo} = modoSlice.actions

export default modoSlice.reducer
