import React, {useEffect, useImperativeHandle, useRef, useState} from "react";

type Props = {
    onVerificar: (arg: number) => void,
    disabled?: boolean
}

export interface CodigoVerificacionElement {
    limpiar: () => void
}

const CodigoVerificacion = React.forwardRef<CodigoVerificacionElement, Props>((
    {onVerificar, disabled}, ref
) => {
    const cantidad = 6
    const [vals, setVals] = useState<Array<string>>([])
    const campoRef = useRef<Array<HTMLInputElement>>([])
    const focusRef = useRef<number | null>(null)

    useImperativeHandle(ref, () => ({
        limpiar: () => {
            setVals([])
            campoRef.current[0].focus()
        }
    }))

    const inputHandler = (i: number, val: string) => {
        if (val !== '') {
            const nVal = val.slice(-1)
            if (nVal.match(/^\d$/)) {
                setVals(prev => {
                    const nvo = {...prev}
                    nvo[i] = nVal
                    return nvo
                })
                if (i < cantidad - 1) {
                    campoRef.current[i + 1].focus()
                }
            }
        }
    }

    const keyDownHandler = (i: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace') {
            setVals(prev => {
                const nvo = {...prev}
                nvo[i] = ''
                return nvo
            })
            if (i > 0) {
                campoRef.current[i - 1].focus()
            }
        }
    }

    const focusHandler = (i: number, e: React.FocusEvent<HTMLInputElement>): void => {
        focusRef.current = i
        e.target.select()
    }

    useEffect(() => {
        const val = Object.keys(vals).map(k => vals[parseInt(k)]).join('')
        if (val.length === cantidad && focusRef.current === cantidad - 1) {
            campoRef.current[0].focus()
            onVerificar(parseInt(val))
        }
    }, [onVerificar, vals])

    return (
        <div className='mb-2'>
            {Array(cantidad).fill(0).map((n, i) => (
                <input type='text' className='form-control px-0 text-center form-control-lg d-inline me-2'
                       style={{width: '2em'}} onChange={e => inputHandler(i, e.target.value)} key={i}
                       onKeyDown={e => keyDownHandler(i, e)} autoFocus={i === 0}
                       onFocus={e => focusHandler(i, e)} value={vals[i] || ''} disabled={disabled}
                       ref={e => campoRef.current[i] = e as HTMLInputElement}/>
            ))}
        </div>
    )
})

export default CodigoVerificacion