import React, {useCallback, useEffect, useState} from "react";
import TituloPagina from "../controles/TituloPagina";
import BarraTarjeta from "../controles/BarraTarjeta";
import Boton from "../controles/Boton";
import {estilosApp} from "../estilos";
import {useAppSelector} from "../redux/hooks";
import {useNavigate} from "react-router-dom";
import {nivelAcceso} from "../autorizacion";
import {NivelesAcceso} from "../redux/authReducer";
import Tarjeta from "../controles/Tarjeta";
import Tabla from "../controles/Tabla";
import TrInfo from "../controles/TrInfo";
import ControlPaginas from "../controles/ControlPaginas";
import {useGsBackend} from "../funcionesApi";

type Barril = {
    proveedor: string,
    descripcion: string,
    cantidad: number,
    restantes: number,
    importe: string,
    comentarios: string,
    fecha: string,
    usuario: string
}

type InfoBarriles = {
    barriles: Array<Barril>
}

const CerveceriaBarriles: React.FC = () => {
    const [agregar, setAgregar] = useState<boolean>(false)
    const [datos, setDatos] = useState<InfoBarriles>({barriles: []})
    const [muestra, setMuestra] = useState<Array<Barril>>([])
    const [pagina, setPagina] = useState<number>(1)
    const st = estilosApp(useAppSelector(state => state.estado.modo_oscuro))
    const navigate = useNavigate()
    const acceso = nivelAcceso('cerveceria')

    const {pedidoJson} = useGsBackend()
    useEffect(() => {
        pedidoJson({url: 'cerveceria/barriles', ok: setDatos})
    }, [pedidoJson])

    const filtrar = useCallback(() => {
        if (datos === undefined) return

        setPagina(1)
        setMuestra(datos.barriles)
    }, [datos])

    useEffect(() => {
        filtrar()
    }, [filtrar])

    return (
        <>
            <TituloPagina icono='fas fa-beer' titulo='Stock de Barriles'/>
            <BarraTarjeta>
                <Boton toolbar titulo='Volver' icono='fas fa-chevron-left' className={st.boton_claro}
                       onClick={() => navigate('../')}/>
                {acceso >= NivelesAcceso.NivelHabilitado && (
                    <Boton toolbar titulo='Agregar Barril' icono='fas fa-plus' className={st.boton_claro}
                           onClick={() => setAgregar(true)}/>
                )}
            </BarraTarjeta>
            <Tarjeta>
                <Tabla>
                    <thead>
                    <tr>
                        <th colSpan={7}>
                            <ControlPaginas registros={muestra.length} pagina={pagina} onChange={setPagina}/>
                        </th>
                    </tr>
                    <tr className='headerTabla'>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Restantes</th>
                        <th>Importe</th>
                        <th>Comentarios</th>
                        <th>Fecha</th>
                        <th>Usuario</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.barriles.length ? datos.barriles.map((b, k) => (
                        <tr key={k}>
                            <td>{b.descripcion}</td>
                            <td className='text-end'>{b.cantidad}</td>
                            <td className='text-end'>{b.restantes}</td>
                            <td className='text-end'>{b.importe}</td>
                            <td>{b.comentarios}</td>
                            <td>{b.fecha}</td>
                            <td>{b.usuario}</td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={7} texto='No hay barriles para mostrar'/>
                    )}
                    </tbody>
                </Tabla>
            </Tarjeta>
            {agregar && (
                <React.Fragment/>
            )}
        </>
    )
}

export default CerveceriaBarriles