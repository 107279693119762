import React, {useState} from "react";
import {MovimientoCaja} from "./Caja";
import TextoND from "../controles/TextoND";
import ConsultarPedido from "../pedidos/ConsultarPedido";
import ProveedoresHistorialDetalle from "../proveedores/ProveedoresHistorialDetalle";
import VistaModulos from "../controles/VistaModulos";
import Tabla from "../controles/Tabla";
import ControlPaginas, {paginado} from "../controles/ControlPaginas";

type Props = {
    movimientos: Array<MovimientoCaja>
}

export const vistaTipo = (tipo: number): React.ReactNode => {
    switch (tipo) {
        case 1:
            return <><i className='fas fa-circle-right me-1 text-success'/>Ingreso</>
        case 2:
            return <><i className='fas fa-circle-left me-1 text-danger'/>Egreso</>
        case 3:
            return <><i className='fas fa-sync me-1 text-info'/>Actualización</>
        case 4:
            return <><i className='fas fa-cash-register me-1 text-muted'/>Saldo Inicial</>
        default:
            return <></>
    }
}

const TablaMovimientos: React.FC<Props> = ({movimientos}) => {
    const [consultaPedido, setConsultaPedido] = useState<number | undefined>(undefined)
    const [consultaCompra, setConsultaCompra] = useState<number | undefined>(undefined)
    const [pagina, setPagina] = useState<number>(1)

    return (
        <>
            <Tabla>
                <thead>
                <tr>
                    <th colSpan={8}>Detalle de Movimientos</th>
                </tr>
                <tr>
                    <th colSpan={8}>
                        <ControlPaginas registros={movimientos.length} pagina={pagina} onChange={setPagina}/>
                    </th>
                </tr>
                <tr className='headerTabla'>
                    <th>Tipo</th>
                    <th>Origen</th>
                    <th>Fecha y Hora</th>
                    <th>Descripción</th>
                    <th>Medio de Pago</th>
                    <th>Importe</th>
                    <th>Saldo Parcial</th>
                    <th>Usuario</th>
                </tr>
                </thead>
                <tbody>
                {movimientos.length ? paginado(movimientos, pagina).map((m, k) => (
                    <tr key={k} className={m.id_detalle !== 0 ? 'trhover' : undefined}
                        onClick={() => {
                            if (m.origen_movimiento === 1) {
                                setConsultaPedido(m.id_detalle)
                            } else if (m.origen_movimiento === 4) {
                                setConsultaCompra(m.id_detalle)
                            }
                        }}>
                        <td>{vistaTipo(m.tipo_movimiento)}</td>
                        <td><VistaModulos modulo={m.origen_movimiento}/></td>
                        <td>{m.fechayhora}</td>
                        <td>{m.descripcion}</td>
                        <td><TextoND texto={m.mediodepago}/></td>
                        <td className={'text-end fw-bold ' +
                            (m.tipo_movimiento === 3 ? 'text-info' :
                                m.tipo_movimiento === 2 ? 'text-danger' :
                                    m.tipo_movimiento === 1 ? 'text-success' : '')}>
                            {m.importe_fmt}
                        </td>
                        <td className='text-end'>{m.saldo_parcial_fmt}</td>
                        <td>{m.usuario}</td>
                    </tr>
                )) : (
                    <tr>
                        <td colSpan={8}>
                            <i className='fas fa-info-circle me-2 text-info'/>
                            Sin datos para mostrar
                        </td>
                    </tr>
                )}
                </tbody>
            </Tabla>
            {consultaPedido !== undefined && (
                <ConsultarPedido idPedido={consultaPedido}
                                 onCerrar={() => setConsultaPedido(undefined)}/>
            )}
            {consultaCompra !== undefined && (
                <ProveedoresHistorialDetalle idCompra={consultaCompra}
                                             onCerrar={() => setConsultaCompra(undefined)}/>
            )}
        </>
    )
}

export default TablaMovimientos