import React, {useCallback, useEffect, useState} from "react";
import Boton from "../controles/Boton";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import CheckEstado from "../controles/CheckEstado";
import InputTexto from "../controles/InputTexto";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import ProveedoresCargarCompra from "./ProveedoresCargarCompra";
import {useNavigate} from "react-router-dom";
import TrInfo from "../controles/TrInfo";
import BarraTarjeta from "../controles/BarraTarjeta";
import Tarjeta from "../controles/Tarjeta";
import TituloPagina from "../controles/TituloPagina";
import Tabla from "../controles/Tabla";
import ControlPaginas, {paginado} from "../controles/ControlPaginas";
import {useGsBackend} from "../funcionesApi";

export interface Proveedor {
    id: number,
    razon_social: string,
    direccion: string,
    contacto: string,
    email: string,
    telefono: string,
    observaciones: string,
    habilitado: boolean,
    eliminable: boolean
}

type InfoProveedores = {
    proveedores: Array<Proveedor>
}

type ValidacionEdicion = {
    razon_social: boolean
}

type EdicionProveedor = {
    razon_social: string,
    direccion: string,
    contacto: string,
    email: string,
    telefono: string,
    observaciones: string,
    habilitado: number,
    validacion: ValidacionEdicion
}

const Proveedores: React.FC = () => {
    const validacionOk: ValidacionEdicion = {razon_social: true}
    const edicionInicial: EdicionProveedor =
        {
            razon_social: '', direccion: '', contacto: '', email: '', telefono: '', observaciones: '',
            habilitado: 1, validacion: validacionOk
        }
    const [datos, setDatos] = useState<InfoProveedores>({proveedores: []})
    const [muestra, setMuestra] = useState<Array<Proveedor>>([])
    const [pagina, setPagina] = useState<number>(1)
    const [filtroRazonSocial, setFiltroRazonSocial] = useState<string>('')
    const [editando, setEditando] = useState<number | undefined>(undefined)
    const [edicion, setEdicion] = useState<EdicionProveedor>(edicionInicial)
    const [eliminable, setEliminable] = useState<boolean>(false)
    const [cargarOrden, setCargarOrden] = useState<Proveedor | undefined>(undefined)
    const [validacion, setValidacion] = useState<ValidacionEdicion>(validacionOk)
    const navigate = useNavigate()

    const opcionesHabilitado = [
        {id: 1, descripcion: 'Sí'},
        {id: 2, descripcion: 'No'}
    ] as Array<OpcionSelect>

    const {pedidoJson, guardando, actualizar} = useGsBackend()
    useEffect(() => {
        pedidoJson({url: 'proveedores', ok: setDatos})
    }, [pedidoJson])

    const filtrar = useCallback(() => {
        if (datos === undefined) return

        setPagina(1)
        setMuestra(datos.proveedores
            .filter(p => filtroRazonSocial === '' ? true :
                (p.razon_social.toLocaleLowerCase().includes(filtroRazonSocial.toLocaleLowerCase()))))
    }, [datos, filtroRazonSocial])

    useEffect(() => {
        filtrar()
    }, [filtrar])

    const editarHandler = (id: number | undefined): void => {
        if (id === undefined) {
            setEditando(undefined)
        } else if (id === 0) {
            setEdicion(edicionInicial)
            setEditando(id)
        } else if (id > 0) {
            const item = datos?.proveedores.filter(p => p.id === id)[0]
            if (item === undefined) return
            setEdicion({
                razon_social: item.razon_social, direccion: item.direccion, contacto: item.contacto,
                email: item.email, telefono: item.telefono, observaciones: item.observaciones,
                habilitado: item.habilitado ? 1 : 2, validacion: validacionOk
            })
            setEliminable(item.eliminable)
            setEditando(id)
        }
    }

    const guardarHandler = async (eliminar?: boolean): Promise<void> => {
        if (editando === undefined) return

        const vld: ValidacionEdicion = {
            razon_social: edicion.razon_social !== ''
        }
        setValidacion(vld)
        if (!vld.razon_social) return

        const fd = new FormData()
        fd.append('accion', editando === 0 ? 'nuevo' : eliminar ? 'eliminar' : 'editar')
        fd.append('id', editando.toString())
        fd.append('razon_social', edicion.razon_social)
        fd.append('direccion', edicion.direccion)
        fd.append('contacto', edicion.contacto)
        fd.append('email', edicion.email)
        fd.append('telefono', edicion.telefono)
        fd.append('observaciones', edicion.observaciones)
        fd.append('habilitado', edicion.habilitado === 1 ? '1' : '0')

        pedidoJson({
            url: 'proveedores', method: 'post', body: fd,
            ok: () => {
                editarHandler(undefined)
                actualizar()
            }
        })
    }

    return (
        <>
            <TituloPagina icono='fas fa-truck' titulo='Proveedores'/>
            <BarraTarjeta>
                <div className='row g-2'>
                    <div className='col-md-auto text-nowrap'>
                        <Boton toolbar titulo='Alta de Proveedor' icono='fas fa-plus' className='btn-light'
                               onClick={() => editarHandler(0)}/>
                    </div>
                    <div className='col-md-auto pt-1'>
                        <i className='fas fa-search text-primary'/>
                    </div>
                    <div className='col-md'>
                        <InputTexto chico titulo='Proveedor' value={filtroRazonSocial} onChange={setFiltroRazonSocial}
                                    placeholder='Buscar por Razón Social'/>
                    </div>
                </div>
            </BarraTarjeta>
            <Tarjeta>
                <Tabla>
                    <thead>
                    <tr>
                        <td colSpan={5}>
                            <ControlPaginas registros={muestra.length} pagina={pagina} onChange={setPagina}/>
                        </td>
                    </tr>
                    <tr className='headerTabla'>
                        <th style={{width: '30%'}}>Proveedor</th>
                        <th style={{width: '30%'}}>Contacto</th>
                        <th style={{width: '30%'}}>Observaciones</th>
                        <th style={{width: '10%'}}>Habilitado</th>
                        <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {muestra.length ? paginado(muestra, pagina).map((p, k) => (
                        <tr key={k} style={{cursor: 'pointer'}} className='trhover' onClick={() => editarHandler(p.id)}>
                            <td>
                                <div className='fw-bold'>{p.razon_social}</div>
                                {p.direccion !== '' && (
                                    <div className='small'>
                                        <i className='fas fa-location-dot text-muted me-2'/>
                                        {p.direccion}
                                    </div>
                                )}
                            </td>
                            <td className='small'>
                                {p.contacto !== '' && (
                                    <div className='fw-bold'>
                                        {p.contacto}
                                    </div>
                                )}
                                {p.email !== '' && (
                                    <div>
                                        <i className='fas fa-envelope text-muted me-2'/>
                                        {p.email}
                                    </div>
                                )}
                                {p.telefono !== '' && (
                                    <div>
                                        <i className='fas fa-phone text-muted me-2'/>
                                        {p.telefono}
                                    </div>
                                )}
                            </td>
                            <td>
                                {p.observaciones}
                            </td>
                            <td>
                                <CheckEstado estado={p.habilitado} textosino/>
                            </td>
                            <td className='text-nowrap'>
                                {p.habilitado && (
                                    <Boton chico toolbar titulo='Nueva Compra' icono='fas fa-plus'
                                           className='btn-success'
                                           onClick={e => {
                                               e.stopPropagation()
                                               setCargarOrden(p)
                                           }}/>
                                )}
                                <Boton chico toolbar titulo='Historial' icono='fas fa-search' onClick={e => {
                                    e.stopPropagation()
                                    navigate(`historial?id=${p.id}`)
                                }}/>
                            </td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={5} texto='No se encuentran proveedores para mostrar'/>
                    )}
                    </tbody>
                </Tabla>
            </Tarjeta>
            {editando !== undefined && (
                <Modal grande>
                    <h4>{editando === 0 ? 'Alta de Proveedor' : 'Editar Proveedor'}</h4>
                    <div className='row'>
                        <div className='col-md-5'>
                            <InputTexto titulado titulo='Razón Social' value={edicion.razon_social}
                                        onChange={v => setEdicion({...edicion, razon_social: v})} autoFocus
                                        invalido={!validacion.razon_social}/>
                        </div>
                        <div className='col-md-7'>
                            <InputTexto titulado titulo='Dirección' value={edicion.direccion}
                                        onChange={v => setEdicion({...edicion, direccion: v})}/>
                        </div>
                    </div>
                    <hr/>
                    <h5>Información de Contacto</h5>
                    <div className='row'>
                        <div className='col-md-4'>
                            <InputTexto titulado titulo='Contacto' value={edicion.contacto}
                                        onChange={v => setEdicion({...edicion, contacto: v})}/>
                        </div>
                        <div className='col-md-4'>
                            <InputTexto titulado titulo='E-Mail' value={edicion.email}
                                        onChange={v => setEdicion({...edicion, email: v})}/>
                        </div>
                        <div className='col-md-4'>
                            <InputTexto titulado titulo='Teléfono' value={edicion.telefono}
                                        onChange={v => setEdicion({...edicion, telefono: v})}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-8'>
                            <InputTexto titulado titulo='Observaciones' value={edicion.observaciones}
                                        onChange={v => setEdicion({...edicion, observaciones: v})}/>
                        </div>
                        <div className='col-md-4'>
                            <InputSelect titulado titulo='Habilitado ' value={edicion.habilitado}
                                         onChange={v => setEdicion({...edicion, habilitado: v})}
                                         opciones={opcionesHabilitado}/>
                        </div>
                    </div>
                    <ModalBotones editando={editando > 0} soloEditar={editando > 0 && !eliminable}
                                  onCancelar={() => editarHandler(undefined)} guardando={guardando}
                                  onEliminar={() => guardarHandler(true).catch(err => alert(err))}
                                  onGuardar={guardarHandler}/>
                </Modal>
            )}
            {cargarOrden && (
                <ProveedoresCargarCompra proveedor={cargarOrden}
                                         onCancelar={() => setCargarOrden(undefined)}
                                         onGuardar={() => {
                                             setCargarOrden(undefined)
                                             actualizar()
                                         }}/>
            )}
        </>
    )
}

export default Proveedores