import React from "react";
import config from "../config";
import './EnlacesLogin.css'

const EnlacesLogin: React.FC = () => {
    const linkHandler = (link: string) => window.open(link, '_blank')

    return (
        <div className='text-center text-light small fw-bold'>
            <div className='linklogin' onClick={() => linkHandler(config.link_sistema)}>
                <i className='fas fa-globe-americas text-muted me-1'/>
                {config.link_sistema}
            </div>
            <div className='linklogin' onClick={() => linkHandler(config.link_privacidad)}>
                <i className='fas fa-circle-user text-muted me-1'/>
                Política de Privacidad
            </div>
        </div>
    )
}

export default EnlacesLogin