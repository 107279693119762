import React, {useCallback, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {AuthState, setLogueo} from "../redux/authReducer";
import TituloVentana from "../TituloVentana";
import Modal from "../controles/Modal";
import InputTexto from "../controles/InputTexto";
import ToolbarBtn from "../controles/ToolbarBtn";
import Boton from "../controles/Boton";
import LogoSistemas from "../controles/LogoSistemas";
import BotonCarga from "../controles/BotonCarga";
import './PantallaLogin.css'
import IconoCarga from "../controles/IconoCarga";
import {ModoServidor, ModoState, setModo} from "../redux/modoReducer";
import infoVersion from '../infoVersion.json';
import LogoApp from "../controles/LogoApp";
import ModalError from "../controles/ModalError";
import EnlacesLogin from "./EnlacesLogin";
import AlertaAplicacion from "../controles/AlertaAplicacion";
import {setModoOscuro, setNotificacion} from "../redux/estadoReducer";
import VistaModo from "../controles/VistaModo";
import Notificacion from "../controles/Notificacion";
import {useGsBackend} from "../funcionesApi";
import RecuperarClave from "./RecuperarClave";

type AuthStorage = {
    usuario: string,
    token: string
}

const PantallaLogin: React.FC = () => {
    const montado = useRef(false)
    const [errorConexion, setErrorConexion] = useState<boolean>(false)
    const [usuario, setUsuario] = useState<string>('')
    const [clave, setClave] = useState<string>('')
    const [validoUsuario, setValidoUsuario] = useState<boolean>(true)
    const [validoClave, setValidoClave] = useState<boolean>(true)
    const [mensajeError, setMensajeError] = useState<string | undefined>(undefined)
    const [loginGuardado, setLoginGuardado] = useState<boolean>(false)
    const [mensajeCarga, setMensajeCarga] = useState<string>('Conectando')
    const [recuperarClave, setRecuperarClave] = useState<boolean>(false)
    const modo: ModoState = useAppSelector(state => state.modo)
    const errorSistema = useAppSelector(state => state.estado.error_sistema)
    const dispatch = useAppDispatch()
    const {pedidoJson, guardando} = useGsBackend()

    TituloVentana(undefined, 'Iniciar Sesión')

    const conectar = useCallback((): void => {
        setMensajeCarga('Conectando')
        setErrorConexion(false)
        pedidoJson({
            url: '', sinHeaders: true, sinErrorDispatch: true,
            ok: res => {
                if (res.modo !== undefined) {
                    const nuevoModo = res.modo
                    dispatch(setModo({
                        modoServidor:
                            nuevoModo === 'prod' ? ModoServidor.Prod :
                                nuevoModo === 'test' ? ModoServidor.Test :
                                    nuevoModo === 'desa' ? ModoServidor.Desa :
                                        undefined,
                        codigo_vf: res.codigo_vf
                    } as ModoState))
                } else {
                    setErrorConexion(true)
                }
            },
            err: () => setErrorConexion(true)
        })
    }, [dispatch, pedidoJson])

    const loguear = useCallback(async (guardado?: AuthStorage): Promise<void> => {
        // Validar solo si no es con valores previos
        if (guardado === undefined) {
            const usuarioOk = usuario !== ''
            const claveOk = clave !== ''
            setValidoUsuario(usuarioOk)
            setValidoClave(claveOk)
            if (!usuarioOk || !claveOk) return
        }

        setMensajeCarga('Iniciando sesión')

        const fd = new FormData()
        if (guardado === undefined) {
            fd.append('metodo', 'login')
            fd.append('usuario', usuario)
            fd.append('clave', clave)
            fd.append('token', '')
        } else {
            fd.append('metodo', 'token')
            fd.append('usuario', guardado.usuario)
            fd.append('clave', '')
            fd.append('token', guardado.token)
        }

        pedidoJson({
            url: 'login', method: 'post', body: fd, sinNotificacion: true,
            ok: res => {
                if (res.resultado === true) {
                    // Verificar que el usuario tenga módulos habilitados
                    if (res.accesos.length === 0) {
                        setMensajeError(`El usuario ${res.usuario} no tiene ningún módulo habilitado.`)
                        return
                    }

                    if (guardado === undefined) {
                        localStorage.setItem('login', JSON.stringify({
                            usuario: res.usuario,
                            token: res.token
                        } as AuthStorage))
                    }

                    dispatch(setNotificacion({tipo: 'success', mensaje: 'Sesión iniciada'}))
                    dispatch(setModoOscuro(res.opciones.modo_oscuro))
                    dispatch(setLogueo({
                        usuario: res.usuario,
                        nombrecompleto: res.nombrecompleto,
                        cliente: res.cliente,
                        token: res.token,
                        accesos: res.accesos,
                        admin: res.admin
                    } as AuthState))
                } else {
                    if (guardado === undefined) {
                        setMensajeError(res.mensaje)
                    } else {
                        localStorage.removeItem('login')
                        setLoginGuardado(false)
                    }
                }
            }
        })
    }, [pedidoJson, clave, dispatch, usuario])

    useEffect(() => {
        if (modo.modoServidor === undefined) {
            conectar()
        } else {
            const ls = localStorage.getItem('login')
            if (ls !== null) {
                const prueba: AuthStorage = JSON.parse(ls) || {usuario: '', token: ''} as AuthStorage
                if (prueba.usuario !== '' && prueba.token !== '') {
                    setLoginGuardado(true)
                    loguear(prueba).catch(err => alert(err))
                }
            }
        }

        montado.current = true
        return () => {
            montado.current = false
        }
    }, [loguear, conectar, modo.modoServidor])

    const enterHandler = (): void => {
        loguear(undefined).catch(err => alert(err))
    }

    const mensajePantalla: string = recuperarClave ?
        'Recuperación de Contraseña' : 'Ingresa tus credenciales para acceder al Sistema'

    return <div className='fondologin'>
        <VistaModo/>
        <AlertaAplicacion/>
        {errorConexion ?
            <Modal sinFondo colorBorde='border-danger'>
                <div className='mb-3'>
                    El Servicio no se encuentra disponible. Intenta nuevamente en unos minutos.
                </div>
                <ToolbarBtn derecha>
                    <Boton toolbar titulo='Reintentar' icono='fas fa-sync' className='btn-danger'
                           onClick={conectar}/>
                </ToolbarBtn>
            </Modal> :
            loginGuardado || modo.modoServidor === undefined ?
                <Modal mini sinFondo colorBorde='border-danger'>
                    <IconoCarga/>
                    {mensajeCarga}
                </Modal> :
                <Modal sinFondo colorBorde='border-primary' bajoModal={<EnlacesLogin/>}>
                    <div className='row'>
                        <div className='col-auto pt-2'>
                            <i className='fas fa-user text-primary' style={{fontSize: '2em'}}/>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-lg'>
                                    <LogoApp/>
                                    <div className='mb-2'>{mensajePantalla}</div>
                                    {mensajeError !== undefined && (
                                        <div className='text-danger small mb-2'>
                                            <i className='fas fa-exclamation-triangle me-2'/>{mensajeError}
                                        </div>
                                    )}
                                </div>
                                <div className='col-lg-auto d-none d-lg-block'>
                                    <LogoSistemas/>
                                </div>
                                {recuperarClave ? (
                                    <RecuperarClave onVolver={() => setRecuperarClave(false)}/>
                                ) : (
                                    <>
                                        <InputTexto titulo='Usuario' value={usuario} onChange={setUsuario}
                                                    className='mb-3' chico disabled={guardando}
                                                    invalido={!validoUsuario}
                                                    onEnter={enterHandler} autoFocus/>
                                        <InputTexto titulo='Contraseña' value={clave} onChange={setClave} clave
                                                    className='mb-3' chico disabled={guardando} invalido={!validoClave}
                                                    onEnter={enterHandler}/>
                                        <div className='row mb-2'>
                                            <div className='col-md'>
                                                &nbsp;
                                            </div>
                                            <div className='col-md-auto text-nowrap text-muted small'>
                                                Revisión {infoVersion.revision} ({infoVersion.codigo})
                                            </div>
                                        </div>
                                        <ToolbarBtn derecha>
                                            {!guardando ? (
                                                <>
                                                    <Boton toolbar titulo='Recuperar contraseña' icono='fas fa-key'
                                                           onClick={() => setRecuperarClave(true)}
                                                           className='btn-secondary mb-2 mb-md-0'/>
                                                    <Boton toolbar titulo='Iniciar sesión' icono='fas fa-sign-in-alt'
                                                           onClick={() => loguear(undefined)}
                                                           className='btn-primary mb-2 mb-md-0'/>
                                                </>
                                            ) : (
                                                <BotonCarga titulo='Iniciando sesión...'/>
                                            )}
                                        </ToolbarBtn>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
        }
        {errorSistema && <ModalError/>}
        <Notificacion/>
    </div>
}

export default PantallaLogin
