import React, {useCallback, useEffect, useState} from "react";
import TituloPagina from "../controles/TituloPagina";
import BarraTarjeta from "../controles/BarraTarjeta";
import Boton from "../controles/Boton";
import {useNavigate} from "react-router-dom";
import Tarjeta from "../controles/Tarjeta";
import TrInfo from "../controles/TrInfo";
import TextoND from "../controles/TextoND";
import Modal from "../controles/Modal";
import InputTexto from "../controles/InputTexto";
import ModalBotones from "../controles/ModalBotones";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputMoneda from "../controles/InputMoneda";
import Tabla from "../controles/Tabla";
import ControlPaginas, {paginado} from "../controles/ControlPaginas";
import {useGsBackend} from "../funcionesApi";
import AjusteDePrecios, {ProductoAjuste} from "./AjusteDePrecios";

type Producto = {
    id: number,
    descripcion: string,
    descripcion_corta: string,
    descripcion_larga: string,
    id_tipodeproducto: number,
    tipodeproducto: string,
    id_categoria: number,
    categoria: string,
    id_proveedor: number,
    proveedor: string,
    id_unidaddemedida: number,
    unidaddemedida: string,
    preciounitario: number,
    preciounitario_fmt: string
}

export interface CategoriaProductos {
    id: number,
    descripcion: string,
    stock: boolean,
    insumos: boolean,
    mantenimiento: boolean
}

type InfoProductos = {
    productos: Array<Producto>,
    tiposdeproductos: Array<OpcionSelect>,
    proveedores: Array<OpcionSelect>,
    categorias: Array<CategoriaProductos>,
    unidadesdemedida: Array<OpcionSelect>
}

type EdicionProducto = {
    id: number,
    descripcion: string,
    descripcion_corta: string,
    descripcion_larga: string,
    id_tipodeproducto: number,
    id_categoria: number,
    id_proveedor: number,
    id_unidaddemedida: number,
    preciounitario: number
}

type ValidacionProducto = {
    descripcion: boolean,
    id_tipodeproducto: boolean,
    id_categoria: boolean
}

type FiltrosProducto = {
    descripcion: string,
    id_tipodeproducto: number,
    id_categoria: number,
    id_proveedor: number
}

const StockProductos: React.FC = () => {
    const navigate = useNavigate()
    const validacionInicial: ValidacionProducto = {descripcion: true, id_tipodeproducto: true, id_categoria: true}
    const filtroInicial: FiltrosProducto = {descripcion: '', id_tipodeproducto: 0, id_categoria: 0, id_proveedor: 0}
    const [datos, setDatos] = useState<InfoProductos | undefined>(undefined)
    const [muestra, setMuestra] = useState<Array<Producto>>([])
    const [filtros, setFiltros] = useState<FiltrosProducto>(filtroInicial)
    const [pagina, setPagina] = useState<number>(1)
    const [edicion, setEdicion] = useState<EdicionProducto | undefined>(undefined)
    const [valid, setValid] = useState<ValidacionProducto>(validacionInicial)
    const [ajuste, setAjuste] = useState<boolean>(false)
    const {pedidoJson, guardando, actualizar} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'stock/productos', ok: setDatos})
    }, [pedidoJson])

    const filtrar = useCallback(() => {
        if (datos === undefined) return

        setPagina(1)
        setMuestra(datos.productos
            .filter(d => filtros.descripcion === '' ? true :
                (d.descripcion.toLocaleLowerCase().includes(filtros.descripcion.toLocaleLowerCase()) ||
                    d.descripcion_corta.toLocaleLowerCase().includes(filtros.descripcion.toLocaleLowerCase())))
            .filter(d => filtros.id_proveedor === 0 ? true : d.id_proveedor === filtros.id_proveedor)
            .filter(d => filtros.id_tipodeproducto === 0 ? true : d.id_tipodeproducto === filtros.id_tipodeproducto)
            .filter(d => filtros.id_categoria === 0 ? true : d.id_categoria === filtros.id_categoria))
    }, [datos, filtros])

    useEffect(() => {
        filtrar()
    }, [filtrar])

    const guardarHandler = async (eliminar?: boolean) => {
        if (datos === undefined || edicion === undefined) return

        // Validaciones
        const vld: ValidacionProducto = {
            descripcion: edicion.descripcion !== '',
            id_categoria: edicion.id_categoria !== 0,
            id_tipodeproducto: edicion.id_tipodeproducto !== 0
        }
        setValid(vld)
        if (Object.values(vld).some(v => !v)) return

        const fd = new FormData()
        fd.append('accion', edicion.id === 0 ? 'nuevo' : eliminar ? 'eliminar' : 'editar')
        fd.append('id', edicion.id.toString())
        fd.append('descripcion', edicion.descripcion)
        fd.append('descripcion_corta',
            edicion.descripcion_corta === '' ? edicion.descripcion : edicion.descripcion_corta)
        fd.append('descripcion_larga',
            edicion.id_tipodeproducto === 1 ? edicion.descripcion_larga : '')
        fd.append('tipodeproducto', edicion.id_tipodeproducto.toString())
        fd.append('proveedor', edicion.id_proveedor.toString())
        fd.append('categoria', edicion.id_categoria.toString())
        fd.append('unidaddemedida', edicion.id_unidaddemedida.toString())
        fd.append('preciounitario',
            edicion.id_tipodeproducto === 1 ? edicion.preciounitario.toString() : '0')

        pedidoJson({
            url: 'stock/productos', method: 'post', body: fd,
            ok: () => {
                setEdicion(undefined)
                actualizar()
            }
        })
    }

    return (
        <>
            <TituloPagina icono='fas fa-boxes-packing' titulo='Configuración de Productos'/>
            <BarraTarjeta>
                <div className='row g-2'>
                    <div className='col-md-auto text-nowrap'>
                        <Boton toolbar titulo='Volver' icono='fas fa-chevron-left' className='btn-light'
                               onClick={() => navigate('../')}/>
                        <Boton toolbar titulo='Alta de Producto' icono='fas fa-plus' className='btn-light'
                               onClick={() => {
                                   setValid(validacionInicial)
                                   setEdicion({
                                       id: 0,
                                       descripcion: '',
                                       descripcion_corta: '',
                                       descripcion_larga: '',
                                       id_tipodeproducto: 1,
                                       id_categoria: 0,
                                       id_proveedor: 0,
                                       id_unidaddemedida: 1,
                                       preciounitario: 0
                                   })
                               }}/>
                        <Boton toolbar titulo='Ajuste de Precios' icono='fas fa-money-check-dollar'
                               className='btn-light' onClick={() => setAjuste(true)}/>
                    </div>
                    <div className='col-md-auto pt-1'>
                        <i className='fas fa-search text-primary'/>
                    </div>
                    <div className='col-md'>
                        {datos !== undefined && (
                            <div className='row g-2'>
                                <div className='col-sm-3'>
                                    <InputTexto chico titulo='Descripción' value={filtros.descripcion}
                                                onChange={v => setFiltros({...filtros, descripcion: v})}
                                                placeholder='Buscar por Producto'/>
                                </div>
                                <div className='col-sm-3'>
                                    <InputSelect chico titulo='Tipo' value={filtros.id_tipodeproducto}
                                                 onChange={v => setFiltros(
                                                     {...filtros, id_tipodeproducto: v, id_categoria: 0}
                                                 )}
                                                 opciones={datos.tiposdeproductos} textocero='(Todos)'/>
                                </div>
                                <div className='col-sm-3'>
                                    <InputSelect chico titulo='Categoría' value={filtros.id_categoria}
                                                 onChange={v => setFiltros({...filtros, id_categoria: v})}
                                                 opciones={datos.categorias
                                                     .filter(d =>
                                                         filtros.id_tipodeproducto === 1 ? d.stock :
                                                             filtros.id_tipodeproducto === 4 ? d.insumos :
                                                                 filtros.id_tipodeproducto === 5 ? d.mantenimiento :
                                                                     false)}
                                                 textocero='(Todas)'/>
                                </div>
                                <div className='col-sm-3'>
                                    <InputSelect chico titulo='Proveedor' value={filtros.id_proveedor}
                                                 onChange={v => setFiltros({...filtros, id_proveedor: v})}
                                                 opciones={datos.proveedores} textocero='(Todos)'/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </BarraTarjeta>
            <Tarjeta>
                <Tabla>
                    <thead>
                    <tr>
                        <th colSpan={6}>
                            <ControlPaginas registros={muestra.length} pagina={pagina} onChange={setPagina}/>
                        </th>
                    </tr>
                    <tr className='headerTabla'>
                        <th style={{width: '30%'}}>Producto</th>
                        <th style={{width: '15%'}}>Tipo de Producto</th>
                        <th style={{width: '15%'}}>Categoría</th>
                        <th style={{width: '20%'}}>Proveedor</th>
                        <th style={{width: '10%'}}>Unidad de Medida</th>
                        <th style={{width: '10%'}}>Precio de Venta</th>
                    </tr>
                    </thead>
                    <tbody>
                    {muestra.length ? paginado(muestra, pagina).map((p, k) => (
                        <tr key={k} className='trhover' onClick={() => {
                            setValid(validacionInicial)
                            setEdicion(p)
                        }}>
                            <td>
                                {p.descripcion === p.descripcion_corta || p.descripcion_corta === '' ? (
                                    <strong>{p.descripcion}</strong>
                                ) : (
                                    <>
                                        <strong>{p.descripcion}</strong>
                                        <small className='text-muted ms-2'>({p.descripcion_corta})</small>
                                    </>
                                )}
                            </td>
                            <td>{p.tipodeproducto}</td>
                            <td><TextoND texto={p.categoria}/></td>
                            <td><TextoND texto={p.proveedor}/></td>
                            <td>{p.unidaddemedida}</td>
                            {p.id_tipodeproducto === 1 ? (
                                <td className='text-end'>{p.preciounitario_fmt}</td>
                            ) : (
                                <td className='text-muted'>(N/A)</td>
                            )}
                        </tr>
                    )) : (
                        <TrInfo colSpan={6} texto='No hay productos para mostrar'/>
                    )}
                    </tbody>
                </Tabla>
            </Tarjeta>
            {edicion !== undefined && datos !== undefined && (
                <Modal grande>
                    <h4>{edicion.id === 0 ? 'Alta de Producto' : 'Editar producto'}</h4>
                    <div className='row'>
                        <div className='col-md-7'>
                            <InputTexto titulado titulo='Descripción' value={edicion.descripcion}
                                        onChange={v => setEdicion({...edicion, descripcion: v})}
                                        autoFocus={edicion.id === 0} invalido={!valid.descripcion}/>
                        </div>
                        <div className='col-md-5'>
                            <InputTexto titulado titulo='Descripción Corta' value={edicion.descripcion_corta}
                                        onChange={v => setEdicion({...edicion, descripcion_corta: v})}/>
                        </div>
                    </div>
                    {edicion.id_tipodeproducto === 1 && (
                        <div className='row'>
                            <div className='col'>
                                <InputTexto titulado titulo='Descripción Larga (Menú)'
                                            value={edicion.descripcion_larga} placeholder='(Opcional)'
                                            onChange={v => setEdicion({...edicion, descripcion_larga: v})}/>
                            </div>
                        </div>
                    )}
                    <div className='row'>
                        <div className='col-md-4'>
                            <InputSelect value={edicion.id_tipodeproducto} opciones={datos.tiposdeproductos}
                                         onChange={v => setEdicion(
                                             {...edicion, id_tipodeproducto: v, id_categoria: 0}
                                         )}
                                         titulado titulo='Tipo de Producto' invalido={!valid.id_tipodeproducto}/>
                        </div>
                        <div className='col-md-4'>
                            <InputSelect value={edicion.id_categoria} opciones={datos.categorias
                                .filter(d => edicion.id_tipodeproducto === 1 ? d.stock :
                                    edicion.id_tipodeproducto === 4 ? d.insumos :
                                        edicion.id_tipodeproducto === 5 ? d.mantenimiento : false)}
                                         onChange={v => setEdicion({...edicion, id_categoria: v})}
                                         titulado titulo='Categoría' invalido={!valid.id_categoria} forzarCero/>
                        </div>
                        <div className='col-md-4'>
                            <InputSelect value={edicion.id_proveedor} opciones={datos.proveedores}
                                         onChange={v => setEdicion({...edicion, id_proveedor: v})}
                                         titulado titulo='Proveedor (Opcional)' textocero='(Sin especificar)'/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            {edicion.id_tipodeproducto === 1 ? (
                                <InputMoneda value={edicion.preciounitario} autoFocus={edicion.id !== 0}
                                             onChange={v => setEdicion({...edicion, preciounitario: v})}
                                             titulado titulo='Precio de Venta'/>
                            ) : (
                                <InputTexto value='N/A' disabled titulado titulo='Precio de Venta'/>
                            )}
                        </div>
                        <div className='col-md-8'>
                            <InputSelect value={edicion.id_unidaddemedida} opciones={datos.unidadesdemedida}
                                         onChange={v => setEdicion({...edicion, id_unidaddemedida: v})}
                                         titulado titulo='Unidad de Medida' sincero/>
                        </div>
                    </div>
                    <ModalBotones onCancelar={() => setEdicion(undefined)} editando={edicion.id !== 0}
                                  onGuardar={() => guardarHandler()} guardando={guardando}
                                  onEliminar={edicion.id === 0 ? undefined : () => guardarHandler(true)}/>
                </Modal>
            )}
            {datos !== undefined && ajuste && (
                <AjusteDePrecios tipo='stock' onVolver={() => setAjuste(false)}
                                 categorias={datos.categorias} onActualizar={() => actualizar()}
                                 proveedores={datos.proveedores}
                                 productos={datos.productos.filter(p => p.id_tipodeproducto === 1).map(p => {
                                     return {
                                         id: p.id, id_categoria: p.id_categoria, descripcion: p.descripcion,
                                         precio_actual: p.preciounitario, id_proveedor: p.id_proveedor
                                     } as ProductoAjuste
                                 })}/>
            )}
        </>
    )
}

export default StockProductos