import React, {useImperativeHandle, useRef} from "react";
import {estilosApp} from "../estilos";
import {useAppSelector} from "../redux/hooks";

export interface OpcionSelect {
    id: number,
    descripcion: string
}

type InputSelectProps = {
    opciones?: Array<OpcionSelect>,
    textocero?: string,
    sincero?: boolean,
    titulo?: string | JSX.Element,
    className?: string,
    chico?: boolean,
    value: number,
    onChange: (arg: number) => void,
    titulado?: boolean,
    obligatorio?: boolean,
    invalido?: boolean,
    forzarCero?: boolean,
    autoFocus?: boolean,
    estiloTitulo?: string,
    disabled?: boolean
}

/**
 * Control generador de un input tipo Select
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InputSelect = React.forwardRef<HTMLSelectElement, InputSelectProps>((
    {
        opciones, textocero, sincero, titulo,
        className, chico, value, onChange, titulado,
        obligatorio, invalido, forzarCero,
        autoFocus, estiloTitulo, disabled
    }, ref
) => {
    const st = estilosApp(useAppSelector(state => state.estado.modo_oscuro))
    const localRef = useRef<HTMLSelectElement>(null)

    useImperativeHandle<HTMLSelectElement | null, HTMLSelectElement | null>(ref, () => localRef.current)

    const valores = (opciones && opciones.length) ?
        opciones.map(({id, descripcion}: OpcionSelect) => <option value={id} key={id}>{descripcion}</option>)
        : textocero !== undefined || forzarCero ? <></> : <option value='0'>{textocero ? textocero : '(N/D)'}</option>

    const valorCero = (!sincero && opciones && opciones.length > 1) || textocero !== undefined || forzarCero ?
        <option value='0'>{textocero ? textocero : '(Seleccione una opción)'}</option>
        : <></>

    if (titulo && titulado) {
        return (
            <div className={'mb-2' + (chico ? ' small' : '')}>
                <div className='form-label ms-1 mb-1'>
                    {titulo}
                    {obligatorio && <span className='text-muted small ms-1'> (obligatorio)</span>}
                </div>
                <select className={`form-select ${st.inputs} ` + (value === 0 ? ' text-muted' : '') +
                    (invalido ? ' is-invalid' : '') + (chico ? ' form-select-sm' : '')} disabled={disabled}
                        value={value} ref={localRef}
                        onChange={e => onChange(parseInt(e.target.value))} autoFocus={autoFocus}>
                    {valorCero}
                    {valores}
                </select>
            </div>
        )
    } else if (titulo) {
        const estilo = className + ' input-group' + (chico ? ' input-group-sm' : '')

        return (
            <div className={estilo}>
                <div className={'input-group-text' + (estiloTitulo === undefined ? '' : ` ${estiloTitulo}`)}>
                    {titulo}
                </div>
                <select className={`form-select ${st.inputs} ` + (value === 0 ? ' text-muted' : '') +
                    (invalido ? ' is-invalid' : '')} disabled={disabled}
                        value={value} ref={localRef}
                        onChange={e => onChange(parseInt(e.target.value))} autoFocus={autoFocus}>
                    {valorCero}
                    {valores}
                </select>
            </div>
        )
    } else {
        const estilo = (className || '') + ` form-select ${st.inputs}` + (chico ? ' form-select-sm' : '') +
            (value === 0 ? ' text-muted' : '') + (invalido ? ' is-invalid' : '')

        return (
            <select className={estilo} value={value} disabled={disabled} ref={localRef}
                    onChange={e => onChange(parseInt(e.target.value))} autoFocus={autoFocus}>
                {valorCero}
                {valores}
            </select>
        )
    }
})

export default InputSelect
