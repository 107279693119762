import React from "react";
import TituloPagina from "../controles/TituloPagina";
import BarraTarjeta from "../controles/BarraTarjeta";
import Boton from "../controles/Boton";
import Tarjeta from "../controles/Tarjeta";
import {nivelAcceso} from "../autorizacion";
import {NivelesAcceso} from "../redux/authReducer";
import {useAppSelector} from "../redux/hooks";
import {useNavigate} from "react-router-dom";

const Mesas: React.FC = () => {
    const nombreCliente = useAppSelector(state => state.auth.cliente)
    const navigate = useNavigate()
    const acceso = nivelAcceso('mesas')

    return (
        <>
            <TituloPagina icono='fas fa-champagne-glasses' titulo='Mesas'/>
            <BarraTarjeta toolbar>
                {acceso >= NivelesAcceso.NivelSupervisor && (
                    <Boton toolbar titulo='Configuración' icono='fas fa-cog' className='btn-light' disabled/>
                )}
            </BarraTarjeta>
            <Tarjeta>
                <h4>{nombreCliente}</h4>
                <div className='row g-2'>
                    <div className='col-auto'>
                        <i className='fas fa-info-circle text-info me-2'/>
                    </div>
                    <div className='col'>
                        <p>
                            Debes configurar tu local antes de ver el plano en esta sección.<br/>
                            Mientras tanto, podés gestionar desde la sección de <strong>Pedidos</strong>.
                        </p>
                        <Boton titulo='Ir a Pedidos' icono='fas fa-ticket'
                               onClick={() => navigate('/pedidos')}/>
                    </div>
                </div>
            </Tarjeta>
        </>
    )
}

export default Mesas