import React from "react";

type Props = {
    checked: boolean,
    onChange: (v: boolean) => void,
    titulo?: string | JSX.Element,
    disabled?: boolean
}

const InputSwitch: React.FC<Props> = ({checked, onChange, titulo, disabled}) => {
    const interruptor: JSX.Element = (
        <div className='form-check form-switch'>
            <input className='form-check-input' type='checkbox' checked={checked} disabled={disabled}
                   onChange={e => onChange(e.target.checked)}/>
        </div>
    )

    if (titulo === undefined) {
        return interruptor
    } else {
        return (
            <div className='row g-1'>
                <div className='col-auto'>{interruptor}</div>
                <div className='col'>{titulo}</div>
            </div>
        )
    }
}

export default InputSwitch