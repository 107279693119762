import React, {useEffect, useState} from "react";
import Tarjeta from "../controles/Tarjeta";
import {useAppSelector} from "../redux/hooks";
import InputTexto from "../controles/InputTexto";
import Boton from "../controles/Boton";
import InputSwitch from "../controles/InputSwitch";
import InputMoneda from "../controles/InputMoneda";
import {validarEmail} from "../validaciones";
import {useGsBackend} from "../funcionesApi";

type DatosEdicion = {
    direccion: string,
    telefono: string,
    whatsapp: string,
    email: string,
    mail_notif_1: string,
    mail_notif_2: string,
    mail_notif_3: string,
    mail_cierre_caja: boolean,
    mostrar_envio: boolean,
    envio_predeterminado: number,
    mostrar_cubierto: boolean,
    cubierto_predeterminado: number,
    mostrar_valores_comanda: boolean,
    ocultar_envio_comanda: boolean,
    resumen_categorias_comanda: boolean,
    usar_servidor_comanda: boolean,
    url_servidor_comanda: string
}

type InfoNegocio = {
    configuracion: DatosEdicion
}

type Validacion = {
    mail_notif_1: boolean,
    mail_notif_2: boolean,
    mail_notif_3: boolean,
}

const AdminNegocio: React.FC = () => {
    const edicionInicial: DatosEdicion = {
        direccion: '', telefono: '', whatsapp: '', email: '',
        mail_notif_1: '', mail_notif_2: '', mail_notif_3: '', mail_cierre_caja: false,
        mostrar_envio: false, envio_predeterminado: 0,
        mostrar_cubierto: false, cubierto_predeterminado: 0, mostrar_valores_comanda: false,
        ocultar_envio_comanda: false, resumen_categorias_comanda: false,
        usar_servidor_comanda: false, url_servidor_comanda: ''
    }
    const validacionInicial: Validacion = {
        mail_notif_1: true, mail_notif_2: true, mail_notif_3: true
    }
    const [datos, setDatos] = useState<InfoNegocio>({configuracion: edicionInicial})
    const [edicion, setEdicion] = useState<DatosEdicion>(edicionInicial)
    const [validacion, setValidacion] = useState<Validacion>(validacionInicial)
    const auth = useAppSelector(state => state.auth)

    const {pedidoJson, guardando} = useGsBackend()
    useEffect(() => {
        pedidoJson({url: 'admin/negocio', ok: setDatos})
    }, [pedidoJson])

    useEffect(() => {
        setEdicion(datos.configuracion)
    }, [datos])

    const revertirHandler = (): void => setEdicion(datos.configuracion)

    const guardarHandler = async (): Promise<void> => {
        // Validaciones
        const vld: Validacion = {
            mail_notif_1: edicion.mail_notif_1 === '' || validarEmail(edicion.mail_notif_1),
            mail_notif_2: edicion.mail_notif_2 === '' || validarEmail(edicion.mail_notif_2),
            mail_notif_3: edicion.mail_notif_3 === '' || validarEmail(edicion.mail_notif_3)
        }
        setValidacion(vld)
        if (!vld.mail_notif_1 || !vld.mail_notif_2 || !vld.mail_notif_3) return

        const fd = new FormData()
        fd.append('accion', 'editar')

        // Datos del Negocio
        fd.append('direccion', edicion.direccion)
        fd.append('telefono', edicion.telefono)
        fd.append('whatsapp', edicion.whatsapp)
        fd.append('email', edicion.email)

        // Mails para Notificaciones
        fd.append('mail_notif_1', edicion.mail_notif_1)
        fd.append('mail_notif_2', edicion.mail_notif_2)
        fd.append('mail_notif_3', edicion.mail_notif_3)

        // Opciones del Cliente
        fd.append('mail_cierre_caja', edicion.mail_cierre_caja ? '1' : '0')
        fd.append('mostrar_envio', edicion.mostrar_envio ? '1' : '0')
        fd.append('envio_predeterminado', edicion.envio_predeterminado.toString())
        fd.append('mostrar_cubierto', edicion.mostrar_cubierto ? '1' : '0')
        fd.append('cubierto_predeterminado', edicion.cubierto_predeterminado.toString())
        fd.append('mostrar_valores_comanda', edicion.mostrar_valores_comanda ? '1' : '0')
        fd.append('resumen_categorias_comanda', edicion.resumen_categorias_comanda ? '1' : '0')
        fd.append('ocultar_envio_comanda', edicion.ocultar_envio_comanda ? '1' : '0')
        fd.append('usar_servidor_comanda', edicion.usar_servidor_comanda ? '1' : '0')
        fd.append('url_servidor_comanda', edicion.url_servidor_comanda)

        pedidoJson({url: 'admin/negocio', method: 'post', body: fd})
    }

    const sinMailsNotif = edicion.mail_notif_1 === '' && edicion.mail_notif_2 === '' &&
        edicion.mail_notif_3 === ''

    return (
        <Tarjeta>
            <h4><i className='fas fa-home me-2 text-primary'/>{auth.cliente}</h4>
            <div className='row'>
                <div className='col-lg-6'>
                    <h5>Configuración del Negocio</h5>
                    <div className='row g-2 small text-muted mb-2'>
                        <div className='col-auto'><i className='fas fa-info-circle'/></div>
                        <div className='col'>
                            Los datos que completes en esta sección son los que luego podrás mostrar al público
                            en el menú online
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Dirección</div>
                        <div className='col-md-9'>
                            <InputTexto value={edicion.direccion} placeholder='Dirección del Local'
                                        autoCompletar='calles'
                                        onChange={v => setEdicion({...edicion, direccion: v})}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Teléfono</div>
                        <div className='col-md-9'>
                            <InputTexto value={edicion.telefono} placeholder='Teléfono del Local'
                                        onChange={v => setEdicion({...edicion, telefono: v})}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>WhatsApp</div>
                        <div className='col-md-9'>
                            <InputTexto value={edicion.whatsapp} placeholder='Número de WhatsApp'
                                        onChange={v => setEdicion({...edicion, whatsapp: v})}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>E-Mail</div>
                        <div className='col-md-9'>
                            <InputTexto value={edicion.email} placeholder='E-Mail de Contacto'
                                        onChange={v => setEdicion({...edicion, email: v})}/>
                        </div>
                    </div>
                    <hr/>
                    <h5>Notificaciones por E-Mail</h5>
                    <div className='row g-2 small text-muted mb-2'>
                        <div className='col-auto'><i className='fas fa-info-circle'/></div>
                        <div className='col'>
                            Podés agregar hasta tres direcciones de E-Mail para recibir notificaciones
                            del sistema
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Direcciones para envíos</div>
                        <div className='col-md-9'>
                            <div className='mb-2'>
                                <InputTexto value={edicion.mail_notif_1} placeholder='E-Mail nro. 1 (Opcional)'
                                            onChange={v => setEdicion({...edicion, mail_notif_1: v})}
                                            invalido={!validacion.mail_notif_1}/>
                            </div>
                            <div className='mb-2'>
                                <InputTexto value={edicion.mail_notif_2} placeholder='E-Mail nro. 2 (Opcional)'
                                            onChange={v => setEdicion({...edicion, mail_notif_2: v})}
                                            invalido={!validacion.mail_notif_2}/>
                            </div>
                            <div className='mb-2'>
                                <InputTexto value={edicion.mail_notif_3} placeholder='E-Mail nro. 3 (Opcional)'
                                            onChange={v => setEdicion({...edicion, mail_notif_3: v})}
                                            invalido={!validacion.mail_notif_3}/>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3'>Notificaciones a enviar</div>
                        <div className='col-md-9'>
                            <div className='mb-2'>
                                <InputSwitch titulo='E-Mail por Cierre de Caja'
                                             checked={edicion.mail_cierre_caja} disabled={sinMailsNotif}
                                             onChange={v => setEdicion({...edicion, mail_cierre_caja: v})}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <h5>Opciones de Pedidos</h5>
                    <div className='row mb-2'>
                        <div className='col-md-6 pt-1'>
                            <InputSwitch checked={edicion.mostrar_envio} titulo='Habilitar Costo de Envío'
                                         onChange={v => setEdicion({...edicion, mostrar_envio: v})}/>
                        </div>
                        <div className='col-md-6'>
                            <InputMoneda value={edicion.envio_predeterminado} titulo='Envío pred.'
                                         onChange={v => setEdicion({...edicion, envio_predeterminado: v})}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-6 pt-1'>
                            <InputSwitch checked={edicion.mostrar_cubierto} titulo='Habilitar Costo de Cubierto'
                                         onChange={v => setEdicion({...edicion, mostrar_cubierto: v})}/>
                        </div>
                        <div className='col-md-6'>
                            <InputMoneda value={edicion.cubierto_predeterminado} titulo='Costo de Cubierto'
                                         onChange={v => setEdicion({...edicion, cubierto_predeterminado: v})}/>
                        </div>
                    </div>
                    <hr/>
                    <h5>Opciones de Comanda</h5>
                    <div className='mb-2'>
                        <div className='row mb-2'>
                            <div className='col-md-6 pt-1'>
                                <InputSwitch titulo='Usar servidor de impresión'
                                             checked={edicion.usar_servidor_comanda}
                                             onChange={v => setEdicion({...edicion, usar_servidor_comanda: v})}/>
                            </div>
                            <div className='col-md-6'>
                                <InputTexto value={edicion.url_servidor_comanda} titulo='URL'
                                            placeholder='URL del servidor'
                                            onChange={v => setEdicion({...edicion, url_servidor_comanda: v})}/>
                            </div>
                        </div>
                    </div>
                    <div className='mb-2 py-1'>
                        <InputSwitch titulo='Mostrar resumen por categoría'
                                     checked={edicion.resumen_categorias_comanda}
                                     onChange={v => setEdicion({...edicion, resumen_categorias_comanda: v})}/>
                    </div>
                    <div className='mb-2 py-1'>
                        <InputSwitch titulo='Mostrar valores en comanda'
                                     checked={edicion.mostrar_valores_comanda}
                                     onChange={v => setEdicion({...edicion, mostrar_valores_comanda: v})}/>
                    </div>
                    <div className='mb-2 py-1'>
                        <InputSwitch titulo={edicion.mostrar_valores_comanda ?
                            'Ocultar costo de envío' : 'Ocultar envío'}
                                     checked={edicion.ocultar_envio_comanda}
                                     onChange={v => setEdicion({...edicion, ocultar_envio_comanda: v})}/>
                    </div>
                </div>
            </div>
            <div className='text-center'>
                <hr/>
                <Boton chico titulo='Revertir Configuración' icono='fas fa-rotate-left' className='btn-danger me-2'
                       onClick={revertirHandler}/>
                <Boton chico titulo='Guardar Cambios' icono='fas fa-save' className='btn-success'
                       onClick={guardarHandler} guardando={guardando}/>
            </div>
        </Tarjeta>
    )
}

export default AdminNegocio