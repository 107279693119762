import React, {useEffect, useState} from "react";
import Tarjeta from "../controles/Tarjeta";
import InputSwitch from "../controles/InputSwitch";
import Boton from "../controles/Boton";
import {funcionDescarga} from "../funcionDescarga";
import InputTexto from "../controles/InputTexto";
import TextoND from "../controles/TextoND";
import {useGsBackend} from "../funcionesApi";

type DatosEdicion = {
    habilitar: boolean,
    texto: string,
    ver_direccion: boolean,
    ver_telefono: boolean,
    ver_whatsapp: boolean,
    ver_email: boolean
}

type DatosConfiguracionPublica = {
    url_publica: string,
    direccion: string,
    telefono: string,
    whatsapp: string,
    email: string,
    configuracion: DatosEdicion,
    visitas: number
}

const AdminPerfilPublico: React.FC = () => {
    const edicionInicial: DatosEdicion = {
        habilitar: false, texto: '', ver_direccion: false, ver_telefono: false, ver_whatsapp: false,
        ver_email: false
    }
    const datosIniciales: DatosConfiguracionPublica = {
        url_publica: '', direccion: '', telefono: '', whatsapp: '', email: '', configuracion: edicionInicial,
        visitas: 0
    }
    const [edicion, setEdicion] = useState<DatosEdicion>(edicionInicial)
    const [datos, setDatos] = useState<DatosConfiguracionPublica>(datosIniciales)

    const {pedidoJson, pedidoBlob, guardando} = useGsBackend()
    useEffect(() => {
        pedidoJson({url: 'admin/publica', ok: setDatos})
    }, [pedidoJson])

    const qrHandler = (tipo: 'publico' | 'mesas'): void => {
        pedidoBlob({
            url: `imprimir/qrpublico?tipo=${tipo}`,
            ok: res => funcionDescarga(res, `qr-${tipo}.pdf`)
        })
    }

    useEffect(() => {
        setEdicion(datos.configuracion)
    }, [datos])

    const revertirHandler = (): void => setEdicion(datos.configuracion)

    const guardarHandler = async (): Promise<void> => {
        const fd = new FormData()
        fd.append('accion', 'editar')
        fd.append('habilitar', edicion.habilitar ? '1' : '0')
        fd.append('texto', edicion.texto)
        fd.append('ver_direccion', edicion.ver_direccion ? '1' : '0')
        fd.append('ver_telefono', edicion.ver_telefono ? '1' : '0')
        fd.append('ver_whatsapp', edicion.ver_whatsapp ? '1' : '0')
        fd.append('ver_email', edicion.ver_email ? '1' : '0')

        pedidoJson({url: 'admin/publica', method: 'post', body: fd})
    }

    return (
        <Tarjeta>
            <div className='row'>
                <div className='col-lg-6'>
                    <h5>Configuración del Menú Online</h5>
                    <div className='row mb-2'>
                        <div className='col-md-3'>Habilitar</div>
                        <div className='col-md-9'>
                            <InputSwitch checked={edicion.habilitar}
                                         titulo={edicion.habilitar ? (
                                             <a href={datos.url_publica} target='_blank' rel='noreferrer'>
                                                 {datos.url_publica}
                                             </a>
                                         ) : undefined}
                                         onChange={v => setEdicion({...edicion, habilitar: v})}/>
                        </div>
                    </div>
                    {datos.visitas > 0 && (
                        <div className='row mb-2'>
                            <div className='col-md-9 offset-md-3'>
                                <i className='fas fa-info-circle me-2 text-info'/>
                                Tu menú online fue visitado {datos.visitas} {datos.visitas === 1? 'vez' : 'veces'}
                            </div>
                        </div>
                    )}
                    <div className='row mb-2'>
                        <div className='col-md-9 offset-md-3'>
                            <Boton titulo='Descargar QR Grande' icono='fas fa-file-pdf' chico
                                   onClick={() => qrHandler('publico')}
                                   className={edicion.habilitar ? 'btn-primary' : 'btn-secondary'}
                                   disabled={!edicion.habilitar} toolbar/>
                            <Boton titulo='Descargar QR Mesas' icono='fas fa-file-pdf' chico
                                   onClick={() => qrHandler('mesas')}
                                   className={edicion.habilitar ? 'btn-primary' : 'btn-secondary'}
                                   disabled={!edicion.habilitar} toolbar/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Texto del Perfil</div>
                        <div className='col-md-9'>
                            <InputTexto value={edicion.texto} placeholder='(Opcional)'
                                        onChange={v => setEdicion({...edicion, texto: v})}/>
                        </div>
                    </div>
                    <hr/>
                    <h5>Datos del Negocio</h5>
                    <div className='row mb-2'>
                        <div className='col-md-3'>Mostrar Dirección</div>
                        <div className='col-md-9'>
                            <InputSwitch checked={datos.direccion !== '' ? edicion.ver_direccion : false}
                                         titulo={<TextoND texto={datos.direccion}/>}
                                         onChange={v => setEdicion({...edicion, ver_direccion: v})}
                                         disabled={datos.direccion === ''}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3'>Mostrar Teléfono</div>
                        <div className='col-md-9'>
                            <InputSwitch checked={datos.telefono !== '' ? edicion.ver_telefono : false}
                                         titulo={<TextoND texto={datos.telefono}/>}
                                         onChange={v => setEdicion({...edicion, ver_telefono: v})}
                                         disabled={datos.telefono === ''}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3'>Mostrar WhatsApp</div>
                        <div className='col-md-9'>
                            <InputSwitch checked={datos.whatsapp !== '' ? edicion.ver_whatsapp : false}
                                         titulo={<TextoND texto={datos.whatsapp}/>}
                                         onChange={v => setEdicion({...edicion, ver_whatsapp: v})}
                                         disabled={datos.whatsapp === ''}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3'>Mostrar E-Mail</div>
                        <div className='col-md-9'>
                            <InputSwitch checked={datos.email !== '' ? edicion.ver_email : false}
                                         titulo={<TextoND texto={datos.email}/>}
                                         onChange={v => setEdicion({...edicion, ver_email: v})}
                                         disabled={datos.email === ''}/>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <h5>Contenido del Menú</h5>
                </div>
            </div>
            <div className='text-center'>
                <hr/>
                <Boton chico titulo='Revertir Configuración' icono='fas fa-rotate-left' className='btn-danger me-2'
                       onClick={revertirHandler}/>
                <Boton chico titulo='Guardar Cambios' icono='fas fa-save' className='btn-success'
                       onClick={guardarHandler} guardando={guardando}/>
            </div>
        </Tarjeta>
    )
}

export default AdminPerfilPublico