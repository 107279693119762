import React from "react";

type Props = {
    mensaje: string
}

const TarjetaMensajeAdicion: React.FC<Props> = ({mensaje}) => {
    return (
        <div className='col-12'>
            <div className='card shadow-sm border-0 h-100 p-2 border-4 border-start border-secondary'>
                <div className='row g-2'>
                    <div className='col-6'>
                        <div className='row g-2'>
                            <div className='col-auto'>
                                <i className='fas fa-info-circle text-info'/>
                            </div>
                            <div className='col'>
                                <div>{mensaje}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TarjetaMensajeAdicion