import React from "react";
import TituloPagina from "../controles/TituloPagina";
import BarraTarjeta from "../controles/BarraTarjeta";
import Boton from "../controles/Boton";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

const Admin: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const estiloNormal = 'btn-light'
    const estiloActivo = 'btn-primary'

    const l = location.pathname.split('/').slice(1)[1] || ''

    return (
        <>
            <TituloPagina icono='fas fa-cog' titulo='Configurar'/>
            <BarraTarjeta>
                <Boton toolbar titulo='Datos del Negocio' icono='fas fa-home'
                       className={l === 'negocio' ? estiloActivo : estiloNormal}
                       onClick={() => navigate('negocio')}/>
                <Boton toolbar titulo='Menú Online' icono='fas fa-earth-americas'
                       className={l === 'perfilpublico' ? estiloActivo : estiloNormal}
                       onClick={() => navigate('perfilpublico')}/>
                <Boton toolbar titulo='Categorías de Productos' icono='fas fa-list'
                       className={l === 'categorias' ? estiloActivo : estiloNormal}
                       onClick={() => navigate('categorias')}/>
                <Boton toolbar titulo='Usuarios' icono='fas fa-users'
                       className={l === 'usuarios' ? estiloActivo : estiloNormal}
                       onClick={() => navigate('usuarios')}/>
                <Boton toolbar titulo='Auditoría' icono='fas fa-eye'
                       className={l === 'auditoria' ? estiloActivo : estiloNormal}
                       onClick={() => navigate('auditoria')}/>
            </BarraTarjeta>
            <Outlet/>
        </>
    )
}

export default Admin