import React from "react";
import BotonCarga from "./BotonCarga";

type BotonProps = {
    className?: string,
    chico?: boolean,
    toolbar?: boolean,
    icono?: string,
    titulo: string,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    disabled?: boolean,
    estiloIcono?: string,
    sinEspacio?: boolean,
    autoFocus?: boolean,
    guardando?: boolean
}

/**
 * Funcion generadora de un botón simple, con o sin ícono
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Boton: React.FC<BotonProps> = (
    {
        className, chico, toolbar, icono, titulo,
        onClick, disabled, estiloIcono, sinEspacio,
        autoFocus, guardando
    }
) => {
    const estilo = 'btn ' + (className ? className : 'btn-primary') + ' ' +
        ((chico || toolbar) && 'btn-sm')

    const boton = (
        <button type='button' className={estilo} onClick={onClick} disabled={disabled} autoFocus={autoFocus}>
            {icono && <i className={icono + ' me-2' + (estiloIcono !== undefined ? ` ${estiloIcono}` :
                className?.includes('btn-light') ? ' text-primary' : '')}/>}
            {titulo}
        </button>
    )

    if (guardando) {
        return (
            <BotonCarga grande={!chico} titulo='Guardando...'/>
        )
    } else if (toolbar) {
        return (
            <div className={'btn-group btn-group-sm' + (sinEspacio ? '' : ' me-2')}>{boton}</div>
        )
    } else {
        return boton
    }
}

export default Boton
