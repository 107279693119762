import React from "react";
import {estilosApp} from "../estilos";
import {useAppSelector} from "../redux/hooks";

type Props = {
    children: React.ReactNode,
    espacio?: boolean
}

const Tabla: React.FC<Props> = ({children, espacio}) => {
    const st = estilosApp(useAppSelector(state => state.estado.modo_oscuro))

    return (
        <div className={`table-responsive ${st.tabla} border-1 border` + (espacio ? ' mb-2' : '')}>
            <table className='table table-sm small mb-0'>
                {children}
            </table>
        </div>
    )
}

export default Tabla