export type ClaveSeccion = ''
    | 'pedidos'
    | 'caja'
    | 'cerveceria'
    | 'stock'
    | 'elaborados'
    | 'clientes'
    | 'admin'
    | 'estadisticas'
    | 'mesas'
    | 'proveedores'

export type SeccionApp = {
    clave: ClaveSeccion,
    titulo: string,
    icono: string,
    id: number,
    orden: number
}

export const seccionesApp: Array<SeccionApp> = [
    {orden: 2, id: 1, clave: 'pedidos', titulo: 'Pedidos', icono: 'fas fa-ticket'},
    {orden: 7, id: 2, clave: 'caja', titulo: 'Caja', icono: 'fas fa-cash-register'},
    {orden: 3, id: 3, clave: 'cerveceria', titulo: 'Cervecería', icono: 'fas fa-beer'},
    {orden: 4, id: 4, clave: 'stock', titulo: 'Stock', icono: 'fas fa-boxes-stacked'},
    {orden: 5, id: 5, clave: 'elaborados', titulo: 'Elaborados', icono: 'fas fa-bowl-rice'},
    {orden: 8, id: 6, clave: 'clientes', titulo: 'Clientes', icono: 'fas fa-users'},
    {orden: 10, id: 7, clave: 'admin', titulo: 'Configurar', icono: 'fas fa-cog'},
    {orden: 9, id: 8, clave: 'estadisticas', titulo: 'Estadísticas', icono: 'fas fa-chart-pie'},
    {orden: 1, id: 9, clave: 'mesas', titulo: 'Mesas', icono: 'fas fa-champagne-glasses'},
    {orden: 6, id: 10, clave: 'proveedores', titulo: 'Proveedores', icono: 'fas fa-truck'}
]