import React, {useImperativeHandle, useRef, useState} from "react";
import {useAppSelector} from "../redux/hooks";
import {estilosApp} from "../estilos";
import {useGsBackend} from "../funcionesApi";

type InputTextoProps = {
    titulo?: string,
    className?: string,
    chico?: boolean,
    value: string,
    placeholder?: string,
    onChange?: (arg: string) => void,
    invalido?: boolean,
    autoFocus?: boolean,
    titulado?: boolean,
    disabled?: boolean,
    obligatorio?: boolean,
    numerico?: boolean,
    clave?: boolean,
    onEnter?: () => void,
    textoInvalido?: string,
    sinBorde?: boolean,
    onFocus?: () => void,
    onBlur?: () => void,
    autoSelect?: boolean,
    componentePosterior?: React.ReactNode,
    izquierda?: boolean,
    decimal?: boolean,
    autoCompletar?: string,
    readOnly?: boolean
}

/**
 * Funcion generadora de un input tipo Texto
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InputTexto = React.forwardRef<HTMLInputElement, InputTextoProps>((
    {
        titulo, className, chico, value,
        placeholder, onChange, invalido, autoFocus,
        titulado, disabled, obligatorio,
        numerico, clave, onEnter,
        textoInvalido, sinBorde, onFocus,
        autoSelect, onBlur, componentePosterior,
        izquierda, decimal, autoCompletar,
        readOnly
    }, ref
) => {
    const [mostrarClave, setMostrarClave] = useState<boolean>(false)
    const [listaValores, setListaValores] = useState<Array<string>>([])
    const tipo = clave && !mostrarClave ? 'password' : 'text'
    const estado = useAppSelector(state => state.estado)
    const desactivar = disabled || estado.cargando
    const ultimoParamAutocompletar = useRef<string>('')
    const refLocal = useRef<HTMLInputElement>(null)
    const st = estilosApp(estado.modo_oscuro)
    const {pedidoJson} = useGsBackend()

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => refLocal.current)

    const autoCompletarHandler = (arg: string): void => {
        const minimo = 3

        if (autoCompletar === undefined || arg.length < minimo) {
            setListaValores([])
            ultimoParamAutocompletar.current = ''
            return
        }

        if (ultimoParamAutocompletar.current.length >= minimo &&
            arg.startsWith(ultimoParamAutocompletar.current)) return

        ultimoParamAutocompletar.current = arg
        pedidoJson({
            url: `autocompletar/${autoCompletar}?param=${encodeURIComponent(arg)}`,
            ok: res => setListaValores(res.valores)
        })
    }

    const edicionHandler = (arg: string, omitirAutocompletar?: boolean): void => {
        if (onChange === undefined) return
        if (numerico) {
            if (decimal) {
                const numero = arg
                    .replace(/[^\d.]/g, '')
                    .replace(/^0+/, '')
                    .split('.')
                if (numero.length === 1) {
                    onChange(numero[0] === '' ? '0' : numero[0])
                } else {
                    onChange((numero[0].length === 0 ? '0' : numero[0]) + '.' + numero[1].substring(0, 2))
                }
            } else {
                const numero = arg
                    .replace(/\D/g, '')
                    .replace(/^0+/, '')
                onChange(numero === '' ? '0' : numero)
            }
        } else {
            if (autoCompletar !== undefined && !omitirAutocompletar)
                autoCompletarHandler(arg.toLocaleUpperCase())
            onChange(arg)
        }
    }

    const focusHandler: React.FocusEventHandler<HTMLInputElement> = (e) => {
        if (autoSelect) e.target.select()
        if (onFocus) onFocus()
    }

    const teclaHandler = onEnter === undefined ? undefined : (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onEnter()
        }
    }

    const feedbackInvalido = () => {
        if (textoInvalido !== undefined && textoInvalido !== '') {
            return <div className='invalid-feedback'>{textoInvalido}</div>
        } else {
            return <></>
        }
    }

    // Generar el control
    const vistaControl = (): JSX.Element => {
        if (titulo && titulado) {
            return (
                <div className={'mb-2' + (chico ? ' small' : '')}>
                    <div className='form-label ms-1 mb-1'>
                        {titulo}
                        {obligatorio && <span className='text-muted small ms-1'> (obligatorio)</span>}
                    </div>
                    <input type={tipo} className={`form-control ${st.inputs} ` + (invalido ? 'is-invalid ' : '') +
                        (numerico && !izquierda ? 'text-end ' : '') + (chico ? 'form-control-sm' : '')} value={value}
                           placeholder={placeholder} disabled={desactivar} onKeyDown={teclaHandler}
                           onChange={e => edicionHandler(e.target.value)} autoFocus={autoFocus}
                           onFocus={focusHandler} onBlur={onBlur} ref={refLocal} readOnly={readOnly}/>
                    {feedbackInvalido()}
                </div>
            )
        } else if (titulo) {
            return (
                <div className={className + ' input-group' + (chico ? ' input-group-sm' : '') +
                    (textoInvalido !== undefined && textoInvalido !== '' ? ' has-validation' : '')}>
                    <div className='input-group-text'>
                        {titulo.startsWith('fas ') ? <i className={titulo}/> : titulo}
                    </div>
                    <input type={tipo} className={`form-control ${st.inputs} ` + (invalido ? 'is-invalid ' : '') +
                        (numerico && !izquierda ? 'text-end ' : '')} value={value}
                           placeholder={placeholder} disabled={desactivar} onKeyDown={teclaHandler}
                           onChange={e => edicionHandler(e.target.value)} autoFocus={autoFocus}
                           onFocus={focusHandler} onBlur={onBlur} ref={refLocal} readOnly={readOnly}/>
                    {clave && (
                        <div className='input-group-text bg-white' style={{cursor: 'pointer'}}
                             onClick={() => setMostrarClave(!mostrarClave)}>
                            <i className={mostrarClave ? 'fas fa-eye-slash' : 'fas fa-eye'}/>
                        </div>
                    )}
                    {componentePosterior && (
                        <div className='input-group-text'>{componentePosterior}</div>
                    )}
                    {feedbackInvalido()}
                </div>
            )
        } else {
            return (
                <div className={'input-group' + (chico ? ' input-group-sm' : '')}>
                    <input type={tipo} placeholder={placeholder} disabled={desactivar}
                           className={className + ` form-control ${st.inputs} `
                               + (invalido ? 'is-invalid ' : '') + (chico ? 'form-control-sm ' : '') +
                               (numerico && !izquierda ? 'text-end ' : '') + (sinBorde ? 'border-0 ' : '')}
                           onKeyDown={teclaHandler}
                           value={value} onChange={e => edicionHandler(e.target.value)} autoFocus={autoFocus}
                           onFocus={focusHandler} onBlur={onBlur} ref={refLocal} readOnly={readOnly}/>
                    {clave && (
                        <div className='input-group-text bg-white' style={{cursor: 'pointer'}}
                             onClick={() => setMostrarClave(!mostrarClave)}>
                            <i className={mostrarClave ? 'fas fa-eye-slash' : 'fas fa-eye'}/>
                        </div>
                    )}
                    {componentePosterior && (
                        <div
                            className={'input-group-text' + (disabled ? '' : ' bg-white') +
                                (sinBorde ? ' border-0' : '')}>
                            {componentePosterior}
                        </div>
                    )}
                    {feedbackInvalido()}
                </div>
            )
        }
    }

    const vistaAutocompletar = (): JSX.Element => {
        if (autoCompletar === undefined || !listaValores.length) return <React.Fragment/>

        const maxValores = 5
        const valoresAutocompletar = listaValores
            .filter(v => v.toLocaleUpperCase().includes(value.toLocaleUpperCase()))

        const cantValores = valoresAutocompletar.length

        if (!valoresAutocompletar.length) return <React.Fragment/>

        return (
            <div className='border rounded-1 mt-1 mx-1 shadow-sm'>
                {valoresAutocompletar.slice(0, maxValores).map((v, k) => (
                    <div key={k} className='p-1 renglonhover'
                         onClick={() => {
                             edicionHandler(`${v} `, true)
                             refLocal.current?.focus()
                         }}>
                        {v}
                    </div>
                ))}
                {cantValores > maxValores && (
                    <div className='p-1 small text-danger'>
                        +{cantValores - maxValores} valores más
                    </div>
                )}
            </div>
        )
    }

    // Devolver solo el control o incluir el marco de un campo autocompletar
    return (
        <div>
            {vistaControl()}
            {vistaAutocompletar()}
        </div>
    )
})

export default InputTexto
