import React, {useEffect} from "react";
import BotonBarraNavegacion from "./BotonBarraNavegacion";
import {seccionesApp} from "../modulos";
import './BarraNavegacion.css'
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {useLocation} from "react-router-dom";
import TituloVentana from "../TituloVentana";
import {useGsBackend} from "../funcionesApi";
import {setDeslogueo} from "../redux/authReducer";

const BarraNavegacion: React.FC = () => {
    const modulosHabilitados = useAppSelector(state => state.auth.accesos)
    const admin = useAppSelector(state => state.auth.admin)
    const location = useLocation()
    const dispatch = useAppDispatch()
    const {pedidoJson} = useGsBackend()

    const desloguear = (): void => {
        pedidoJson({
            url: 'logout', method: 'post', ok: () => {
                localStorage.removeItem('login')
                dispatch(setDeslogueo())
            }
        })
    }

    useEffect(() => {
        const l = location.pathname.split('/').slice(1)[0] || 'inicio'
        TituloVentana(undefined, seccionesApp.filter(s => s.clave === l)[0].titulo || '')
    }, [location])

    return (
        <nav className='navbar navbar-expand navbar-dark barraNavegacion pe-3 navbar-nav-scroll'>
            <ul className='navbar-nav'>
                {seccionesApp
                    .filter(s => modulosHabilitados?.map(m => m.modulo).includes(s.clave)
                        || (admin && s.clave === 'admin'))
                    .sort((a, b) => a.orden - b.orden)
                    .map((s, k) => (
                        <BotonBarraNavegacion key={k} icono={s.icono} titulo={s.titulo}
                                              clave={s.clave}/>
                    ))}
            </ul>
            <ul className='navbar-nav ms-auto'>
                <BotonBarraNavegacion icono='fas fa-sign-out-alt' titulo='Salir' clave='cerrarsesion'
                                      onClick={desloguear}/>
            </ul>
        </nav>
    )
}

export default BarraNavegacion