import React from "react";
import AceptarCancelar from "../controles/AceptarCancelar";
import Modal from "../controles/Modal";
import {useGsBackend} from "../funcionesApi";

type Props = {
    idPedido: number,
    onAceptar: () => void,
    onVolver: () => void
}

const CerrarPedido: React.FC<Props> = ({idPedido, onAceptar, onVolver}) => {
    const {pedidoJson, guardando} = useGsBackend()

    const cerrarPedidoHandler = async (): Promise<void> => {
        const fd = new FormData()
        fd.append('id', idPedido.toString())

        pedidoJson({url: 'pedidos/cerrar', method: 'post', body: fd, ok: onAceptar})
    }

    return (
        <Modal colorBorde='border-success'>
            <div className='row g-3'>
                <div className='col-auto pt-2' style={{fontSize: '2em'}}>
                    <i className='fas fa-check-circle text-success'/>
                </div>
                <div className='col'>
                    <h4>Cerrar Pedido</h4>
                    <p>El pedido se marcará como completado</p>
                    <p>¿Confirmar cierre del pedido?</p>
                </div>
            </div>
            <AceptarCancelar onCancelar={() => onVolver()} colorAceptar='btn-success'
                             onAceptar={() => cerrarPedidoHandler()} tituloAceptar='Aceptar y cerrar pedido'
                             guardando={guardando}/>
        </Modal>
    )
}

export default CerrarPedido