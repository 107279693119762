import React from "react";

type Props = {
    titulo: string,
    detalle: string,
    negrita?: boolean,
    espacio?: boolean,
    chico?: boolean,
    rojo?: boolean
}

const FilaDetalle: React.FC<Props> = (
    {
        titulo, detalle, negrita, espacio, chico,
        rojo
    }
) => {
    if (detalle === '') {
        return (
            <></>
        )
    } else {
        return (
            <div className={'row' + (espacio ? ' mb-3' : ' mb-2') + (chico ? ' small' : '')}>
                <div className='col-md-3'>{titulo}</div>
                <div className={'col-md-9' + (negrita ? ' fw-bold' : '') + (rojo ? ' text-danger' : '')}>
                    {detalle}
                </div>
            </div>
        )
    }
}

export default FilaDetalle