import React from "react";
import {estilosApp} from "../estilos";
import {useAppSelector} from "../redux/hooks";

type Props = {
    children: React.ReactNode,
    toolbar?: boolean
}

const BarraTarjeta: React.FC<Props> = ({children, toolbar}) => {
    const st = estilosApp(useAppSelector(state => state.estado.modo_oscuro))

    if (!React.Children.toArray(children).map(c => typeof c).some(c => c !== null)) return <React.Fragment/>

    return (
        <div className={`card ${st.tarjeta} shadow-sm border-0 mb-2 text-nowrap`}
             style={{overflowX: 'auto', overflowY: 'hidden'}}>
            <div className='card-body p-2'>
                {toolbar ? <div className='btn-toolbar'>{children}</div> : children}
            </div>
        </div>
    )
}

export default BarraTarjeta