import React from "react";

type Props = {
    value: number,
    min?: number,
    onChange: (v: number) => void
}

const MasMenos: React.FC<Props> = ({value, min, onChange}) => {
    const minimo = min !== undefined? min : 0

    return (
        <div className='input-group input-group-sm flex-nowrap'>
            <button type='button' className='btn btn-light text-primary px-1 px-xl-2' disabled={value <= minimo}
                    onClick={() => onChange(value <= minimo ? minimo : value - 1)}>
                <i className='fas fa-chevron-left text-primary'/>
            </button>
            <input className='form-control px-0 text-center' type='text' readOnly value={value.toString()}/>
            <button type='button' className='btn btn-light text-primary px-1 px-xl-2'
                    onClick={() => onChange(value + 1)}>
                <i className='fas fa-chevron-right text-primary'/>
            </button>
        </div>
    )
}

export default MasMenos