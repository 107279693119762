import {seccionesApp} from "./modulos";

const TituloVentana = (cliente: string | undefined, pantalla: string) => {
    if (cliente !== undefined) {
        const info = seccionesApp.filter(mod => mod.clave === pantalla)
        document.title = `${cliente} :: ${info.length === 1 && info[0].titulo}`
    } else {
        document.title = pantalla
    }
}

export default TituloVentana
