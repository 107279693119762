import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import CerrarModal from "../controles/CerrarModal";
import FilaDetalle from "../controles/FilaDetalle";
import {useGsBackend} from "../funcionesApi";

type DetalleItem = {
    descripcion: string,
    cantidad: string,
    costo_unitario: string,
    costo_total: string
}

type InfoDetalle = {
    proveedor: string,
    fecha: string,
    factura: string,
    importe: string,
    comentarios: string,
    usuario: string,
    detalle: Array<DetalleItem>
}

type Props = {
    idCompra: number,
    onCerrar: () => void
}

const ProveedoresHistorialDetalle: React.FC<Props> = ({idCompra, onCerrar}) => {
    const [datos, setDatos] = useState<InfoDetalle | undefined>(undefined)
    const {pedidoJson} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: `proveedores/historial/detalle?id=${idCompra}`, ok: setDatos})
    }, [pedidoJson, idCompra])

    if (datos !== undefined) {
        return (
            <Modal>
                <h4>Detalle de la Compra</h4>
                <FilaDetalle titulo='Proveedor' detalle={datos.proveedor} negrita chico/>
                <FilaDetalle titulo='Fecha' detalle={datos.fecha} chico/>
                <FilaDetalle titulo='Factura' detalle={datos.factura} chico/>
                <FilaDetalle titulo='Comentarios' detalle={datos.comentarios} chico/>
                <table className='table table-sm border small mb-2'>
                    <thead>
                    <tr className='headerTabla'>
                        <th>Item</th>
                        <th className='text-end'>Cantidad</th>
                        <th className='text-end'>Costo Un.</th>
                        <th className='text-end'>Costo Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.detalle.map((d, k) => (
                        <tr key={k}>
                            <td>{d.descripcion}</td>
                            <td className='text-nowrap text-end border-end'>{d.cantidad}</td>
                            <td className='text-nowrap text-end border-end'>{d.costo_unitario}</td>
                            <td className='text-nowrap text-end'>{d.costo_total}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <FilaDetalle titulo='Importe Total' detalle={datos.importe} chico negrita/>
                <FilaDetalle titulo='Usuario' detalle={datos.usuario} chico/>
                <CerrarModal onCerrar={onCerrar}/>
            </Modal>
        )
    } else {
        return (
            <></>
        )
    }
}

export default ProveedoresHistorialDetalle