import React from "react";

type Props = {
    titulo: string,
    col: number,
    tipo: number,
    badge?: string,
    onClick: () => void,
    stock?: number,
    restaStock?: number,
    precio?: string
}

const TarjetaAdicion: React.FC<Props> = (
    {
        titulo, col, tipo, badge, onClick,
        stock, restaStock, precio
    }
) => {
    const nroStock = stock !== undefined ? (stock - (restaStock || 0)) : undefined
    const agregable = tipo !== 1 || (nroStock !== undefined && nroStock > 0)

    const iconoProducto = (): string => {
        switch (tipo) {
            case 1:
                return 'fas fa-boxes-stacked'
            case 2:
                return 'fas fa-bowl-rice'
            default:
                return 'fas fa-chevron-right'
        }
    }

    return (
        <div className={`col-lg-${col}`} onClick={agregable ? onClick : undefined}>
            <div className={'card shadow-sm border-0 h-100 p-2 border-start border-4' +
                (agregable ? ' border-primary cardhover' : ' border-secondary')}
                 style={{opacity: agregable ? undefined : '0.5'}}>
                <div className='row g-2'>
                    <div className='col-auto'>
                        <i className={iconoProducto() + (agregable ? ' text-primary' : ' text-muted')}/>
                    </div>
                    <div className='col'>
                        <div className='fw-bold'>{titulo}</div>
                        {badge && (
                            <div><span className='badge bg-secondary'>{badge}</span></div>
                        )}
                        {precio !== undefined && (
                            <div>{precio}</div>
                        )}
                    </div>
                    {nroStock !== undefined && tipo === 1 && (
                        <div className='col-auto text-nowrap fw-bold'>{nroStock}</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TarjetaAdicion