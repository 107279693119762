import React, {useState} from "react";
import Modal from "../controles/Modal";
import InputTexto from "../controles/InputTexto";
import Boton from "../controles/Boton";
import ModalBotones from "../controles/ModalBotones";
import {useGsBackend} from "../funcionesApi";
import InputMoneda from "../controles/InputMoneda";
import InputSwitch from "../controles/InputSwitch";

export interface DatosEdicionCliente {
    id: number,
    nombre: string,
    telefono: string,
    email: string,
    direccion: string,
    comentarios: string,
    descuento: number,
    envio_gratis: boolean
}

type Validacion = {
    nombre: boolean
}

type Props = {
    datosIniciales: DatosEdicionCliente
    onGuardado: (tel: string) => void,
    onCancelar: () => void
}

const EdicionClientes: React.FC<Props> = ({datosIniciales, onGuardado, onCancelar}) => {
    const validacionInicial: Validacion = {nombre: true}
    const [edicion, setEdicion] = useState<DatosEdicionCliente>(datosIniciales)
    const [valid, setValid] = useState<Validacion>(validacionInicial)
    const {pedidoJson, guardando} = useGsBackend()

    const guardarHandler = async (eliminar?: boolean) => {
        const vld: Validacion = {
            nombre: edicion.nombre !== ''
        }
        setValid(vld)
        if (!vld.nombre) return

        const fd = new FormData()
        fd.append('accion', eliminar ? 'eliminar' : edicion.id === 0 ? 'nuevo' : 'editar')
        fd.append('id_cliente', edicion.id.toString())
        fd.append('nombre', edicion.nombre)
        fd.append('telefono', edicion.telefono)
        fd.append('email', edicion.email)
        fd.append('direccion', edicion.direccion)
        fd.append('comentarios', edicion.comentarios)
        fd.append('descuento', edicion.descuento.toString())
        fd.append('envio_gratis', edicion.envio_gratis ? '1' : '0')

        pedidoJson({url: 'clientes/editar', method: 'post', body: fd, ok: () => onGuardado(edicion.telefono)})
    }

    return (
        <Modal grande>
            <h4>{edicion.id === 0 ? 'Alta de Cliente' : 'Editar Cliente'}</h4>
            <div className='row mb-2'>
                <div className='col-md-3 pt-1'>Nombre</div>
                <div className='col-md-9'>
                    <InputTexto value={edicion.nombre} onChange={v => setEdicion({...edicion, nombre: v})}
                                autoFocus invalido={!valid.nombre}/>
                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-md-3 pt-1'>Teléfono</div>
                <div className='col-md-9'>
                    <InputTexto value={edicion.telefono} onChange={v => setEdicion({...edicion, telefono: v})}/>
                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-md-3 pt-1'>E-Mail</div>
                <div className='col-md-9'>
                    <InputTexto value={edicion.email} onChange={v => setEdicion({...edicion, email: v})}/>
                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-md-3 pt-1'>Dirección</div>
                <div className='col-md-9'>
                    <div className='row g-2'>
                        <div className='col-md'>
                            <InputTexto value={edicion.direccion} autoCompletar='calles'
                                        onChange={v => setEdicion({...edicion, direccion: v})}/>
                        </div>
                        <div className='col-md-auto'>
                            <Boton className='btn-light' icono='fas fa-location-dot'
                                   titulo='Maps' onClick={() => {
                                window.open('https://maps.google.com?q=' +
                                    encodeURIComponent(edicion.direccion), '_blank')
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-md-3 pt-1'>Comentarios</div>
                <div className='col-md-9'>
                    <InputTexto value={edicion.comentarios}
                                onChange={v => setEdicion({...edicion, comentarios: v})}/>
                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-md-3 pt-1'>Descuento</div>
                <div className='col-md-9'>
                    <InputMoneda value={edicion.descuento} porcentaje
                                 onChange={v => setEdicion({...edicion, descuento: v})}/>
                </div>
            </div>
            <div className='row mb-2 py-2'>
                <div className='col-md-3'>Envío Gratis</div>
                <div className='col-md-9'>
                    <InputSwitch checked={edicion.envio_gratis}
                                 onChange={v => setEdicion({...edicion, envio_gratis: v})}/>
                </div>
            </div>
            <ModalBotones editando={true}
                          onCancelar={() => onCancelar()}
                          onGuardar={() => guardarHandler()} guardando={guardando}
                          onEliminar={() => guardarHandler(true)}/>
        </Modal>
    )
}

export default EdicionClientes