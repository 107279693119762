import React from "react";
import './Modal.css'
import {estilosApp} from "../estilos";
import {useAppSelector} from "../redux/hooks";

type Props = {
    children: any,
    className?: string | undefined,
    sinFondo?: boolean,
    colorBorde?: string,
    titulo?: string,
    grande?: boolean,
    mini?: boolean,
    full?: boolean,
    bajoModal?: JSX.Element
}

const Modal: React.FC<Props> = (
    {
        children, className, sinFondo, colorBorde,
        titulo, grande, bajoModal, mini,
        full
    }
) => {
    const tamanio = mini ? '' : grande ? 'modal-grande ' : full ? 'modal-full' : 'modal-mediano '
    const estilo = className === undefined ? '' : className
    const st = estilosApp(useAppSelector(state => state.estado.modo_oscuro))

    return (
        <div className={'alineacionmodal ' + (sinFondo ? '' : 'fondomodal')}>
            <div className={'contenidomodal ' + tamanio + estilo}>
                <div className={`card ${st.modal} m-4 sombramodal border-0 border-start border-4 ` +
                    (colorBorde === undefined ? 'border-secondary' : colorBorde)}>
                    {titulo !== undefined && <div className='card-header'>{titulo}</div>}
                    <div className='card-body border-1'>{children}</div>
                </div>
                {bajoModal !== undefined && (<div className='mt-2'>{bajoModal}</div>)}
            </div>
        </div>
    )
}

export default Modal
