import React from "react";
import paramsApp from "../paramsApp";

type Props = {
    registros: number,
    pagina: number,
    onChange: (arg: number) => void,
    porPagina?: number
}

export const paginado = <T, >(inp: Array<T>, pagina: number, porPagina?: number): Array<T> => {
    const registrosPag = porPagina || paramsApp.porpagina
    return inp.slice((pagina - 1) * registrosPag, registrosPag * pagina)
}

const ControlPaginas: React.FC<Props> = ({registros, pagina, onChange, porPagina}) => {
    const paginas = Math.max(Math.ceil(registros / (porPagina || paramsApp.porpagina)), 1)

    return (
        <div className='d-inline-block'>
            <div className='row fw-normal g-3'>
                <div className='col-auto'>
                    <div className='input-group input-group-sm'>
                        <button type='button' className='btn btn-light' disabled={pagina === 1}
                                onClick={() => onChange(1)}>
                            <i className='fas fa-angle-double-left text-primary'/>
                        </button>
                        <button type='button' className='btn btn-light text-primary' disabled={pagina === 1}
                                onClick={() => onChange(pagina - 1)}>
                            <i className='fas fa-angle-left'/>
                        </button>
                        <input type='text' className='form-control text-center' readOnly
                               value={`Página ${pagina} de ${paginas}`}/>
                        <button type='button' className='btn btn-light text-primary' disabled={pagina === paginas}
                                onClick={() => onChange(pagina + 1)}>
                            <i className='fas fa-angle-right'/>
                        </button>
                        <button type='button' className='btn btn-light text-primary' disabled={pagina === paginas}
                                onClick={() => onChange(paginas)}>
                            <i className='fas fa-angle-double-right'/>
                        </button>
                    </div>
                </div>
                <div className='col pt-1'>
                    <i className='text-info'/>{registros} registros
                </div>
            </div>
        </div>
    )
}

export default ControlPaginas