import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ClaveSeccion} from "../modulos";

export enum NivelesAcceso {
    NivelSinAcceso = 0,
    NivelConsulta = 1,
    NivelHabilitado = 2,
    NivelSupervisor = 3
}

export interface AuthState {
    usuario?: string,
    nombrecompleto?: string,
    token?: string,
    cliente?: string,
    accesos?: Array<AccesoModulo>,
    admin?: boolean
}

export interface AccesoModulo {
    modulo: ClaveSeccion,
    nivel_acceso: NivelesAcceso
}

export interface AccesoState {
    accesos?: Array<AccesoModulo>,
    admin: boolean
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        usuario: undefined,
        nombrecompleto: undefined,
        token: undefined,
        cliente: undefined,
        accesos: [] as Array<AccesoModulo>,
        admin: undefined
    } as AuthState,
    reducers: {
        setLogueo: (state, action: PayloadAction<AuthState>) => {
            state.usuario = action.payload.usuario
            state.nombrecompleto = action.payload.nombrecompleto
            state.token = action.payload.token
            state.cliente = action.payload.cliente
            state.accesos = action.payload.accesos
            state.admin = action.payload.admin
        },
        setPermisos: (state, action: PayloadAction<AccesoState>) => {
            state.accesos = action.payload.accesos
            state.admin = action.payload.admin
        },
        setDeslogueo: state => {
            state.usuario = undefined
            state.nombrecompleto = undefined
            state.token = undefined
            state.cliente = undefined
            state.accesos = [] as Array<AccesoModulo>
            state.admin = undefined
        }
    }
})

export const {setLogueo, setPermisos, setDeslogueo} = authSlice.actions

export default authSlice.reducer
