import React, {useState} from "react";
import {Cerveza, InfoRecarga, ValidacionRecarga} from "./Cerveceria";
import Tarjeta from "../controles/Tarjeta";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import {nivelAcceso} from "../autorizacion";
import Modal from "../controles/Modal";
import InputTexto from "../controles/InputTexto";
import ModalBotones from "../controles/ModalBotones";
import {NivelesAcceso} from "../redux/authReducer";
import {useGsBackend} from "../funcionesApi";

type Props = {
    cerveza: Cerveza,
    mediosDePago: Array<OpcionSelect>,
    medioDePagoPredeterminado: number,
    onRecargar: () => void,
    proveedores: Array<OpcionSelect>,
    categorias: Array<OpcionSelect>
}

const TarjetaCerveza: React.FC<Props> = (
    {
        cerveza, onRecargar, mediosDePago, medioDePagoPredeterminado,
        proveedores, categorias
    }
) => {
    const validacionRecargaInicial: ValidacionRecarga =
        {id_proveedor: true, descripcion: true, litros: true, id_categoria: true}
    const [medioDePago, setMedioDePago] = useState<number>(medioDePagoPredeterminado)
    const [desactivado, setDesactivado] = useState<boolean>(false)
    const [gratis, setGratis] = useState<boolean>(false)
    const [recarga, setRecarga] = useState<InfoRecarga | undefined>(undefined)
    const acceso = nivelAcceso('cerveceria')

    const anchoBarra = (100 * cerveza.restante / cerveza.ultima_cantidad).toString() + '%'
    const formatoNumeros = Intl.NumberFormat('es-AR')
    const categoriaLarga = cerveza.categoria.length > 16
    const tiempoEspera = 1000

    const {pedidoJson, guardando} = useGsBackend()

    const ventaHandler = (cerveza: number, tipo: string): void => {
        const fd = new FormData()
        fd.append('accion', 'venta')
        fd.append('cerveza', cerveza.toString())
        fd.append('tipo', tipo)
        fd.append('gratis', gratis ? '1' : '0')
        fd.append('mediodepago', medioDePago.toString())

        setDesactivado(true)
        pedidoJson({url: 'cerveceria/venta', method: 'post', body: fd, ok: onRecargar})
        setTimeout(() => {
            setMedioDePago(medioDePagoPredeterminado)
            setGratis(false)
            setDesactivado(false)
        }, tiempoEspera)
    }

    const recargarHandler = async (eliminar?: boolean): Promise<void> => {
        if (recarga === undefined) return

        const vld: ValidacionRecarga = {
            id_proveedor: recarga.id_proveedor !== 0,
            descripcion: recarga.descripcion !== '',
            litros: (parseFloat(recarga.litros) || 0) !== 0,
            id_categoria: recarga.id_categoria !== 0
        }
        setRecarga({...recarga, validacion: vld})
        if (Object.values(vld).some(v => !v)) return

        const fd = new FormData()
        fd.append('accion', eliminar ? 'eliminar' : 'recarga')
        fd.append('id', recarga.id.toString())
        fd.append('id_proveedor', recarga.id_proveedor.toString())
        fd.append('id_categoria', recarga.id_categoria.toString())
        fd.append('descripcion', recarga.descripcion)
        fd.append('descripcion_larga', recarga.descripcion_larga)
        fd.append('litros', recarga.litros)

        pedidoJson({
            url: 'cerveceria/recarga', method: 'post', body: fd,
            ok: () => {
                setRecarga(undefined)
                onRecargar()
            }
        })
    }

    return (
        <div className='col'>
            <Tarjeta>
                <div className={categoriaLarga ? '' : 'row g-2'}>
                    <div className={categoriaLarga ? 'mb-2' : 'col-auto h5'}>
                        <span className='badge bg-light px-2 text-muted'>{cerveza.categoria}</span>
                    </div>
                    <div className={categoriaLarga ? 'mb-2 h5' : 'col h5'}>
                        {cerveza.descripcion} <span className='text-muted'>({cerveza.proveedor})</span>
                    </div>
                </div>
                {acceso >= NivelesAcceso.NivelHabilitado && (
                    <div className='row g-2 mb-2'>
                        <div className='col'>
                            <InputSelect chico value={gratis ? 0 : medioDePago}
                                         textocero={gratis ? '(No aplica)' : '(No especificar)'}
                                         estiloTitulo='bg-white text-primary'
                                         titulo={(<i className='fas fa-money-bill'/>)}
                                         opciones={mediosDePago} onChange={setMedioDePago}
                                         disabled={gratis}/>
                        </div>
                        <div className='col-auto text-nowrap'>
                            <button type='button'
                                    className={'btn btn-sm me-2 ' +
                                        (gratis ? 'btn-primary text-light' :
                                            'btn-light text-primary')}
                                    onClick={() => setGratis(!gratis)}>
                                <i className='fab fa-creative-commons-nc'/>
                            </button>
                            <button type='button' className='btn btn-light text-primary btn-sm'
                                    onClick={() => {
                                        setRecarga({
                                            id: cerveza.id,
                                            id_proveedor: cerveza.id_proveedor,
                                            id_categoria: cerveza.id_categoria,
                                            descripcion: cerveza.descripcion,
                                            descripcion_larga: cerveza.descripcion_larga,
                                            litros: cerveza.ultima_cantidad.toString(),
                                            validacion: validacionRecargaInicial
                                        } as InfoRecarga)
                                    }}>
                                <i className='fas fa-sync-alt'/>
                            </button>
                        </div>
                    </div>
                )}
                <div className='progress bg-light'>
                    <div className='progress-bar progress-bar-striped bg-primary'
                         style={{width: anchoBarra}}/>
                </div>
                <div className='small'>
                    {formatoNumeros.format(cerveza.restante)}L restantes
                </div>
                {acceso >= NivelesAcceso.NivelHabilitado && (
                    <div className='row gx-1 mt-2'>
                        {cerveza.resumen_recargas_grandes !== '' && (
                            <div className='col'>
                                <button type='button' disabled={desactivado} onClick={() =>
                                    ventaHandler(cerveza.id, 'grande')}
                                        className='btn btn-light btn-sm text-center w-100'>
                                    <div>
                                        <i className='fas fa-bottle-water text-primary'/>
                                    </div>
                                    <div className='small'>
                                        {cerveza.desc_recargas_grandes}
                                    </div>
                                    <div className='small fw-bold'>{cerveza.resumen_recargas_grandes}</div>
                                </button>
                            </div>
                        )}
                        {cerveza.resumen_recargas_medianas !== '' && (
                            <div className='col'>
                                <button type='button' disabled={desactivado} onClick={() =>
                                    ventaHandler(cerveza.id, 'mediana')}
                                        className='btn btn-light btn-sm text-center w-100'>
                                    <div>
                                        <i className='fas fa-bottle-water text-primary'/>
                                    </div>
                                    <div className='small'>
                                        {cerveza.desc_recargas_medianas}
                                    </div>
                                    <div className='small fw-bold'>{cerveza.resumen_recargas_medianas}</div>
                                </button>
                            </div>
                        )}
                        {cerveza.resumen_recargas_chicas !== '' && (
                            <div className='col'>
                                <button type='button' disabled={desactivado} onClick={() =>
                                    ventaHandler(cerveza.id, 'chica')}
                                        className='btn btn-light btn-sm text-center w-100'>
                                    <div>
                                        <i className='fas fa-bottle-water text-primary'/>
                                    </div>
                                    <div className='small'>
                                        {cerveza.desc_recargas_chicas}
                                    </div>
                                    <div className='small fw-bold'>{cerveza.resumen_recargas_chicas}</div>
                                </button>
                            </div>
                        )}
                        {cerveza.resumen_pintas_grandes !== '' && (
                            <div className='col'>
                                <button type='button' disabled={desactivado} onClick={() =>
                                    ventaHandler(cerveza.id, 'pintagrande')}
                                        className='btn btn-light btn-sm text-center w-100'>
                                    <div>
                                        <i className='fas fa-beer-mug-empty text-primary'/>
                                    </div>
                                    <div className='small'>
                                        {cerveza.desc_pintas_grandes}
                                    </div>
                                    <div className='small fw-bold'>{cerveza.resumen_pintas_grandes}</div>
                                </button>
                            </div>
                        )}
                        {cerveza.resumen_pintas_chicas !== '' && (
                            <div className='col'>
                                <button type='button' disabled={desactivado} onClick={() =>
                                    ventaHandler(cerveza.id, 'pintachica')}
                                        className='btn btn-light btn-sm text-center w-100'>
                                    <div>
                                        <i className='fas fa-beer-mug-empty text-primary'/>
                                    </div>
                                    <div className='small'>
                                        {cerveza.desc_pintas_chicas}
                                    </div>
                                    <div className='small fw-bold'>{cerveza.resumen_pintas_chicas}</div>
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </Tarjeta>
            {recarga !== undefined && (
                <Modal>
                    <h4>Recargar Cerveza</h4>
                    <div className='row mb-2'>
                        <div className='col-md-4 pt-1'>Proveedor</div>
                        <div className='col-md-8'>
                            <InputSelect value={recarga.id_proveedor} opciones={proveedores}
                                         onChange={v => setRecarga({...recarga, id_proveedor: v})}
                                         invalido={!recarga.validacion.id_proveedor}
                                         autoFocus={recarga.id === 0}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-4 pt-1'>Estilo</div>
                        <div className='col-md-8'>
                            <InputTexto value={recarga.descripcion} autoFocus={recarga.id !== 0}
                                        onChange={v => setRecarga({...recarga, descripcion: v})}
                                        invalido={!recarga.validacion.descripcion}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-4 pt-1'>Descripción (Menú)</div>
                        <div className='col-md-8'>
                            <InputTexto value={recarga.descripcion_larga} placeholder='(Opcional)'
                                        onChange={v => setRecarga({...recarga, descripcion_larga: v})}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-4 pt-1'>Categoría</div>
                        <div className='col-md-8'>
                            <InputSelect value={recarga.id_categoria} opciones={categorias}
                                         onChange={v => setRecarga({...recarga, id_categoria: v})}
                                         invalido={!recarga.validacion.id_categoria}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-4 pt-1'>Litros</div>
                        <div className='col-md-8'>
                            <InputTexto value={recarga.litros} numerico izquierda decimal
                                        onChange={v => setRecarga({...recarga, litros: v})}
                                        invalido={!recarga.validacion.litros}/>
                        </div>
                    </div>
                    <ModalBotones guardando={guardando}
                                  editando onCancelar={() => setRecarga(undefined)}
                                  onEliminar={() => recargarHandler(true)}
                                  onGuardar={() => recargarHandler()}/>
                </Modal>
            )}
        </div>
    )
}

export default TarjetaCerveza