import React, {useCallback, useEffect} from "react";
import Boton from "./Boton";
import BotonCarga from "./BotonCarga";

type Props = {
    onAceptar: () => void,
    onCancelar: () => void,
    aceptarDisable?: boolean,
    tituloCancelar?: string,
    tituloAceptar?: string,
    colorAceptar?: string,
    guardando?: boolean
}

const AceptarCancelar: React.FC<Props> = (
    {
        onAceptar, onCancelar, aceptarDisable, tituloAceptar,
        tituloCancelar, colorAceptar, guardando
    },
) => {
    const tituloBotonAceptar = tituloAceptar !== undefined ? tituloAceptar : 'Aceptar'
    const tituloBotonCancelar = tituloCancelar !== undefined ? tituloCancelar : 'Cancelar'
    const estiloAceptar = colorAceptar === undefined ? 'btn-primary' : colorAceptar

    const teclasEspeciales = useCallback(e => {
        if (e.keyCode === 27) {
            onCancelar()
        } else if (e.keyCode === 13) {
            onAceptar()
        }
    }, [onAceptar, onCancelar])

    useEffect(() => {
        document.addEventListener('keydown', teclasEspeciales, false)
        return () => document.removeEventListener('keydown', teclasEspeciales, false)
    }, [teclasEspeciales])

    if (guardando) {
        return (
            <div className='text-end'>
                <BotonCarga grande titulo='Guardando...'/>
            </div>
        )
    } else {
        return (
            <div className='text-end'>
                <Boton titulo={tituloBotonCancelar} icono='fas fa-times' onClick={onCancelar}
                       className='btn-secondary me-2'/>
                <Boton titulo={tituloBotonAceptar} icono='fas fa-check' onClick={onAceptar} disabled={aceptarDisable}
                       className={estiloAceptar}/>
            </div>
        )
    }
}

export default AceptarCancelar