import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import TituloPagina from "../controles/TituloPagina";
import BarraTarjeta from "../controles/BarraTarjeta";
import Boton from "../controles/Boton";
import Tarjeta from "../controles/Tarjeta";
import TrInfo from "../controles/TrInfo";
import TextoND from "../controles/TextoND";
import ProveedoresHistorialDetalle from "./ProveedoresHistorialDetalle";
import Tabla from "../controles/Tabla";
import {useGsBackend} from "../funcionesApi";

type HistorialProveedor = {
    tipo: number,
    id: number,
    fecha: string,
    factura: string,
    cantidad_productos: number,
    importe: string,
    comentarios: string,
    usuario: string,
    nombre_usuario: string
}

type InfoHistorial = {
    proveedor: string,
    historial: Array<HistorialProveedor>
}

const ProveedoresHistorial: React.FC = () => {
    const [params] = useSearchParams()
    const idProveedor = parseInt(params.get('id') || '0') || 0
    const [datos, setDatos] = useState<InfoHistorial>({proveedor: '', historial: []})
    const [detalle, setDetalle] = useState<number | undefined>(undefined)
    const navigate = useNavigate()

    const {pedidoJson} = useGsBackend()
    useEffect(() => {
        pedidoJson({url: `proveedores/historial?id=${idProveedor}`, ok: setDatos})
    }, [pedidoJson, idProveedor])

    return (
        <>
            <TituloPagina icono='fas fa-search' titulo={'Historial del Proveedor ' + datos.proveedor}/>
            <BarraTarjeta>
                <Boton toolbar titulo='Volver' icono='fas fa-chevron-left' className='btn-light'
                       onClick={() => navigate('../')}/>
            </BarraTarjeta>
            <Tarjeta>
                <Tabla>
                    <thead>
                    <tr className='headerTabla'>
                        <th>Tipo</th>
                        <th>Fecha</th>
                        <th>Factura</th>
                        <th>Productos</th>
                        <th>Importe</th>
                        <th>Comentarios</th>
                        <th>Usuario</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.historial.length ? datos.historial.map((h, k) => (
                        <tr key={k} className={h.tipo === 1 ? 'trhover' : ''} onClick={
                            h.tipo === 1 ? () => setDetalle(h.id) : undefined
                        }>
                            {h.tipo === 1 ? (
                                <td className='text-primary'><i className='fas fa-circle-plus me-2'/>Compra</td>
                            ) : (
                                <td>&nbsp;</td>
                            )}
                            <td>{h.fecha}</td>
                            <td><TextoND texto={h.factura} guion/></td>
                            <td className='text-end'>{h.cantidad_productos}</td>
                            <td className='text-nowrap text-end border-end pe-2'>{h.importe}</td>
                            <td><TextoND texto={h.comentarios} guion/></td>
                            <td>{h.usuario}</td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={7} texto='No hay registros para mostrar'/>
                    )}
                    </tbody>
                </Tabla>
            </Tarjeta>
            {detalle !== undefined && (
                <ProveedoresHistorialDetalle idCompra={detalle} onCerrar={() => setDetalle(undefined)}/>
            )}
        </>
    )
}

export default ProveedoresHistorial