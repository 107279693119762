import React from "react";

type Props = {
    titulo: string,
    className?: string,
    checked: boolean,
    onChange: (arg: boolean) => void,
    htmlId?: string,
    disabled?: boolean
}

const InputCheck: React.FC<Props> = (
    {
        titulo, className, checked, onChange, htmlId,
        disabled
    }
) => {
    return <div>
        <div className={'form-check ' + (className ? className : '')}>
            <input className='form-check-input cursorpointer' type='checkbox' value='' checked={checked} id={htmlId}
                   onChange={(e) => onChange(e.target.checked)}
                   disabled={disabled}/>
            <label className='form-check-label cursorpointer' htmlFor={htmlId}>{titulo}</label>
        </div>
    </div>
}

export default InputCheck