import React, {useEffect} from 'react';
import BarraNavegacion from "./navegacion/BarraNavegacion";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import PantallaLogin from "./login/PantallaLogin";
import PantallaCargando from "./controles/PantallaCargando";
import {seccionesApp} from "./modulos";
import BarraSuperior from "./navegacion/BarraSuperior";
import ModalError from "./controles/ModalError";
import {setDeslogueo} from "./redux/authReducer";
import AlertaAplicacion from "./controles/AlertaAplicacion";
import infoVersion from './infoVersion.json'
import {InfoAlertaSistema, setAlertaSistema} from "./redux/estadoReducer";
import VistaModo from "./controles/VistaModo";
import Notificacion from "./controles/Notificacion";

function App() {
    const auth = useAppSelector(state => state.auth)
    const estado = useAppSelector(state => state.estado)
    const cargando = estado.cargando
    const errorSistema = estado.error_sistema
    const modoOscuro = estado.modo_oscuro
    const codigoVf = useAppSelector(state => state.modo.codigo_vf)
    const location = useLocation()
    const dispatch = useAppDispatch()

    const seccionesHabilitadas = auth.token !== undefined ?
        seccionesApp.filter(s => auth.accesos?.map(a => a.modulo).includes(s.clave) ||
            (auth.admin && s.clave === 'admin')) : seccionesApp
    const pantallaInicial = seccionesHabilitadas
        .sort((a, b) => a.orden - b.orden)[0]?.clave || ''
    const pantalla = location.pathname.split('/').slice(1)[0] || 'inicio'

    // Deshabilitar el menu contextual en la aplicación
    const prevenirMenuContextual = (e: MouseEvent): void => e.preventDefault()
    useEffect(() => {
        document.addEventListener('contextmenu', prevenirMenuContextual, false)
        return () => document.removeEventListener('contextmenu', prevenirMenuContextual, false)
    })

    // Verificar si hay actualizaciones y está ejecutandose una versión en caché
    useEffect(() => {
        if (codigoVf !== undefined && codigoVf !== infoVersion.codigo) {
            dispatch(setAlertaSistema({
                mensaje: 'Hay una actualización del sistema disponible. Actualizá antes de continuar trabajando.',
                botonActualizar: true,
                cerrable: false
            } as InfoAlertaSistema))
        }
    }, [dispatch, codigoVf])

    // Prevenir usuarios sin ningún módulo habilitado
    if (pantallaInicial === '') {
        localStorage.removeItem('login')
        dispatch(setDeslogueo())
        return <></>
    }

    if (auth.token !== undefined && pantalla === 'inicio') {
        return <Navigate to={pantallaInicial}/>
    }

    // Validar que un usuario no entre en una pantalla que no tiene habilitada
    if (auth.token !== undefined && !seccionesHabilitadas.some(s => s.clave === pantalla)) {
        return <Navigate to={pantallaInicial}/>
    }

    if (auth.token === undefined) {
        return <PantallaLogin/>
    } else {
        return (
            <div className={`${modoOscuro ? 'modoOscuro' : ''} App`}>
                <VistaModo/>
                <BarraSuperior/>
                <BarraNavegacion/>
                <AlertaAplicacion/>
                <div className='container-fluid p-3'>
                    <Outlet/>
                </div>
                {cargando && <PantallaCargando/>}
                {errorSistema && <ModalError/>}
                <Notificacion/>
            </div>
        )
    }
}

export default App;
