import React from "react";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {setModoOscuro} from "../redux/estadoReducer";
import {useGsBackend} from "../funcionesApi";

const SwitchModoOscuro: React.FC = () => {
    const modoOscuro = useAppSelector(state => state.estado.modo_oscuro)
    const dispatch = useAppDispatch()
    const {guardarOpcionesUsuario} = useGsBackend()

    return (
        <div className='row g-2'>
            <div className='col-auto'>
                <i className={`fas fa-sun ${modoOscuro ? 'text-muted' : 'text-white'}`}/>
            </div>
            <div className='col text-center'>
                <div className='form-switch'>
                    <input className='form-check-input' type='checkbox' checked={modoOscuro}
                           onChange={() => {
                               const actual = modoOscuro
                               dispatch(setModoOscuro(!actual))
                               guardarOpcionesUsuario('modo_oscuro', actual ? 0 : 1)
                           }}/>
                </div>
            </div>
            <div className='col-auto'>
                <i className={`fas fa-moon me-3 ${modoOscuro ? 'text-white' : 'text-muted'}`}/>
            </div>
        </div>
    )
}

export default SwitchModoOscuro