import React from "react";
import AceptarCancelar from "../controles/AceptarCancelar";
import Modal from "../controles/Modal";
import {useGsBackend} from "../funcionesApi";

type Props = {
    idPedido: number,
    onAceptar: () => void,
    onVolver: () => void
}

const CancelarPedido: React.FC<Props> = ({idPedido, onAceptar, onVolver}) => {
    const {pedidoJson, guardando} = useGsBackend()

    const cancelarPedidoHandler = async (): Promise<void> => {
        const fd = new FormData()
        fd.append('accion', 'cancelar')
        fd.append('id_pedido', idPedido.toString())

        pedidoJson({url: 'pedidos/cancelar', method: 'post', body: fd, ok: onAceptar})
    }

    return (
        <Modal colorBorde='border-danger'>
            <div className='row g-3'>
                <div className='col-auto pt-2' style={{fontSize: '2em'}}>
                    <i className='fas fa-times-circle text-danger'/>
                </div>
                <div className='col'>
                    <h4>Cancelar Pedido</h4>
                    <p>
                        El pedido abierto se eliminará. <strong>Esta acción no se
                        puede deshacer.</strong>
                    </p>
                    <p>¿Confirmar cancelación del pedido?</p>
                </div>
            </div>
            <AceptarCancelar onCancelar={() => onVolver()} tituloCancelar='Volver' tituloAceptar='Cancelar pedido'
                             onAceptar={() => cancelarPedidoHandler()} colorAceptar='btn-danger'
                             guardando={guardando}/>
        </Modal>
    )
}

export default CancelarPedido