import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {formatoMoneda} from "./formatoMoneda";
import {estilosApp} from "../estilos";
import {useAppSelector} from "../redux/hooks";

type Props = {
    value: number,
    onChange: (v: number) => void,
    chico?: boolean,
    invalido?: boolean,
    autoFocus?: boolean,
    titulo?: string,
    titulado?: boolean,
    sinSimbolo?: boolean,
    derecha?: boolean,
    autoSelect?: boolean,
    disabled?: boolean,
    controlDerecha?: React.ReactNode,
    porcentaje?: boolean,
    sinMaximoPorcentaje?: boolean
}

type DatosEntrada = {
    nro: number,
    coma: boolean,
    cero: boolean,
    doblecero: boolean
}

const InputMoneda = React.forwardRef<HTMLInputElement, Props>((
    {
        value, onChange, chico, invalido, autoFocus,
        titulo, titulado, sinSimbolo, derecha,
        autoSelect, disabled, controlDerecha, porcentaje,
        sinMaximoPorcentaje
    }, ref
) => {
    const st = estilosApp(useAppSelector(state => state.estado.modo_oscuro))
    const [datos, setDatos] = useState<DatosEntrada>(
        {nro: value, coma: false, cero: false, doblecero: false}
    )
    const [texto, setTexto] = useState<string>('')
    const refLocal = useRef<HTMLInputElement>(null)

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => refLocal.current)

    const valorHandler = useCallback((numero: number, arg?: string): void => {
        const rnd = Math.floor(numero * 100) / 100
        if (arg?.endsWith(',000')) arg = arg?.substring(0, arg?.length - 1)
        setDatos({
            nro: rnd,
            coma: arg !== undefined ? numero % 1 === 0 && (arg.endsWith(',') || arg.endsWith(',0')
                || arg.endsWith(',00')) : false,
            cero: arg !== undefined ? arg.includes(',') && arg.endsWith('0') : false,
            doblecero: arg !== undefined ? arg.endsWith(',00') : false
        })
        onChange(rnd)
    }, [onChange])

    const cambioHandler = (arg: string): void => {
        if (porcentaje) {
            const argVal = arg.endsWith(' %') ? arg.substring(0, arg.length - 2) : arg
            const numero = parseFloat(argVal
                .replace(/[^\d,]/g, '')
                .split(',')
                .slice(0, 2)
                .join('.')) || 0
            if (sinMaximoPorcentaje) {
                valorHandler(numero, argVal)
            } else {
                valorHandler(Math.min(numero, 100), argVal)
            }
        } else {
            const numero = parseFloat(arg
                .replace(/[^\d,]/g, '')
                .split(',')
                .slice(0, 2)
                .join('.')) || 0
            valorHandler(numero, arg)
        }
    }

    useEffect(() => {
        let str = formatoMoneda(datos.nro, true, sinSimbolo || porcentaje) +
            (datos.coma ? ',' : '') + (datos.cero ? '0' : '') + (datos.doblecero ? '0' : '')
        const posComa = str.lastIndexOf(',')
        if (-1 < posComa && posComa < str.length - 3 && str.endsWith('0')) str = str.substring(0, str.length - 1)
        setTexto(porcentaje ? str + ' %' : str)
    }, [datos, sinSimbolo, porcentaje])

    const posHandler = useCallback(() => {
        if (porcentaje) {
            if (refLocal.current === null) return

            const pos = Math.max(0, refLocal.current.value.length - 2)
            refLocal.current.selectionStart = pos
            refLocal.current.selectionEnd = pos
        }
    }, [porcentaje])

    useEffect(() => {
        posHandler()
    }, [texto, posHandler])

    useEffect(() => {
        if (datos.nro !== value) valorHandler(value)
    }, [valorHandler, value, datos.nro])

    const focusHandler: React.FocusEventHandler<HTMLInputElement> = (e) => {
        if (autoSelect) e.target.select()
    }

    const input: JSX.Element = (
        <input type='text' value={texto} onChange={e => cambioHandler(e.target.value)} autoFocus={autoFocus}
               onKeyUp={posHandler} onClick={posHandler}
               className={`form-control ${st.inputs} ` + (chico ? ' form-control-sm' : '') +
                   (invalido ? ' is-invalid' : '') + (derecha ? ' text-end' : '')}
               ref={refLocal} onFocus={focusHandler} disabled={disabled}/>
    )

    if (titulo && titulado) {
        return (
            <div>
                <div className='form-label ms-1 mb-1'>{titulo}</div>
                {input}
            </div>
        )
    } else {
        return (
            <div className={'input-group' + (chico ? ' input-group-sm' : '')}>
                {titulo !== undefined && (
                    <div className='input-group-text'>{titulo}</div>
                )}
                {input}
                {controlDerecha !== undefined && (
                    <div className='input-group-text'>{controlDerecha}</div>
                )}
            </div>
        )
    }
})

export default InputMoneda