import React, {useEffect, useState} from "react";
import Boton from "../controles/Boton";
import Tarjeta from "../controles/Tarjeta";
import ToolbarBtn from "../controles/ToolbarBtn";
import {useAppDispatch} from "../redux/hooks";
import TrInfo from "../controles/TrInfo";
import CheckEstado from "../controles/CheckEstado";
import {seccionesApp} from "../modulos";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import Modal from "../controles/Modal";
import InputTexto from "../controles/InputTexto";
import ModalBotones from "../controles/ModalBotones";
import Tabla from "../controles/Tabla";
import ControlPaginas, {paginado} from "../controles/ControlPaginas";
import {AccesoModulo, AccesoState, setPermisos} from "../redux/authReducer";
import {validarEmail} from "../validaciones";
import {useGsBackend} from "../funcionesApi";

type Usuario = {
    id: number,
    usuario: string,
    nombre: string,
    apellido: string,
    email: string,
    ultimoacceso: string,
    habilitado: boolean,
    modulos: Array<AccesoModulo>,
    administrador: boolean
}

type InfoUsuarios = {
    usuarios: Array<Usuario>,
    prefijo: string,
    cantidad_usuarios: number,
    maximo_usuarios: number
    modulos_disponibles: Array<string>
}

type Validaciones = {
    usuario: boolean,
    clave: boolean,
    nombre: boolean,
    apellido: boolean,
    email: boolean
}

type DatosEdicion = {
    id: number,
    usuario: string,
    password: string,
    nombre: string,
    apellido: string,
    email: string,
    habilitado: number,
    tipodeusuario: number,
    ultimoacceso: string,
    modulos: Array<AccesoModulo>
}

const AdminUsuarios: React.FC = () => {
    const validInicial: Validaciones = {usuario: true, clave: true, nombre: true, apellido: true, email: true}
    const editInicial: DatosEdicion = {
        id: 0, usuario: '', password: '', nombre: '', apellido: '', email: '', habilitado: 1, tipodeusuario: 1,
        ultimoacceso: '', modulos: []
    }
    const usrInicial: InfoUsuarios = {
        usuarios: [], prefijo: '', cantidad_usuarios: 0, maximo_usuarios: 0,
        modulos_disponibles: []
    }
    const [datos, setDatos] = useState<InfoUsuarios>(usrInicial)
    const [pagina, setPagina] = useState<number>(1)
    const [editando, setEditando] = useState<DatosEdicion | undefined>(undefined)
    const [validaciones, setValidaciones] = useState<Validaciones>(validInicial)
    const dispatch = useAppDispatch()

    const opcionesSiNo = [
        {id: 1, descripcion: 'Sí'},
        {id: 2, descripcion: 'No'}
    ] as Array<OpcionSelect>

    const tiposDeUsuario = [
        {id: 1, descripcion: 'Usuario normal'},
        {id: 2, descripcion: 'Administrador'}
    ] as Array<OpcionSelect>

    const {pedidoJson, guardando, actualizar} = useGsBackend()
    useEffect(() => {
        setPagina(1)
        pedidoJson({url: 'admin/usuarios', ok: setDatos})
    }, [pedidoJson])

    const editarHandler = (u?: Usuario): void => {
        setValidaciones(validInicial)
        if (u === undefined) {
            setEditando({...editInicial, usuario: `${datos.prefijo}.`})
        } else {
            setEditando({
                id: u.id, usuario: u.usuario, password: '', nombre: u.nombre, apellido: u.apellido,
                email: u.email, habilitado: u.habilitado ? 1 : 2,
                tipodeusuario: u.administrador ? 2 : 1,
                ultimoacceso: u.ultimoacceso, modulos: [...u.modulos]
            })
        }
    }

    const moduloHandler = (modulo: string, nivel: number): void => {
        if (editando === undefined) return

        const existe = editando.modulos.some(m => m.modulo === modulo && m.nivel_acceso === nivel)
        const previos = editando.modulos.filter(m => m.modulo !== modulo)
        const nuevosAccesos = existe || nivel === 0 ? previos :
            [...previos, {modulo: modulo, nivel_acceso: nivel} as AccesoModulo]

        setEditando({
            ...editando, modulos: nuevosAccesos
        })
    }

    const editUsuarioHandler = (arg: string): void => {
        if (editando === undefined) return

        // Verificar que el prefijo sea el correcto
        const pre = `${datos.prefijo}.`
        if (arg.startsWith(pre)) {
            setEditando({...editando, usuario: arg})
        } else if (arg.length <= pre.length) {
            setEditando({...editando, usuario: pre})
        } else {
            const pos = arg.indexOf('.')
            if (pos === -1) {
                setEditando({...editando, usuario: pre + arg})
            } else {
                setEditando({...editando, usuario: pre + arg.substring(pos + 1)})
            }
        }
    }

    const guardarHandler = async (eliminar?: boolean): Promise<void> => {
        if (editando === undefined) return

        // Validar campos
        const pre = `${datos.prefijo}.`
        const verif: Validaciones = {
            usuario: editando.usuario !== '' && editando.usuario !== pre && editando.usuario.length > pre.length,
            clave: editando.id === 0 ? editando.password !== '' : true,
            nombre: editando.nombre !== '',
            apellido: editando.apellido !== '',
            email: editando.email !== '' && validarEmail(editando.email)
        }
        setValidaciones(verif)
        if (Object.values(verif).some(v => !v)) return

        const fd = new FormData()
        fd.append('accion', eliminar ? 'eliminar' : editando.id === 0 ? 'nuevo' : 'editar')
        fd.append('id', editando.id.toString())
        fd.append('usuario', editando.usuario)
        fd.append('clave', editando.password)
        fd.append('nombre', editando.nombre)
        fd.append('apellido', editando.apellido)
        fd.append('email', editando.email)
        fd.append('habilitado', editando.habilitado === 1 ? '1' : '0')
        fd.append('admin', editando.tipodeusuario === 2 ? '1' : '0')
        fd.append('modulos', editando.modulos.map(m => `${m.modulo}:${m.nivel_acceso}`).join(','))

        pedidoJson({
            url: 'admin/usuarios', method: 'post', body: fd,
            ok: res => {
                // Eventualmente un usuario se podria cambiar a si mismo, en ese caso actualizo
                // los accesos y permisos actuales
                const resActual = res.permisos_usuario_actual as AccesoState
                dispatch(setPermisos(resActual))

                setEditando(undefined)
                actualizar()
            }
        })
    }

    return (
        <>
            <Tarjeta>
                <div className='row g-2'>
                    <div className='col-auto text-nowrap'>
                        <ToolbarBtn margen>
                            <Boton toolbar titulo='Agregar Usuario' icono='fas fa-user-plus' className='btn-light'
                                   onClick={() => editarHandler()}/>
                        </ToolbarBtn>
                    </div>
                    <div className='col pt-1 small'>
                        <i className='fas fa-info-circle text-info me-2'/>
                        <strong>{datos.cantidad_usuarios} de {datos.maximo_usuarios}</strong> usuarios
                        creados. Quedan {datos.maximo_usuarios - datos.cantidad_usuarios} usuarios disponibles
                        en su suscripción.
                    </div>
                </div>
                <Tabla>
                    <thead>
                    <tr>
                        <th colSpan={5}>
                            <ControlPaginas registros={datos.usuarios.length} pagina={pagina} onChange={setPagina}/>
                        </th>
                    </tr>
                    <tr className='headerTabla'>
                        <th>Usuario</th>
                        <th>Nombre y Apellido</th>
                        <th>E-Mail</th>
                        <th>Hablitado</th>
                        <th>Módulos Habilitados</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.usuarios.length ? paginado(datos.usuarios, pagina).map((u, k) => (
                        <tr key={k} className='trhover' onClick={() => editarHandler(u)}>
                            <td>
                                <strong>{u.usuario}</strong>
                                {u.administrador ? (
                                    <div className='small'>
                                        <i className='fas fa-users-cog text-warning me-2'/>
                                        Administrador
                                    </div>
                                ) : <></>}
                            </td>
                            <td>{u.nombre} {u.apellido}</td>
                            <td>{u.email}</td>
                            <td><CheckEstado estado={u.habilitado} textosino={true}/></td>
                            <td>
                                {u.modulos
                                    .map(m => m.modulo)
                                    .filter(m => datos.modulos_disponibles.includes(m)).length ? (
                                    <ul className='list-unstyled small m-0'>
                                        {u.modulos
                                            .map(m => m.modulo)
                                            .filter(m => datos.modulos_disponibles.includes(m))
                                            .map((m, k) => <li key={k}>
                                                {seccionesApp.filter(s => s.clave === m)[0]?.titulo || '(N/D)'}
                                            </li>)}
                                    </ul>
                                ) : <span className='text-muted small'>(Ninguno habilitado)</span>}
                            </td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={5} texto='No hay usuarios para mostrar'/>
                    )}
                    </tbody>
                </Tabla>
            </Tarjeta>
            {editando !== undefined && (
                <Modal full>
                    <div className='row g-2'>
                        <div className='col-md-6'>
                            <h4>Datos del Usuario</h4>
                            <div className='row g-2'>
                                <div className='col-md-6'>
                                    <InputTexto titulado titulo='Usuario' autoFocus chico
                                                invalido={!validaciones.usuario} value={editando.usuario}
                                                onChange={v => editUsuarioHandler(v)}/>
                                </div>
                                <div className='col-md-6'>
                                    <InputTexto titulado titulo='Contraseña' clave invalido={!validaciones.clave}
                                                placeholder={editando.id !== 0 ? '(Dejar en blanco para no cambiar)' : ''}
                                                value={editando.password} chico
                                                onChange={v => setEditando({...editando, password: v})}/>
                                </div>
                            </div>
                            <div className='row g-2'>
                                <div className='col-md-6'>
                                    <InputTexto titulado titulo='Nombre' invalido={!validaciones.nombre}
                                                value={editando.nombre} chico
                                                onChange={v => setEditando({...editando, nombre: v})}/>
                                </div>
                                <div className='col-md-6'>
                                    <InputTexto titulado titulo='Apellido' invalido={!validaciones.apellido}
                                                value={editando.apellido} chico
                                                onChange={v => setEditando({...editando, apellido: v})}/>
                                </div>
                            </div>
                            <div className='row g-2'>
                                <div className='col-md-6'>
                                    <InputTexto titulado titulo='E-Mail' invalido={!validaciones.email}
                                                value={editando.email} chico
                                                onChange={v => setEditando({...editando, email: v})}/>
                                </div>
                                <div className='col-md-3'>
                                    <InputSelect titulado titulo='Habilitar' sincero value={editando.habilitado}
                                                 onChange={v => setEditando({...editando, habilitado: v})}
                                                 opciones={opcionesSiNo} chico/>
                                </div>
                                <div className='col-md-3'>
                                    <InputSelect titulado titulo='Tipo de Usuario' sincero
                                                 value={editando.tipodeusuario}
                                                 onChange={v => setEditando({...editando, tipodeusuario: v})}
                                                 opciones={tiposDeUsuario} chico/>
                                </div>
                            </div>
                            {editando.ultimoacceso !== '' &&
                                <div className='mt-2'>
                                    <i className='fas fa-info-circle text-info me-2'/>
                                    <strong>Último acceso al Sistema: </strong>{editando.ultimoacceso}
                                </div>}
                            {editando.habilitado !== 1 &&
                                <div className='mt-2'>
                                    <i className='fas fa-times-circle text-danger me-2'/>
                                    El usuario no estará habilitado para acceder al Sistema
                                </div>}
                            {editando.tipodeusuario === 2 &&
                                <div className='mt-2'>
                                    <i className='fas fa-exclamation-triangle text-warning me-2'/>
                                    El usuario tendrá permisos de Administrador
                                </div>}
                        </div>
                        <div className='col-md-6 mb-3'>
                            <h4>Módulos habilitados</h4>
                            {seccionesApp
                                .sort((a, b) => a.orden - b.orden)
                                .filter(s => datos.modulos_disponibles.includes(s.clave))
                                .map((s, k) => {
                                    const accesoActual: number =
                                        editando.modulos.filter(m => m.modulo === s.clave)[0]?.nivel_acceso || 0

                                    return (
                                        <div key={k} className={'border rounded-1 mb-1'}>
                                            <div className={'border-0 border-start border-4 p-1 rounded-1' +
                                                (accesoActual !== 0 ? ' border-primary' : '')}>
                                                <div className={'row g-2 ' + (accesoActual === 0 ?
                                                    'text-muted text-decoration-line-through' : '')}>
                                                    <div className='col text-nowrap small pt-1'>
                                                        <i className={s.icono + ' mx-2 ' +
                                                            (accesoActual === 0 ? 'text-light' : 'text-primary')}/>
                                                        {s.titulo}
                                                    </div>
                                                    <div className='col-auto text-nowrap'>
                                                        <Boton chico titulo='Sin Acceso'
                                                               onClick={() => moduloHandler(s.clave, 0)}
                                                               className={'me-1 ' + (accesoActual === 0 ?
                                                                   'btn-secondary' : 'btn-light text-muted')}/>
                                                        <Boton chico titulo='Sólo Consulta'
                                                               onClick={() => moduloHandler(s.clave, 1)}
                                                               className={'me-1 ' + (accesoActual === 1 ?
                                                                   'btn-primary' : 'btn-light text-muted')}/>
                                                        <Boton chico titulo='Habilitado'
                                                               onClick={() => moduloHandler(s.clave, 2)}
                                                               className={'me-1 ' + (accesoActual === 2 ?
                                                                   'btn-primary' : 'btn-light text-muted')}/>
                                                        <Boton chico titulo='Supervisor'
                                                               onClick={() => moduloHandler(s.clave, 3)}
                                                               className={(accesoActual === 3 ?
                                                                   'btn-primary' : 'btn-light text-muted')}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                    <ModalBotones editando={editando.id !== 0}
                                  onCancelar={() => setEditando(undefined)}
                                  onGuardar={() => guardarHandler()} guardando={guardando}
                                  onEliminar={() => guardarHandler(true)}/>
                </Modal>
            )}
        </>
    )
}

export default AdminUsuarios