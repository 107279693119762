import React, {useCallback, useEffect, useRef, useState} from "react";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import {Proveedor} from "./Proveedores";
import InputTexto from "../controles/InputTexto";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputMoneda from "../controles/InputMoneda";
import {formatoMoneda} from "../controles/formatoMoneda";
import Boton from "../controles/Boton";
import ToolbarBtn from "../controles/ToolbarBtn";
import Tarjeta from "../controles/Tarjeta";
import Tabla from "../controles/Tabla";
import {useGsBackend} from "../funcionesApi";

type Props = {
    proveedor: Proveedor,
    onCancelar: () => void,
    onGuardar: () => void
}

type ProductoOrden = {
    id: number,
    id_producto: number,
    desc_producto: string,
    cantidad: number,
    cantidad_fmt: string,
    costo_unitario: number,
    costo_unitario_fmt: string,
    costo: number,
    costo_fmt: string
}

type BarrilOrden = {
    id: number,
    descripcion: string,
    litros: number,
    litros_fmt: string,
    cantidad: number,
    costo_unitario: number,
    costo_unitario_fmt: string,
    costo: number,
    costo_fmt: string
}

type Producto = {
    id: number,
    descripcion: string,
    unidaddemedida: string,
}

type InfoCargaOrden = {
    productos: Array<Producto>
}

type ValidRenglonProducto = {
    producto: boolean,
    cantidad: boolean,
    costo_unitario: boolean,
    costo_total: boolean
}

type ValidRenglonCerveza = {
    descripcion: boolean,
    litros: boolean,
    cantidad: boolean,
    costo_unitario: boolean,
    costo_total: boolean
}

const ProveedoresCargarCompra: React.FC<Props> = ({proveedor, onCancelar, onGuardar}) => {
    const validRenglonProductoOk: ValidRenglonProducto =
        {producto: true, cantidad: true, costo_unitario: true, costo_total: true}
    const validRenglonCervezaOk: ValidRenglonCerveza =
        {descripcion: true, litros: true, cantidad: true, costo_unitario: true, costo_total: true}
    const [datos, setDatos] = useState<InfoCargaOrden | undefined>(undefined)
    const [agregarProducto, setAgregarProducto] = useState<boolean>(false)
    const [agregarCerveza, setAgregarCerveza] = useState<boolean>(false)
    const [nroFactura, setNroFactura] = useState<string>('')
    const [comentarios, setComentarios] = useState<string>('')
    const [productosOrden, setProductosOrden] = useState<Array<ProductoOrden>>([] as Array<ProductoOrden>)
    const [barrilesOrden, setBarrilesOrden] = useState<Array<BarrilOrden>>([] as Array<BarrilOrden>)
    const [nvoProducto, setNvoProducto] = useState<number>(0)
    const [nvoDescripcion, setNvoDescripcion] = useState<string>('')
    const [nvoLitros, setNvoLitros] = useState<string>('0')
    const [nvoCantidad, setNvoCantidad] = useState<string>('0')
    const [nvoCostoUnitario, setNvoCostoUnitario] = useState<number>(0)
    const [nvoCostoTotal, setNvoCostoTotal] = useState<number>(0)
    const [nvoProductoValid, setNvoProductoValid] = useState<ValidRenglonProducto>(validRenglonProductoOk)
    const [nvoCervezaValid, setNvoCervezaValid] = useState<ValidRenglonCerveza>(validRenglonCervezaOk)
    const [rngId, setRngId] = useState<number>(1)

    const prodRef = useRef<HTMLSelectElement>(null)
    const cervezaRef = useRef<HTMLInputElement>(null)
    const cantRef = useRef<HTMLInputElement>(null)
    const unitRef = useRef<HTMLInputElement>(null)
    const totalRef = useRef<HTMLInputElement>(null)

    const {pedidoJson, guardando} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: `proveedores/compras/datos?id_proveedor=${proveedor.id}`, ok: setDatos})
    }, [proveedor.id, pedidoJson])

    const agregarProductoHandler = useCallback((): void => {
        if (datos === undefined) return

        const vld: ValidRenglonProducto = {
            producto: nvoProducto !== 0,
            cantidad: nvoCantidad !== '0',
            costo_unitario: nvoCostoUnitario !== 0,
            costo_total: nvoCostoTotal !== 0
        }
        setNvoProductoValid(vld)
        if (!vld.producto || !vld.cantidad || !vld.costo_unitario || !vld.costo_total) return

        // Agregar producto a la lista
        const infoProd = datos.productos.filter(p => p.id === nvoProducto)[0]
        const nvo: ProductoOrden = {
            id: rngId,
            id_producto: nvoProducto,
            desc_producto: infoProd?.descripcion || '',
            cantidad: parseFloat(nvoCantidad),
            cantidad_fmt: nvoCantidad + ' ' + (infoProd?.unidaddemedida || ''),
            costo_unitario: nvoCostoUnitario,
            costo_unitario_fmt: formatoMoneda(nvoCostoUnitario),
            costo: nvoCostoTotal,
            costo_fmt: formatoMoneda(nvoCostoTotal)
        }

        setRngId(rngId + 1)
        setProductosOrden(v => [...v, nvo])

        setNvoProducto(datos.productos.length === 1 ? datos.productos[0].id : 0)
        setNvoCostoTotal(0)
        setNvoCostoUnitario(0)
        setNvoCantidad('0')
        prodRef.current?.focus()
    }, [datos, nvoCantidad, nvoCostoTotal, nvoCostoUnitario, nvoProducto, rngId])

    const agregarCervezaHandler = useCallback(() => {
        if (datos === undefined) return

        const vld: ValidRenglonCerveza = {
            descripcion: nvoDescripcion !== '',
            litros: nvoLitros !== '0',
            cantidad: nvoCantidad !== '0',
            costo_unitario: nvoCostoUnitario !== 0,
            costo_total: nvoCostoTotal !== 0
        }
        setNvoCervezaValid(vld)
        if (!vld.descripcion || !vld.litros || !vld.cantidad || !vld.costo_unitario || !vld.costo_total) return

        // Agregar barriles a la lista
        const nvo: BarrilOrden = {
            id: rngId,
            descripcion: nvoDescripcion,
            litros: parseInt(nvoLitros),
            litros_fmt: nvoLitros + 'L',
            cantidad: parseFloat(nvoCantidad),
            costo_unitario: nvoCostoUnitario,
            costo_unitario_fmt: formatoMoneda(nvoCostoUnitario),
            costo: nvoCostoTotal,
            costo_fmt: formatoMoneda(nvoCostoTotal)
        }

        setRngId(rngId + 1)
        setBarrilesOrden(v => [...v, nvo])

        setNvoDescripcion('')
        setNvoLitros('0')
        setNvoCostoTotal(0)
        setNvoCostoUnitario(0)
        setNvoCantidad('0')
        cervezaRef.current?.focus()
    }, [datos, nvoCantidad, nvoCostoTotal, nvoCostoUnitario, nvoDescripcion, nvoLitros, rngId])

    const cancelarIngresoHandler = useCallback((): void => {
        if (datos === undefined) return

        setNvoProducto(datos.productos.length === 1 ? datos.productos[0].id : 0)
        setNvoDescripcion('')
        setNvoLitros('0')
        setNvoCostoTotal(0)
        setNvoCostoUnitario(0)
        setNvoCantidad('0')
        setAgregarProducto(false)
        setAgregarCerveza(false)
    }, [datos])

    const teclasEspeciales = useCallback(e => {
        if (e.keyCode === 27) {
            if (agregarProducto || agregarCerveza) {
                cancelarIngresoHandler()
            }
        } else if (e.keyCode === 13) {
            if (agregarProducto) {
                agregarProductoHandler()
            } else if (agregarCerveza) {
                agregarCervezaHandler()
            }
        }
    }, [agregarCerveza, agregarCervezaHandler, agregarProducto, agregarProductoHandler, cancelarIngresoHandler])

    useEffect(() => {
        document.addEventListener('keydown', teclasEspeciales, false)
        return () => document.removeEventListener('keydown', teclasEspeciales, false)
    }, [teclasEspeciales])

    const quitarHandler = (arg: number): void => {
        if (productosOrden.some(v => v.id === arg)) {
            setProductosOrden(v => v.filter(p => p.id !== arg))
        } else if (barrilesOrden.some(v => v.id === arg)) {
            setBarrilesOrden(v => v.filter(p => p.id !== arg))
        }
    }

    const guardarHandler = async (): Promise<void> => {
        const fd = new FormData()
        fd.append('accion', 'nuevo')
        fd.append('id_proveedor', proveedor.id.toString())
        fd.append('nro_factura', nroFactura)
        fd.append('comentarios', comentarios)
        fd.append('productos_orden', JSON.stringify(productosOrden))
        fd.append('barriles_orden', JSON.stringify(barrilesOrden))

        pedidoJson({url: 'proveedores/compras/nueva', method: 'post', body: fd, ok: onGuardar})
    }

    // Si hay un producto solo disponible para ese proveedor seleccionarlo
    useEffect(() => {
        if (datos !== undefined && datos.productos.length === 1) {
            setNvoProducto(datos.productos[0].id)
        }
    }, [datos])

    const cantHandler = (arg: string): void => {
        setNvoCantidad(arg)

        const val = parseInt(arg) || 0
        if (document.activeElement !== cantRef.current || val === 0) return

        if (nvoCostoUnitario !== 0) {
            setNvoCostoTotal(nvoCostoUnitario * val)
        }
    }

    const unitHandler = (arg: number): void => {
        setNvoCostoUnitario(arg)

        const cant = parseInt(nvoCantidad) || 0
        if (document.activeElement !== unitRef.current || cant === 0) return

        if (arg > 0) {
            setNvoCostoTotal(arg * cant)
        }
    }

    const totalHandler = (arg: number): void => {
        setNvoCostoTotal(arg)

        const cant = parseInt(nvoCantidad) || 0
        if (document.activeElement !== totalRef.current || cant === 0) return

        if (arg > 0) {
            setNvoCostoUnitario(arg / cant)
        }
    }

    if (datos === undefined) {
        return <></>
    } else {
        return (
            <Modal grande>
                <h4>Nueva Compra ({proveedor.razon_social})</h4>
                <div className='row mb-2'>
                    <div className='col-md-3 pt-1 small'>
                        Nro. Factura / Remito
                    </div>
                    <div className='col-md-9'>
                        <InputTexto value={nroFactura} onChange={setNroFactura} autoFocus
                                    placeholder='(Opcional)' chico/>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-md-3 pt-1 small'>
                        Comentarios
                    </div>
                    <div className='col-md-9'>
                        <InputTexto value={comentarios} onChange={setComentarios}
                                    placeholder='(Opcional)' chico/>
                    </div>
                </div>
                <Tabla espacio>
                    <thead>
                    <tr className='headerTabla'>
                        <th style={{width: '40%'}}>Producto</th>
                        <th style={{width: '20%'}}>Cantidad</th>
                        <th style={{width: '20%'}}>Costo Unitario</th>
                        <th style={{width: '20%'}}>Costo Total</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {productosOrden.length ? productosOrden.map((p, k) => (
                        <tr key={k}>
                            <td>{p.desc_producto}</td>
                            <td className='text-nowrap'>{p.cantidad_fmt}</td>
                            <td className='text-end text-nowrap'>{p.costo_unitario_fmt}</td>
                            <td className='text-end text-nowrap'>{p.costo_fmt}</td>
                            <td className='trhover px-2' onClick={() => quitarHandler(p.id)}>
                                <i className='fas fa-trash text-danger'/>
                            </td>
                        </tr>
                    )) : <React.Fragment/>}
                    {barrilesOrden.length ? barrilesOrden.map((b, k) => (
                        <tr key={k}>
                            <td>{b.descripcion} ({b.litros_fmt})</td>
                            <td className='text-nowrap'>
                                {b.cantidad} {b.cantidad === 1 ? 'Barril' : 'Barriles'}
                            </td>
                            <td className='text-end text-nowrap'>{b.costo_unitario_fmt}</td>
                            <td className='text-end text-nowrap'>{b.costo_fmt}</td>
                            <td className='trhover px-2' onClick={() => quitarHandler(b.id)}>
                                <i className='fas fa-trash text-danger'/>
                            </td>
                        </tr>
                    )) : <React.Fragment/>}
                    <tr>
                        <td colSpan={5} className='bg-light'>
                            {agregarProducto ? (
                                <Tarjeta bordeIzquierda className='p-2'>
                                    <div className='row g-2'>
                                        <div className='col-md-4'>
                                            <strong>Agregar Producto</strong>
                                            <InputSelect chico value={nvoProducto} autoFocus ref={prodRef}
                                                         onChange={v => setNvoProducto(v)}
                                                         opciones={datos.productos.map(p => {
                                                             return {
                                                                 id: p.id,
                                                                 descripcion: p.descripcion
                                                             } as OpcionSelect
                                                         })} invalido={!nvoProductoValid.producto}/>
                                        </div>
                                        <div className='col-md-2'>
                                            Cantidad
                                            <InputTexto value={nvoCantidad} onChange={cantHandler} numerico chico
                                                        invalido={!nvoProductoValid.cantidad} autoSelect ref={cantRef}/>
                                        </div>
                                        <div className='col-md-3'>
                                            Costo Unitario
                                            <InputMoneda value={nvoCostoUnitario} onChange={unitHandler} chico
                                                         invalido={!nvoProductoValid.costo_unitario} autoSelect derecha
                                                         ref={unitRef}/>
                                        </div>
                                        <div className='col-md-3'>
                                            Costo Total
                                            <InputMoneda value={nvoCostoTotal} onChange={totalHandler} chico
                                                         invalido={!nvoProductoValid.costo_total} autoSelect derecha
                                                         ref={totalRef}/>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <ToolbarBtn derecha>
                                            <Boton toolbar chico titulo='Agregar a la Compra' icono='fas fa-check'
                                                   className='btn-success' onClick={agregarProductoHandler}/>
                                            <Boton toolbar chico titulo='Cancelar' icono='fas fa-times'
                                                   className='btn-danger' onClick={cancelarIngresoHandler}/>
                                        </ToolbarBtn>
                                    </div>
                                </Tarjeta>
                            ) : agregarCerveza ? (
                                <Tarjeta bordeIzquierda className='p-2'>
                                    <div className='row g-2'>
                                        <div className='col-md-4'>
                                            <strong>Agregar Cerveza ({proveedor.razon_social})</strong>
                                            <InputTexto chico value={nvoDescripcion} onChange={setNvoDescripcion}
                                                        autoFocus ref={cervezaRef}
                                                        invalido={!nvoCervezaValid.descripcion}/>
                                        </div>
                                        <div className='col-md-2'>
                                            Litros
                                            <InputTexto value={nvoLitros} onChange={setNvoLitros} numerico chico
                                                        invalido={!nvoCervezaValid.litros} autoSelect/>
                                        </div>
                                        <div className='col-md-2'>
                                            Cantidad
                                            <InputTexto value={nvoCantidad} onChange={cantHandler} numerico chico
                                                        invalido={!nvoCervezaValid.cantidad} autoSelect ref={cantRef}/>
                                        </div>
                                        <div className='col-md-2'>
                                            Costo Unitario
                                            <InputMoneda value={nvoCostoUnitario} onChange={unitHandler} chico
                                                         invalido={!nvoCervezaValid.costo_unitario} autoSelect derecha
                                                         ref={unitRef}/>
                                        </div>
                                        <div className='col-md-2'>
                                            Costo Total
                                            <InputMoneda value={nvoCostoTotal} onChange={totalHandler} chico
                                                         invalido={!nvoCervezaValid.costo_total} autoSelect derecha
                                                         ref={totalRef}/>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <ToolbarBtn derecha>
                                            <Boton toolbar chico titulo='Agregar a la Compra' icono='fas fa-check'
                                                   className='btn-success' onClick={agregarCervezaHandler}/>
                                            <Boton toolbar chico titulo='Cancelar' icono='fas fa-times'
                                                   className='btn-danger' onClick={cancelarIngresoHandler}/>
                                        </ToolbarBtn>
                                    </div>
                                </Tarjeta>
                            ) : (
                                <ToolbarBtn>
                                    <Boton toolbar titulo='Agregar productos (Stock)' icono='fas fa-plus'
                                           onClick={() => setAgregarProducto(true)}/>
                                    <Boton toolbar titulo='Agregar cervezas (Barriles)' icono='fas fa-beer'
                                           onClick={() => setAgregarCerveza(true)}/>
                                </ToolbarBtn>
                            )}
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th colSpan={3}>
                            Total: {productosOrden.length} {productosOrden.length === 1 ? 'Producto' : 'Productos'}
                        </th>
                        <th className='text-nowrap text-end'>
                            {formatoMoneda(
                                productosOrden.map(p => p.costo).reduce((s, v) => s + v, 0) +
                                barrilesOrden.map(b => b.costo).reduce((s, v) => s + v, 0))}
                        </th>
                        <th>&nbsp;</th>
                    </tr>
                    </tfoot>
                </Tabla>
                <ModalBotones onCancelar={onCancelar} onGuardar={guardarHandler} guardando={guardando}
                              bloquearTeclas={agregarProducto || agregarCerveza}
                              aceptarDisable={productosOrden.length === 0}/>
            </Modal>
        )
    }
}

export default ProveedoresCargarCompra