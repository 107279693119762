import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import CerrarModal from "../controles/CerrarModal";
import TablaEstadisticaCervezas from "./TablaEstadisticaCervezas";
import {useGsBackend} from "../funcionesApi";

export interface DetalleCervezaLitros {
    tipo: string,
    litros: number,
    litros_fmt: string,
    cantidad: number,
    importe: number,
    importe_fmt: string,
}

export interface CervezaLitros {
    categoria: string,
    litros: number,
    litros_fmt: string,
    cantidad: number,
    importe: number,
    importe_fmt: string,
    detalle: Array<DetalleCervezaLitros>
}

type DatosEstadisticaCervezas = {
    vendidas: Array<CervezaLitros>,
    pendientes: Array<CervezaLitros>
}

type Props = {
    onCerrar: () => void
}

const CerveceriaEstadisticas: React.FC<Props> = ({onCerrar}) => {
    const [datos, setDatos] = useState<DatosEstadisticaCervezas>({vendidas: [], pendientes: []})
    const {pedidoJson} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'estadisticas/cerveceria', ok: setDatos})
    }, [pedidoJson])

    return (
        <Modal>
            <h4>Estadísticas</h4>
            <div className='row g-2'>
                <div className='col-auto'>
                    <i className='fas fa-info-circle text-info'/>
                </div>
                <div className='col mb-3'>
                    Estas estadísticas corresponden a la apertura actual de caja. Podés consultar las
                    estadísticas de cierres anteriores en el módulo de Caja.
                </div>
            </div>
            <TablaEstadisticaCervezas titulo='Cervezas Vendidas por Categoría' datos={datos.vendidas}/>
            <TablaEstadisticaCervezas titulo='Cervezas en Pedidos Pendientes' datos={datos.pendientes}/>
            <CerrarModal onCerrar={onCerrar}/>
        </Modal>
    )
}

export default CerveceriaEstadisticas