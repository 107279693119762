import React, {useCallback, useEffect, useState} from "react";
import TituloPagina from "../controles/TituloPagina";
import Boton from "../controles/Boton";
import {nivelAcceso} from "../autorizacion";
import InputTexto from "../controles/InputTexto";
import Tarjeta from "../controles/Tarjeta";
import BarraTarjeta from "../controles/BarraTarjeta";
import EdicionClientes, {DatosEdicionCliente} from "./EdicionClientes";
import BotonIcono from "../controles/BotonIcono";
import Tabla from "../controles/Tabla";
import ControlPaginas, {paginado} from "../controles/ControlPaginas";
import {NivelesAcceso} from "../redux/authReducer";
import {useGsBackend} from "../funcionesApi";

type Cliente = {
    id: number,
    nombre: string,
    telefono: string,
    email: string,
    direccion: string,
    comentarios: string,
    descuento: number,
    descuento_fmt: string,
    envio_gratis: boolean
}

type InfoClientes = {
    clientes: Array<Cliente>
}

const Clientes: React.FC = () => {
    const [datos, setDatos] = useState<InfoClientes>({clientes: []})
    const [muestra, setMuestra] = useState<Array<Cliente>>([] as Array<Cliente>)
    const [editando, setEditando] = useState<DatosEdicionCliente | undefined>(undefined)
    const [filtroNombre, setFiltroNombre] = useState<string>('')
    const [filtroTelefono, setFiltroTelefono] = useState<string>('')
    const [filtroDireccion, setFiltroDireccion] = useState<string>('')
    const [pagina, setPagina] = useState<number>(1)
    const acceso = nivelAcceso('clientes')
    const {pedidoJson, actualizar} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'clientes/lista', ok: setDatos})
    }, [pedidoJson])

    const editarHandler = (arg: number | undefined) => {
        const edicionInicial: DatosEdicionCliente =
            {
                id: 0, nombre: '', telefono: '', email: '', direccion: '', comentarios: '', descuento: 0,
                envio_gratis: false
            }
        if (arg === undefined) {
            setEditando(undefined)
        } else if (arg === 0) {
            setEditando(edicionInicial)
        } else {
            const item = datos.clientes.filter(c => c.id === arg)[0]
            if (item === undefined) return
            setEditando({
                id: item.id, nombre: item.nombre, telefono: item.telefono, email: item.email,
                direccion: item.direccion, comentarios: item.comentarios, descuento: item.descuento,
                envio_gratis: item.envio_gratis
            })
        }
    }

    const filtrar = useCallback((): void => {
        setPagina(1)
        setMuestra(datos === undefined ? [] as Array<Cliente> : datos.clientes
            .filter(c => filtroNombre !== '' ? c.nombre.toLocaleLowerCase()
                .includes(filtroNombre.toLocaleLowerCase()) : true)
            .filter(c => filtroTelefono !== '' ? c.telefono.toLocaleLowerCase()
                .includes(filtroTelefono.toLocaleLowerCase()) : true)
            .filter(c => filtroDireccion !== '' ? c.direccion.toLocaleLowerCase()
                .includes(filtroDireccion.toLocaleLowerCase()) : true))
    }, [datos, filtroDireccion, filtroNombre, filtroTelefono])

    useEffect(() => {
        filtrar()
    }, [filtrar])

    return (
        <>
            <TituloPagina icono='fas fa-users' titulo='Clientes'/>
            <BarraTarjeta>
                <div className='row g-2'>
                    {acceso >= NivelesAcceso.NivelHabilitado && (
                        <div className='col-lg-auto text-nowrap'>
                            <Boton titulo='Alta de Cliente' icono='fas fa-user-plus' className='btn-light btn-sm'
                                   onClick={() => editarHandler(0)}/>
                        </div>
                    )}
                    <div className='col-lg-auto pt-1 d-none d-lg-block'>
                        <i className='fas fa-search ms-3 text-primary'/>
                    </div>
                    <div className='col-lg'>
                        <div className='row g-2'>
                            <div className='col-md-4'>
                                <InputTexto value={filtroNombre} onChange={setFiltroNombre}
                                            chico titulo='Nombre' placeholder='Buscar por Nombre'/>
                            </div>
                            <div className='col-md-4'>
                                <InputTexto value={filtroTelefono} onChange={setFiltroTelefono}
                                            chico titulo='Teléfono' placeholder='Buscar por Teléfono'/>
                            </div>
                            <div className='col-md-4'>
                                <InputTexto value={filtroDireccion} onChange={setFiltroDireccion}
                                            chico titulo='Dirección' placeholder='Buscar por Dirección'/>
                            </div>
                        </div>
                    </div>
                </div>
            </BarraTarjeta>
            <Tarjeta>
                <Tabla>
                    <thead>
                    <tr>
                        <th colSpan={6}>
                            <ControlPaginas registros={muestra.length} pagina={pagina} onChange={setPagina}/>
                        </th>
                    </tr>
                    <tr className='headerTabla'>
                        <th style={{width: '20%'}}>Nombre</th>
                        <th style={{width: '15%'}}>Telefono</th>
                        <th style={{width: '15%'}}>E-Mail</th>
                        <th style={{width: '20%'}}>Dirección</th>
                        <th style={{width: '15%'}}>Comentarios</th>
                        <th style={{width: '15%'}}>Opcionales</th>
                    </tr>
                    </thead>
                    <tbody>
                    {muestra.length ? paginado(muestra, pagina).map((c, k) => (
                        <tr key={k} className={acceso >= NivelesAcceso.NivelHabilitado ? 'trhover' : ''}
                            onClick={acceso >= NivelesAcceso.NivelHabilitado ? () => editarHandler(c.id) : undefined}>
                            <td>{c.nombre}</td>
                            <td>{c.telefono}</td>
                            <td>{c.email}</td>
                            <td>
                                <BotonIcono icono='fas fa-location-dot' espacio onClick={e => {
                                    e.stopPropagation()
                                    window.open('https://maps.google.com?q=' + encodeURIComponent(c.direccion),
                                        '_blank')
                                }}/>
                                {c.direccion}
                            </td>
                            <td>{c.comentarios}</td>
                            <td>
                                {c.descuento > 0 && (
                                    <div>Descuento: {c.descuento_fmt}</div>
                                )}
                                {c.envio_gratis && (
                                    <div>Envío Gratis</div>
                                )}
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td colSpan={6}>
                                <i className='fas fa-info-circle me-2 text-info'/>
                                Sin resultados para la búsqueda
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Tabla>
            </Tarjeta>
            {editando !== undefined && (
                <EdicionClientes datosIniciales={editando} onCancelar={() => setEditando(undefined)}
                                 onGuardado={() => {
                                     setEditando(undefined)
                                     actualizar()
                                 }}/>
            )}
        </>
    )
}

export default Clientes