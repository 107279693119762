import React, {useEffect, useState} from "react";
import TituloPagina from "../controles/TituloPagina";
import BarraTarjeta from "../controles/BarraTarjeta";
import Boton from "../controles/Boton";
import {useNavigate} from "react-router-dom";
import Tarjeta from "../controles/Tarjeta";
import TextoND from "../controles/TextoND";
import Tabla from "../controles/Tabla";
import ControlPaginas, {paginado} from "../controles/ControlPaginas";
import {useGsBackend} from "../funcionesApi";

type RenglonHistorial = {
    id: number,
    nro_cierre: number,
    fechayhora: string,
    saldo: number,
    saldo_fmt: string,
    movimientos_cantidad: number,
    movimientos_ingresos: number,
    movimientos_ingresos_fmt: string,
    movimientos_egresos: number,
    movimientos_egresos_fmt: string,
    movimientos_saldo: number,
    movimientos_saldo_fmt: string,
    comentarios: string,
    usuario: string
}

type RegistroCaja = {
    saldo_fmt: string,
    ultimo_cierre: string
}

type InfoHistorial = {
    registro: RegistroCaja,
    historial: Array<RenglonHistorial>
}

const HistorialCierres: React.FC = () => {
    const [datos, setDatos] = useState<InfoHistorial>(
        {registro: {saldo_fmt: '', ultimo_cierre: ''}, historial: []})
    const [pagina, setPagina] = useState<number>(1)
    const navigate = useNavigate()
    const {pedidoJson} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'caja/historial', ok: setDatos, err: () => navigate('/caja')})
    }, [pedidoJson, navigate])

    return (
        <>
            <TituloPagina icono='fas fa-history' titulo='Historial de Cierres de Caja'/>
            <BarraTarjeta>
                <Boton toolbar titulo='Volver' icono='fas fa-chevron-left' className='btn-light'
                       onClick={() => navigate('../')}/>
            </BarraTarjeta>
            <Tarjeta>
                <div className='row g-2 mb-3'>
                    <div className='col-4 text-center h3 pt-2'>
                        Saldo actual: <strong>{datos.registro.saldo_fmt}</strong>
                    </div>
                    <div className='col-8 text-center'>
                        <strong>Último cierre</strong><br/><TextoND texto={datos.registro.ultimo_cierre}/>
                    </div>
                </div>
                <Tabla>
                    <thead>
                    <tr>
                        <th colSpan={9}>Cierres de Caja</th>
                    </tr>
                    <tr>
                        <th colSpan={9}>
                            <ControlPaginas registros={datos.historial.length} pagina={pagina} onChange={setPagina}/>
                        </th>
                    </tr>
                    <tr className='headerTabla'>
                        <th>Nro. Cierre</th>
                        <th>Fecha y Hora</th>
                        <th>Saldo de Caja</th>
                        <th>Movimientos</th>
                        <th>Ingresos</th>
                        <th>Egresos</th>
                        <th>Saldo del Cierre</th>
                        <th>Comentarios</th>
                        <th>Usuario</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.historial.length ? paginado(datos.historial, pagina).map((h, k) => (
                        <tr key={k} className='trhover' onClick={() => navigate(`consulta?id=${h.id}`)}>
                            <td className='text-end'>{h.nro_cierre}</td>
                            <td>{h.fechayhora}</td>
                            <td className='text-end fw-bold'>{h.saldo_fmt}</td>
                            <td className='text-end fw-bold'>{h.movimientos_cantidad}</td>
                            <td className={'text-end fw-bold' + (h.movimientos_ingresos > 0 ? ' text-success' : '')}>
                                {h.movimientos_ingresos_fmt}
                            </td>
                            <td className={'text-end fw-bold' + (h.movimientos_egresos < 0 ? ' text-danger' : '')}>
                                {h.movimientos_egresos_fmt}
                            </td>
                            <td className='text-end fw-bold'>{h.movimientos_saldo_fmt}</td>
                            <td>{h.comentarios}</td>
                            <td>{h.usuario}</td>
                        </tr>
                    )) : (
                        <tr>
                            <td colSpan={9}>
                                <i className='fas fa-info-circle me-2 text-info'/>
                                Sin datos para mostrar
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Tabla>
            </Tarjeta>
        </>
    )
}

export default HistorialCierres