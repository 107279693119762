import React, {useEffect, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import './BotonBarraNavegacion.css'
import {Tooltip as BsTooltip} from "bootstrap";

type Props = {
    icono: string,
    titulo: string,
    clave?: string,
    onClick?: () => void
}

const BotonBarraNavegacion: React.FC<Props> = ({icono, titulo, clave, onClick}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const claveNav = clave === undefined || clave === 'inicio' ? '' : clave
    const activo = location.pathname.split('/')[1] === claveNav
    const botonRef = useRef<HTMLLIElement>(null)

    useEffect(() => {
        if (botonRef.current === null) return

        const t = new BsTooltip(botonRef.current, {
            placement: 'bottom'
        })
        return () => t.dispose()
    })

    return (
        <li className='nav-item itemBarra'
            ref={botonRef}
            data-bs-toogle='tooltip'
            data-bs-title={titulo}
            onClick={() => {
                if (onClick === undefined) {
                    navigate(claveNav)
                } else {
                    onClick()
                }
            }}>
            <div className={activo ? 'nav-link active' : 'nav-link'}
                 style={{cursor: 'pointer'}}>
                <i className={icono} style={{fontSize: '1.25em', marginTop: '5px'}}/>
            </div>
        </li>
    )
}

export default BotonBarraNavegacion