import React, {useCallback, useEffect, useState} from "react";
import TituloPagina from "../controles/TituloPagina";
import BarraTarjeta from "../controles/BarraTarjeta";
import Boton from "../controles/Boton";
import {useNavigate} from "react-router-dom";
import Tarjeta from "../controles/Tarjeta";
import {nivelAcceso} from "../autorizacion";
import TrInfo from "../controles/TrInfo";
import ToolbarBtn from "../controles/ToolbarBtn";
import InputTexto from "../controles/InputTexto";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import InputMoneda from "../controles/InputMoneda";
import Tabla from "../controles/Tabla";
import ControlPaginas, {paginado} from "../controles/ControlPaginas";
import {NivelesAcceso} from "../redux/authReducer";
import {useGsBackend} from "../funcionesApi";
import AjusteDePrecios, {ProductoAjuste} from "../stock/AjusteDePrecios";

type Elaborado = {
    id: number,
    descripcion: string,
    descripcion_corta: string,
    descripcion_larga: string,
    id_categoria: number,
    categoria: string,
    preciounitario: number,
    preciounitario_fmt: string
}

type InfoElaborados = {
    elaborados: Array<Elaborado>,
    categorias: Array<OpcionSelect>
}

type FiltrosElaborados = {
    descripcion: string,
    id_categoria: number
}

type EdicionElaborado = {
    id: number,
    descripcion: string,
    descripcion_corta: string,
    descripcion_larga: string,
    id_categoria: number,
    preciounitario: number
}

type ValidacionElaborado = {
    descripcion: boolean,
    id_categoria: boolean
}

const Elaborados: React.FC = () => {
    const navigate = useNavigate()
    const filtroInicial: FiltrosElaborados = {descripcion: '', id_categoria: 0}
    const validacionInicial: ValidacionElaborado = {descripcion: true, id_categoria: true}
    const [datos, setDatos] = useState<InfoElaborados | undefined>(undefined)
    const [muestra, setMuestra] = useState<Array<Elaborado>>([])
    const [filtros, setFiltros] = useState<FiltrosElaborados>(filtroInicial)
    const [pagina, setPagina] = useState<number>(1)
    const [edicion, setEdicion] = useState<EdicionElaborado | undefined>(undefined)
    const [valid, setValid] = useState<ValidacionElaborado>(validacionInicial)
    const [ajuste, setAjuste] = useState<boolean>(false)
    const acceso = nivelAcceso('elaborados')

    const {pedidoJson, guardando, actualizar} = useGsBackend()
    useEffect(() => {
        pedidoJson({url: 'elaborados/lista', ok: setDatos})
    }, [pedidoJson])

    const filtrar = useCallback(() => {
        if (datos === undefined) return

        setPagina(1)
        setMuestra(datos.elaborados
            .filter(d => filtros.descripcion === '' ? true :
                d.descripcion.toLocaleLowerCase().includes(filtros.descripcion.toLocaleLowerCase()) ||
                d.descripcion_corta.toLocaleLowerCase().includes(filtros.descripcion.toLocaleLowerCase()))
            .filter(d => filtros.id_categoria === 0 ? true : d.id_categoria === filtros.id_categoria))
    }, [filtros, datos])

    useEffect(() => {
        filtrar()
    }, [filtrar])

    const guardarHandler = async (eliminar?: boolean) => {
        if (datos === undefined || edicion === undefined) return

        // Validaciones
        const vld: ValidacionElaborado = {
            descripcion: edicion.descripcion !== '',
            id_categoria: edicion.id_categoria !== 0
        }
        setValid(vld)
        if (Object.values(vld).some(v => !v)) return

        const fd = new FormData()
        fd.append('id', edicion.id.toString())
        fd.append('accion', edicion.id === 0 ? 'nuevo' : eliminar ? 'eliminar' : 'editar')
        fd.append('categoria', edicion.id_categoria.toString())
        fd.append('preciounitario', edicion.preciounitario.toString())
        fd.append('descripcion', edicion.descripcion)
        fd.append('descripcion_corta',
            edicion.descripcion_corta === '' ? edicion.descripcion : edicion.descripcion_corta)
        fd.append('descripcion_larga', edicion.descripcion_larga)

        pedidoJson({
            url: 'elaborados/lista', method: 'post', body: fd,
            ok: () => {
                setEdicion(undefined)
                actualizar()
            }
        })
    }

    return (
        <>
            <TituloPagina icono='fas fa-bowl-rice' titulo='Elaborados'/>
            <BarraTarjeta>
                {nivelAcceso('stock') >= NivelesAcceso.NivelHabilitado && (
                    <Boton toolbar titulo='Ver Insumos / Ingredientes' icono='fas fa-search' className='btn-light'
                           onClick={() => navigate('../stock?tipo=insumos')}/>
                )}
                {acceso >= NivelesAcceso.NivelHabilitado && (
                    <Boton toolbar titulo='Ajuste de Precios' icono='fas fa-money-check-dollar'
                           className='btn-light' onClick={() => setAjuste(true)}/>
                )}
            </BarraTarjeta>
            <Tarjeta>
                {datos !== undefined && (
                    <div className='row g-2 mb-2'>
                        {acceso >= NivelesAcceso.NivelHabilitado && (
                            <div className='col-lg-auto text-nowrap'>
                                <ToolbarBtn>
                                    <Boton toolbar titulo='Agregar Elaborado' icono='fas fa-plus' className='btn-light'
                                           onClick={() => {
                                               setValid(validacionInicial)
                                               setEdicion({
                                                   id: 0,
                                                   descripcion: '',
                                                   descripcion_corta: '',
                                                   descripcion_larga: '',
                                                   id_categoria: 0,
                                                   preciounitario: 0
                                               })
                                           }}/>
                                </ToolbarBtn>
                            </div>
                        )}
                        <div className='col-lg-auto pt-1 d-none d-lg-block'>
                            <i className='fas fa-search text-primary'/>
                        </div>
                        <div className='col-lg'>
                            <div className='row g-2'>
                                <div className='col-md-6'>
                                    <InputTexto value={filtros.descripcion} chico titulo='Descripción'
                                                onChange={v => setFiltros({...filtros, descripcion: v})}
                                                placeholder='Buscar por Producto'/>
                                </div>
                                <div className='col-md-6'>
                                    <InputSelect chico titulo='Categoría' value={filtros.id_categoria}
                                                 onChange={v => setFiltros({...filtros, id_categoria: v})}
                                                 opciones={datos.categorias}
                                                 textocero='(Todas)'/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Tabla>
                    <thead>
                    <tr>
                        <th colSpan={3}>
                            <ControlPaginas registros={muestra.length} pagina={pagina} onChange={setPagina}/>
                        </th>
                    </tr>
                    <tr className='headerTabla'>
                        <th style={{width: '40%'}}>Producto</th>
                        <th style={{width: '40%'}}>Categoría</th>
                        <th style={{width: '20%'}} className='text-end'>Precio Unitario</th>
                    </tr>
                    </thead>
                    <tbody>
                    {muestra.length ? paginado(muestra, pagina).map((e, k) => (
                        <tr key={k} className={acceso >= NivelesAcceso.NivelHabilitado ? 'trhover' : ''}
                            onClick={acceso >= NivelesAcceso.NivelHabilitado ? () => {
                                setValid(validacionInicial)
                                setEdicion(e)
                            } : undefined}>
                            <td>
                                {e.descripcion === e.descripcion_corta || e.descripcion_corta === '' ? (
                                    <strong>{e.descripcion}</strong>
                                ) : (
                                    <>
                                        <strong>{e.descripcion}</strong>
                                        <small className='text-muted ms-2'>({e.descripcion_corta})</small>
                                    </>
                                )}
                            </td>
                            <td>{e.categoria}</td>
                            <td className='text-end'>{e.preciounitario_fmt}</td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={3} texto='No hay productos elaborados para mostrar'/>
                    )}
                    </tbody>
                </Tabla>
            </Tarjeta>
            {edicion !== undefined && datos !== undefined && (
                <Modal>
                    <h4>{edicion.id === 0 ? 'Alta de Producto Elaborado' : 'Editar Producto Elaborado'}</h4>
                    <div className='row'>
                        <div className='col-md-6'>
                            <InputTexto titulado titulo='Descripción' value={edicion.descripcion}
                                        onChange={v => setEdicion({...edicion, descripcion: v})}
                                        autoFocus={edicion.id === 0} invalido={!valid.descripcion}/>
                        </div>
                        <div className='col-md-6'>
                            <InputTexto titulado titulo='Descripción Corta' value={edicion.descripcion_corta}
                                        onChange={v => setEdicion({...edicion, descripcion_corta: v})}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <InputTexto titulado titulo='Descripción Larga (Menú)'
                                        value={edicion.descripcion_larga} placeholder='(Opcional)'
                                        onChange={v => setEdicion({...edicion, descripcion_larga: v})}/>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-6'>
                            <InputSelect value={edicion.id_categoria} opciones={datos.categorias}
                                         onChange={v => setEdicion({...edicion, id_categoria: v})}
                                         titulado titulo='Categoría' invalido={!valid.id_categoria} forzarCero/>
                        </div>
                        <div className='col-md-6'>
                            <InputMoneda value={edicion.preciounitario} autoFocus={edicion.id !== 0}
                                         onChange={v => setEdicion({...edicion, preciounitario: v})}
                                         titulado titulo='Precio Unitario'/>
                        </div>
                    </div>
                    <ModalBotones onCancelar={() => setEdicion(undefined)} editando={edicion.id !== 0}
                                  onGuardar={() => guardarHandler()} guardando={guardando}
                                  onEliminar={edicion.id === 0 ? undefined : () => guardarHandler(true)}/>
                </Modal>
            )}
            {datos !== undefined && ajuste && (
                <AjusteDePrecios tipo='elaborados' onVolver={() => setAjuste(false)}
                                 onActualizar={() => actualizar()}
                                 categorias={datos.categorias}
                                 productos={datos.elaborados.map(e => {
                                     return {
                                         id: e.id, id_categoria: e.id_categoria, descripcion: e.descripcion,
                                         precio_actual: e.preciounitario, id_proveedor: 0
                                     } as ProductoAjuste
                                 })}/>
            )}
        </>
    )
}

export default Elaborados