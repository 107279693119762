import React from "react";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {coloresBordes, coloresGraficos} from "./configuracionGraficos";

type Props = {
    leyendas: Array<string>,
    datos: Array<number>
}

const GraficoMediosDePago: React.FC<Props> = ({leyendas, datos}) => {
    ChartJS.register(ArcElement, Tooltip, Legend)

    return (
        <div style={{width: '300px'}}>
            <Doughnut data={{
                labels: leyendas,
                datasets: [
                    {
                        backgroundColor: coloresGraficos,
                        borderColor: coloresBordes,
                        borderWidth: 1,
                        data: datos
                    }
                ]
            }}
                      options={{
                          plugins: {
                              legend: {
                                  position: 'bottom'
                              }
                          }
                      }}/>
        </div>
    )
}

export default GraficoMediosDePago