import React, {useCallback, useEffect, useState} from "react";
import {ModoServidor, ModoState} from "../redux/modoReducer";
import {useAppSelector} from "../redux/hooks";

const VistaModo: React.FC = () => {
    const modo: ModoState = useAppSelector(state => state.modo)
    const [cantidad, setCantidad] = useState<number>(0)
    const anchoIndividual = 400

    const tamanioHandler = useCallback(() => {
        const px = window.innerWidth
        setCantidad(Math.floor(px / anchoIndividual))
    }, [])

    useEffect(() => {
        window.addEventListener('resize', tamanioHandler)
        return () => window.removeEventListener('resize', tamanioHandler)
    })

    useEffect(() => tamanioHandler())

    switch (modo.modoServidor) {
        case ModoServidor.Desa:
            return (
                <div className='py-2 px-4 bg-dark text-center text-light fw-bold'>
                    <div className='row g-0'>
                        {Array(cantidad).fill(0).map((i, k) => (
                            <div className='col' key={k}>
                                <i className='fas fa-code me-2'/>
                                Desarrollo
                            </div>
                        ))}
                    </div>
                </div>
            )
        case ModoServidor.Test:
            return (
                <div className='py-2 px-4 bg-danger text-center text-light fw-bold'>
                    <div className='row g-0'>
                        {Array(cantidad).fill(0).map((i, k) => (
                            <div className='col' key={k}>
                                <i className='fas fa-terminal me-2'/>
                                Testing
                            </div>
                        ))}
                    </div>
                </div>
            )
        default:
            return <></>
    }
}

export default VistaModo