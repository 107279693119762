import React from "react";

type Props = {
    icono: string,
    titulo: string
}

const TituloPagina: React.FC<Props> = ({icono, titulo}) => {
    return (
        <h4 className='mb-2'><i className={icono + ' text-primary me-2'}/>{titulo}</h4>
    )
}

export default TituloPagina