import {AuthState, NivelesAcceso, setDeslogueo} from "./redux/authReducer";
import store from "./redux/store";
import {setErrorSistema} from "./redux/estadoReducer";
import {ClaveSeccion} from "./modulos";

export const authHeader = (): HeadersInit => {
    const [usuario, token] = authToken()
    const auth = 'Basic ' + btoa(`${usuario}:${token}`)
    return new Headers({
        'Authorization': auth
    })
}

export const authToken = (): [string, string] => {
    const auth: AuthState = store.getState().auth
    return [auth.usuario || '', auth.token || '']
}

export const verificarAutorizacion = (res: Response): Response => {
    if (res.status === 401) {
        throw new Error('NOAUTH')
    } else if (res.status === 403) {
        throw new Error('NOACCESS')
    } else {
        return res
    }
}

export const errorApi = (err: Error, sinAlerta?: boolean): boolean => {
    if (err.message === 'NOAUTH') {
        store.dispatch(setDeslogueo())
        return true
    } else if (err.message === 'NOACCESS') {
        store.dispatch(setErrorSistema('Acceso denegado'))
        return true
    } else {
        if (!sinAlerta) store.dispatch(setErrorSistema(err.message))
        return false
    }
}

export const nivelAcceso = (modulo: ClaveSeccion): NivelesAcceso => {
    const acceso = store.getState().auth.accesos?.filter(m => m.modulo === modulo)
    if (acceso === undefined || !acceso.length) {
        return NivelesAcceso.NivelSinAcceso
    } else {
        return acceso[0].nivel_acceso
    }
}