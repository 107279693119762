export const estilosApp = (oscuro: boolean) => {
    const claro = !oscuro // Para mayor placer

    return {
        pagina: claro ? 'bg-light' : 'bg-black text-light',
        modal: claro ? '' : 'bg-black text-light',
        tarjeta: claro ? '' : 'bg-dark text-light',
        boton_claro: claro ? 'btn-light' : 'btn-dark text-light',
        boton_cerveza: claro ? 'btn-light' : 'btn-dark bg-black rounded-2',
        inputs: claro ? '' : 'bg-black text-light',
        pre_inputs: claro ? 'bg-white' : 'bg-black border-0',
        categoria_cerveza: claro ? 'bg-light' : 'bg-black',
        fondo_progreso: claro ? 'bg-light' : 'bg-black',
        tabla: claro? '' : 'bg-black text-light'
    }
}