import React from "react";

type Props = {
    espacio?: boolean,
    children: React.ReactNode
}

const Recuadro: React.FC<Props> = ({espacio, children}) => {
    return (
        <div className={'border p-3 rounded-2 shadow-sm' + (espacio? ' mb-3' : '')}>
            {children}
        </div>
    )
}

export default Recuadro