import React from "react";
import Tarjeta from "../controles/Tarjeta";
import Tabla from "../controles/Tabla";
import TrInfo from "../controles/TrInfo";
import GraficoCategoriasProductos from "./GraficoCategoriasProductos";

export interface VentasCategoriasProductos {
    id_categoria: number,
    categoria: string,
    cantidad: number,
    importe: number,
    importe_fmt: string
}

type Props = {
    datos: Array<VentasCategoriasProductos>
}

const EstadisticasCategoriasProductos: React.FC<Props> = ({datos}) => {
    return (
        <Tarjeta espacio bordeArriba>
            <h5>Ventas por Categorías de Productos</h5>
            <div className='row g-2'>
                <div className='col-lg-6'>
                    <Tabla>
                        <thead>
                        <tr className='headerTabla'>
                            <th style={{width: '40%'}}>Categoría</th>
                            <th style={{width: '30%'}}>Cant. Productos</th>
                            <th style={{width: '30%'}}>Importe</th>
                        </tr>
                        </thead>
                        <tbody>
                        {datos.length ?
                            datos.map((d, k) => (
                                <tr key={k}>
                                    <td>{d.categoria}</td>
                                    <td className='text-end text-nowrap'>{d.cantidad}</td>
                                    <td className='text-end text-nowrap'>{d.importe_fmt}</td>
                                </tr>
                            )) : (
                                <TrInfo colSpan={3} texto='Sin datos para mostrar'/>
                            )}
                        </tbody>
                    </Tabla>
                </div>
                <div className='col-lg-6'>
                    <GraficoCategoriasProductos leyendas={datos.map(d => d.categoria)}
                                                datos={datos.map(d => d.cantidad)}/>
                </div>
            </div>
        </Tarjeta>
    )
}

export default EstadisticasCategoriasProductos