import React, {useRef, useState} from "react";
import ToolbarBtn from "../controles/ToolbarBtn";
import Boton from "../controles/Boton";
import InputTexto from "../controles/InputTexto";
import {useGsBackend} from "../funcionesApi";
import {validarEmail} from "../validaciones";
import BotonCarga from "../controles/BotonCarga";
import CodigoVerificacion, {CodigoVerificacionElement} from "../controles/CodigoVerificacion";

type Props = {
    onVolver: () => void
}

const RecuperarClave: React.FC<Props> = ({onVolver}) => {
    const [usuario, setUsuario] = useState<string>('')
    const [mail, setMail] = useState<string>('')
    const [validoUsuario, setValidoUsuario] = useState<boolean>(true)
    const [validoMail, setValidoMail] = useState<boolean>(true)
    const [mensajeError, setMensajeError] = useState<string | undefined>(undefined)
    const [pantalla, setPantalla] = useState<'inicio' | 'codigo' | 'cambiarclave' | 'finalizado'>('inicio')
    const [nuevaClave, setNuevaClave] = useState<string>('')
    const [nuevaClaveBis, setNuevaClaveBis] = useState<string>('')
    const [valido, setValido] = useState<boolean>(true)
    const {pedidoJson, guardando} = useGsBackend()

    const codigoRef = useRef<CodigoVerificacionElement | null>(null)
    const codigoEnviado = useRef<number | null>(null)

    const codigoHandler = (): void => {
        setMensajeError(undefined)
        const vldUsuario = usuario !== ''
        const vldMail = mail !== '' && validarEmail(mail)
        setValidoUsuario(vldUsuario)
        setValidoMail(vldMail)
        if (!vldUsuario || !vldMail) return

        const fd = new FormData()
        fd.append('usuario', usuario)
        fd.append('email', mail)

        pedidoJson({
            url: 'recuperacion/solicitar', method: 'post', body: fd, sinHeaders: true, sinNotificacion: true,
            ok: res => {
                if (res.resultado === true) {
                    setPantalla('codigo')
                } else {
                    setMensajeError(res.mensaje)
                }
            }
        })
    }

    const verificarHandler = (v: number): void => {
        const fd = new FormData()
        fd.append('usuario', usuario)
        fd.append('codigo', v.toString())

        codigoEnviado.current = v
        pedidoJson({
            url: 'recuperacion/verificar', method: 'post', body: fd, sinHeaders: true, sinNotificacion: true,
            ok: res => {
                codigoRef.current?.limpiar()
                if (res.resultado === true) {
                    setMensajeError(undefined)
                    setPantalla('cambiarclave')
                } else {
                    setMensajeError(res.mensaje)
                }
            }
        })
    }

    const cambioHandler = (): void => {
        setMensajeError(undefined)
        const coinciden: boolean = nuevaClave === nuevaClaveBis
        const vld: boolean = coinciden && nuevaClave !== ''
        setValido(vld)
        if (!coinciden) setMensajeError('Las contraseñas no coinciden')
        if (!vld) return

        const fd = new FormData()
        fd.append('codigo', (codigoEnviado.current || 0).toString())
        fd.append('usuario', usuario)
        fd.append('email', mail)
        fd.append('nuevaclave', nuevaClave)

        pedidoJson({
            url: 'recuperacion/cambiar', method: 'post', body: fd, sinHeaders: true, sinNotificacion: true,
            ok: res => {
                if (res.resultado === true) {
                    setPantalla('finalizado')
                } else {
                    setMensajeError(res.mensaje)
                }
            }
        })
    }

    switch (pantalla) {
        case 'finalizado':
            return (
                <>
                    <p>
                        Se actualizó tu contraseña exitosamente.<br/>
                        Podés volver a iniciar sesión con tus nuevas credenciales.
                    </p>
                    <ToolbarBtn derecha>
                        <Boton toolbar titulo='Volver a Inicio' icono='fas fa-chevron-left' className='btn-secondary'
                               onClick={onVolver}/>
                    </ToolbarBtn>
                </>
            )
        case 'cambiarclave':
            return (
                <>
                    <InputTexto titulo='Nueva Contraseña' value={nuevaClave} onChange={setNuevaClave} clave
                                className='mb-3' chico disabled={guardando} invalido={!valido}
                                autoFocus/>
                    <InputTexto titulo='Repetir Contraseña' value={nuevaClaveBis} onChange={setNuevaClaveBis} clave
                                className='mb-3' chico disabled={guardando} invalido={!valido}/>
                    {mensajeError !== undefined && !guardando && (
                        <div className='text-danger small mb-2'>
                            <i className='fas fa-exclamation-triangle me-2'/>{mensajeError}
                        </div>
                    )}
                    <p>Luego de realizado el cambio podrás iniciar sesión con tus nuevas credenciales</p>
                    <ToolbarBtn derecha>
                        {!guardando ? (
                            <>
                                <Boton toolbar titulo='Cancelar' className='btn-secondary' icono='fas fa-times'
                                       onClick={onVolver}/>
                                <Boton toolbar titulo='Cambiar contraseña' icono='fas fa-key'
                                       onClick={cambioHandler}/>
                            </>
                        ) : (
                            <BotonCarga titulo='Procesando...'/>
                        )}
                    </ToolbarBtn>
                </>
            )
        case 'codigo':
            return (
                <>
                    <p>Ingresá el código que te enviamos por E-Mail a continuación</p>
                    <CodigoVerificacion onVerificar={verificarHandler} disabled={guardando}
                                        ref={codigoRef}/>
                    {mensajeError !== undefined && !guardando && (
                        <div className='text-danger small mb-2'>
                            <i className='fas fa-exclamation-triangle me-2'/>{mensajeError}
                        </div>
                    )}
                    {guardando && (
                        <div className='mb-2'>
                            <span className='spinner-border spinner-border-sm me-2 text-primary'/>Verificando...
                        </div>
                    )}
                    <ToolbarBtn derecha>
                        {!guardando ? (
                            <Boton toolbar titulo='Volver' className='btn-secondary' icono='fas fa-chevron-left'
                                   onClick={() => {
                                       setMensajeError(undefined)
                                       setPantalla('inicio')
                                   }}/>
                        ) : (
                            <BotonCarga titulo='Procesando...'/>
                        )}
                    </ToolbarBtn>
                </>
            )
        case 'inicio':
            return (
                <>
                    <InputTexto titulo='Usuario' value={usuario} onChange={setUsuario}
                                className='mb-3' chico disabled={guardando}
                                invalido={!validoUsuario} autoFocus/>
                    <InputTexto titulo='E-Mail' value={mail} onChange={setMail}
                                className='mb-3' chico disabled={guardando}
                                invalido={!validoMail}/>
                    {mensajeError !== undefined && (
                        <div className='text-danger small mb-2'>
                            <i className='fas fa-exclamation-triangle me-2'/>{mensajeError}
                        </div>
                    )}
                    <p>
                        Para recuperar tu usuario si olvidaste la contraseña, ingresá tu nombre de usuario y tu
                        dirección de E-Mail, y luego presioná el botón Solicitar Código.<br/>
                        Te enviaremos un código de recuperación a esta dirección.
                    </p>
                    <ToolbarBtn derecha>
                        {!guardando ? (
                            <>
                                <Boton toolbar titulo='Volver' className='btn-secondary' icono='fas fa-chevron-left'
                                       onClick={onVolver}/>
                                <Boton toolbar titulo='Solicitar Código' icono='fas fa-envelope'
                                       onClick={codigoHandler}/>
                            </>
                        ) : (
                            <BotonCarga titulo='Procesando...'/>
                        )}
                    </ToolbarBtn>
                </>
            )
    }
}

export default RecuperarClave