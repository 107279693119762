import React from "react";
import {useAppSelector} from "../redux/hooks";
import LogoApp from "../controles/LogoApp";
import SwitchModoOscuro from "../controles/SwitchModoOscuro";

const BarraSuperior: React.FC = () => {
    const auth = useAppSelector(state => state.auth)

    return (
        <div className='bg-black py-2 px-2 text-white'>
            <div className='row g-2'>
                <div className='col-auto'>
                    <LogoApp enLinea link/>
                </div>
                <div className='col'>
                    <strong>{auth.cliente}</strong>
                </div>
                <div className='col-auto'>
                    <SwitchModoOscuro/>
                </div>
                <div className='col-auto'>
                    <i className='fas fa-user me-2'/>
                    <strong>
                        <span className='d-none d-md-inline'>{auth.nombrecompleto} (</span>
                        {auth.usuario}
                        <span className='d-none d-md-inline'>)</span>
                    </strong>
                </div>
            </div>
        </div>
    )
}

export default BarraSuperior