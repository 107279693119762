import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import CerrarModal from "../controles/CerrarModal";
import Tabla from "../controles/Tabla";
import {formatoMoneda} from "../controles/formatoMoneda";
import TrInfo from "../controles/TrInfo";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import {useGsBackend} from "../funcionesApi";

type Props = {
    onCerrar: () => void
}

type InfoMediosDePago = {
    medio_de_pago: string,
    cant_pendientes: number,
    importe_pendientes: number,
    cant_cerrados: number,
    importe_cerrados: number,
    cant_total: number,
    importe_total: number,
}

type InfoCategorias = {
    categoria: string,
    cant_pendientes: number,
    importe_pendientes: number,
    cant_cerrados: number,
    importe_cerrados: number,
    cant_total: number,
    importe_total: number,
}

type Informacion = {
    medios_de_pago: Array<InfoMediosDePago>,
    categorias: Array<InfoCategorias>
}

const SeguimientoPedidos: React.FC<Props> = ({onCerrar}) => {
    const tiposPedidos: Array<OpcionSelect> = [
        {id: 1, descripcion: 'Sólo local'},
        {id: 2, descripcion: 'Sólo delivery'}
    ]

    const [datos, setDatos] = useState<Informacion | undefined>(undefined)
    const [tipo, setTipo] = useState<number>(0)
    const {pedidoJson} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: `pedidos/seguimientopedidos?tipo=${tipo}`, ok: setDatos})
    }, [pedidoJson, tipo])

    if (datos === undefined) return <React.Fragment/>
    return (
        <Modal colorBorde='border-primary'>
            <h4>Seguimiento de Pedidos</h4>
            <div className='row g-2'>
                <div className='col-lg-6 mb-3'>
                    <InputSelect value={tipo} onChange={setTipo} opciones={tiposPedidos} chico
                                 titulo='Tipo de Pedidos' textocero='(Todos los pedidos)'/>
                </div>
            </div>
            <h5>Ventas por Medio de Pago</h5>
            <Tabla espacio>
                <thead>
                <tr>
                    <th style={{width: '25%'}} rowSpan={2} className='border-end'>Medio de Pago</th>
                    <th colSpan={2} className='border-end'>Abiertos</th>
                    <th colSpan={2} className='border-end'>Cerrados</th>
                    <th colSpan={2}>Total</th>
                </tr>
                <tr className='headerTabla'>
                    <th style={{width: '12.5%'}}>Pedidos</th>
                    <th style={{width: '12.5%'}} className='border-end'>Importe</th>
                    <th style={{width: '12.5%'}}>Pedidos</th>
                    <th style={{width: '12.5%'}} className='border-end'>Importe</th>
                    <th style={{width: '12.5%'}}>Pedidos</th>
                    <th style={{width: '12.5%'}}>Importe</th>
                </tr>
                </thead>
                <tbody>
                {datos.medios_de_pago.length ? datos.medios_de_pago.map((m, k) => (
                    <tr key={k}>
                        <td className='border-end'>
                            {m.medio_de_pago !== '' ? m.medio_de_pago : (
                                <span className='text-muted'>(No especificado)</span>
                            )}
                        </td>
                        {m.cant_pendientes > 0 || m.importe_pendientes > 0 ? (
                            <>
                                <td className='text-end text-nowrap'>{m.cant_pendientes}</td>
                                <td className='text-end text-nowrap border-end'>
                                    {formatoMoneda(m.importe_pendientes)}
                                </td>
                            </>
                        ) : (
                            <>
                                <td className='text-end text-muted'>-</td>
                                <td className='text-end text-muted border-end'>-</td>
                            </>
                        )}
                        {m.cant_cerrados > 0 || m.importe_cerrados > 0 ? (
                            <>
                                <td className='text-end text-nowrap'>{m.cant_cerrados}</td>
                                <td className='text-end text-nowrap border-end'>
                                    {formatoMoneda(m.importe_cerrados)}
                                </td>
                            </>
                        ) : (
                            <>
                                <td className='text-end text-muted'>-</td>
                                <td className='text-end text-muted border-end'>-</td>
                            </>
                        )}
                        {m.cant_total > 0 || m.importe_total > 0 ? (
                            <>
                                <td className='text-end text-nowrap'>{m.cant_total}</td>
                                <td className='text-end text-nowrap border-end'>
                                    {formatoMoneda(m.importe_total)}
                                </td>
                            </>
                        ) : (
                            <>
                                <td className='text-end text-muted'>-</td>
                                <td className='text-end text-muted'>-</td>
                            </>
                        )}
                    </tr>
                )) : (
                    <TrInfo colSpan={9} texto='Sin información para mostrar'/>
                )}
                </tbody>
            </Tabla>
            <h5>Ventas por Categoría de Productos</h5>
            <Tabla espacio>
                <thead>
                <tr>
                    <th style={{width: '25%'}} rowSpan={2} className='border-end'>Categoría</th>
                    <th colSpan={2} className='border-end'>Abiertos</th>
                    <th colSpan={2} className='border-end'>Cerrados</th>
                    <th colSpan={2}>Total</th>
                </tr>
                <tr className='headerTabla'>
                    <th style={{width: '12.5%'}}>Productos</th>
                    <th style={{width: '12.5%'}} className='border-end'>Importe</th>
                    <th style={{width: '12.5%'}}>Productos</th>
                    <th style={{width: '12.5%'}} className='border-end'>Importe</th>
                    <th style={{width: '12.5%'}}>Productos</th>
                    <th style={{width: '12.5%'}}>Importe</th>
                </tr>
                </thead>
                <tbody>
                {datos.categorias.length ? datos.categorias.map((m, k) => (
                    <tr key={k}>
                        <td className='border-end'>
                            {m.categoria !== '' ? m.categoria : (
                                <span className='text-muted'>(No especificado)</span>
                            )}
                        </td>
                        {m.cant_pendientes > 0 || m.importe_pendientes > 0 ? (
                            <>
                                <td className='text-end text-nowrap'>{m.cant_pendientes}</td>
                                <td className='text-end text-nowrap border-end'>
                                    {formatoMoneda(m.importe_pendientes)}
                                </td>
                            </>
                        ) : (
                            <>
                                <td className='text-end text-muted'>-</td>
                                <td className='text-end text-muted border-end'>-</td>
                            </>
                        )}
                        {m.cant_cerrados > 0 || m.importe_cerrados > 0 ? (
                            <>
                                <td className='text-end text-nowrap'>{m.cant_cerrados}</td>
                                <td className='text-end text-nowrap border-end'>
                                    {formatoMoneda(m.importe_cerrados)}
                                </td>
                            </>
                        ) : (
                            <>
                                <td className='text-end text-muted'>-</td>
                                <td className='text-end text-muted border-end'>-</td>
                            </>
                        )}
                        {m.cant_total > 0 || m.importe_total > 0 ? (
                            <>
                                <td className='text-end text-nowrap'>{m.cant_total}</td>
                                <td className='text-end text-nowrap border-end'>
                                    {formatoMoneda(m.importe_total)}
                                </td>
                            </>
                        ) : (
                            <>
                                <td className='text-end text-muted'>-</td>
                                <td className='text-end text-muted'>-</td>
                            </>
                        )}
                    </tr>
                )) : (
                    <TrInfo colSpan={9} texto='Sin información para mostrar'/>
                )}
                </tbody>
            </Tabla>
            <CerrarModal onCerrar={onCerrar}/>
        </Modal>
    )
}

export default SeguimientoPedidos