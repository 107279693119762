import React, {useCallback, useEffect} from "react";
import Boton from "./Boton";

type Props = {
    onCerrar: () => void,
    autoFocus?: boolean,
    chico?: boolean
}

const CerrarModal: React.FC<Props> = ({onCerrar, autoFocus, chico}) => {
    const teclasEspeciales = useCallback(e => {
        if (e.keyCode === 27) onCerrar()
    }, [onCerrar])

    useEffect(() => {
        document.addEventListener('keydown', teclasEspeciales, false)
        return () => document.removeEventListener('keydown', teclasEspeciales, false)
    }, [teclasEspeciales])

    return (
        <div className='text-end'>
            <Boton titulo='Cerrar' icono='fas fa-times' onClick={onCerrar} autoFocus={autoFocus}
                   chico={chico} className='btn-secondary me-2'/>
        </div>
    )
}

export default CerrarModal