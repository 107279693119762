import React, {useState} from "react";
import Modal from "./Modal";
import AceptarCancelar from "./AceptarCancelar";
import {useGsBackend} from "../funcionesApi";
import InputTexto from "./InputTexto";
import LogoApp from "./LogoApp";

interface Props {
    onLoginOk: () => void,
    onCancelar: () => void
}

interface RespuestaAdministrador {
    resultado: boolean,
    mensaje: string
}

const UsuarioAdministrador: React.FC<Props> = ({onLoginOk, onCancelar}) => {
    const [usuario, setUsuario] = useState<string>('')
    const [clave, setClave] = useState<string>('')
    const [validoUsuario, setValidoUsuario] = useState<boolean>(true)
    const [validoClave, setValidoClave] = useState<boolean>(true)
    const [mensajeError, setMensajeError] = useState<string | undefined>(undefined)
    const {pedidoJson, guardando} = useGsBackend()

    const verificarUsuario = async () => {
        const usuarioOk = usuario !== ''
        const claveOk = clave !== ''
        setValidoUsuario(usuarioOk)
        setValidoClave(claveOk)
        if (!usuarioOk || !claveOk) return

        const fd = new FormData()
        fd.append('usuario', usuario)
        fd.append('clave', clave)

        pedidoJson({
            url: 'verificaradministrador', method: 'post', body: fd, sinNotificacion: true,
            ok: (res: RespuestaAdministrador) => {
                if (res.resultado) {
                    onLoginOk()
                } else {
                    setMensajeError(res.mensaje)
                }
            },
            err: res => setMensajeError(res.toString())
        })
    }

    const aceptarHandler = () => {
        verificarUsuario().finally()
    }

    const enterHandler = () => {
        verificarUsuario().finally()
    }

    return (
        <Modal>
            <LogoApp/>
            <div className='mb-2'>
                Ingrese las credenciales de Administrador para desbloquear esta función
            </div>
            {mensajeError !== undefined && (
                <div className='text-danger small mb-2'>
                    <i className='fas fa-exclamation-triangle me-2'/>{mensajeError}
                </div>
            )}
            <InputTexto titulo='Usuario' value={usuario} onChange={setUsuario}
                        className='mb-3' chico disabled={guardando}
                        invalido={!validoUsuario}
                        onEnter={enterHandler} autoFocus/>
            <InputTexto titulo='Contraseña' value={clave} onChange={setClave} clave
                        className='mb-3' chico disabled={guardando} invalido={!validoClave}
                        onEnter={enterHandler}/>
            <AceptarCancelar onAceptar={aceptarHandler} onCancelar={onCancelar}/>
        </Modal>
    )
}

export default UsuarioAdministrador