import React, {useEffect, useState} from "react";
import TituloPagina from "../controles/TituloPagina";
import BarraTarjeta from "../controles/BarraTarjeta";
import Boton from "../controles/Boton";
import Tarjeta from "../controles/Tarjeta";
import {nivelAcceso} from "../autorizacion";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import InputMoneda from "../controles/InputMoneda";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputTexto from "../controles/InputTexto";
import TextoND from "../controles/TextoND";
import {formatoMoneda} from "../controles/formatoMoneda";
import {useNavigate} from "react-router-dom";
import TablaMovimientos from "./TablaMovimientos";
import InputSwitch from "../controles/InputSwitch";
import {NivelesAcceso} from "../redux/authReducer";
import {useGsBackend} from "../funcionesApi";
import UsuarioAdministrador from "../controles/UsuarioAdministrador";

export interface MovimientoCaja {
    tipo_movimiento: number,
    origen_movimiento: number,
    id_detalle: number,
    fechayhora: string,
    descripcion: string,
    importe: number,
    importe_fmt: string,
    mediodepago: string,
    saldo_parcial: number,
    saldo_parcial_fmt: string,
    usuario: string
}

type RegistroCaja = {
    saldo: number,
    saldo_fmt: string,
    saldo_pendiente: number,
    saldo_pendiente_fmt: string,
    ultimo_cierre: string,
    ultimo_movimiento: string
}

type InfoMovimientos = {
    movimientos: Array<MovimientoCaja>,
    mediosdepago: Array<OpcionSelect>,
    registro: RegistroCaja,
    pedidos_pendientes: number,
    ocultar_saldo: boolean
}

type ValidacionMovimiento = {
    importe: boolean,
    descripcion: boolean
}

const Caja: React.FC = () => {
    const [datos, setDatos] = useState<InfoMovimientos>(
        {
            movimientos: [], mediosdepago: [], registro: {} as RegistroCaja, pedidos_pendientes: 0,
            ocultar_saldo: false
        })
    const [movTipo, setMovTipo] = useState<number>(1)
    const [movImporte, setMovImporte] = useState<number>(0)
    const [movDescripcion, setMovDescripcion] = useState<string>('')
    const [movMedioDePago, setMovMedioDePago] = useState<number>(0)
    const [movValidacion, setMovValidacion] = useState<ValidacionMovimiento>(
        {importe: true, descripcion: true})
    const [ingresar, setIngresar] = useState<boolean>(false)
    const [cierreCaja, setCierreCaja] = useState<boolean>(false)
    const [cierreComentarios, setCierreComentarios] = useState<string>('')
    const [cierreSaldoInicial, setCierreSaldoInicial] = useState<number | undefined>(0)
    const [actualizarSaldo, setActualizarSaldo] = useState<boolean>(false)
    const [saldoCaja, setSaldoCaja] = useState<number>(0)
    const [saldoComentarios, setSaldoComentarios] = useState<string>('')
    const [verificarPermisoOcultar, setVerificarPermisoOcultar] = useState<boolean>(false)
    const navigate = useNavigate()
    const acceso = nivelAcceso('caja')
    const {pedidoJson, guardando, actualizar, guardarOpcionesUsuario} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'caja/movimientos', ok: setDatos})
    }, [pedidoJson])

    const nuevoMovimientoHandler = (): void => {
        setMovValidacion({importe: true, descripcion: true})
        setMovTipo(1)
        setMovMedioDePago(0)
        setMovImporte(0)
        setMovDescripcion('')
        setIngresar(true)
    }

    const guardarMovimientoHandler = (): void => {
        // Validar el nuevo movimiento
        const vld: ValidacionMovimiento = {
            importe: movImporte !== 0,
            descripcion: movDescripcion !== ''
        }
        setMovValidacion(vld)
        if (!vld.importe || !vld.descripcion) return

        const fd = new FormData()
        fd.append('accion', 'nuevo')
        fd.append('tipo', movTipo.toString())
        fd.append('importe', movImporte.toString())
        fd.append('descripcion', movDescripcion)
        fd.append('mediodepago', movMedioDePago.toString())

        pedidoJson({
            url: 'caja/movimientos/nuevo', method: 'post', body: fd,
            ok: () => {
                setIngresar(false)
                actualizar()
            }
        })
    }

    const calcularMovimientosCierre = (tipo: number): React.ReactNode => {
        const flt: Array<MovimientoCaja> = datos.movimientos.filter(m => m.tipo_movimiento === tipo)
        const cantidad: number = flt.length
        if (cantidad === 0) {
            return <span className='text-muted'>(Sin Movimientos)</span>
        }

        const importe: number = flt.map(m => m.importe).reduce((p, a) => p + a)

        return (
            <span className={tipo === 1 ? 'text-success' : 'text-danger'}>{formatoMoneda(importe)}</span>
        )
    }

    const cierreCajaHandler = async (): Promise<void> => {
        const fd = new FormData()
        fd.append('accion', 'cierre')
        fd.append('saldoinicial', cierreSaldoInicial === undefined ? '' : cierreSaldoInicial.toString())
        fd.append('comentarios', cierreComentarios)

        pedidoJson({
            url: 'caja/cierre', method: 'post', body: fd,
            ok: () => {
                setCierreCaja(false)
                actualizar()
            }
        })
    }

    const saldoCajaHandler = async (): Promise<void> => {
        const fd = new FormData()
        fd.append('accion', 'actualizarsaldo')
        fd.append('saldo', saldoCaja.toString())
        fd.append('comentarios', saldoComentarios)

        pedidoJson({
            url: 'caja/actualizarsaldo', method: 'post', body: fd,
            ok: () => {
                setActualizarSaldo(false)
                actualizar()
            }
        })
    }

    return (
        <>
            <TituloPagina icono='fas fa-cash-register' titulo='Caja'/>
            <BarraTarjeta>
                {acceso >= NivelesAcceso.NivelHabilitado && (
                    <Boton toolbar titulo='Ingresar Movimiento' icono='fas fa-dollar-sign'
                           className='btn-light' onClick={() => nuevoMovimientoHandler()}/>
                )}
                {datos.movimientos.some(m => m.tipo_movimiento !== 4) && acceso >= NivelesAcceso.NivelHabilitado && (
                    <Boton toolbar titulo='Cierre de Caja' icono='fas fa-cash-register'
                           className='btn-light' onClick={() => {
                        setCierreComentarios('')
                        setCierreSaldoInicial(0)
                        setCierreCaja(true)
                    }}/>
                )}
                {acceso >= NivelesAcceso.NivelSupervisor && (
                    <Boton toolbar titulo='Historial de Cierres' icono='fas fa-history'
                           className='btn-light' onClick={() => navigate('historial')}/>
                )}
                {acceso >= NivelesAcceso.NivelHabilitado && (
                    <Boton toolbar titulo='Actualización de Saldo' icono='fas fa-sync'
                           className='btn-light' onClick={() => {
                        setSaldoCaja(datos.registro.saldo)
                        setSaldoComentarios('')
                        setActualizarSaldo(true)
                    }}/>
                )}
                <Boton toolbar titulo='Ocultar' onClick={() => {
                    if (datos.ocultar_saldo) {
                        setVerificarPermisoOcultar(true)
                    } else {
                        guardarOpcionesUsuario('ocultar_saldo', 1, actualizar)
                    }
                }}
                       className={datos.ocultar_saldo ? 'btn-primary' : 'btn-light'}
                       icono={datos.ocultar_saldo ? 'fas fa-eye' : 'fas fa-eye-slash'}/>
            </BarraTarjeta>
            <Tarjeta>
                <div className='row g-2 mb-3' style={{fontSize: '1.2em'}}>
                    <div className='col-md-3 text-center'>
                        <strong>Saldo</strong><br/>
                        {datos.ocultar_saldo ? '***' : datos.registro.saldo_fmt}
                    </div>
                    <div className='col-md-3 text-center'>
                        <strong>Saldo pendiente</strong><br/>
                        {datos.ocultar_saldo ? '***' : datos.registro.saldo_pendiente_fmt}
                    </div>
                    <div className='col-md-3 text-center'>
                        <strong>Último cierre</strong><br/><TextoND texto={datos.registro.ultimo_cierre}/>
                    </div>
                    <div className='col-md-3 text-center'>
                        <strong>Último movimiento</strong><br/><TextoND texto={datos.registro.ultimo_movimiento}/>
                    </div>
                </div>
                <TablaMovimientos movimientos={datos.movimientos}/>
            </Tarjeta>
            {ingresar && (
                <Modal>
                    <h4>Ingresar Movimiento</h4>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Tipo de Movimiento</div>
                        <div className='col-md-9'>
                            <InputSelect value={movTipo} onChange={setMovTipo} sincero
                                         opciones={[
                                             {id: 1, descripcion: 'Ingreso'},
                                             {id: 2, descripcion: 'Egreso'}
                                         ] as Array<OpcionSelect>}
                                         className={movTipo === 1 ? 'text-success' : 'text-danger'}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Importe</div>
                        <div className='col-md-9'>
                            <InputMoneda value={movImporte} onChange={setMovImporte} autoFocus
                                         invalido={!movValidacion.importe}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Descripción</div>
                        <div className='col-md-9'>
                            <InputTexto value={movDescripcion} onChange={setMovDescripcion}
                                        invalido={!movValidacion.descripcion}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Medio de Pago</div>
                        <div className='col-md-9'>
                            <InputSelect value={movMedioDePago} onChange={setMovMedioDePago}
                                         opciones={datos.mediosdepago} textocero='(No especificar)'/>
                        </div>
                    </div>
                    <ModalBotones onCancelar={() => setIngresar(false)} guardando={guardando}
                                  onGuardar={() => guardarMovimientoHandler()} tituloNuevo='Aceptar'/>
                </Modal>
            )}
            {cierreCaja && (
                <Modal>
                    <h4>Cierre de Caja</h4>
                    <p>
                        <i className='fas fa-exclamation-triangle text-warning me-2'/>
                        Verifique la información antes de confirmar el cierre de caja. <strong>Esta acción
                        no se puede deshacer.</strong>
                    </p>
                    <div className='row mb-2'>
                        <div className='col-md-3'>Saldo al Cierre</div>
                        <div className='col-md-9 fw-bold'>
                            {datos.registro.saldo_fmt}
                            {datos.registro.saldo_pendiente > 0 && (
                                <span className='ms-2'>(+ {datos.registro.saldo_pendiente_fmt})</span>
                            )}
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3'>Movimientos</div>
                        <div className='col-md-9 fw-bold'>{datos.movimientos.length}</div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3'>Ingresos</div>
                        <div className='col-md-9 fw-bold'>{calcularMovimientosCierre(1)}</div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3'>Egresos</div>
                        <div className='col-md-9 fw-bold'>{calcularMovimientosCierre(2)}</div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-3'>Pedidos pendientes</div>
                        <div className='col-md-9 fw-bold'>
                            {datos.pedidos_pendientes === 0 ? 'No hay pedidos pendientes' : datos.pedidos_pendientes}
                        </div>
                    </div>
                    <hr/>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Saldo inicial</div>
                        <div className='col-md-9'>
                            <InputMoneda value={cierreSaldoInicial === undefined ?
                                datos.registro.saldo : cierreSaldoInicial}
                                         onChange={v => {
                                             if (cierreSaldoInicial !== undefined) setCierreSaldoInicial(v)
                                         }} disabled={cierreSaldoInicial === undefined}/>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-9 offset-md-3'>
                            <InputSwitch checked={cierreSaldoInicial === undefined} titulo='Mantener saldo'
                                         onChange={() =>
                                             setCierreSaldoInicial(cierreSaldoInicial === undefined ? 0 :
                                                 undefined)}/>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-3 pt-1'>Comentarios</div>
                        <div className='col-md-9'>
                            <InputTexto value={cierreComentarios} onChange={setCierreComentarios} autoFocus
                                        placeholder='(Opcional)'/>
                        </div>
                    </div>
                    <ModalBotones onCancelar={() => setCierreCaja(false)} guardando={guardando}
                                  onGuardar={() => cierreCajaHandler()} tituloNuevo='Confirmar Cierre'/>
                </Modal>
            )}
            {actualizarSaldo && (
                <Modal>
                    <h4>Actualización de Saldo</h4>
                    <div className='row mb-2'>
                        <div className='col-md-3'>Nuevo Saldo</div>
                        <div className='col-md-9 fw-bold'>
                            <InputMoneda value={saldoCaja} onChange={setSaldoCaja} autoFocus/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3'>Comentarios</div>
                        <div className='col-md-9 fw-bold'>
                            <InputTexto value={saldoComentarios} onChange={setSaldoComentarios}
                                        placeholder='(Opcional)' className='mb-3'/>
                        </div>
                    </div>
                    <ModalBotones onCancelar={() => setActualizarSaldo(false)} guardando={guardando}
                                  onGuardar={() => saldoCajaHandler()} tituloNuevo='Confirmar Actualización'/>
                </Modal>
            )}
            {verificarPermisoOcultar && (
                <UsuarioAdministrador onLoginOk={() =>
                    guardarOpcionesUsuario('ocultar_saldo', 0, () => {
                        actualizar()
                        setVerificarPermisoOcultar(false)
                    })
                }
                                      onCancelar={() => setVerificarPermisoOcultar(false)}/>
            )}
        </>
    )
}

export default Caja