import React from "react";

type Props = {
    colSpan: number,
    texto: string
}

const TrInfo: React.FC<Props> = ({colSpan, texto}) => {
    return <tr>
        <td colSpan={colSpan}>
            <i className='fas fa-info-circle me-2 text-info'/>{texto}
        </td>
    </tr>
}

export default TrInfo