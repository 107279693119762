import React from 'react';
import {createRoot} from "react-dom/client";
import './css/App.scss';
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css'
import App from './App';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Caja from "./caja/Caja";
import ListaPedidos from "./pedidos/ListaPedidos";
import Cerveceria from "./cerveceria/Cerveceria";
import Clientes from "./clientes/Clientes";
import {Provider} from "react-redux";
import store from "./redux/store";
import Stock from "./stock/Stock";
import Elaborados from "./elaborados/Elaborados";
import EdicionPedido from "./pedidos/EdicionPedido";
import HistorialCierres from "./caja/HistorialCierres";
import ConsultarCierre from "./caja/ConsultarCierre";
import Admin from "./admin/Admin";
import AdminCategorias from "./admin/AdminCategorias";
import AdminNegocio from "./admin/AdminNegocio";
import Proveedores from "./proveedores/Proveedores";
import StockProductos from "./stock/StockProductos";
import AdminUsuarios from "./admin/AdminUsuarios";
import CerveceriaConfiguracion from "./cerveceria/CerveceriaConfiguracion";
import ProveedoresHistorial from "./proveedores/ProveedoresHistorial";
import StockMovimientosProducto from "./stock/StockMovimientosProducto";
import config from "./config";
import AdminAuditoria from "./admin/AdminAuditoria";
import AdminPerfilPublico from "./admin/AdminPerfilPublico";
import Estadisticas from "./estadisticas/Estadisticas";
import Mesas from "./mesas/Mesas";
import StockAnalisisDeMargen from "./stock/StockAnalisisDeMargen";
import CerveceriaBarriles from "./cerveceria/CerveceriaBarriles";

createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter basename={config.basename}>
                <Routes>
                    <Route path='/' element={<App/>}>
                        <Route path='caja'>
                            <Route index element={<Caja/>}/>
                            <Route path='historial'>
                                <Route index element={<HistorialCierres/>}/>
                                <Route path='consulta' element={<ConsultarCierre/>}/>
                            </Route>
                        </Route>
                        <Route path='pedidos'>
                            <Route index element={<ListaPedidos/>}/>
                            <Route path='editar' element={<EdicionPedido/>}/>
                        </Route>
                        <Route path='cerveceria'>
                            <Route index element={<Cerveceria/>}/>
                            <Route path='barriles' element={<CerveceriaBarriles/>}/>
                            <Route path='configuracion' element={<CerveceriaConfiguracion/>}/>
                        </Route>
                        <Route path='elaborados' element={<Elaborados/>}/>
                        <Route path='stock'>
                            <Route index element={<Stock/>}/>
                            <Route path='productos' element={<StockProductos/>}/>
                            <Route path='movimientos' element={<StockMovimientosProducto/>}/>
                            <Route path='analisisdemargen' element={<StockAnalisisDeMargen/>}/>
                        </Route>
                        <Route path='proveedores'>
                            <Route index element={<Proveedores/>}/>
                            <Route path='historial' element={<ProveedoresHistorial/>}/>
                        </Route>
                        <Route path='clientes' element={<Clientes/>}/>
                        <Route path='estadisticas' element={<Estadisticas/>}/>
                        <Route path='admin' element={<Admin/>}>
                            <Route index element={<Navigate to='negocio' replace/>}/>
                            <Route path='negocio' element={<AdminNegocio/>}/>
                            <Route path='perfilpublico' element={<AdminPerfilPublico/>}/>
                            <Route path='categorias' element={<AdminCategorias/>}/>
                            <Route path='usuarios' element={<AdminUsuarios/>}/>
                            <Route path='auditoria' element={<AdminAuditoria/>}/>
                        </Route>
                        <Route path='mesas' element={<Mesas/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);
