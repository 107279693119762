import React from "react";

type Props = {
    children: React.ReactNode
}

const ContainerAdicion: React.FC<Props> = ({children}) => {
    return (
        <tr>
            <td className='bg-light'>
                <div className='row g-2'>
                    {children}
                </div>
            </td>
        </tr>
    )
}

export default ContainerAdicion