import React from "react";
import './LogoApp.css'
import config from "../config";

type Props = {
    enLinea?: boolean,
    link?: boolean
}

const LogoApp: React.FC<Props> = ({enLinea, link}) => {
    const linkHandler = () => {
        if (link !== undefined) window.open(config.link_sistema, '_blank')
    }

    if (enLinea) {
        return (
            <div className={'px-2 fuentetitulo d-inline-block' + (link ? ' hoverLogoApp' : '')}
                 style={{fontSize: '0.95em'}} onClick={linkHandler}>
                <span className='text-primary'>gs.</span>
                <span className='text-muted'>bares</span>
            </div>
        )
    } else {
        return (
            <h3 className='fuentetitulo'>
                <span className='text-primary'>gs.</span>
                <span className='text-black'>bares</span>
            </h3>
        )
    }
}

export default LogoApp