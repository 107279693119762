import React from "react";

type ToolbarBtnProps = {
    children: React.ReactNode,
    derecha?: boolean,
    fondo?: boolean,
    margen?: boolean
}

const ToolbarBtn: React.FC<ToolbarBtnProps> = (
    {
        children, derecha,
        fondo, margen
    }
) => {
    return (
        <div className={'btn-toolbar' + (fondo ? ' bg-light rounded-3 p-2' : '')
            + (margen ? ' mb-2' : '')}>
            {derecha ? (
                <div className='ms-auto'>{children}</div>
            ) : children}
        </div>
    )
}

export default ToolbarBtn
