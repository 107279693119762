import React, {useEffect, useState} from "react";
import TituloPagina from "../controles/TituloPagina";
import Tarjeta from "../controles/Tarjeta";
import BarraTarjeta from "../controles/BarraTarjeta";
import InputFecha from "../controles/InputFecha";
import moment from "moment/moment";
import Boton from "../controles/Boton";
import EstadisticasMediosDePago, {IngresosMediosDePago} from "./EstadisticasMediosDePago";
import EstadisticasTotalIngresosGastos, {TotalIngresosGastos} from "./EstadisticasTotalIngresosGastos";
import EstadisticasCategoriasProductos, {VentasCategoriasProductos} from "./EstadisticasCategoriasProductos";
import {useGsBackend} from "../funcionesApi";

type TiposFechas = 'mesencurso' | 'semanaencurso' | 'personalizar'

type FechasPeriodo = {
    desde: string,
    hasta: string
}

type InfoGenerales = {
    total_ig: TotalIngresosGastos,
    ingresos_mp: Array<IngresosMediosDePago>,
    ventas_cat: Array<VentasCategoriasProductos>
}

const Estadisticas: React.FC = () => {
    const hoy = moment().format('YYYY-MM-DD')
    const inicioMes = moment().startOf('month').format('YYYY-MM-DD')
    const inicioSemana = moment().startOf('week').format('YYYY-MM-DD')
    const [datos, setDatos] = useState<InfoGenerales>({
        ingresos_mp: [], ventas_cat: [], total_ig: {
            ingresos: 0, ingresos_fmt: '', gastos: 0, gastos_fmt: '',
            saldo: 0, saldo_fmt: ''
        }
    })
    const [tipoFecha, setTipoFecha] = useState<TiposFechas>('mesencurso')
    const [fechas, setFechas] = useState<FechasPeriodo>({desde: inicioMes, hasta: hoy})
    const {pedidoJson} = useGsBackend()

    useEffect(() => {
        pedidoJson({
            url: `estadisticas/reportesgenerales?desde=${fechas.desde}&hasta=${fechas.hasta}`,
            ok: setDatos
        })
    }, [pedidoJson, fechas.desde, fechas.hasta])

    const descMes = (mes: number): string => {
        const descMeses: Array<{ mes: number, desc_corta: string }> = [
            {mes: 1, desc_corta: 'Enero'}, {mes: 2, desc_corta: 'Febrero'}, {mes: 3, desc_corta: 'Marzo'},
            {mes: 4, desc_corta: 'Abril'}, {mes: 5, desc_corta: 'Mayo'}, {mes: 6, desc_corta: 'Junio'},
            {mes: 7, desc_corta: 'Julio'}, {mes: 8, desc_corta: 'Agosto'}, {mes: 9, desc_corta: 'Septiembre'},
            {mes: 10, desc_corta: 'Octubre'}, {mes: 11, desc_corta: 'Noviembre'}, {mes: 12, desc_corta: 'Diciembre'}
        ]
        return (descMeses.filter(d => d.mes === mes)[0]?.desc_corta || '')
    }

    const fechaHandler = (tipo: 'desde' | 'hasta', valor: string): void => {
        if (valor === '') return

        if (tipo === 'desde') {
            if (moment(valor).isAfter(moment(fechas.hasta))) {
                setFechas({desde: fechas.hasta, hasta: fechas.hasta})
            } else {
                setFechas({desde: valor, hasta: fechas.hasta})
            }
        } else {
            if (moment(valor).isBefore(moment(fechas.desde))) {
                setFechas({desde: fechas.desde, hasta: fechas.desde})
            } else {
                setFechas({desde: fechas.desde, hasta: valor})
            }
        }
    }

    // Adecuar a la seleccion de nuevos tipos de fecha
    useEffect(() => {
        if (tipoFecha === 'mesencurso') {
            setFechas({desde: inicioMes, hasta: hoy})
        } else if (tipoFecha === 'semanaencurso') {
            setFechas({desde: inicioSemana, hasta: hoy})
        }
    }, [tipoFecha, hoy, inicioMes, inicioSemana])

    // Titulo para la visualización de fechas
    const tituloFechas = (): JSX.Element => {
        const fd = moment(fechas.desde)
        const fh = moment(fechas.hasta)

        const descFd = `${fd.date()} de ${descMes(fd.month() + 1)} de ${fd.year()}`
        const descFh = `${fh.date()} de ${descMes(fh.month() + 1)} de ${fh.year()}`

        if (descFd === descFh) {
            return (
                <>
                    <i className='fas fa-info-circle me-2 text-info'/>
                    Datos del <strong>{descFd}</strong>
                </>
            )
        } else {
            return (
                <>
                    <i className='fas fa-info-circle me-2 text-info'/>
                    Datos desde el <strong>{descFd}</strong> hasta el <strong>{descFh}</strong>
                </>
            )
        }
    }

    return (
        <>
            <TituloPagina icono='fas fa-chart-pie' titulo='Estadísticas'/>
            <BarraTarjeta>
                <div className='row g-2'>
                    <div className='col-auto text-nowrap'>
                        <Boton toolbar titulo='Mes en curso' icono='fas fa-calendar'
                               className={tipoFecha === 'mesencurso' ? 'btn-primary' : 'btn-light'}
                               onClick={() => setTipoFecha('mesencurso')}/>
                        <Boton toolbar titulo='Semana en curso' icono='fas fa-calendar-week'
                               className={tipoFecha === 'semanaencurso' ? 'btn-primary' : 'btn-light'}
                               onClick={() => setTipoFecha('semanaencurso')}/>
                        <Boton toolbar titulo='Elegir fechas' icono='fas fa-calendar-days'
                               className={tipoFecha === 'personalizar' ? 'btn-primary' : 'btn-light'}
                               onClick={() => setTipoFecha('personalizar')}/>
                    </div>
                    {tipoFecha === 'personalizar' && (
                        <>
                            <div className='col-auto text-nowrap'>
                                <InputFecha value={fechas.desde} onChange={v => fechaHandler('desde', v)}
                                            titulo='Desde' chico/>
                            </div>
                            <div className='col-auto text-nowrap'>
                                <InputFecha value={fechas.hasta} onChange={v => fechaHandler('hasta', v)}
                                            titulo='Hasta' chico/>
                            </div>
                        </>
                    )}
                </div>
            </BarraTarjeta>
            <Tarjeta espacio>
                <h5 className='mb-0'>{tituloFechas()}</h5>
            </Tarjeta>
            <div className='row g-2'>
                <div className='col-xl-6'>
                    <EstadisticasTotalIngresosGastos datos={datos.total_ig}/>
                    <EstadisticasMediosDePago datos={datos.ingresos_mp}/>
                </div>
                <div className='col-xl-6'>
                    <EstadisticasCategoriasProductos datos={datos.ventas_cat}/>
                </div>
            </div>
        </>
    )
}

export default Estadisticas