import React from "react";
import {Pedido, TiempoPedido} from "./ListaPedidos";
import {posicionMenuContextual} from "../controles/menuContextual";

type Props = {
    pedido: Pedido,
    anchoCols: number,
    onClick: () => void,
    onContextMenu: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    posMenu?: posicionMenuContextual,
    tiempos?: Array<TiempoPedido>,
    mostrarIcono?: boolean
}

const TarjetaPedido: React.FC<Props> = (
    {
        pedido, anchoCols, onClick, onContextMenu, posMenu, tiempos,
        mostrarIcono
    }
) => {
    switch (pedido.tipo) {
        case 'local':
            return (
                <div className={'col-' + anchoCols} onClick={onClick} onContextMenu={onContextMenu}>
                    <div className={'card shadow-sm cardhover border-0 border-start border-4 ' +
                        'border-primary h-100' + (posMenu?.elem === pedido.id ? ' bg-secondary' : '')}>
                        <div className='card-body py-1'>
                            <div className='d-flex'>
                                <div className='fw-bold text-center'>
                                    <div style={{fontSize: '1.75em'}} className='pe-3'>
                                        {pedido.nro_pedido}
                                    </div>
                                </div>
                                <div className='flex-grow-1'>
                                    <div className='fw-bold'>
                                        {mostrarIcono && (<i className='fas fa-home text-primary me-2'/>)}
                                        {pedido.descripcion}
                                    </div>
                                    {pedido.pedido_confirmado ? (
                                        <>
                                            <div className='small'>{pedido.fecha_y_hora}</div>
                                            <div className='small fw-bold'>{pedido.importe_total_fmt}</div>
                                        </>
                                    ) : (
                                        <div className='badge bg-secondary'>
                                            <i className='fas fa-exclamation-triangle me-1'/>
                                            Pedido Sin Confirmar
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        case 'delivery':
            return (
                <div className={'col-' + anchoCols} onClick={onClick} onContextMenu={onContextMenu}>
                    <div className={'card shadow-sm cardhover border-0 border-start border-4 ' +
                        'border-info h-100' + (posMenu?.elem === pedido.id ? ' bg-secondary' : '')}>
                        <div className='card-body py-1 pe-1'>
                            <div className='d-flex'>
                                <div className='fw-bold text-center'>
                                    <div style={{fontSize: '1.75em'}} className='pe-3'>
                                        {pedido.nro_pedido}
                                    </div>
                                </div>
                                <div className='flex-grow-1'>
                                    <div className='fw-bold'>
                                        {mostrarIcono && (<i className='fas fa-motorcycle text-info me-2'/>)}
                                        {pedido.descripcion} ({pedido.telefono})
                                    </div>
                                    {pedido.pedido_confirmado ? (
                                        <>
                                            <div className='small'>{pedido.direccion}</div>
                                            {(pedido.repartidor !== '' || pedido.mediodepago !== '') && (
                                                <div className='small'>
                                                    {pedido.repartidor !== '' && (
                                                        <span className='me-2'>
                                                    <i className='fas fa-motorcycle me-1'/>
                                                            {pedido.repartidor}
                                                </span>
                                                    )}
                                                    {pedido.mediodepago !== '' && (
                                                        <span>
                                                    <i className='fas fa-money-bill me-1'/>{pedido.mediodepago}
                                                </span>
                                                    )}
                                                </div>
                                            )}
                                            <div className='small'>
                                                {pedido.fecha_y_hora}
                                                <span className='text-danger ms-2 text-nowrap'>
                                            {tiempos?.filter(t => t.id_pedido === pedido.id)[0]?.tiempo || ''}
                                        </span>
                                            </div>
                                            <div className='small fw-bold'>{pedido.importe_total_fmt}</div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='badge bg-secondary'>
                                                <i className='fas fa-exclamation-triangle me-1'/>
                                                Pedido Sin Confirmar
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div>
                                    <button type='button' className='btn btn-light btn-sm'
                                            onClick={e => {
                                                e.stopPropagation()
                                                window.open('https://maps.google.com?q=' +
                                                    encodeURIComponent(pedido.direccion), '_blank')
                                            }}>
                                        <i className='fas fa-location-dot text-primary'/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        default:
            return <React.Fragment/>
    }
}

export default TarjetaPedido