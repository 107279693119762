import React from "react";

type InfoCerveza = {
    descripcion?: string,
    precio?: number,
    precio_fmt?: string,
    subtipo: number
}

type Props = {
    titulo: string,
    categoria?: string,
    cervezas: Array<InfoCerveza>,
    onClick: (subtipo: number) => void
}

const TarjetaAdicionCerveza: React.FC<Props> = ({titulo, cervezas, categoria, onClick}) => {
    const botones = cervezas.filter(c => c.descripcion && c.precio && c.precio_fmt)
    const vistaCategoria = categoria !== undefined ? (
        <div><span className='badge bg-light px-2 text-muted'>{categoria}</span></div>
    ) : <React.Fragment/>

    return (
        <div className='col-12'>
            <div className='card shadow-sm border-0 h-100 p-2 border-4 border-start border-secondary'>
                <div className='row g-2'>
                    <div className='col-6'>
                        <div className='row g-2'>
                            <div className='col-auto'>
                                <i className='fas fa-beer text-primary'/>
                            </div>
                            <div className='col'>
                                <div className='fw-bold'>{titulo}</div>
                                {vistaCategoria}
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='row g-2'>
                            {botones.map((b, k) => (
                                <div key={k} className='col h-100'>
                                    <div
                                        className='card border-0 border-4 border-primary border-start shadow-sm cardhover'
                                        onClick={() => onClick(b.subtipo)}>
                                        <div className='text-center py-1'>
                                            <div className='fw-bold'>{b.descripcion}</div>
                                            <div className='text-nowrap'>{b.precio_fmt}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TarjetaAdicionCerveza