import React, {useCallback, useEffect, useState} from "react";
import TituloPagina from "../controles/TituloPagina";
import Boton from "../controles/Boton";
import {useNavigate} from "react-router-dom";
import {nivelAcceso} from "../autorizacion";
import BarraTarjeta from "../controles/BarraTarjeta";
import {posicionMenuContextual} from "../controles/menuContextual";
import MenuContextualPedidos from "./MenuContextualPedidos";
import CancelarPedido from "./CancelarPedido";
import CerrarPedido from "./CerrarPedido";
import {crearPedido, imprimirComanda} from "./funcionesPedidos";
import moment from "moment";
import TarjetaPedido from "./TarjetaPedido";
import SeguimientoDelivery from "./SeguimientoDelivery";
import SeguimientoPedidos from "./SeguimientoPedidos";
import {NivelesAcceso} from "../redux/authReducer";
import {useGsBackend} from "../funcionesApi";

export interface Pedido {
    id: number,
    nro_pedido: number,
    tipo: 'local' | 'delivery',
    descripcion: string,
    direccion: string,
    telefono: string,
    fecha_orig: string,
    fecha_y_hora: string,
    importe_total: number,
    importe_total_fmt: string,
    repartidor: string,
    mediodepago: string,
    pedido_confirmado: boolean
}

type InfoPedidos = {
    pedidos: Array<Pedido>,
    vista: number,
    mostrar_seguimiento_pedidos: boolean,
    mostrar_seguimiento_delivery: boolean
}

export interface TiempoPedido {
    id_pedido: number,
    tiempo: string
}

const ListaPedidos: React.FC = () => {
    const [posMenu, setPosMenu] = useState<posicionMenuContextual | undefined>(undefined)
    const [datos, setDatos] = useState<InfoPedidos>({
        pedidos: [], vista: 0, mostrar_seguimiento_delivery: false, mostrar_seguimiento_pedidos: false
    })
    const [locales, setLocales] = useState<Array<Pedido>>([])
    const [deliverys, setDeliverys] = useState<Array<Pedido>>([])
    const [tiempos, setTiempos] = useState<Array<TiempoPedido>>([])
    const [cancelarPedido, setCancelarPedido] = useState<number | undefined>(undefined)
    const [cerrarPedido, setCerrarPedido] = useState<number | undefined>(undefined)
    const [seguimientoDelivery, setSeguimientoDelivery] = useState<boolean>(false)
    const [seguimientoPedidos, setSeguimientoPedidos] = useState<boolean>(false)
    const [cargaInicial, setCargaInicial] = useState<boolean>(false)
    const tamanioLocal: number = datos.vista * 3
    const navigate = useNavigate()
    const acceso = nivelAcceso('pedidos')

    const {pedidoJson, actualizar, guardarOpcionesUsuario} = useGsBackend()
    useEffect(() => {
        pedidoJson({
            url: 'pedidos/lista', ok: res => {
                setDatos(res)
                setCargaInicial(true)
            }
        })
    }, [pedidoJson])

    useEffect(() => {
        setLocales(datos.pedidos.filter(p => p.tipo === 'local'))
        setDeliverys(datos.pedidos.filter(p => p.tipo === 'delivery'))
    }, [datos.pedidos])

    const expandir = (tabla: 'local' | 'delivery'): void => {
        if (tabla === 'local') {
            guardarOpcionesUsuario('pedidos_vista', datos.vista === 3 ? 0 : datos.vista + 1, actualizar)
        } else if (tabla === 'delivery') {
            guardarOpcionesUsuario('pedidos_vista', datos.vista - 1, actualizar)
        }
    }

    const dividirVista = (): void => {
        guardarOpcionesUsuario('pedidos_vista', 2, actualizar)
    }

    const anchoCols = (espacio: number): number => {
        switch (espacio) {
            case 12:
                return 3
            case 9:
                return 4
            case 6:
                return 6
            case 3:
                return 12
            default:
                return 0
        }
    }

    const menuHandler = (e: any, elem: number): void => {
        e.preventDefault()
        setPosMenu({posX: e.clientX, posY: e.clientY, elem: elem})
    }

    const tiempoHandler = useCallback(() => {
        setTiempos(deliverys.map(d => {
            const inicio = moment(d.fecha_orig, 'YYYY-MM-DD HH:mm:ss')
            const fin = moment()

            const min = fin.diff(inicio, 'minute')
            const seg = fin.diff(inicio, 'second') % 60

            return {
                id_pedido: d.id,
                tiempo: `+${min}m ${seg}s`
            } as TiempoPedido
        }))
    }, [deliverys])

    useEffect(() => {
        const intervalTiempos = setInterval(tiempoHandler, 1000)
        return () => clearInterval(intervalTiempos)
    })

    useEffect(() => {
        tiempoHandler()
    }, [tiempoHandler, deliverys])

    return (
        <>
            <TituloPagina icono='fas fa-ticket' titulo='Pedidos vigentes'/>
            <BarraTarjeta>
                <div className='row g-2'>
                    <div className='col-auto'>
                        <div className='btn-toolbar'>
                            {acceso > NivelesAcceso.NivelConsulta && (
                                <>
                                    <Boton toolbar titulo='Abrir pedido local' icono='fas fa-home'
                                           className='btn-light' onClick={() =>
                                        crearPedido('local').then(res => {
                                            if (res !== 0) navigate(`editar?id=${res}`)
                                        })} estiloIcono='text-primary'/>
                                    <Boton toolbar titulo='Abrir pedido delivery' icono='fas fa-motorcycle'
                                           className='btn-light' onClick={() =>
                                        crearPedido('delivery').then(res => {
                                            if (res !== 0) navigate(`editar?id=${res}`)
                                        })} estiloIcono='text-info'/>
                                </>
                            )}
                            {datos.mostrar_seguimiento_pedidos && (
                                <Boton toolbar titulo='Seguimiento de Pedidos' icono='fas fa-ticket'
                                       className='btn-light' estiloIcono='text-secondary'
                                       onClick={() => setSeguimientoPedidos(true)}/>
                            )}
                            {datos.mostrar_seguimiento_delivery && (
                                <Boton toolbar titulo='Seguimiento de Delivery' icono='fas fa-clock'
                                       className='btn-light' estiloIcono='text-secondary'
                                       onClick={() => setSeguimientoDelivery(true)}/>
                            )}
                        </div>
                    </div>
                    <div className='col pt-1'>
                        {!cargaInicial && (
                            <>
                                <div className='spinner-border spinner-border-sm text-primary me-2'/>
                                Cargando...
                            </>
                        )}
                    </div>
                </div>
            </BarraTarjeta>
            {cargaInicial && (
                <div className='row g-2'>
                    {datos.vista === 0 ? (
                        <div className='col'>
                            <div className='bg-dark rounded-2 p-1 text-white small fw-bold mb-2'>
                                <div className='py-1 ps-2 d-inline-block'>
                                    <i className='fas fa-ticket me-2'/>Todos los pedidos
                                </div>
                                <div className='float-end px-3 py-1 botoncitoTitulo'
                                     onClick={() => dividirVista()}>
                                    <i className='fas fa-caret-left'/><i className='fas fa-caret-right'/>
                                </div>
                            </div>
                            <div className='row g-2'>
                                {datos.pedidos.length ? datos.pedidos.map((p, k) => (
                                    <TarjetaPedido key={k} pedido={p} anchoCols={anchoCols(12)}
                                                   onClick={() => navigate(`editar?id=${p.id}`)}
                                                   onContextMenu={e => menuHandler(e, p.id)} posMenu={posMenu}
                                                   mostrarIcono={true} tiempos={tiempos}/>
                                )) : (
                                    <div className='col'>
                                        <i className='fas fa-check text-success me-2'/>
                                        No hay pedidos pendientes
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className={'col-md-' + tamanioLocal.toString()}>
                                <div className='bg-primary rounded-2 p-1 text-white small fw-bold mb-2'>
                                    <div className='py-1 ps-2 d-inline-block'>
                                        <i className='fas fa-house me-2'/>Local
                                    </div>
                                    <div className='float-end px-3 py-1 botoncitoTitulo'
                                         onClick={() => expandir('delivery')}>
                                        <i className='fas fa-caret-left'/>
                                    </div>
                                </div>
                                <div className='row g-2'>
                                    {locales.length ? locales.map((p, k) => (
                                        <TarjetaPedido key={k} pedido={p} anchoCols={anchoCols(tamanioLocal)}
                                                       onClick={() => navigate(`editar?id=${p.id}`)}
                                                       onContextMenu={e => menuHandler(e, p.id)} posMenu={posMenu}/>
                                    )) : (
                                        <div className='col'>
                                            <i className='fas fa-check text-success me-2'/>
                                            No hay pedidos locales pendientes
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={'col-md-' + (12 - tamanioLocal).toString()}>
                                <div className='bg-info rounded-2 p-1 text-white small fw-bold mb-2'>
                                    <div className='float-start px-3 py-1 botoncitoTitulo'
                                         onClick={() => expandir('local')}>
                                        <i className='fas fa-caret-right'/>
                                    </div>
                                    <div className='py-1 ps-2 d-inline-block'>
                                        <i className='fas fa-motorcycle me-2'/>Delivery
                                    </div>
                                </div>
                                <div className='row g-2'>
                                    {deliverys.length ? deliverys.map((p, k) => (
                                        <TarjetaPedido key={k} pedido={p} tiempos={tiempos}
                                                       anchoCols={anchoCols(12 - tamanioLocal)}
                                                       onClick={() => navigate(`editar?id=${p.id}`)}
                                                       onContextMenu={e => menuHandler(e, p.id)} posMenu={posMenu}/>
                                    )) : (
                                        <div className='col'>
                                            <i className='fas fa-check text-success me-2'/>
                                            No hay pedidos para delivery pendientes
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
            {posMenu !== undefined && (
                <MenuContextualPedidos posX={posMenu.posX} posY={posMenu.posY}
                                       onEditar={() => navigate(`editar?id=${posMenu?.elem}`)}
                                       onCerrarPedido={() => setCerrarPedido(posMenu?.elem)}
                                       onCancelarPedido={() => setCancelarPedido(posMenu?.elem)}
                                       onCerrarMenu={() => setPosMenu(undefined)}
                                       onComanda={() => imprimirComanda(posMenu?.elem)}
                                       sinConfirmar={!datos.pedidos
                                           .filter(p => p.id === posMenu?.elem)[0]?.pedido_confirmado}/>
            )}
            {cancelarPedido !== undefined && (
                <CancelarPedido idPedido={cancelarPedido}
                                onAceptar={() => {
                                    setCancelarPedido(undefined)
                                    actualizar()
                                }}
                                onVolver={() => setCancelarPedido(undefined)}/>
            )}
            {cerrarPedido !== undefined && (
                <CerrarPedido idPedido={cerrarPedido}
                              onAceptar={() => {
                                  setCerrarPedido(undefined)
                                  actualizar()
                              }}
                              onVolver={() => setCerrarPedido(undefined)}/>
            )}
            {seguimientoDelivery && (<SeguimientoDelivery onCerrar={() => setSeguimientoDelivery(false)}/>)}
            {seguimientoPedidos && (<SeguimientoPedidos onCerrar={() => setSeguimientoPedidos(false)}/>)}
        </>
    )
}

export default ListaPedidos