import React from "react";
import {estilosApp} from "../estilos";
import {useAppSelector} from "../redux/hooks";

type Props = {
    children: React.ReactNode,
    bordeArriba?: boolean,
    bordeIzquierda?: boolean,
    estiloBorde?: string
    espacio?: boolean,
    className?: string
}

const Tarjeta: React.FC<Props> = (
    {
        children, bordeArriba, bordeIzquierda, espacio,
        estiloBorde, className
    }
) => {
    const st = estilosApp(useAppSelector(state => state.estado.modo_oscuro))
    const sb = estiloBorde || 'border-primary'

    let estilo = 'border-0'
    if (bordeArriba) {
        estilo += ' border-top border-4 ' + sb
    } else if (bordeIzquierda) {
        estilo += ' border-start border-4 ' + sb
    }
    if (espacio) estilo += ' mb-2'

    return (
        <div className={`card ${st.tarjeta} shadow-sm ${estilo}`}>
            <div className={'card-body ' + (className === undefined ? '' : className)}>
                {children}
            </div>
        </div>
    )
}

export default Tarjeta