import React from "react";
import Tabla from "../controles/Tabla";
import {CervezaLitros} from "./CerveceriaEstadisticas";

type Props = {
    titulo: string,
    datos: Array<CervezaLitros>
}

const TablaEstadisticaCervezas: React.FC<Props> = ({titulo, datos}) => {
    const maximo = datos.length > 0 ? datos.map(c => c.litros).reduce((p, n) => p + n) : 0

    const anchoBarra = (dato: number, maximo: number): string => {
        if (maximo === 0) {
            return '0%'
        } else {
            return `${100 * dato / maximo}%`
        }
    }

    return datos.length > 0 ? (
        <>
            <h5>{titulo}</h5>
            <Tabla espacio>
                <thead>
                <tr className='headerTabla'>
                    <th style={{width: '35%'}}>Categoría</th>
                    <th style={{width: '20%'}}>&nbsp;</th>
                    <th style={{width: '15%'}}>Cantidad</th>
                    <th style={{width: '15%'}}>Litros</th>
                    <th style={{width: '15%'}}>Importe</th>
                </tr>
                </thead>
                <tbody>
                {datos.map((c, k) => (
                    <React.Fragment key={k}>
                        <tr>
                            <td>{c.categoria}</td>
                            <td>
                                <div className='progress bg-light'>
                                    <div className='progress-bar progress-bar-striped'
                                         style={{width: anchoBarra(c.litros, maximo)}}/>
                                </div>
                            </td>
                            <td className='text-end text-nowrap'>{c.cantidad}</td>
                            <td className='text-end text-nowrap'>{c.litros_fmt}</td>
                            <td className='text-end text-nowrap'>{c.importe_fmt}</td>
                        </tr>
                        {c.detalle.map((d, k) => (
                            <tr key={k} className='bg-light'>
                                <td colSpan={2} className='ps-3'>{d.tipo}</td>
                                <td className='text-end text-nowrap'>{d.cantidad}</td>
                                <td className='text-end text-nowrap'>{d.litros_fmt}</td>
                                <td className='text-end text-nowrap'>{d.importe_fmt}</td>
                            </tr>
                        ))}
                    </React.Fragment>
                ))}
                </tbody>
            </Tabla>
        </>
    ) : <React.Fragment/>
}

export default TablaEstadisticaCervezas