import React, {useEffect, useState} from "react";
import TituloPagina from "../controles/TituloPagina";
import BarraTarjeta from "../controles/BarraTarjeta";
import Boton from "../controles/Boton";
import {useNavigate} from "react-router-dom";
import Tarjeta from "../controles/Tarjeta";
import ToolbarBtn from "../controles/ToolbarBtn";
import TrInfo from "../controles/TrInfo";
import Modal from "../controles/Modal";
import InputTexto from "../controles/InputTexto";
import ModalBotones from "../controles/ModalBotones";
import InputMoneda from "../controles/InputMoneda";
import Tabla from "../controles/Tabla";
import ControlPaginas, {paginado} from "../controles/ControlPaginas";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import {useGsBackend} from "../funcionesApi";

type Categoria = {
    id: number,
    descripcion: string,
    desc_recargas_grandes: string,
    desc_recargas_medianas: string,
    desc_recargas_chicas: string,
    desc_pintas_grandes: string,
    desc_pintas_chicas: string,
    precio_recargas_grandes: number,
    precio_recargas_medianas: number,
    precio_recargas_chicas: number,
    precio_pintas_grandes: number,
    precio_pintas_chicas: number,
    litros_recargas_grandes: number,
    litros_recargas_medianas: number,
    litros_recargas_chicas: number,
    litros_pintas_grandes: number,
    litros_pintas_chicas: number,
    resumen_recargas_grandes: string,
    resumen_recargas_medianas: string,
    resumen_recargas_chicas: string,
    resumen_pintas_grandes: string,
    resumen_pintas_chicas: string
}

type EdicionOpciones = {
    mediodepago: number
}

type InfoCategorias = {
    categorias: Array<Categoria>,
    mediosdepago: Array<OpcionSelect>,
    opciones: EdicionOpciones
}

type Validacion = {
    descripcion: boolean
}

const CerveceriaConfiguracion: React.FC = () => {
    const edicionInicial: Categoria = {
        id: 0, descripcion: '', desc_recargas_grandes: '', desc_recargas_medianas: '', desc_recargas_chicas: '',
        desc_pintas_grandes: '', desc_pintas_chicas: '', precio_recargas_grandes: 0, precio_recargas_medianas: 0,
        precio_recargas_chicas: 0, precio_pintas_grandes: 0, precio_pintas_chicas: 0, litros_recargas_grandes: 0,
        litros_recargas_medianas: 0, litros_recargas_chicas: 0, litros_pintas_grandes: 0, litros_pintas_chicas: 0,
        resumen_recargas_grandes: '', resumen_recargas_medianas: '', resumen_recargas_chicas: '',
        resumen_pintas_grandes: '', resumen_pintas_chicas: ''
    }
    const validacionInicial: Validacion = {descripcion: true}
    const [datos, setDatos] = useState<InfoCategorias>(
        {categorias: [], mediosdepago: [], opciones: {mediodepago: 0}}
    )
    const [pagina, setPagina] = useState<number>(1)
    const [editar, setEditar] = useState<Categoria | undefined>(undefined)
    const [opciones, setOpciones] = useState<EdicionOpciones>({mediodepago: 0})
    const [valid, setValid] = useState<Validacion>(validacionInicial)
    const navigate = useNavigate()
    const {pedidoJson, guardando, actualizar} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'cerveceria/categorias', ok: setDatos})
    }, [pedidoJson])

    const guardarHandler = async (eliminar?: boolean): Promise<void> => {
        if (datos === undefined || editar === undefined) return

        const vld: Validacion = {
            descripcion: editar.descripcion !== ''
        }
        setValid(vld)
        if (!vld.descripcion) return

        const fd = new FormData()
        fd.append('accion', eliminar ? 'eliminar' : editar.id === 0 ? 'nuevo' : 'editar')
        fd.append('id', editar.id.toString())
        fd.append('descripcion', editar.descripcion)
        fd.append('desc_recargas_grandes', editar.desc_recargas_grandes)
        fd.append('litros_recargas_grandes', editar.litros_recargas_grandes.toString())
        fd.append('precio_recargas_grandes', editar.precio_recargas_grandes.toString())
        fd.append('desc_recargas_medianas', editar.desc_recargas_medianas)
        fd.append('litros_recargas_medianas', editar.litros_recargas_medianas.toString())
        fd.append('precio_recargas_medianas', editar.precio_recargas_medianas.toString())
        fd.append('desc_recargas_chicas', editar.desc_recargas_chicas)
        fd.append('litros_recargas_chicas', editar.litros_recargas_chicas.toString())
        fd.append('precio_recargas_chicas', editar.precio_recargas_chicas.toString())
        fd.append('desc_pintas_grandes', editar.desc_pintas_grandes)
        fd.append('litros_pintas_grandes', editar.litros_pintas_grandes.toString())
        fd.append('precio_pintas_grandes', editar.precio_pintas_grandes.toString())
        fd.append('desc_pintas_chicas', editar.desc_pintas_chicas)
        fd.append('litros_pintas_chicas', editar.litros_pintas_chicas.toString())
        fd.append('precio_pintas_chicas', editar.precio_pintas_chicas.toString())

        pedidoJson({
            url: 'cerveceria/categorias', method: 'post', body: fd,
            ok: () => {
                setEditar(undefined)
                actualizar()
            }
        })
    }

    const guardarOpcionesCerveceria = async (): Promise<void> => {
        const fd = new FormData()
        fd.append('accion', 'editar')
        fd.append('id_mediodepago', opciones.mediodepago.toString())

        pedidoJson({url: 'cerveceria/opciones', method: 'post', body: fd})
    }

    // Mostrar opciones una vez que se actualicen los datos
    useEffect(() => {
        setOpciones({
            mediodepago: datos.opciones.mediodepago
        })
    }, [datos])

    return (
        <>
            <TituloPagina icono='fas fa-cog' titulo='Configuración de Cervecería'/>
            <BarraTarjeta toolbar>
                <Boton toolbar titulo='Volver' icono='fas fa-chevron-left' className='btn-light'
                       onClick={() => navigate('../')}/>
            </BarraTarjeta>
            <Tarjeta>
                <h5>Opciones</h5>
                <div className='row mb-2'>
                    <div className='col-xl-6'>
                        <div className='row'>
                            <div className='col-md-6 pt-1 small'>
                                Medio de pago predeterminado
                            </div>
                            <div className='col-md-6'>
                                <InputSelect value={opciones.mediodepago} textocero='(Ninguno)' chico
                                             onChange={v => setOpciones({...opciones, mediodepago: v})}
                                             opciones={datos.mediosdepago}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-6'>
                        <div className='row'>
                            <div className='col-md-6 offset-md-6'>
                                <Boton chico titulo='Guardar Configuración' icono='fas fa-save' className='btn-success'
                                       onClick={guardarOpcionesCerveceria} guardando={guardando}/>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <h5>Categorías</h5>
                <ToolbarBtn margen>
                    <Boton toolbar titulo='Nueva categoría' icono='fas fa-plus' className='btn-light'
                           onClick={() => {
                               setValid(validacionInicial)
                               setEditar(edicionInicial)
                           }}/>
                </ToolbarBtn>
                <Tabla>
                    <thead>
                    <tr>
                        <th colSpan={6}>
                            <ControlPaginas registros={datos.categorias.length} pagina={pagina} onChange={setPagina}/>
                        </th>
                    </tr>
                    <tr className='headerTabla'>
                        <th style={{width: '25%'}}>Descripción</th>
                        <th style={{width: '15%'}}>Tamaño 1</th>
                        <th style={{width: '15%'}}>Tamaño 2</th>
                        <th style={{width: '15%'}}>Tamaño 3</th>
                        <th style={{width: '15%'}}>Tamaño 4</th>
                        <th style={{width: '15%'}}>Tamaño 5</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.categorias.length ? paginado(datos.categorias, pagina).map((c, k) => (
                        <tr className='trhover' key={k} onClick={() => {
                            setValid(validacionInicial)
                            setEditar(c)
                        }}>
                            <td className='fw-bold'>{c.descripcion}</td>
                            {c.litros_recargas_grandes === 0 ? (
                                <td className='text-muted'>(N/D)</td>
                            ) : (
                                <td>{c.desc_recargas_grandes}<br/>{c.resumen_recargas_grandes}</td>
                            )}
                            {c.litros_recargas_medianas === 0 ? (
                                <td className='text-muted'>(N/D)</td>
                            ) : (
                                <td>{c.desc_recargas_medianas}<br/>{c.resumen_recargas_medianas}</td>
                            )}
                            {c.litros_recargas_chicas === 0 ? (
                                <td className='text-muted'>(N/D)</td>
                            ) : (
                                <td>{c.desc_recargas_chicas}<br/>{c.resumen_recargas_chicas}</td>
                            )}
                            {c.litros_pintas_grandes === 0 ? (
                                <td className='text-muted'>(N/D)</td>
                            ) : (
                                <td>{c.desc_pintas_grandes}<br/>{c.resumen_pintas_grandes}</td>
                            )}
                            {c.litros_pintas_chicas === 0 ? (
                                <td className='text-muted'>(N/D)</td>
                            ) : (
                                <td>{c.desc_pintas_chicas}<br/>{c.resumen_pintas_chicas}</td>
                            )}
                        </tr>
                    )) : (
                        <TrInfo colSpan={6} texto='No hay categorías para mostrar'/>
                    )}
                    </tbody>
                </Tabla>
            </Tarjeta>
            {editar !== undefined && (
                <Modal>
                    <h4>{editar.id === 0 ? 'Nueva Categoría' : 'Editar Categoría'}</h4>
                    <div className='mb-2'>
                        <InputTexto titulado titulo='Descripción' value={editar.descripcion} autoFocus
                                    onChange={v => setEditar({...editar, descripcion: v})}
                                    invalido={!valid.descripcion}/>
                    </div>
                    <table className='table table-sm border small mb-2'>
                        <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>Descripción</th>
                            <th>Litros</th>
                            <th>Precio</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className={editar.litros_recargas_grandes === 0 ?
                                'text-muted text-decoration-line-through' : ''}>Tamaño 1
                            </td>
                            <td>
                                <InputTexto value={editar.desc_recargas_grandes} chico autoSelect
                                            onChange={v => setEditar({...editar, desc_recargas_grandes: v})}/>
                            </td>
                            <td>
                                <InputMoneda value={editar.litros_recargas_grandes} chico sinSimbolo derecha autoSelect
                                             onChange={v => setEditar({...editar, litros_recargas_grandes: v})}/>
                            </td>
                            <td>
                                <InputMoneda value={editar.precio_recargas_grandes} chico derecha autoSelect
                                             onChange={v => setEditar({...editar, precio_recargas_grandes: v})}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={editar.litros_recargas_medianas === 0 ?
                                'text-muted text-decoration-line-through' : ''}>Tamaño 2
                            </td>
                            <td>
                                <InputTexto value={editar.desc_recargas_medianas} chico autoSelect
                                            onChange={v => setEditar({...editar, desc_recargas_medianas: v})}/>
                            </td>
                            <td>
                                <InputMoneda value={editar.litros_recargas_medianas} chico sinSimbolo derecha autoSelect
                                             onChange={v => setEditar({...editar, litros_recargas_medianas: v})}/>
                            </td>
                            <td>
                                <InputMoneda value={editar.precio_recargas_medianas} chico derecha autoSelect
                                             onChange={v => setEditar({...editar, precio_recargas_medianas: v})}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={editar.litros_recargas_chicas === 0 ?
                                'text-muted text-decoration-line-through' : ''}>Tamaño 3
                            </td>
                            <td>
                                <InputTexto value={editar.desc_recargas_chicas} chico autoSelect
                                            onChange={v => setEditar({...editar, desc_recargas_chicas: v})}/>
                            </td>
                            <td>
                                <InputMoneda value={editar.litros_recargas_chicas} chico sinSimbolo derecha autoSelect
                                             onChange={v => setEditar({...editar, litros_recargas_chicas: v})}/>
                            </td>
                            <td>
                                <InputMoneda value={editar.precio_recargas_chicas} chico derecha autoSelect
                                             onChange={v => setEditar({...editar, precio_recargas_chicas: v})}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={editar.litros_pintas_grandes === 0 ?
                                'text-muted text-decoration-line-through' : ''}>Tamaño 4
                            </td>
                            <td>
                                <InputTexto value={editar.desc_pintas_grandes} chico autoSelect
                                            onChange={v => setEditar({...editar, desc_pintas_grandes: v})}/>
                            </td>
                            <td>
                                <InputMoneda value={editar.litros_pintas_grandes} chico sinSimbolo derecha autoSelect
                                             onChange={v => setEditar({...editar, litros_pintas_grandes: v})}/>
                            </td>
                            <td>
                                <InputMoneda value={editar.precio_pintas_grandes} chico derecha autoSelect
                                             onChange={v => setEditar({...editar, precio_pintas_grandes: v})}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={editar.litros_pintas_chicas === 0 ?
                                'text-muted text-decoration-line-through' : ''}>Tamaño 5
                            </td>
                            <td>
                                <InputTexto value={editar.desc_pintas_chicas} chico autoSelect
                                            onChange={v => setEditar({...editar, desc_pintas_chicas: v})}/>
                            </td>
                            <td>
                                <InputMoneda value={editar.litros_pintas_chicas} chico sinSimbolo derecha autoSelect
                                             onChange={v => setEditar({...editar, litros_pintas_chicas: v})}/>
                            </td>
                            <td>
                                <InputMoneda value={editar.precio_pintas_chicas} chico derecha autoSelect
                                             onChange={v => setEditar({...editar, precio_pintas_chicas: v})}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className='mb-3'>
                        <i className='fas fa-info-circle text-info me-2'/>
                        Asignar los litros en 0 (cero) deshabilitará ese tipo de recarga para esta categoría
                    </div>
                    <ModalBotones editando={editar.id !== 0}
                                  onCancelar={() => setEditar(undefined)}
                                  onGuardar={() => guardarHandler()} guardando={guardando}
                                  onEliminar={() => guardarHandler(true)}/>
                </Modal>
            )}
        </>
    )
}

export default CerveceriaConfiguracion