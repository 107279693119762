import React, {createRef, useCallback, useEffect, useRef, useState} from "react";
import TituloPagina from "../controles/TituloPagina";
import {useNavigate, useSearchParams} from "react-router-dom";
import Boton from "../controles/Boton";
import ToolbarBtn from "../controles/ToolbarBtn";
import InputTexto from "../controles/InputTexto";
import {useAppDispatch} from "../redux/hooks";
import {setNotificacion} from "../redux/estadoReducer";
import {nivelAcceso} from "../autorizacion";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import Tarjeta from "../controles/Tarjeta";
import CancelarPedido from "./CancelarPedido";
import CerrarPedido from "./CerrarPedido";
import EdicionClientes, {DatosEdicionCliente} from "../clientes/EdicionClientes";
import {imprimirComanda} from "./funcionesPedidos";
import BarraTarjeta from "../controles/BarraTarjeta";
import TarjetaAdicion from "./TarjetaAdicion";
import ContainerAdicion from "./ContainerAdicion";
import TarjetaAdicionCerveza from "./TarjetaAdicionCerveza";
import TarjetaMensajeAdicion from "./TarjetaMensajeAdicion";
import InputMoneda from "../controles/InputMoneda";
import MasMenos from "../controles/MasMenos";
import {NivelesAcceso} from "../redux/authReducer";
import moment from "moment";
import sha256 from 'crypto-js/sha256'
import {useGsBackend} from "../funcionesApi";

export interface DetalleProducto {
    id: number,
    tipo: number,
    descripcion: string,
    precio_unitario?: number,
    precio_unitario_fmt?: string,
    recarga_grande?: number,
    recarga_grande_fmt?: string,
    recarga_mediana?: number,
    recarga_mediana_fmt?: string,
    recarga_chica?: number,
    recarga_chica_fmt?: string,
    pinta_grande?: number,
    pinta_grande_fmt?: string,
    pinta_chica?: number,
    pinta_chica_fmt?: string,
    stock: number,
    desc_recargas_grandes?: string,
    desc_recargas_medianas?: string,
    desc_recargas_chicas?: string,
    desc_pintas_grandes?: string,
    desc_pintas_chicas?: string,
    desc_categoria_cerveza?: string
}

type ProductoPedido = {
    id: number,
    id_propio: number,
    tipo: number,
    subtipo: number,
    descripcion: string,
    cantidad: number,
    precio_unitario: number,
    precio_total: number
}

type Categoria = {
    id: number,
    descripcion: string,
    cantidad_productos: number,
    productos: Array<DetalleProducto>
}

type InfoPedido = {
    fechayhora: string,
    nro_pedido: number,
    tipo: string,
    telefono: string,
    nombre: string,
    id_clientenegocio: number,
    direccion: string,
    id_repartidor: number,
    id_mediodepago: number,
    observaciones: string,
    observaciones_cliente: string,
    importe_total: number,
    total_sin_descuento: number,
    descuento: number,
    paga_con: number,
    cantidad_cubierto: number,
    valor_cubierto: number,
    valor_envio: number,
    productos: Array<ProductoPedido>,
    pedido_confirmado: boolean
}

type Datos = {
    categorias: Array<Categoria>,
    repartidores: Array<OpcionSelect>,
    mediosdepago: Array<OpcionSelect>
}

type InfoClienteNegocio = {
    id: number,
    nombre: string,
    telefono: string,
    email: string,
    direccion: string,
    comentarios: string,
    descuento: number,
    envio_gratis: boolean
}

type DatosEdicion = {
    nroPedido: number
    nombre: string,
    telefono: string,
    idCliente: number,
    direccion: string,
    repartidor: number,
    medioDePago: number,
    observaciones: string,
    observacionesCliente: string,
    descuento: number,
    paga_con: number,
    cantidad_cubierto: number,
    valor_cubierto: number,
    valor_envio: number
}

type OpcionesPedido = {
    mostrar_envio: boolean,
    envio_predeterminado: number,
    mostrar_cubierto: boolean,
    cubierto_predeterminado: number
}

type OpcionesGuardado = {
    comanda?: boolean,
    volver?: boolean,
    confirmar?: boolean
}

type TipoPedido = 'local' | 'delivery'

type EstadoGuardado = {
    guardando: boolean,
    mensaje?: React.ReactNode
}

type Totales = {
    total: number,
    sin_descuento: number
}

const EdicionPedido: React.FC = () => {
    const edicionInicial: DatosEdicion = {
        nroPedido: 0, nombre: '', telefono: '', idCliente: 0, direccion: '', repartidor: 0, medioDePago: 0,
        observaciones: '', observacionesCliente: '', descuento: 0, paga_con: 0, valor_envio: 0, valor_cubierto: 0,
        cantidad_cubierto: 0
    }
    const opcionesInicial: OpcionesPedido = {
        mostrar_envio: false, envio_predeterminado: 0, mostrar_cubierto: false, cubierto_predeterminado: 0
    }
    const [params, setParams] = useSearchParams()
    const tipoInicial: TipoPedido = params.get('tipo') === 'delivery' ? 'delivery' : 'local'
    const [tipo, setTipo] = useState<TipoPedido>(tipoInicial)
    const [datos, setDatos] = useState<Datos>({categorias: [], repartidores: [], mediosdepago: []})
    const [edicion, setEdicion] = useState<DatosEdicion>(edicionInicial)
    const [opciones, setOpciones] = useState<OpcionesPedido>(opcionesInicial)
    const [categ, setCateg] = useState<Categoria | undefined>(undefined)
    const [productosPedido, setProductosPedido] = useState<Array<ProductoPedido>>([] as Array<ProductoPedido>)
    const [total, setTotal] = useState<Totales>({total: 0, sin_descuento: 0})
    const [confirmarCancelacion, setConfirmarCancelacion] = useState<boolean>(false)
    const [confirmarCierre, setConfirmarCierre] = useState<number | undefined>(undefined)
    const [busqueda, setBusqueda] = useState<string>('')
    const [resultados, setResultados] = useState<Array<DetalleProducto>>([])
    const [filtroCateg, setFiltroCateg] = useState<string>('')
    const [productosFiltrados, setProductosFiltrados] = useState<Array<DetalleProducto>>([])
    const [habilitarAlta, setHabilitarAlta] = useState<boolean>(false)
    const [altaCliente, setAltaCliente] = useState<boolean>(false)
    const [guardando, setGuardando] = useState<EstadoGuardado>({guardando: false})
    const [cargaInicial, setCargaInicial] = useState<boolean>(false)
    const [aConfirmar, setAConfirmar] = useState<boolean>(false)
    const idEdicion = parseInt(params.get('id') || '0') || 0
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const acceso = nivelAcceso('pedidos')
    const intervaloGuardado = 1000
    const timeoutGuardado = 2000

    const txtTelefonoRef = createRef<HTMLInputElement>()
    const noCalcularTotal = useRef<boolean>(false)
    const editado = useRef<boolean>(false)
    const ultimoGuardado = useRef<number>(0)
    const cargando = useRef<boolean>(false)
    const envioOrig = useRef<number>(0)

    const {pedidoJson} = useGsBackend()
    useEffect(() => {
        if (idEdicion === 0) {
            navigate('../')
            return
        }

        cargando.current = true
        pedidoJson({
            url: `pedidos/datos?id=${idEdicion}`,
            ok: res => {
                setDatos({
                    categorias: res.categorias,
                    repartidores: res.repartidores,
                    mediosdepago: res.mediosdepago
                })
                const opc: OpcionesPedido = res.opciones
                setOpciones(opc)

                const info = res.info_pedido as InfoPedido
                noCalcularTotal.current = true
                envioOrig.current = info.valor_envio
                setTipo(info.tipo === 'delivery' ? 'delivery' : 'local')
                setEdicion({
                    nombre: info.nombre,
                    idCliente: info.id_clientenegocio,
                    telefono: info.telefono,
                    direccion: info.direccion,
                    observaciones: info.observaciones,
                    repartidor: info.id_repartidor,
                    medioDePago: info.id_mediodepago,
                    nroPedido: info.nro_pedido,
                    observacionesCliente: info.observaciones_cliente,
                    descuento: info.descuento,
                    paga_con: info.paga_con,
                    cantidad_cubierto: info.cantidad_cubierto,
                    valor_envio: info.valor_envio,
                    valor_cubierto: info.valor_cubierto
                })
                setAConfirmar(!info.pedido_confirmado)
                setTotal({total: info.importe_total, sin_descuento: info.total_sin_descuento})
                setProductosPedido(info.productos)
                setCargaInicial(true)
            },
            finally: () => cargando.current = false
        })
    }, [pedidoJson, navigate, idEdicion])

    // Manejo de las funciones de edición y guardado automático
    useEffect(() => {
        const guardadoAutomatico = () => {

            if (editado.current && ultimoGuardado.current <= Date.now() - timeoutGuardado && !cargando.current) {
                editado.current = false

                // Guardar los datos del pedido
                guardarHandler({}).catch(err => alert(err))
            }
        }
        const itv = setInterval(guardadoAutomatico, intervaloGuardado)
        return () => clearInterval(itv)
    })

    useEffect(() => {
        editado.current = true
        setGuardando({
            guardando: false, mensaje: (
                <><i className='fas fa-pen-to-square fa-fade text-muted me-2'/>Editando</>
            )
        })
        ultimoGuardado.current = Date.now()
    }, [edicion])

    useEffect(() => {
        setTipo(params.get('tipo') === 'delivery' ? 'delivery' : 'local')
    }, [params])

    const precioProducto = (p: DetalleProducto, subtipo: number = 0): number => {
        if (p.tipo !== 3) {
            return p.precio_unitario || 0
        } else {
            switch (subtipo) {
                case 1:
                    return p.recarga_grande || 0
                case 2:
                    return p.recarga_mediana || 0
                case 3:
                    return p.recarga_chica || 0
                case 4:
                    return p.pinta_grande || 0
                case 5:
                    return p.pinta_chica || 0
                default:
                    return 0
            }
        }
    }

    const calcularTotal = useCallback((lista: Array<ProductoPedido>): void => {
        if (noCalcularTotal.current) {
            noCalcularTotal.current = false
            return
        }

        let costos: number = 0
        if (tipo === 'local') {
            if (opciones.mostrar_cubierto) costos += edicion.valor_cubierto
        } else if (tipo === 'delivery') {
            if (opciones.mostrar_envio) costos += edicion.valor_envio
        }

        const nuevoTotal: number =
            lista.map(p => p.precio_total).reduce((suma, p) => suma + p, 0) + costos
        const totalProc: number = nuevoTotal - (nuevoTotal * (edicion.descuento / 100))
        setTotal({total: totalProc, sin_descuento: nuevoTotal})
        setEdicion(prev => {
            return {
                ...prev,
                paga_con: totalProc
            } as DatosEdicion
        })
    }, [edicion.valor_cubierto, edicion.valor_envio, edicion.descuento, opciones, tipo])

    const descripcionCerveza = (p: DetalleProducto, subtipo: number): string => {
        switch (subtipo) {
            case 1:
                return `${p.descripcion} ${p.desc_recargas_grandes}`
            case 2:
                return `${p.descripcion} ${p.desc_recargas_medianas}`
            case 3:
                return `${p.descripcion} ${p.desc_recargas_chicas}`
            case 4:
                return `${p.descripcion} ${p.desc_pintas_grandes}`
            case 5:
                return `${p.descripcion} ${p.desc_pintas_chicas}`
            default:
                return p.descripcion
        }
    }

    const agregarHandler = (p: DetalleProducto, subtipo: number = 0): void => {
        const nuevaLista: Array<ProductoPedido> = []
        const precio: number = precioProducto(p, subtipo)
        const descripcion: string = p.tipo !== 3 ? p.descripcion : descripcionCerveza(p, subtipo)

        let previo: boolean = false
        let x: number = 0
        productosPedido.forEach(i => {
            x++
            if (i.id_propio === p.id && i.tipo === p.tipo && i.subtipo === subtipo) {
                previo = true
                const cantidad: number = i.cantidad + 1
                nuevaLista.push({
                    id: x, id_propio: p.id, tipo: p.tipo, subtipo: subtipo, cantidad: cantidad,
                    precio_unitario: precio, precio_total: precio * cantidad, descripcion: descripcion
                } as ProductoPedido)
            } else {
                nuevaLista.push({...i, id: x})
            }
        })
        if (!previo) {
            x++
            nuevaLista.push({
                id: x, id_propio: p.id, tipo: p.tipo, subtipo: subtipo, cantidad: 1,
                precio_unitario: precio, precio_total: precio, descripcion: descripcion
            } as ProductoPedido)
        }
        setProductosPedido(nuevaLista.sort((a, b) =>
            a.descripcion.localeCompare(b.descripcion)))
    }

    const cantidadHandler = (p: ProductoPedido, nuevaCantidad: number): void => {
        // Verificar stock
        if (p.tipo === 1) {
            const prod: DetalleProducto = Array.prototype.concat(...datos.categorias.filter(c => c.id !== -1)
                .map(c => c.productos)).filter(s => s.tipo === 1 && s.id === p.id_propio)[0]
            if (prod === undefined) return

            if (prod.stock - nuevaCantidad < 0) return
        }

        const idItem = p.id
        const nuevaLista = productosPedido.map(prev => prev.id === idItem ? {
            ...prev,
            cantidad: nuevaCantidad,
            precio_total: prev.precio_unitario * nuevaCantidad
        } : prev)
        setProductosPedido(nuevaLista)
    }

    const formatoMoneda = (numero: number): string => {
        return numero.toLocaleString('es-AR', {
            style: 'currency',
            currency: 'ARS'
        })
    }

    const quitarProductoHandler = (arg: number): void => {
        const nuevaLista = productosPedido.filter(p => p.id !== arg)
        setProductosPedido(nuevaLista)
    }

    useEffect(() => {
        calcularTotal(productosPedido)
    }, [calcularTotal, productosPedido])

    const guardarHandler = useCallback(async (opc: OpcionesGuardado): Promise<void> => {
        // Generar hash de items esperados
        const hashEsperado = sha256(productosPedido
            .map(p => `${p.tipo}-${p.subtipo}-${p.id_propio}-${p.cantidad}`)
            .sort((a, b) => a.localeCompare(b))
            .join('|'))
            .toString()

        // Info para la grabacion del pedido
        const fd = new FormData()
        fd.append('accion', 'editar')
        fd.append('id_pedido', idEdicion.toString())
        fd.append('tipo', tipo)
        fd.append('telefono', edicion.telefono)
        fd.append('nombre', edicion.nombre)
        fd.append('id_clientenegocio', edicion.idCliente.toString())
        fd.append('direccion', edicion.direccion)
        fd.append('repartidor', edicion.repartidor.toString())
        fd.append('mediodepago', edicion.medioDePago.toString())
        fd.append('observaciones', edicion.observaciones)
        fd.append('observaciones_cliente', edicion.observacionesCliente)
        fd.append('total', total.total.toString())
        fd.append('total_sin_descuento', total.sin_descuento.toString())
        fd.append('descuento', edicion.descuento.toString())
        fd.append('paga_con', edicion.paga_con.toString())
        fd.append('cantidad_cubierto', opciones.mostrar_cubierto && tipo === 'local' ?
            edicion.cantidad_cubierto.toString() : '0')
        fd.append('valor_cubierto', opciones.mostrar_cubierto && tipo === 'local' ?
            edicion.valor_cubierto.toString() : '0')
        fd.append('valor_envio', opciones.mostrar_envio && tipo === 'delivery' ?
            edicion.valor_envio.toString() : '0')
        fd.append('items', JSON.stringify(productosPedido))
        fd.append('confirmar_pedido', opc.confirmar ? '1' : '0')

        setGuardando({guardando: true})
        pedidoJson({
            url: 'pedidos/guardar', method: 'post', body: fd, sinNotificacion: !opc.volver,
            ok: res => {
                if (res.resultado === true) {
                    // Verificar grabacion exitosa
                    const grabacionOk = hashEsperado === res.hash_items
                    const hora = moment().format('HH:mm')
                    setGuardando({
                        guardando: false, mensaje: grabacionOk ? (
                            <>
                                <i className='fas fa-check text-success me-2'/>
                                Guardado {hora}
                            </>
                        ) : (
                            <>
                                <i className='fas fa-exclamation-triangle text-warning me-2'/>
                                Guardado con verificación incorrecta {hora}
                            </>
                        )
                    })

                    if (opc.comanda) imprimirComanda(idEdicion)
                    if (opc.volver) navigate('../')
                } else {
                    dispatch(setNotificacion({tipo: 'error', mensaje: res.mensaje}))
                    setGuardando({
                        guardando: false, mensaje: (
                            <><i className='fas fa-times text-danger me-2'/>{res.mensaje}</>
                        )
                    })
                }
            }
        })
    }, [dispatch, edicion, idEdicion, navigate, opciones, productosPedido, tipo, total, pedidoJson])

    const buscarTelefono = useCallback(async (tel?: string): Promise<void> => {
        const telefono = tel === undefined ? edicion.telefono : tel

        pedidoJson({
            url: `clientes/buscartelefono?telefono=${encodeURIComponent(telefono)}`,
            ok: res => {
                const infoCliente = res.cliente_negocio as InfoClienteNegocio
                if (infoCliente !== undefined && infoCliente.id !== 0) {
                    setEdicion({
                        ...edicion,
                        idCliente: infoCliente.id,
                        telefono: infoCliente.telefono,
                        direccion: infoCliente.direccion,
                        nombre: infoCliente.nombre,
                        observacionesCliente: infoCliente.comentarios,
                        descuento: infoCliente.descuento,
                        valor_envio: infoCliente.envio_gratis ? 0 : envioOrig.current
                    })
                    setHabilitarAlta(false)
                } else {
                    setEdicion({
                        ...edicion,
                        idCliente: 0,
                        direccion: '',
                        nombre: '',
                        observacionesCliente: '',
                        descuento: 0
                    })
                    setHabilitarAlta(true)
                }
            }
        })
    }, [edicion, pedidoJson])

    const teclasEspeciales = useCallback(e => {
        if (e.keyCode === 27) {
            if (!confirmarCancelacion && !confirmarCierre && !altaCliente) {
                guardarHandler({volver: true}).catch(err => alert(err))
            }
        } else if (e.keyCode === 13) {
            if (document.activeElement === txtTelefonoRef.current) {
                buscarTelefono().catch(err => alert(err))
            }
        }
    }, [confirmarCierre, confirmarCancelacion, txtTelefonoRef, buscarTelefono, altaCliente, guardarHandler])

    useEffect(() => {
        document.addEventListener('keydown', teclasEspeciales, false)
        return () => document.removeEventListener('keydown', teclasEspeciales, false)
    }, [teclasEspeciales])

    const buscarProducto = useCallback(() => {
        if (datos === undefined || busqueda === '') return

        const prods: Array<DetalleProducto> = Array.prototype.concat(...datos.categorias.filter(c => c.id !== -1)
            .map(c => c.productos))
        setResultados(prods.filter(p => p.descripcion.toLocaleLowerCase().includes(busqueda.toLocaleLowerCase())))
    }, [datos, busqueda])

    useEffect(() => {
        buscarProducto()
    }, [buscarProducto])

    const filtrarCategoria = useCallback(() => {
        if (categ === undefined) {
            setProductosFiltrados([])
        } else if (filtroCateg === '') {
            setProductosFiltrados(categ.productos)
        } else {
            setProductosFiltrados(categ.productos.filter(p =>
                p.descripcion.toLocaleLowerCase().includes(filtroCateg.toLocaleLowerCase())))
        }
    }, [categ, filtroCateg])

    useEffect(() => {
        filtrarCategoria()
    }, [filtrarCategoria])

    const restaStock = (id: number, tipo: number): number => {
        if (tipo !== 1) return 0
        const existencia = productosPedido.filter(p => p.id_propio === id && p.tipo === tipo)
        if (!existencia.length) {
            return 0
        } else {
            return existencia[0].cantidad
        }
    }

    const calcularVuelto = (): React.ReactNode | undefined => {
        if (edicion.paga_con > total.total) {
            const vuelto = edicion.paga_con - total.total
            return (
                <div>Vuelto: <strong>{formatoMoneda(vuelto)}</strong></div>
            )
        } else {
            return undefined
        }
    }

    const titulo: React.ReactNode = (
        <TituloPagina icono='fas fa-ticket'
                      titulo={'Editar Pedido' + (cargaInicial ?
                          ' (' + (tipo === 'local' ? 'Local' : 'Delivery') + ')' : '')}/>
    )

    // Tarjeta de carga en progreso
    if (!cargaInicial) {
        return (
            <>
                {titulo}
                <BarraTarjeta>
                    <div className='row g-2'>
                        <div className='col-auto text-nowrap'>
                            <Boton titulo='Volver' icono='fas fa-chevron-left' className='btn-light' toolbar
                                   onClick={() => navigate('../')}/>
                        </div>
                        <div className='col pt-1'>
                            <div className='spinner-border spinner-border-sm text-primary me-2'/>
                            Cargando Pedido...
                        </div>
                    </div>
                </BarraTarjeta>
            </>
        )
    }

    // Interfaz de usuario
    const tituloGuardar = aConfirmar ? 'Confirmar Pedido' : 'Guardar y Volver'
    return (
        <>
            {titulo}
            <BarraTarjeta>
                <div className='row g-2'>
                    <div className='col-auto text-nowrap'>
                        <Boton titulo={tituloGuardar} icono='fas fa-save' className='btn-light' toolbar
                               onClick={() => {
                                   guardarHandler({volver: true, confirmar: true}).catch(err => alert(err))
                               }}/>
                    </div>
                    <div className='col pt-1'>
                        {guardando.guardando ? (
                            <>
                                <div className='spinner-border spinner-border-sm text-primary me-2'/>
                                Guardando...
                            </>
                        ) : guardando.mensaje}
                    </div>
                </div>
            </BarraTarjeta>
            <Tarjeta>
                <div className='row'>
                    <div className={acceso >= NivelesAcceso.NivelHabilitado ? 'col-lg-6' : 'col-lg-12'}>
                        <h4>
                            <i className={'fas fa-receipt me-2 ' + (tipo === 'local' ? 'text-primary' : 'text-info')}/>
                            Pedido #{edicion.nroPedido}
                        </h4>
                        <div className='row mb-2 small'>
                            <div className='col-md-4 pt-1'>
                                Tipo
                            </div>
                            <div className='col-md-8'>
                                {acceso > NivelesAcceso.NivelConsulta ? (
                                    <ToolbarBtn>
                                        <Boton toolbar titulo='Local'
                                               onClick={() => setParams(
                                                   {id: idEdicion.toString(), tipo: 'local'})}
                                               className={tipo === 'local' ? 'btn btn-primary' :
                                                   'btn btn-outline-primary'}/>
                                        <Boton toolbar titulo='Delivery'
                                               onClick={() => setParams(
                                                   {id: idEdicion.toString(), tipo: 'delivery'})}
                                               className={tipo === 'delivery' ? 'btn btn-info' :
                                                   'btn btn-outline-info'}/>
                                    </ToolbarBtn>
                                ) : (
                                    <>
                                        {tipo === 'local' ? (
                                            <Boton chico className='btn-primary' titulo='Local'/>
                                        ) : (
                                            <Boton chico className='btn-info' titulo='Delivery'/>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        {tipo === 'delivery' && (
                            <div className='row mb-2'>
                                <div className='col-md-4 pt-1 small'>Teléfono</div>
                                <div className='col-md-8'>
                                    <div className='row g-2'>
                                        <div className='col'>
                                            <InputTexto value={edicion.telefono} chico
                                                        onChange={v => setEdicion({...edicion, telefono: v})}
                                                        autoFocus={tipo === 'delivery'} ref={txtTelefonoRef}
                                                        placeholder='Ingrese el nro. de teléfono'
                                                        disabled={acceso <= NivelesAcceso.NivelConsulta}/>
                                        </div>
                                        {acceso >= NivelesAcceso.NivelHabilitado && (
                                            <div className='col-auto text-nowrap'>
                                                <Boton chico titulo='Buscar' icono='fas fa-search'
                                                       className={'btn-light' + (habilitarAlta ? ' me-2' : '')}
                                                       onClick={() => {
                                                           buscarTelefono().catch(err => alert(err))
                                                       }}/>
                                                {habilitarAlta && (
                                                    <Boton chico titulo='Alta de Cliente' icono='fas fa-user-plus'
                                                           className='btn-light'
                                                           onClick={() => setAltaCliente(true)}/>
                                                )}
                                                {altaCliente && (
                                                    <EdicionClientes datosIniciales={{
                                                        id: 0, nombre: edicion.nombre, telefono: edicion.telefono,
                                                        email: '', direccion: edicion.direccion, comentarios: ''
                                                    } as DatosEdicionCliente}
                                                                     onGuardado={tel => {
                                                                         buscarTelefono(tel).catch(err => alert(err))
                                                                         setAltaCliente(false)
                                                                     }}
                                                                     onCancelar={() => setAltaCliente(false)}/>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='row mb-2'>
                            <div className='col-md-4 pt-1 small'>
                                {tipo === 'local' ? 'Descripción' : 'Nombre'}
                            </div>
                            <div className='col-md-8'>
                                <InputTexto value={edicion.nombre} chico
                                            onChange={v => setEdicion({...edicion, nombre: v})}
                                            placeholder={'Nombre del cliente' +
                                                (tipo === 'local' ? ' o mesa (Opcional)' : '')}
                                            autoFocus={tipo === 'local'}
                                            disabled={acceso <= NivelesAcceso.NivelConsulta}/>
                            </div>
                        </div>
                        {tipo === 'delivery' && (
                            <div className='row mb-2'>
                                <div className='col-md-4 pt-1 small'>Dirección</div>
                                <div className='col-md-8'>
                                    <div className='row g-2'>
                                        <div className='col'>
                                            <InputTexto value={edicion.direccion} chico
                                                        onChange={v => setEdicion({...edicion, direccion: v})}
                                                        placeholder='Dirección del envío'
                                                        disabled={acceso <= NivelesAcceso.NivelConsulta}/>
                                        </div>
                                        <div className='col-auto'>
                                            <Boton chico titulo='Maps' icono='fas fa-location-dot'
                                                   className='btn-light' onClick={() => {
                                                window.open('https://maps.google.com?q=' +
                                                    encodeURIComponent(edicion.direccion), '_blank')
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {tipo === 'delivery' && (
                            <div className='row mb-2'>
                                <div className='col-md-4 pt-1 small'>Repartidor</div>
                                <div className='col-md-8'>
                                    <InputSelect value={edicion.repartidor} chico
                                                 onChange={v => setEdicion({...edicion, repartidor: v})}
                                                 opciones={datos.repartidores}
                                                 disabled={acceso <= NivelesAcceso.NivelConsulta}/>
                                </div>
                            </div>
                        )}
                        <table className='table table-sm small border'>
                            <thead>
                            <tr>
                                <th style={{width: '15%'}} className='text-center'>Cant.</th>
                                <th style={{width: '55%'}}>Item</th>
                                <th style={{width: '15%'}}>P. Unitario</th>
                                <th style={{width: '15%'}} colSpan={2}>P. Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {productosPedido.length ? productosPedido.map((p, k) => (
                                <tr key={k}>
                                    <td className='text-nowrap'>
                                        {acceso >= NivelesAcceso.NivelHabilitado ? (
                                            <MasMenos value={p.cantidad} onChange={v => cantidadHandler(p, v)}
                                                      min={1}/>
                                        ) : (
                                            <div className='pt-1 text-center'>{p.cantidad}</div>
                                        )}
                                    </td>
                                    <td className='pt-2'>{p.descripcion}</td>
                                    <td className='text-end text-nowrap pt-2'>
                                        {formatoMoneda(p.precio_unitario)}
                                    </td>
                                    <td className='text-end text-nowrap pt-2'>
                                        {formatoMoneda(p.precio_total)}
                                    </td>
                                    {acceso >= NivelesAcceso.NivelHabilitado ? (
                                        <td className='text-center trhover pt-2' onClick={() => {
                                            quitarProductoHandler(p.id)
                                        }}>
                                            <i className='fas fa-trash text-danger'/>
                                        </td>
                                    ) : (
                                        <td>&nbsp;</td>
                                    )}
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={5}>
                                        <i className='fas fa-info-circle text-info me-2'/>
                                        No se agregó ningún ítem al pedido
                                    </td>
                                </tr>
                            )}
                            {tipo === 'delivery' ? (opciones.mostrar_envio && (
                                <tr>
                                    <td className='text-end pt-2'>
                                        <i className='fas fa-motorcycle text-muted'/>
                                    </td>
                                    <td className='pt-2'>Envío</td>
                                    <td colSpan={2}>
                                        <InputMoneda value={edicion.valor_envio} derecha chico autoSelect
                                                     onChange={v => setEdicion({...edicion, valor_envio: v})}
                                                     disabled={acceso <= NivelesAcceso.NivelConsulta}/>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )) : tipo === 'local' ? (opciones.mostrar_cubierto && (
                                <tr>
                                    <td className='text-end'>
                                        {acceso >= NivelesAcceso.NivelHabilitado ? (
                                            <MasMenos value={edicion.cantidad_cubierto}
                                                      onChange={v => setEdicion({
                                                          ...edicion,
                                                          cantidad_cubierto: v,
                                                          valor_cubierto: v * opciones.cubierto_predeterminado
                                                      })}/>
                                        ) : (
                                            <div className='pt-1'>{edicion.cantidad_cubierto}</div>
                                        )}
                                    </td>
                                    <td className='pt-2'>Cubierto</td>
                                    <td className='pt-2 text-end'>
                                        {formatoMoneda(opciones.cubierto_predeterminado)}
                                    </td>
                                    <td className='pt-2 text-end'>
                                        {formatoMoneda(edicion.valor_cubierto)}
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )) : <React.Fragment/>}
                            </tbody>
                            <thead>
                            <tr>
                                <th colSpan={2} className='fw-bold'>Total</th>
                                {total.total === total.sin_descuento ? (
                                    <th colSpan={2} className='text-end fw-bold'>{formatoMoneda(total.total)}</th>
                                ) : (
                                    <th colSpan={2} className='text-end fw-bold'>
                                        <span className='text-muted text-decoration-line-through me-2 fw-normal'>
                                            {formatoMoneda(total.sin_descuento)}
                                        </span>
                                        {formatoMoneda(total.total)}
                                    </th>
                                )}
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                        </table>
                        <div className='row mb-2'>
                            <div className='col-md-4 pt-1 small'>Descuento</div>
                            <div className='col-md-8'>
                                <InputMoneda chico value={edicion.descuento} porcentaje
                                             onChange={v => setEdicion({...edicion, descuento: v})}
                                             disabled={acceso <= NivelesAcceso.NivelConsulta}/>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-md-4 pt-1 small'>Medio de Pago</div>
                            <div className='col-md-8'>
                                <InputSelect value={edicion.medioDePago} chico
                                             onChange={v => setEdicion({...edicion, medioDePago: v})}
                                             opciones={datos.mediosdepago}
                                             disabled={acceso <= NivelesAcceso.NivelConsulta}/>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-md-4 pt-1 small'>Paga Con</div>
                            <div className='col-md-8'>
                                <InputMoneda chico value={edicion.paga_con}
                                             onChange={v => setEdicion({...edicion, paga_con: v})}
                                             controlDerecha={calcularVuelto()}
                                             disabled={acceso <= NivelesAcceso.NivelConsulta}/>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-md-4 pt-1 small'>Observaciones</div>
                            <div className='col-md-8'>
                                <InputTexto value={edicion.observaciones} chico
                                            onChange={v => setEdicion({...edicion, observaciones: v})}
                                            placeholder='Notas (Opcional)'
                                            disabled={acceso <= NivelesAcceso.NivelConsulta}/>
                            </div>
                        </div>
                        {edicion.observacionesCliente !== '' && (
                            <div className='row mb-2'>
                                <div className='col-md-4 py-1 small'>Observaciones cliente</div>
                                <div className='col-md-8 py-1 small'>{edicion.observacionesCliente}</div>
                            </div>
                        )}
                    </div>
                    {acceso >= NivelesAcceso.NivelHabilitado && (
                        <div className='col-lg-6'>
                            <h5>Agregar productos</h5>
                            {categ === undefined && busqueda === '' ? (
                                <table className='table table-sm small border'>
                                    <thead>
                                    <tr>
                                        <th>
                                            <div className='row'>
                                                <div className='col-auto pt-1'>
                                                    <i className='fas fa-list text-primary me-2'/>
                                                    Categorías
                                                </div>
                                                <div className='col'>
                                                    <InputTexto chico titulo='fas fa-search'
                                                                placeholder='Buscar Productos'
                                                                value={busqueda} onChange={setBusqueda}/>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <ContainerAdicion>
                                        {datos.categorias.length ?
                                            datos.categorias.map((c, k) => (
                                                <TarjetaAdicion key={k} titulo={c.descripcion} col={6} tipo={0}
                                                                badge={c.cantidad_productos +
                                                                    (c.cantidad_productos === 1 ? ' producto' :
                                                                        ' productos')}
                                                                onClick={() => {
                                                                    setFiltroCateg('')
                                                                    setCateg(c)
                                                                }}/>
                                            )) : (
                                                <TarjetaMensajeAdicion mensaje='No hay categorías configuradas'/>
                                            )}
                                    </ContainerAdicion>
                                    </tbody>
                                </table>
                            ) : categ === undefined ? (
                                <table className='table table-sm small border'>
                                    <thead>
                                    <tr>
                                        <th colSpan={3}>
                                            <div className='row g-2'>
                                                <div className='col-auto text-nowrap'>
                                                    <button type='button'
                                                            className='btn btn-light text-primary btn-sm'
                                                            onClick={() => setBusqueda('')}>
                                                        <i className='fas fa-chevron-left'/>
                                                    </button>
                                                </div>
                                                <div className='col'>
                                                    <InputTexto chico titulo='fas fa-search'
                                                                placeholder='Buscar Productos'
                                                                value={busqueda} onChange={setBusqueda}/>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <ContainerAdicion>
                                        {resultados.length ? resultados.map((r, k) => (
                                            <TarjetaAdicion key={k} titulo={r.descripcion} col={4}
                                                            tipo={r.tipo} onClick={() => agregarHandler(r)}
                                                            stock={r.stock} restaStock={restaStock(r.id, r.tipo)}
                                                            precio={r.precio_unitario_fmt}/>
                                        )) : (
                                            <TarjetaMensajeAdicion mensaje='No hay resultados para mostrar'/>
                                        )}
                                    </ContainerAdicion>
                                    </tbody>
                                </table>
                            ) : (
                                <table className='table table-sm small border'>
                                    <thead>
                                    <tr>
                                        <th colSpan={categ.id === -1 ? 6 : 3}>
                                            <div className='row g-3'>
                                                <div className='col-auto'>
                                                    <button type='button'
                                                            className='btn btn-light text-primary btn-sm'
                                                            onClick={() => setCateg(undefined)}>
                                                        <i className='fas fa-chevron-left'/>
                                                    </button>
                                                </div>
                                                {categ.id === -1 ? (
                                                    <div className='col pt-1'>{categ.descripcion}</div>
                                                ) : (
                                                    <>
                                                        <div className='col-auto pt-1'>
                                                            {categ.descripcion}
                                                        </div>
                                                        <div className='col ps-3'>
                                                            <InputTexto chico titulo='fas fa-search' autoFocus
                                                                        placeholder='Buscar en esta categoría'
                                                                        value={filtroCateg} onChange={setFiltroCateg}/>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <ContainerAdicion>
                                        {productosFiltrados.map((p, k) => categ.id === -1 ? (
                                            <TarjetaAdicionCerveza titulo={p.descripcion} key={k}
                                                                   categoria={p.desc_categoria_cerveza}
                                                                   cervezas={[{
                                                                       descripcion: p.desc_recargas_grandes,
                                                                       precio: p.recarga_grande,
                                                                       precio_fmt: p.recarga_grande_fmt,
                                                                       subtipo: 1
                                                                   }, {
                                                                       descripcion: p.desc_recargas_medianas,
                                                                       precio: p.recarga_mediana,
                                                                       precio_fmt: p.recarga_mediana_fmt,
                                                                       subtipo: 2
                                                                   }, {
                                                                       descripcion: p.desc_recargas_chicas,
                                                                       precio: p.recarga_chica,
                                                                       precio_fmt: p.recarga_chica_fmt,
                                                                       subtipo: 3
                                                                   }, {
                                                                       descripcion: p.desc_pintas_grandes,
                                                                       precio: p.pinta_grande,
                                                                       precio_fmt: p.pinta_grande_fmt,
                                                                       subtipo: 4
                                                                   }, {
                                                                       descripcion: p.desc_pintas_chicas,
                                                                       precio: p.pinta_chica,
                                                                       precio_fmt: p.pinta_chica_fmt,
                                                                       subtipo: 5
                                                                   }]}
                                                                   onClick={s => agregarHandler(p, s)}/>
                                        ) : (
                                            <TarjetaAdicion key={k} titulo={p.descripcion} col={4}
                                                            tipo={p.tipo} onClick={() => agregarHandler(p)}
                                                            stock={p.stock} restaStock={restaStock(p.id, p.tipo)}
                                                            precio={p.precio_unitario_fmt}/>
                                        ))}
                                    </ContainerAdicion>
                                    </tbody>
                                </table>
                            )}
                        </div>
                    )}
                </div>
                {acceso > NivelesAcceso.NivelConsulta && (
                    <>
                        <hr/>
                        <div className='text-center'>
                            <Boton chico titulo={tituloGuardar} icono='fas fa-save'
                                   className='me-2 btn-secondary' onClick={() => {
                                guardarHandler({volver: true, confirmar: true}).catch(err => alert(err))
                            }}/>
                            <Boton chico titulo='Imprimir Comanda' icono='fas fa-receipt'
                                   className='me-2 btn-primary' onClick={() => {
                                guardarHandler({comanda: true, volver: true, confirmar: true})
                                    .catch(err => alert(err))
                            }}/>
                            <Boton chico titulo='Cancelar pedido' icono='fas fa-trash'
                                   className='btn-danger me-2' onClick={() => {
                                guardarHandler({}).then(() => setConfirmarCancelacion(true))
                            }}/>
                            <Boton chico titulo='Cerrar Pedido' icono='fas fa-clipboard-check'
                                   className='btn-success' onClick={() => {
                                guardarHandler({confirmar: true}).then(() => setConfirmarCierre(idEdicion))
                            }}/>
                        </div>
                    </>
                )}
                {confirmarCancelacion && (
                    <CancelarPedido idPedido={idEdicion} onAceptar={() => navigate('../')}
                                    onVolver={() => setConfirmarCancelacion(false)}/>
                )}
                {confirmarCierre !== undefined && (
                    <CerrarPedido idPedido={confirmarCierre} onAceptar={() => navigate('../')}
                                  onVolver={() => setConfirmarCierre(undefined)}/>
                )}
            </Tarjeta>
        </>
    )
}

export default EdicionPedido