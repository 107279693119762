export const coloresGraficos: Array<string> = [
    'rgba(233, 84, 32, 0.25)',
    'rgba(56, 180, 74, 0.25)',
    'rgba(0, 123, 255, 0.25)',
    'rgba(239, 183, 62, 0.25)',
    'rgba(32, 201, 151, 0.25)',
    'rgba(119, 41, 83, 0.25)',
    'rgba(232, 62, 140, 0.25)',
    'rgba(232, 62, 140, 0.25)'
]

export const coloresBordes: Array<string> = [
    'rgba(233, 84, 32, 0.5)',
    'rgba(56, 180, 74, 0.5)',
    'rgba(0, 123, 255, 0.5)',
    'rgba(239, 183, 62, 0.5)',
    'rgba(32, 201, 151, 0.5)',
    'rgba(119, 41, 83, 0.5)',
    'rgba(232, 62, 140, 0.5)',
    'rgba(232, 62, 140, 0.5)'
]