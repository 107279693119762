import React from "react";

export interface DatosCierre {
    nro_cierre: number,
    fechayhora: string,
    saldo_fmt: string,
    movimientos_cantidad: number,
    movimientos_ingresos_fmt: string,
    movimientos_egresos_fmt: string,
    movimientos_saldo_fmt: string,
    comentarios: string,
    usuario: string
}

type Props = {
    datos: DatosCierre
}

const TablaDatosCierre: React.FC<Props> = ({datos}) => {
    return (
        <div className='table-responsive'>
            <table className='table table-sm table-bordered table-striped small mb-2'>
                <thead>
                <tr>
                    <th colSpan={2} style={{width: '33%'}}>Información del Cierre</th>
                    <th colSpan={2} style={{width: '33%'}}>Movimientos</th>
                    <th colSpan={2} style={{width: '33%'}}>Resultados</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className='fw-bold'>Nro. de Cierre</td>
                    <td>{datos.nro_cierre}</td>
                    <td className='fw-bold'>Movimientos</td>
                    <td className='text-end'>{datos.movimientos_cantidad}</td>
                    <td className='fw-bold'>Saldo del Cierre</td>
                    <td className='text-end'>{datos.movimientos_saldo_fmt}</td>
                </tr>
                <tr>
                    <td className='fw-bold'>Fecha y Hora</td>
                    <td>{datos.fechayhora}</td>
                    <td className='fw-bold'>Ingresos</td>
                    <td className='text-end text-success'>{datos.movimientos_ingresos_fmt}</td>
                    <td className='fw-bold'>Saldo de Caja</td>
                    <td className='text-end'>{datos.saldo_fmt}</td>
                </tr>
                <tr>
                    <td className='fw-bold'>Usuario</td>
                    <td>{datos.usuario}</td>
                    <td className='fw-bold'>Egresos</td>
                    <td className='text-end text-danger'>{datos.movimientos_egresos_fmt}</td>
                    <td className='fw-bold'>Comentarios</td>
                    <td>{datos.comentarios}</td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TablaDatosCierre