import React from "react";

type Props = {
    origen: number
}

const OrigenMovimiento: React.FC<Props> = ({origen}) => {
    switch (origen) {
        case 1:
            return <><i className='fas fa-boxes-stacked me-2 text-primary'/>Stock</>
        case 2:
            return <><i className='fas fa-ticket me-2 text-primary'/>Pedidos</>
        default:
            return <></>
    }
}

export default OrigenMovimiento