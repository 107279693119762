import React, {useState} from "react";
import {Existencia} from "./Stock";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import FilaDetalle from "../controles/FilaDetalle";
import InputTexto from "../controles/InputTexto";
import {useGsBackend} from "../funcionesApi";

type InfoBaja = {
    cantidad: string,
    comentarios: string
}

type ValidacionBaja = {
    cantidad: boolean
}

type Props = {
    existencia: Existencia,
    onAceptar: () => void,
    onCancelar: () => void
}

const StockBaja: React.FC<Props> = ({existencia, onAceptar, onCancelar}) => {
    const [baja, setBaja] = useState<InfoBaja>({cantidad: '0', comentarios: ''})
    const [valid, setValid] = useState<ValidacionBaja>({cantidad: true})
    const {pedidoJson, guardando} = useGsBackend()

    const guardarHandler = async (): Promise<void> => {
        // Validacion
        const cant: number = parseInt(baja.cantidad) || 0
        const vld: ValidacionBaja = {
            cantidad: cant > 0 && cant <= existencia.stock
        }
        setValid(vld)
        if (!vld.cantidad) return

        const fd = new FormData()
        fd.append('accion', 'baja')
        fd.append('id_producto', existencia.id_producto.toString())
        fd.append('cantidad', baja.cantidad)
        fd.append('comentarios', baja.comentarios)

        pedidoJson({url: 'stock/existencias/baja', method: 'post', body: fd, ok: onAceptar})
    }

    return (
        <Modal>
            <h4>{existencia.descripcion}</h4>
            <FilaDetalle titulo='Categoría' detalle={existencia.categoria}/>
            <FilaDetalle titulo='Stock Actual' detalle={existencia.stock_fmt}
                         rojo={existencia.stock === 0}/>
            <hr/>
            <h5>Baja de Stock</h5>
            <div className='row mb-2'>
                <div className='col-md-3 pt-1'>Cantidad</div>
                <div className='col-md-9'>
                    <InputTexto value={baja.cantidad} numerico izquierda autoFocus
                                onChange={v => setBaja({...baja, cantidad: v})}
                                componentePosterior={existencia.unidad_de_medida}
                                invalido={!valid.cantidad}/>
                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-md-3 pt-1'>Comentarios</div>
                <div className='col-md-9'>
                    <InputTexto value={baja.comentarios} placeholder='(Opcional)'
                                onChange={v => setBaja({...baja, comentarios: v})}/>
                </div>
            </div>
            <ModalBotones onCancelar={onCancelar} onGuardar={guardarHandler} tituloNuevo='Aceptar'
                          guardando={guardando}/>
        </Modal>
    )
}

export default StockBaja