import React, {createRef, useCallback, useEffect} from "react";
import '../controles/menuContextual.css'
import {nivelAcceso} from "../autorizacion";
import {NivelesAcceso} from "../redux/authReducer";

type Props = {
    posX: number,
    posY: number,
    onEditar: () => void,
    onCerrarPedido: () => void,
    onCancelarPedido: () => void,
    onCerrarMenu: () => void,
    onComanda: () => void,
    sinConfirmar?: boolean
}

const MenuContextualPedidos: React.FC<Props> = (
    {
        posX, posY, onEditar, onCerrarPedido, onCancelarPedido, onCerrarMenu, onComanda,
        sinConfirmar
    }
) => {
    const menuRef = createRef<HTMLUListElement>()
    const acceso = nivelAcceso('pedidos')

    const clickHandler = useCallback(e => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            onCerrarMenu()
        }
    }, [menuRef, onCerrarMenu])

    const escHandler = useCallback(e => {
        if (e.keyCode === 27) onCerrarMenu()
    }, [onCerrarMenu])

    useEffect(() => {
        document.addEventListener('mousedown', clickHandler, false)
        document.addEventListener('keydown', escHandler, false)
        return () => {
            document.removeEventListener('mousedown', clickHandler, false)
            document.removeEventListener('keydown', escHandler, false)
        }
    })

    return (
        <ul className='dropdown-menu d-block shadow menucontextual' style={{
            position: 'fixed',
            top: posY,
            left: posX
        }} ref={menuRef}>
            <li className='dropdown-item' onClick={() => {
                onEditar()
                onCerrarMenu()
            }}>
                <div style={{width: '30px', display: 'inline-block'}}>
                    <i className='fas fa-edit'/>
                </div>
                Ver{acceso > NivelesAcceso.NivelConsulta ? ' / Editar' : ''}
            </li>
            {!sinConfirmar && (
                <li className='dropdown-item' onClick={() => {
                    onComanda()
                    onCerrarMenu()
                }}>
                    <div style={{width: '30px', display: 'inline-block'}}>
                        <i className='fas fa-clipboard-list'/>
                    </div>
                    Imprimir Comanda
                </li>
            )}
            {acceso > NivelesAcceso.NivelConsulta && (
                <>
                    <li>
                        <hr className='dropdown-divider'/>
                    </li>
                    {!sinConfirmar && (
                        <li className='dropdown-item' onClick={() => {
                            onCerrarPedido()
                            onCerrarMenu()
                        }}>
                            <div style={{width: '30px', display: 'inline-block'}}>
                                <i className='fas fa-check'/>
                            </div>
                            Cerrar pedido
                        </li>
                    )}
                    <li className='dropdown-item' onClick={() => {
                        onCancelarPedido()
                        onCerrarMenu()
                    }}>
                        <div style={{width: '30px', display: 'inline-block'}}>
                            <i className='fas fa-trash'/>
                        </div>
                        Cancelar pedido
                    </li>
                </>
            )}
        </ul>
    )
}

export default MenuContextualPedidos