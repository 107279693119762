import React, {useEffect, useState} from "react";
import Tabla from "../controles/Tabla";
import TrInfo from "../controles/TrInfo";
import {CervezaLitros} from "../cerveceria/CerveceriaEstadisticas";
import EstadisticasDetalleCategoria from "./EstadisticasDetalleCategoria";
import Recuadro from "../controles/Recuadro";
import {useGsBackend} from "../funcionesApi";

type IngresoMedioDePago = {
    mediodepago: string,
    ingresos: number,
    ingresos_fmt: string
}

type CategoriaCierre = {
    id_categoria: number,
    categoria: string,
    cantidad: number,
    importe: number,
    importe_fmt: string
}

type InfoConsultasCierre = {
    ingresos_medios_de_pago: Array<IngresoMedioDePago>,
    categorias_productos: Array<CategoriaCierre>,
    cervezas_litros: Array<CervezaLitros>
}

type MaximosProgreso = {
    productos_categoria: number,
    cervezas_litros: number,
    ingresos_mp: number
}

type Props = {
    idCierre: number
}

const EstadisticasCierre: React.FC<Props> = ({idCierre}) => {
    const [datos, setDatos] = useState<InfoConsultasCierre>(
        {categorias_productos: [], ingresos_medios_de_pago: [], cervezas_litros: []}
    )
    const [maximos, setMaximos] = useState<MaximosProgreso>(
        {cervezas_litros: 0, productos_categoria: 0, ingresos_mp: 0}
    )
    const [detalleCategoria, setDetalleCategoria] = useState<number | undefined>(undefined)
    const {pedidoJson} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: `estadisticas/consultascierre?id_cierre=${idCierre}`, ok: setDatos})
    }, [pedidoJson, idCierre])

    useEffect(() => {
        const maxProductosCategoria = datos.categorias_productos.length > 0 ? datos.categorias_productos
            .map(p => p.cantidad)
            .reduce((p, n) => p + n) : 0
        const maxLitros = datos.cervezas_litros.length > 0 ? datos.cervezas_litros
            .map(c => c.litros)
            .reduce((p, n) => p + n) : 0
        const maxIngresoMp = datos.ingresos_medios_de_pago.length > 0 ? datos.ingresos_medios_de_pago
            .map(s => s.ingresos)
            .reduce((p, n) => p + n) : 0
        setMaximos({
            productos_categoria: maxProductosCategoria,
            cervezas_litros: maxLitros,
            ingresos_mp: maxIngresoMp
        })
    }, [datos])

    const anchoBarra = (dato: number, maximo: number): string => {
        if (maximo === 0) {
            return '0%'
        } else {
            return `${100 * dato / maximo}%`
        }
    }

    return (
        <div className='row g-3'>
            <div className='col-xl-5'>
                <Recuadro espacio>
                    <h4>Ingresos por Medio de Pago</h4>
                    <Tabla>
                        <thead>
                        <tr className='headerTabla'>
                            <th style={{width: '50%'}}>Medio de Pago</th>
                            <th style={{width: '25%'}}>Ingresos</th>
                            <th style={{width: '25%'}}>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {datos.ingresos_medios_de_pago.length ?
                            datos.ingresos_medios_de_pago.map((s, k) => (
                                <tr key={k}>
                                    <td>{s.mediodepago}</td>
                                    <td className='text-end text-nowrap'>{s.ingresos_fmt}</td>
                                    <td>
                                        <div className='progress bg-light'>
                                            <div className='progress-bar progress-bar-striped'
                                                 style={{width: anchoBarra(s.ingresos, maximos.ingresos_mp)}}>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )) : (
                                <TrInfo colSpan={3} texto='Sin datos para mostrar'/>
                            )}
                        </tbody>
                    </Tabla>
                </Recuadro>
            </div>
            <div className='col-xl-7'>
                <Recuadro espacio>
                    <h4>Ventas por Categoría de Productos</h4>
                    <Tabla>
                        <thead>
                        <tr className='headerTabla'>
                            <th style={{width: '40%'}}>Categoría</th>
                            <th style={{width: '20%'}}>&nbsp;</th>
                            <th style={{width: '20%'}}>Cant. Productos</th>
                            <th style={{width: '20%'}}>Importe</th>
                        </tr>
                        </thead>
                        <tbody>
                        {datos.categorias_productos.length ?
                            datos.categorias_productos.map((d, k) => (
                                <tr key={k} className={d.id_categoria > 0 ? 'trhover' : ''}
                                    onClick={d.id_categoria > 0 ? () => setDetalleCategoria(d.id_categoria) : undefined}>
                                    <td>{d.categoria}</td>
                                    <td>
                                        <div className='progress bg-light'>
                                            <div className='progress-bar progress-bar-striped'
                                                 style={{width: anchoBarra(d.cantidad, maximos.productos_categoria)}}/>
                                        </div>
                                    </td>
                                    <td className='text-end text-nowrap'>{d.cantidad}</td>
                                    <td className='text-end text-nowrap'>{d.importe_fmt}</td>
                                </tr>
                            )) : (
                                <TrInfo colSpan={4} texto='Sin datos para mostrar'/>
                            )}
                        </tbody>
                    </Tabla>
                </Recuadro>
                {datos.cervezas_litros.length > 0 && (
                    <Recuadro espacio>
                        <h4>Cervezas Tiradas por Litro</h4>
                        <Tabla>
                            <thead>
                            <tr className='headerTabla'>
                                <th style={{width: '35%'}}>Categoría</th>
                                <th style={{width: '20%'}}>&nbsp;</th>
                                <th style={{width: '15%'}}>Cantidad</th>
                                <th style={{width: '15%'}}>Litros</th>
                                <th style={{width: '15%'}}>Importe</th>
                            </tr>
                            </thead>
                            <tbody>
                            {datos.cervezas_litros.map((c, k) => (
                                <React.Fragment key={k}>
                                    <tr>
                                        <td>{c.categoria}</td>
                                        <td>
                                            <div className='progress bg-light'>
                                                <div className='progress-bar progress-bar-striped'
                                                     style={{width: anchoBarra(c.litros, maximos.cervezas_litros)}}/>
                                            </div>
                                        </td>
                                        <td className='text-end text-nowrap'>{c.cantidad}</td>
                                        <td className='text-end text-nowrap'>{c.litros_fmt}</td>
                                        <td className='text-end text-nowrap'>{c.importe_fmt}</td>
                                    </tr>
                                    {c.detalle.map((d, k) => (
                                        <tr key={k} className='bg-light'>
                                            <td colSpan={2} className='ps-3'>{d.tipo}</td>
                                            <td className='text-end text-nowrap'>{d.cantidad}</td>
                                            <td className='text-end text-nowrap'>{d.litros_fmt}</td>
                                            <td className='text-end text-nowrap'>{d.importe_fmt}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                            </tbody>
                        </Tabla>
                    </Recuadro>
                )}
            </div>
            {detalleCategoria !== undefined && (
                <EstadisticasDetalleCategoria idCategoria={detalleCategoria} idCierre={idCierre}
                                              onCerrar={() => setDetalleCategoria(undefined)}/>
            )}
        </div>
    )
}

export default EstadisticasCierre