import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import Tabla from "../controles/Tabla";
import TrInfo from "../controles/TrInfo";
import {formatoMoneda} from "../controles/formatoMoneda";
import ControlPaginas, {paginado} from "../controles/ControlPaginas";
import Boton from "../controles/Boton";
import CerrarModal from "../controles/CerrarModal";
import InputCheck from "../controles/InputCheck";
import InputMoneda from "../controles/InputMoneda";
import {useAppDispatch} from "../redux/hooks";
import {setNotificacion} from "../redux/estadoReducer";
import {useGsBackend} from "../funcionesApi";

export interface ProductoAjuste {
    id: number,
    descripcion: string,
    id_categoria: number,
    id_proveedor: number,
    precio_actual: number
}

type DatosAumento = {
    tipo: 'porcentaje' | 'sumafija' | 'nuevoprecio',
    porcentaje: number,
    redondeo_min: number,
    sumafija: number,
    nuevoprecio: number
}

type EnvioAumento = {
    id_producto: number,
    nuevo_precio: number
}

type Props = {
    tipo: 'stock' | 'elaborados',
    onVolver: () => void,
    onActualizar: () => void,
    categorias: Array<OpcionSelect>,
    proveedores?: Array<OpcionSelect>,
    productos: Array<ProductoAjuste>
}

const AjusteDePrecios: React.FC<Props> = (
    {
        tipo, onVolver, categorias, productos, onActualizar,
        proveedores
    }
) => {
    const porPagina = 15 // Registros para mostrar por cada página de productos
    const [categoria, setCategoria] = useState<number>(0)
    const [proveedor, setProveedor] = useState<number>(0)
    const [muestra, setMuestra] = useState<Array<ProductoAjuste>>([])
    const [actualizables, setActualizables] = useState<Array<number>>([])
    const [aumento, setAumento] = useState<DatosAumento>({
        tipo: 'porcentaje', porcentaje: 0, redondeo_min: 0, sumafija: 0, nuevoprecio: 0
    })
    const [pagina, setPagina] = useState<number>(1)
    const dispatch = useAppDispatch()
    const {pedidoJson} = useGsBackend()

    useEffect(() => {
        setMuestra(productos
            .filter(p => categoria === 0 ? true : p.id_categoria === categoria)
            .filter(p => proveedor === 0 ? true : p.id_proveedor === proveedor))
    }, [productos, categoria, proveedor])

    useEffect(() => {
        setActualizables([])
    }, [productos, categoria, proveedor, pagina])

    const seleccionHandler = (arg: number): void => {
        setActualizables(prev => {
            if (prev.includes(arg)) {
                return prev.filter(p => p !== arg)
            } else {
                return [...prev, arg]
            }
        })
    }

    const calcularNuevo = (anterior: number): number => {
        const redondear = (arg: number): number => {
            if (aumento.redondeo_min === 0) {
                return arg
            } else {
                return Math.ceil(arg / aumento.redondeo_min) * aumento.redondeo_min
            }
        }

        switch (aumento.tipo) {
            case 'porcentaje':
                return redondear(anterior + (anterior * (aumento.porcentaje / 100)))
            case 'sumafija':
                return redondear(anterior + aumento.sumafija)
            case 'nuevoprecio':
                return aumento.nuevoprecio
        }
    }

    const calcularDiferencia = (anterior: number): number => {
        const nuevo = calcularNuevo(anterior)
        return 100 * (nuevo - anterior) / anterior
    }

    const guardarHandler = (): void => {
        // Armar el objeto con el resultado
        const info: Array<EnvioAumento> = actualizables
            .map(a => {
                const orig = productos.filter(p => p.id === a)[0]?.precio_actual
                if (orig === undefined) {
                    return {id_producto: 0, nuevo_precio: 0} as EnvioAumento
                }
                const nvo = calcularNuevo(orig)
                return {id_producto: a, nuevo_precio: nvo} as EnvioAumento
            })

        // Verificar la información
        if (info.some(i => i.id_producto === 0)) {
            dispatch(setNotificacion({tipo: 'error', mensaje: 'Error de asignación'}))
            return
        }

        // Preparar el envío
        const fd = new FormData()
        fd.append('accion', 'editar')
        fd.append('tipo', tipo)
        fd.append('productos', JSON.stringify(info))

        pedidoJson({
            url: 'stock/actualizarprecios', method: 'post', body: fd,
            ok: () => onActualizar()
        })
    }

    return (
        <Modal full>
            <h4>Ajuste de Precios {tipo === 'stock' ? '(Stock)' : '(Elaborados)'}</h4>
            <div className='row mb-2'>
                <div className='col-lg-7'>
                    <div className='row g-2 mb-2'>
                        <div className={tipo === 'stock' ? 'col-sm-6' : 'col-12'}>
                            <InputSelect chico titulo='Categoría' textocero='(Todas)'
                                         value={categoria} onChange={setCategoria} opciones={categorias}/>
                        </div>
                        {tipo === 'stock' && proveedores !== undefined && (
                            <div className='col-sm-6'>
                                <InputSelect chico titulo='Proveedor' textocero='(Todos)'
                                             value={proveedor} onChange={setProveedor} opciones={proveedores}/>
                            </div>
                        )}
                    </div>
                    <Tabla>
                        <thead>
                        <tr>
                            <th colSpan={4}>
                                <div className='row g-2'>
                                    <div className='col'>
                                        <ControlPaginas registros={muestra.length} pagina={pagina} onChange={setPagina}
                                                        porPagina={porPagina}/>
                                    </div>
                                    {muestra.length > 0 && (
                                        <div className='col-auto'>
                                            {actualizables.length === 0 ? (
                                                <Boton chico titulo='Seleccionar Todo' icono='fas fa-check'
                                                       onClick={() => setActualizables(
                                                           paginado(muestra, pagina, porPagina).map(p => p.id)
                                                       )}/>
                                            ) : (
                                                <Boton chico titulo='Deseleccionar' icono='fas fa-times'
                                                       onClick={() => setActualizables([])}/>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </th>
                        </tr>
                        <tr className='headerTabla'>
                            <th style={{width: '45%'}}>Producto</th>
                            <th style={{width: '15%'}}>Actual</th>
                            <th style={{width: '15%'}}>Nuevo</th>
                            <th style={{width: '15%'}}>Diferencia</th>
                        </tr>
                        </thead>
                        <tbody>
                        {muestra.length ? paginado(muestra, pagina, porPagina).map((m, k) => (
                            <tr key={k} className={'trhover' + (actualizables.includes(m.id) ? ' renglonSel' : '')}
                                onClick={() => seleccionHandler(m.id)}>
                                <td>{m.descripcion}</td>
                                <td className='text-end border-end'>{formatoMoneda(m.precio_actual)}</td>
                                {actualizables.includes(m.id) ? (
                                    <>
                                        <td className='text-end border-end'>
                                            {formatoMoneda(calcularNuevo(m.precio_actual))}
                                        </td>
                                        <td className='text-end'>
                                            {formatoMoneda(calcularDiferencia(m.precio_actual),
                                                false, true)} %
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td className='border-end'>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </>
                                )}
                            </tr>
                        )) : (
                            <TrInfo colSpan={4} texto='No hay elementos para mostrar'/>
                        )}
                        </tbody>
                    </Tabla>
                </div>
                <div className='col-lg-5'>
                    <h5>Tipo de Ajuste</h5>
                    <InputCheck checked={aumento.tipo === 'porcentaje'} titulo='Porcentaje' htmlId='opcPorcentaje'
                                onChange={() => setAumento({...aumento, tipo: 'porcentaje'})}/>
                    <InputCheck checked={aumento.tipo === 'sumafija'} titulo='Suma Fija' htmlId='opcSumaFija'
                                onChange={() => setAumento({...aumento, tipo: 'sumafija'})}/>
                    <InputCheck checked={aumento.tipo === 'nuevoprecio'} titulo='Reemplazar Precio'
                                htmlId='opcNuevoPrecio'
                                onChange={() => setAumento({...aumento, tipo: 'nuevoprecio'})}/>
                    <hr/>
                    <h5>Parámetros</h5>
                    {aumento.tipo === 'porcentaje' ? (
                        <>
                            <div className='mb-2'>
                                <InputMoneda value={aumento.porcentaje} porcentaje chico titulo='Porcentaje'
                                             onChange={v => setAumento({...aumento, porcentaje: v})}
                                             sinMaximoPorcentaje/>
                            </div>
                            <div className='mb-2'>
                                <InputMoneda value={aumento.redondeo_min} chico titulo='Redondeo'
                                             onChange={v => setAumento({...aumento, redondeo_min: v})}/>
                            </div>
                        </>
                    ) : aumento.tipo === 'sumafija' ? (
                        <>
                            <div className='mb-2'>
                                <InputMoneda value={aumento.sumafija} chico titulo='Suma Fija'
                                             onChange={v => setAumento({...aumento, sumafija: v})}/>
                            </div>
                            <div className='mb-2'>
                                <InputMoneda value={aumento.redondeo_min} chico titulo='Redondeo'
                                             onChange={v => setAumento({...aumento, redondeo_min: v})}/>
                            </div>
                        </>
                    ) : (
                        <div className='mb-2'>
                            <InputMoneda value={aumento.nuevoprecio} chico titulo='Nuevo Precio'
                                         onChange={v => setAumento({...aumento, nuevoprecio: v})}/>
                        </div>
                    )}
                    {muestra.length > porPagina && (
                        <>
                            <hr/>
                            <div className='row g-2 small mb-3'>
                                <div className='col-auto'>
                                    <i className='fas fa-info-circle text-info'/>
                                </div>
                                <div className='col'>
                                    Se aplicará el ajuste <strong>sólo</strong> a los productos seleccionados
                                    en la página actual. Luego de confirmarlo podés continuar con la página
                                    siguente.
                                </div>
                            </div>
                        </>
                    )}
                    <div className='text-end pe-2'>
                        <Boton chico icono='fas fa-sync-alt'
                               titulo={'Confirmar Actualización' + (actualizables.length === 0 ? '' :
                                   actualizables.length === 1 ? ' (1 producto)' :
                                       ` (${actualizables.length} productos)`)}
                               onClick={guardarHandler} disabled={actualizables.length === 0}/>
                    </div>
                </div>
            </div>
            <CerrarModal onCerrar={onVolver} chico/>
        </Modal>
    )
}

export default AjusteDePrecios