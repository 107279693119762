import store from "../redux/store";
import {setCargando, setErrorSistema, setNotificacion} from "../redux/estadoReducer";
import config from "../config";
import {authHeader, errorApi, verificarAutorizacion} from "../autorizacion";
import printJS from 'print-js'
import {notificaResultado} from "../controles/Notificacion";

export const imprimirComanda = async (idPedido?: number): Promise<void> => {
    if (idPedido === undefined) return

    const dispatch = store.dispatch
    dispatch(setCargando(true))
    await fetch(config.api + `imprimir/comanda?id=${idPedido}`, {
        method: 'get',
        headers: authHeader()
    })
        .then(res => verificarAutorizacion(res))
        .then(res => res.json())
        .then(res => {
            if (res.respuesta === true && res.contenido !== undefined) {
                if (res.usar_servidor && res.url_servidor !== '') {
                    const fd = new FormData()
                    fd.append('formato', res.formato)
                    fd.append('contenido', res.contenido)

                    fetch(res.url_servidor, {method: 'post', body: fd})
                        .then(() => {
                            dispatch(setNotificacion({tipo: 'success', mensaje: 'Comanda enviada'}))
                        })
                        .catch(err => {
                            dispatch(setNotificacion({tipo: 'error', mensaje: err.toString()}))
                        })
                } else {
                    printJS({printable: res.contenido, type: 'pdf', base64: true})
                }
            } else {
                dispatch(setErrorSistema(res.mensaje))
            }
        })
        .catch(err => errorApi(err))
    dispatch(setCargando(false))
}

export const crearPedido = async (tipo: 'local' | 'delivery'): Promise<number> => {
    const fd = new FormData()
    fd.append('accion', 'nuevo')
    fd.append('tipo', tipo)

    const dispatch = store.dispatch
    dispatch(setCargando(true))
    const salida = await fetch(config.api + 'pedidos/nuevo', {
        method: 'post',
        headers: authHeader(),
        body: fd
    })
        .then(res => verificarAutorizacion(res))
        .then(res => res.json())
        .then(res => {
            if (notificaResultado(res)) {
                return parseInt(res.nuevo_id) || 0
            }
        })
        .catch(err => errorApi(err))
    dispatch(setCargando(false))

    return typeof salida === 'number' ? salida : 0
}