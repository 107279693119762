import React from "react";

type Props = {
    titulo?: string,
    chico?: boolean,
    className?: string,
    invalido?: boolean,
    value: string,
    onChange: (arg: string) => void,
    titulado?: boolean,
    disabled?: boolean,
    obligatorio?: boolean,
    hora?: boolean
}

const InputFecha: React.FC<Props> = (
    {
        titulo, chico, className, value, onChange,
        invalido, titulado, disabled,
        obligatorio, hora
    }
) => {
    const tipo = hora? 'time' : 'date'

    if (titulo && titulado) {
        return (
            <div className='mb-2'>
                <div className='form-label ms-1 mb-1'>
                    {titulo}
                    {obligatorio && <span className='text-muted small ms-1'> (obligatorio)</span>}
                </div>
                <input type={tipo} className={'form-control' + (invalido ? ' is-invalid' : '')}
                       value={value} onChange={e => onChange(e.target.value)} disabled={disabled}/>
            </div>
        )
    } else if (titulo) {
        return (
            <div className={'input-group' + (chico ? ' input-group-sm' : '') + (className ? ` ${className}` : '')}>
                <div className='input-group-text'>{titulo}</div>
                <input type={tipo} className={'form-control' + (invalido ? ' is-invalid' : '')}
                       value={value} onChange={e => onChange(e.target.value)} disabled={disabled}/>
            </div>
        )
    } else {
        return (
            <input type={tipo} value={value} onChange={e => onChange(e.target.value)}
                   className={'form-control' + (chico ? ' form-control-sm' : '') +
                       (className ? ` ${className}` : '') + (invalido ? ' is-invalid' : '')}
                   disabled={disabled}/>
        )
    }
}

export default InputFecha
