import React, {useEffect, useState} from "react";
import Tarjeta from "../controles/Tarjeta";
import TrInfo from "../controles/TrInfo";
import VistaModulos from "../controles/VistaModulos";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputFecha from "../controles/InputFecha";
import {seccionesApp} from "../modulos";
import moment from "moment";
import Tabla from "../controles/Tabla";
import ControlPaginas, {paginado} from "../controles/ControlPaginas";
import {useGsBackend} from "../funcionesApi";

type RegistroAuditoria = {
    fechayhora: string,
    usuario: string,
    modulo: number,
    accion: string
}

type InfoAuditoria = {
    registros: Array<RegistroAuditoria>,
    usuarios: Array<OpcionSelect>
}

const AdminAuditoria: React.FC = () => {
    const hoy = moment().format('YYYY-MM-DD')
    const [fecha, setFecha] = useState<string>(hoy)
    const [usuario, setUsuario] = useState<number>(0)
    const [modulo, setModulo] = useState<number>(0)
    const [datos, setDatos] = useState<InfoAuditoria>({registros: [], usuarios: []})
    const [pagina, setPagina] = useState<number>(1)

    const {pedidoJson} = useGsBackend()
    useEffect(() => {
        setPagina(1)
        pedidoJson({url: `admin/auditoria?fecha=${fecha}&usuario=${usuario}&modulo=${modulo}`, ok: setDatos})
    }, [fecha, modulo, pedidoJson, usuario])

    const fechaHandler = (arg: string): void => {
        setFecha(arg === '' ? hoy : arg)
    }

    const listaModulos: Array<OpcionSelect> =
        seccionesApp
            .sort((a, b) => a.titulo.localeCompare(b.titulo))
            .map(s => {
                return {id: s.id, descripcion: s.titulo} as OpcionSelect
            })

    return (
        <Tarjeta>
            <div className='row g-2 mb-2'>
                <div className='col-md-4'>
                    <InputFecha value={fecha} onChange={e => fechaHandler(e)} titulo='Fecha' chico/>
                </div>
                <div className='col-md-4'>
                    <InputSelect titulo='Usuario' value={usuario} onChange={setUsuario} forzarCero chico
                                 opciones={datos.usuarios} textocero='(Todos)'/>
                </div>
                <div className='col-md-4'>
                    <InputSelect titulo='Modulo' value={modulo} onChange={setModulo} forzarCero chico
                                 opciones={listaModulos} textocero='(Todos)'/>
                </div>
            </div>
            <Tabla>
                <thead>
                <tr>
                    <th colSpan={4}>
                        <ControlPaginas registros={datos.registros.length} pagina={pagina} onChange={setPagina}/>
                    </th>
                </tr>
                <tr className='headerTabla'>
                    <th style={{width: '20%'}}>Fecha y Hora</th>
                    <th style={{width: '20%'}}>Usuario</th>
                    <th style={{width: '20%'}}>Modulo</th>
                    <th style={{width: '40%'}}>Acción</th>
                </tr>
                </thead>
                <tbody>
                {datos.registros.length ? paginado(datos.registros, pagina).map((r, k) => (
                    <tr key={k}>
                        <td>{r.fechayhora}</td>
                        <td>{r.usuario}</td>
                        <td><VistaModulos modulo={r.modulo}/></td>
                        <td>{r.accion}</td>
                    </tr>
                )) : (
                    <TrInfo colSpan={4} texto='No hay registros para mostrar'/>
                )}
                </tbody>
            </Tabla>
        </Tarjeta>
    )
}

export default AdminAuditoria