import {useCallback, useRef, useState} from "react";
import config from "./config";
import {authHeader, errorApi, verificarAutorizacion} from "./autorizacion";
import {notificaResultado} from "./controles/Notificacion";

export interface GsRequestParams {
    url: string,
    method?: 'get' | 'post'
    ok?: (res: any) => void,
    err?: (res: any) => void,
    finally?: () => void
    sinHeaders?: boolean,
    sinErrorDispatch?: boolean,
    sinNotificacion?: boolean
    body?: FormData
}

export interface GsBackendInterface {
    cargando: boolean,
    guardando: boolean,
    pedidoJson: (request: GsRequestParams) => void,
    pedidoBlob: (request: GsRequestParams) => void,
    actualizar: () => void,
    errorCarga: string | undefined,
    guardarOpcionesUsuario: (clave: string, valor: number, ok?: () => void) => void
}

export const useGsBackend = () => {
    const [cargando, setCargando] = useState<boolean>(false)
    const [guardando, setGuardando] = useState<boolean>(false)
    const [errorCarga, setErrorCarga] = useState<string | undefined>(undefined)
    const ultimoRequest = useRef<GsRequestParams | null>(null)

    const pedidoJson = useCallback((request: GsRequestParams): void => {
        if (request.method !== 'post') ultimoRequest.current = request

        const statusHandler = (estado: boolean): void => {
            if (request.method === 'post') {
                setGuardando(estado)
            } else {
                setCargando(estado)
            }
        }

        statusHandler(true)
        fetch(config.api + request.url, {
            method: request.method || 'get',
            headers: request.sinHeaders ? undefined : authHeader(),
            body: request.body
        })
            .then(res => verificarAutorizacion(res))
            .then(res => res.json())
            .then(res => {
                if (request.method === 'post' && !request.sinNotificacion) {
                    if (notificaResultado(res)) {
                        if (request.ok !== undefined) request.ok(res)
                    }
                } else {
                    if (request.ok !== undefined) request.ok(res)
                }
            })
            .catch(err => {
                setErrorCarga(err.toString())
                errorApi(err, request.sinErrorDispatch)
                if (request.err !== undefined) request.err(err)
            })
            .finally(() => {
                statusHandler(false)
                if (request.finally !== undefined) request.finally()
            })
    }, [])

    const pedidoBlob = useCallback((request: GsRequestParams): void => {
        setCargando(true)
        fetch(config.api + request.url, {
            method: request.method || 'get',
            headers: request.sinHeaders ? undefined : authHeader(),
            body: request.body
        })
            .then(res => verificarAutorizacion(res))
            .then(res => res.blob())
            .then(res => {
                if (request.ok !== undefined) request.ok(res)
            })
            .catch(err => {
                setErrorCarga(err.toString())
                errorApi(err, request.sinErrorDispatch)
            })
            .finally(() => {
                setCargando(false)
                if (request.finally !== undefined) request.finally()
            })
    }, [])

    const actualizar = () => {
        if (ultimoRequest.current !== null) pedidoJson(ultimoRequest.current)
    }

    const guardarOpcionesUsuario = (clave: string, valor: number, ok?: () => void): void => {
        const fd = new FormData()
        fd.append('clave', clave)
        fd.append('valor', valor.toString())

        fetch(config.api + 'sistema/guardaropciones', {
            method: 'post',
            headers: authHeader(),
            body: fd
        })
            .then(res => verificarAutorizacion(res))
            .then(res => res.json())
            .then(res => {
                if (notificaResultado(res)) {
                    if (ok !== undefined) ok()
                }
            })
            .catch(err => errorApi(err))
    }

    return {
        cargando,
        guardando,
        pedidoJson,
        pedidoBlob,
        actualizar,
        errorCarga,
        guardarOpcionesUsuario
    } as GsBackendInterface
}
