import React from "react";
import Tarjeta from "../controles/Tarjeta";
import Tabla from "../controles/Tabla";
import TrInfo from "../controles/TrInfo";
import GraficoMediosDePago from "./GraficoMediosDePago";

export interface IngresosMediosDePago {
    mediodepago: string,
    ingresos: number,
    ingresos_fmt: string
}

type Props = {
    datos: Array<IngresosMediosDePago>
}

const EstadisticasMediosDePago: React.FC<Props> = ({datos}) => {
    return (
        <Tarjeta bordeArriba espacio>
            <h5>Ingresos por Medio de Pago</h5>
            <div className='row g-2'>
                <div className='col-md'>
                    <Tabla>
                        <thead>
                        <tr className='headerTabla'>
                            <th style={{width: '60%'}}>Medio de Pago</th>
                            <th style={{width: '40%'}}>Ingresos</th>
                        </tr>
                        </thead>
                        <tbody>
                        {datos.length ?
                            datos.map((s, k) => (
                                <tr key={k}>
                                    <td>{s.mediodepago}</td>
                                    <td className='text-end text-nowrap'>{s.ingresos_fmt}</td>
                                </tr>
                            )) : (
                                <TrInfo colSpan={2} texto='Sin datos para mostrar'/>
                            )}
                        </tbody>
                    </Tabla>
                </div>
                <div className='col-md-auto'>
                    <GraficoMediosDePago
                        leyendas={datos.map(i => i.mediodepago)}
                        datos={datos.map(i => i.ingresos)}/>
                </div>
            </div>
        </Tarjeta>
    )
}

export default EstadisticasMediosDePago