import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import CerrarModal from "../controles/CerrarModal";
import Tabla from "../controles/Tabla";
import TrInfo from "../controles/TrInfo";
import {useGsBackend} from "../funcionesApi";

type Props = {
    idCategoria: number,
    idCierre: number,
    onCerrar: () => void
}

type ProductoDetalleCategoria = {
    producto: string,
    cantidad: number,
    importe: number,
    importe_fmt: string
}

type InfoDetalleCategoria = {
    nro_cierre: number,
    fecha_cierre: string,
    categoria: string,
    cantidad: number,
    productos: Array<ProductoDetalleCategoria>
}

const EstadisticasDetalleCategoria: React.FC<Props> = ({idCategoria, idCierre, onCerrar}) => {
    const [datos, setDatos] = useState<InfoDetalleCategoria | undefined>(undefined)
    const [maximo, setMaximo] = useState<number>(0)
    const {pedidoJson} = useGsBackend()

    useEffect(() => {
        pedidoJson({
            url: `estadisticas/categoriacierre?id_cierre=${idCierre}&id_categoria=${idCategoria}`,
            ok: setDatos
        })
    }, [pedidoJson, idCierre, idCategoria])

    useEffect(() => {
        if (datos === undefined) return
        setMaximo(datos.productos.length > 0 ? datos.productos
            .map(p => p.cantidad)
            .reduce((p, n) => p + n) : 0)
    }, [datos])

    const anchoBarra = (dato: number, maximo: number): string => {
        if (maximo === 0) {
            return '0%'
        } else {
            return `${100 * dato / maximo}%`
        }
    }

    return datos === undefined ? <React.Fragment/> : (
        <Modal>
            <h4>{datos.categoria}</h4>
            <Tabla espacio>
                <thead className='text-center'>
                <tr className='headerTabla'>
                    <th>Nro. de Cierre</th>
                    <th>Fecha de Cierre</th>
                    <th>Total de Unidades</th>
                </tr>
                </thead>
                <tbody>
                <tr className='text-center'>
                    <td>{datos.nro_cierre}</td>
                    <td>{datos.fecha_cierre}</td>
                    <td>{datos.cantidad}</td>
                </tr>
                </tbody>
            </Tabla>
            <Tabla espacio>
                <thead>
                <tr className='headerTabla'>
                    <th style={{width: '40%'}}>Producto</th>
                    <th style={{width: '20%'}}>&nbsp;</th>
                    <th style={{width: '20%'}}>Cantidad</th>
                    <th style={{width: '20%'}}>Importe</th>
                </tr>
                </thead>
                <tbody>
                {datos.productos.length ? datos.productos.map((p, k) => (
                    <tr key={k}>
                        <td>{p.producto}</td>
                        <td>
                            <div className='progress bg-light'>
                                <div className='progress-bar progress-bar-striped'
                                     style={{width: anchoBarra(p.cantidad, maximo)}}/>
                            </div>
                        </td>
                        <td className='text-end text-nowrap'>{p.cantidad}</td>
                        <td className='text-end text-nowrap'>{p.importe_fmt}</td>
                    </tr>
                )) : (
                    <TrInfo colSpan={4} texto='No hay productos para mostrar'/>
                )}
                </tbody>
            </Tabla>
            <CerrarModal onCerrar={onCerrar}/>
        </Modal>
    )
}

export default EstadisticasDetalleCategoria