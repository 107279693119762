import React, {useRef, useState} from "react";
import {Existencia} from "./Stock";
import Modal from "../controles/Modal";
import FilaDetalle from "../controles/FilaDetalle";
import InputTexto from "../controles/InputTexto";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputMoneda from "../controles/InputMoneda";
import ModalBotones from "../controles/ModalBotones";
import InputSwitch from "../controles/InputSwitch";
import {useGsBackend} from "../funcionesApi";

type Ingreso = {
    id_proveedor: number,
    cantidad: string,
    importe_unitario: number,
    importe: number,
    nro_factura: string,
    comentarios: string
}

type ValidacionIngreso = {
    cantidad: boolean,
    id_proveedor: boolean,
    importe_unitario: boolean,
    importe: boolean
}

type Props = {
    existencia: Existencia,
    proveedores: Array<OpcionSelect>,
    onAceptar: () => void,
    onCancelar: () => void
}

const StockIngreso: React.FC<Props> = (
    {existencia, proveedores, onAceptar, onCancelar}
) => {
    const ingresoInicial: Ingreso =
        {
            id_proveedor: existencia.id_proveedor, cantidad: '0', importe_unitario: 0, importe: 0,
            nro_factura: '', comentarios: ''
        }
    const validacionIngresoInicial: ValidacionIngreso =
        {cantidad: true, id_proveedor: true, importe_unitario: true, importe: true}
    const [ingreso, setIngreso] = useState<Ingreso>(ingresoInicial)
    const [compra, setCompra] = useState<boolean>(ingresoInicial.id_proveedor !== 0)
    const [valid, setValid] = useState<ValidacionIngreso>(validacionIngresoInicial)
    const {pedidoJson, guardando} = useGsBackend()

    const cantRef = useRef<HTMLInputElement>(null)
    const unitRef = useRef<HTMLInputElement>(null)
    const totalRef = useRef<HTMLInputElement>(null)

    const guardarHandler = async (): Promise<void> => {
        // Validaciones
        const vld: ValidacionIngreso = {
            cantidad: ingreso.cantidad !== '0',
            id_proveedor: !compra || ingreso.id_proveedor !== 0,
            importe_unitario: !compra || ingreso.importe_unitario > 0,
            importe: !compra || ingreso.importe > 0
        }
        setValid(vld)
        if (!vld.cantidad || !vld.id_proveedor || !vld.importe_unitario || !vld.importe) return

        const fd = new FormData()
        fd.append('accion', 'ingreso')
        fd.append('id_producto', existencia.id_producto.toString())
        fd.append('id_proveedor', compra ? ingreso.id_proveedor.toString() : '0')
        fd.append('cantidad', ingreso.cantidad)
        fd.append('importe', compra ? ingreso.importe.toString() : '0')
        fd.append('nro_factura', ingreso.nro_factura)
        fd.append('comentarios', ingreso.comentarios.toString())

        pedidoJson({url: 'stock/existencias/ingreso', method: 'post', body: fd, ok: onAceptar})
    }

    const cantHandler = (arg: string): void => {
        const val = parseInt(arg) || 0
        if (document.activeElement === cantRef.current && val !== 0 && ingreso.importe_unitario !== 0) {
            setIngreso({...ingreso, cantidad: arg, importe: ingreso.importe_unitario * val})
        } else {
            setIngreso({...ingreso, cantidad: arg})
        }
    }

    const unitHandler = (arg: number): void => {
        const cant = parseInt(ingreso.cantidad) || 0
        if (document.activeElement === unitRef.current && cant > 0 && arg > 0) {
            setIngreso({...ingreso, importe_unitario: arg, importe: arg * cant})
        } else {
            setIngreso({...ingreso, importe_unitario: arg})
        }
    }

    const totalHandler = (arg: number): void => {
        const cant = parseInt(ingreso.cantidad) || 0
        if (document.activeElement === totalRef.current && cant > 0 && arg > 0) {
            setIngreso({...ingreso, importe: arg, importe_unitario: arg / cant})
        } else {
            setIngreso({...ingreso, importe: arg})
        }
    }

    return (
        <Modal>
            <h4>{existencia.descripcion}</h4>
            <FilaDetalle titulo='Categoría' detalle={existencia.categoria}/>
            <FilaDetalle titulo='Stock Actual' detalle={existencia.stock_fmt}
                         rojo={existencia.stock === 0}/>
            <hr/>
            <h5>Ingreso de Stock</h5>
            <div className='row mb-2'>
                <div className='col-md-3 pt-1'>Cantidad</div>
                <div className='col-md-9'>
                    <InputTexto value={ingreso.cantidad} numerico izquierda autoFocus ref={cantRef}
                                onChange={cantHandler} componentePosterior={existencia.unidad_de_medida}
                                invalido={!valid.cantidad}/>
                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-md-3 pt-1 pb-2'>Detallar Compra</div>
                <div className='col-md-9 py-1 pb-2'>
                    <InputSwitch checked={compra} onChange={setCompra}/>
                </div>
            </div>
            {compra && (
                <>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Proveedor</div>
                        <div className='col-md-9'>
                            <InputSelect value={ingreso.id_proveedor} opciones={proveedores}
                                         onChange={v => setIngreso({...ingreso, id_proveedor: v})}
                                         invalido={!valid.id_proveedor}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Costo Unitario</div>
                        <div className='col-md-9'>
                            <InputMoneda value={ingreso.importe_unitario} invalido={!valid.importe_unitario}
                                         onChange={unitHandler} ref={unitRef}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Costo Total</div>
                        <div className='col-md-9'>
                            <InputMoneda value={ingreso.importe} invalido={!valid.importe}
                                         onChange={totalHandler} ref={totalRef}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>Nro. Factura</div>
                        <div className='col-md-9'>
                            <InputTexto value={ingreso.nro_factura} placeholder='(Opcional)'
                                        onChange={v => setIngreso({...ingreso, nro_factura: v})}/>
                        </div>
                    </div>
                </>
            )}
            <div className='row mb-3'>
                <div className='col-md-3 pt-1'>Comentarios</div>
                <div className='col-md-9'>
                    <InputTexto value={ingreso.comentarios} placeholder='(Opcional)'
                                onChange={v => setIngreso({...ingreso, comentarios: v})}/>
                </div>
            </div>
            <ModalBotones onCancelar={onCancelar} onGuardar={guardarHandler} tituloNuevo='Aceptar'
                          guardando={guardando}/>
        </Modal>
    )
}

export default StockIngreso