import React, {useEffect, useState} from "react";
import TituloPagina from "../controles/TituloPagina";
import {nivelAcceso} from "../autorizacion";
import Boton from "../controles/Boton";
import Tarjeta from "../controles/Tarjeta";
import BarraTarjeta from "../controles/BarraTarjeta";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputTexto from "../controles/InputTexto";
import {useNavigate} from "react-router-dom";
import CerveceriaEstadisticas from "./CerveceriaEstadisticas";
import TarjetaCerveza from "./TarjetaCerveza";
import {NivelesAcceso} from "../redux/authReducer";
import {useGsBackend} from "../funcionesApi";

export interface Cerveza {
    id: number,
    descripcion: string,
    descripcion_larga: string,
    id_proveedor: number,
    id_categoria: number,
    categoria: string,
    proveedor: string,
    restante: number,
    ultima_cantidad: number,
    ultima_fecha: string,
    recargas_grandes: number,
    recargas_medianas: number,
    recargas_chicas: number,
    pintas_grandes: number,
    pintas_chicas: number,
    desc_recargas_grandes: string,
    desc_recargas_medianas: string,
    desc_recargas_chicas: string,
    desc_pintas_grandes: string,
    desc_pintas_chicas: string,
    resumen_recargas_grandes: string,
    resumen_recargas_medianas: string,
    resumen_recargas_chicas: string,
    resumen_pintas_grandes: string,
    resumen_pintas_chicas: string
}

export interface ValidacionRecarga {
    id_proveedor: boolean,
    descripcion: boolean,
    litros: boolean,
    id_categoria: boolean
}

export interface InfoRecarga {
    id: number,
    id_proveedor: number,
    id_categoria: number,
    descripcion: string,
    descripcion_larga: string,
    litros: string,
    validacion: ValidacionRecarga
}

type InfoCervezas = {
    cervezas: Array<Cerveza>,
    proveedores: Array<OpcionSelect>,
    categorias: Array<OpcionSelect>,
    mediosdepago: Array<OpcionSelect>,
    mediodepago_predeterminado: number
}

const Cerveceria: React.FC = () => {
    const validacionNuevaInicial: ValidacionRecarga =
        {id_proveedor: true, descripcion: true, litros: true, id_categoria: true}
    const infoNuevaInicial: InfoRecarga = {
        id: 0, id_proveedor: 0, id_categoria: 0, descripcion: '', litros: '0', descripcion_larga: '',
        validacion: validacionNuevaInicial
    }
    const [datos, setDatos] = useState<InfoCervezas | undefined>(undefined)
    const [estadisticas, setEstadisticas] = useState<boolean>(false)
    const [agregar, setAgregar] = useState<InfoRecarga | undefined>(undefined)
    const navigate = useNavigate()
    const acceso = nivelAcceso('cerveceria')

    const {pedidoJson, actualizar} = useGsBackend()
    useEffect(() => {
        pedidoJson({url: 'cerveceria/cervezas', ok: setDatos})
    }, [pedidoJson])

    const agregarHandler = async (): Promise<void> => {
        if (agregar === undefined) return

        const vld: ValidacionRecarga = {
            id_proveedor: agregar.id_proveedor !== 0,
            descripcion: agregar.descripcion !== '',
            litros: (parseFloat(agregar.litros) || 0) !== 0,
            id_categoria: agregar.id_categoria !== 0
        }
        setAgregar({...agregar, validacion: vld})
        if (Object.values(vld).some(v => !v)) return

        const fd = new FormData()
        fd.append('accion', 'nueva')
        fd.append('id', '0')
        fd.append('id_proveedor', agregar.id_proveedor.toString())
        fd.append('id_categoria', agregar.id_categoria.toString())
        fd.append('descripcion', agregar.descripcion)
        fd.append('descripcion_larga', agregar.descripcion_larga)
        fd.append('litros', agregar.litros)

        pedidoJson({
            url: 'cerveceria/recarga', method: 'post', body: fd,
            ok: () => {
                setAgregar(undefined)
                actualizar()
            }
        })
    }

    return (
        <>
            <TituloPagina icono='fas fa-beer' titulo='Cervecería'/>
            <BarraTarjeta toolbar>
                {acceso === NivelesAcceso.NivelSupervisor && (
                    <Boton toolbar titulo='Configuración' icono='fas fa-cog' className='btn-light'
                           onClick={() => navigate('configuracion')} estiloIcono='text-primary'/>
                )}
                <Boton toolbar titulo='Stock de Barriles' icono='fas fa-beer' className='btn-light'
                       onClick={() => navigate('barriles')}/>
                {acceso >= NivelesAcceso.NivelHabilitado && (
                    <Boton toolbar titulo='Agregar cerveza' icono='fas fa-plus' className='btn-light'
                           onClick={() => setAgregar(infoNuevaInicial)} estiloIcono='text-primary'/>

                )}
                <Boton toolbar titulo='Seguimiento de Ventas' icono='fas fa-chart-pie' className='btn-light'
                       onClick={() => setEstadisticas(true)} estiloIcono='text-secondary'/>
            </BarraTarjeta>
            {datos !== undefined && (
                <div className='row row-cols-1 row-cols-md-3 g-2'>
                    {datos.cervezas.length ? datos.cervezas.map((c, k) => (
                        <TarjetaCerveza cerveza={c} mediosDePago={datos.mediosdepago} key={k}
                                        medioDePagoPredeterminado={datos.mediodepago_predeterminado}
                                        onRecargar={actualizar} proveedores={datos.proveedores}
                                        categorias={datos.categorias}/>
                    )) : (
                        <div className='col'>
                            <Tarjeta>
                                <i className='fas fa-info-circle text-info me-2'/>
                                No hay cervezas para mostrar
                            </Tarjeta>
                        </div>
                    )}
                </div>
            )}
            {agregar !== undefined && datos !== undefined && (
                <Modal>
                    <h4>Agregar Cerveza</h4>
                    <div className='row mb-2'>
                        <div className='col-md-4 pt-1'>Proveedor</div>
                        <div className='col-md-8'>
                            <InputSelect value={agregar.id_proveedor} opciones={datos.proveedores}
                                         onChange={v => setAgregar({...agregar, id_proveedor: v})}
                                         invalido={!agregar.validacion.id_proveedor}
                                         autoFocus={agregar.id === 0}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-4 pt-1'>Estilo</div>
                        <div className='col-md-8'>
                            <InputTexto value={agregar.descripcion} autoFocus={agregar.id !== 0}
                                        onChange={v => setAgregar({...agregar, descripcion: v})}
                                        invalido={!agregar.validacion.descripcion}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-4 pt-1'>Descripción (Menú)</div>
                        <div className='col-md-8'>
                            <InputTexto value={agregar.descripcion_larga} placeholder='(Opcional)'
                                        onChange={v => setAgregar({...agregar, descripcion_larga: v})}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-4 pt-1'>Categoría</div>
                        <div className='col-md-8'>
                            <InputSelect value={agregar.id_categoria} opciones={datos.categorias}
                                         onChange={v => setAgregar({...agregar, id_categoria: v})}
                                         invalido={!agregar.validacion.id_categoria}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-4 pt-1'>Litros</div>
                        <div className='col-md-8'>
                            <InputTexto value={agregar.litros} numerico izquierda decimal
                                        onChange={v => setAgregar({...agregar, litros: v})}
                                        invalido={!agregar.validacion.litros}/>
                        </div>
                    </div>
                    <ModalBotones onCancelar={() => setAgregar(undefined)}
                                  onGuardar={() => agregarHandler()}/>
                </Modal>
            )}
            {estadisticas && <CerveceriaEstadisticas onCerrar={() => setEstadisticas(false)}/>}
        </>
    )
}

export default Cerveceria