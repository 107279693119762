import React, {useCallback, useEffect, useState} from "react";
import TituloPagina from "../controles/TituloPagina";
import BarraTarjeta from "../controles/BarraTarjeta";
import Boton from "../controles/Boton";
import {useNavigate, useSearchParams} from "react-router-dom";
import {nivelAcceso} from "../autorizacion";
import Tarjeta from "../controles/Tarjeta";
import TrInfo from "../controles/TrInfo";
import TextoND from "../controles/TextoND";
import ToolbarBtn from "../controles/ToolbarBtn";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputTexto from "../controles/InputTexto";
import {CategoriaProductos} from "./StockProductos";
import BotonIcono from "../controles/BotonIcono";
import StockIngreso from "./StockIngreso";
import StockBaja from "./StockBaja";
import Tabla from "../controles/Tabla";
import ControlPaginas, {paginado} from "../controles/ControlPaginas";
import {NivelesAcceso} from "../redux/authReducer";
import {useGsBackend} from "../funcionesApi";

export interface Existencia {
    id_producto: number,
    descripcion: string,
    descripcion_corta: string,
    id_tipodeproducto: number,
    tipodeproducto: string,
    id_categoria: number,
    categoria: string,
    id_proveedor: number,
    proveedor: string,
    precio_unitario_fmt: string,
    stock: number,
    stock_fmt: string,
    unidad_de_medida: string
}

type InfoExistencias = {
    existencias: Array<Existencia>,
    tiposdeproductos: Array<OpcionSelect>,
    proveedores: Array<OpcionSelect>,
    categorias: Array<CategoriaProductos>,
    unidadesdemedida: Array<OpcionSelect>
}

type FiltrosExistencias = {
    descripcion: string,
    id_categoria: number,
    id_proveedor: number
}

const stringATipo = (arg: string | null): number => {
    switch (arg) {
        case 'insumos':
            return 4
        case 'mantenimiento':
            return 5
        case 'productos':
        case null:
        default:
            return 1
    }
}

const tipoAString = (arg: number): string => {
    switch (arg) {
        case 4:
            return 'insumos'
        case 5:
            return 'mantenimiento'
        default:
            return 'productos'
    }
}

const Stock: React.FC = () => {
    const navigate = useNavigate()
    const [params, setParams] = useSearchParams()
    const filtroInicial: FiltrosExistencias = {descripcion: '', id_categoria: 0, id_proveedor: 0}
    const [datos, setDatos] = useState<InfoExistencias | undefined>(undefined)
    const [muestra, setMuestra] = useState<Array<Existencia>>([])
    const [filtros, setFiltros] = useState<FiltrosExistencias>(filtroInicial)
    const [pagina, setPagina] = useState<number>(1)
    const [tipo, setTipo] = useState<number>(stringATipo(params.get('tipo')))
    const [compra, setCompra] = useState<Existencia | undefined>(undefined)
    const [baja, setBaja] = useState<Existencia | undefined>(undefined)
    const accesoStock = nivelAcceso('stock')
    const accesoProv = nivelAcceso('proveedores')

    const {pedidoJson, actualizar} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'stock/existencias', ok: setDatos})
    }, [pedidoJson])

    const filtrar = useCallback(() => {
        if (datos === undefined) return

        setPagina(1)
        setMuestra(datos.existencias
            .filter(e => e.id_tipodeproducto === tipo)
            .filter(e => filtros.id_proveedor === 0 ? true : e.id_proveedor === filtros.id_proveedor)
            .filter(e => filtros.id_categoria === 0 ? true : e.id_categoria === filtros.id_categoria)
            .filter(e => filtros.descripcion === '' ? true :
                e.descripcion.toLocaleLowerCase().includes(filtros.descripcion.toLocaleLowerCase()) ||
                e.descripcion_corta.toLocaleLowerCase().includes(filtros.descripcion.toLocaleLowerCase())))
    }, [tipo, filtros, datos])

    useEffect(() => {
        filtrar()
    }, [filtrar])

    useEffect(() => {
        setTipo(stringATipo(params.get('tipo')))
    }, [params])

    return (
        <>
            <TituloPagina icono='fas fa-boxes-stacked' titulo='Stock'/>
            <BarraTarjeta>
                {accesoStock >= NivelesAcceso.NivelHabilitado && (
                    <Boton toolbar titulo='Configuración y Alta de Productos' icono='fas fa-boxes-packing'
                           className='btn-light' onClick={() => navigate('productos')}/>
                )}
                {accesoProv >= NivelesAcceso.NivelConsulta && (
                    <Boton toolbar titulo='Análisis de Margen por Producto' icono='fas fa-chart-pie'
                           className='btn-light' onClick={() => navigate('analisisdemargen')}/>
                )}
            </BarraTarjeta>
            <Tarjeta>
                {datos !== undefined && (
                    <div className='row g-2 mb-2'>
                        <div className='col-xl-auto text-nowrap'>
                            <ToolbarBtn>
                                {datos.tiposdeproductos.map((t, k) => (
                                    <Boton toolbar key={k} titulo={t.descripcion}
                                           className={tipo === t.id ? 'btn-primary' : 'btn-light'}
                                           onClick={() => {
                                               setFiltros({...filtros, id_categoria: 0})
                                               setParams({tipo: tipoAString(t.id)})
                                           }}/>
                                ))}
                            </ToolbarBtn>
                        </div>
                        <div className='col-xl-auto pt-1 d-none d-xl-block'>
                            <i className='fas fa-search text-primary'/>
                        </div>
                        <div className='col-xl'>
                            <div className='row g-2'>
                                <div className='col-md-4'>
                                    <InputTexto value={filtros.descripcion} chico titulo='Descripción'
                                                onChange={v => setFiltros({...filtros, descripcion: v})}
                                                placeholder='Buscar por Producto'/>
                                </div>
                                <div className='col-md-4'>
                                    <InputSelect chico titulo='Categoría' value={filtros.id_categoria}
                                                 onChange={v => setFiltros({...filtros, id_categoria: v})}
                                                 opciones={datos.categorias
                                                     .filter(d =>
                                                         tipo === 1 ? d.stock :
                                                             tipo === 4 ? d.insumos :
                                                                 tipo === 5 ? d.mantenimiento :
                                                                     false)}
                                                 textocero='(Todas)'/>
                                </div>
                                <div className='col-md-4'>
                                    <InputSelect value={filtros.id_proveedor} chico titulo='Proveedor'
                                                 onChange={v => setFiltros({...filtros, id_proveedor: v})}
                                                 opciones={datos.proveedores} textocero='(Todos)'/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Tabla>
                    <thead>
                    <tr>
                        <th colSpan={tipo === 1 ? 6 : 5}>
                            <ControlPaginas registros={muestra.length} pagina={pagina} onChange={setPagina}/>
                        </th>
                    </tr>
                    <tr className='headerTabla'>
                        <th style={{width: '40%'}}>Producto</th>
                        <th style={{width: '20%'}}>Categoría</th>
                        <th style={{width: '20%'}}>Proveedor</th>
                        {tipo === 1 && (<th style={{width: '10%'}}>Precio de Venta</th>)}
                        <th style={{width: tipo === 1 ? '10%' : '20%'}}>Stock</th>
                        <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {muestra.length ? paginado(muestra, pagina).map((e, k) => (
                        <tr key={k}>
                            <td>
                                {e.descripcion === e.descripcion_corta || e.descripcion_corta === '' ? (
                                    <strong>{e.descripcion}</strong>
                                ) : (
                                    <>
                                        <strong>{e.descripcion}</strong>
                                        <small className='text-muted ms-2'>({e.descripcion_corta})</small>
                                    </>
                                )}
                            </td>
                            <td>{e.categoria}</td>
                            <td><TextoND texto={e.proveedor}/></td>
                            {tipo === 1 && (<td className='text-end'>{e.precio_unitario_fmt}</td>)}
                            <td className={'text-end border-end ' +
                                (e.stock > 0 ? 'fw-bold' : 'fw-bold text-danger')}>
                                {e.stock_fmt}
                            </td>
                            <td className='text-nowrap'>
                                {accesoStock >= NivelesAcceso.NivelHabilitado && (
                                    <>
                                        <BotonIcono className='btn-success' espacio onClick={() => setCompra(e)}
                                                    icono='fas fa-plus'/>
                                        <BotonIcono onClick={() => setBaja(e)} icono='fas fa-minus'
                                                    className='btn-danger' espacio/>
                                    </>
                                )}
                                <BotonIcono onClick={() => navigate(`movimientos?id=${e.id_producto}`)}
                                            icono='fas fa-magnifying-glass-plus' className='btn-primary'/>
                            </td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={tipo === 1 ? 5 : 4} texto='Sin productos para mostrar'/>
                    )}
                    </tbody>
                </Tabla>
            </Tarjeta>
            {compra !== undefined && datos !== undefined && (
                <StockIngreso existencia={compra} proveedores={datos.proveedores}
                              onCancelar={() => setCompra(undefined)}
                              onAceptar={() => {
                                  setCompra(undefined)
                                  actualizar()
                              }}/>
            )}
            {baja !== undefined && (
                <StockBaja existencia={baja} onCancelar={() => setBaja(undefined)}
                           onAceptar={() => {
                               setBaja(undefined)
                               actualizar()
                           }}/>
            )}
        </>
    )
}

export default Stock