import React, {useCallback, useEffect, useState} from "react";
import TituloPagina from "../controles/TituloPagina";
import BarraTarjeta from "../controles/BarraTarjeta";
import Boton from "../controles/Boton";
import {useNavigate} from "react-router-dom";
import Tarjeta from "../controles/Tarjeta";
import Tabla from "../controles/Tabla";
import ControlPaginas, {paginado} from "../controles/ControlPaginas";
import TrInfo from "../controles/TrInfo";
import BotonIcono from "../controles/BotonIcono";
import ProveedoresHistorialDetalle from "../proveedores/ProveedoresHistorialDetalle";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputTexto from "../controles/InputTexto";
import {useGsBackend} from "../funcionesApi";

type ProductoAnalisis = {
    desc_producto: string,
    id_categoria: number,
    categoria: string,
    ultima_compra: string,
    proveedor: string,
    id_proveedor_compra: number,
    importe_total: string,
    cantidad: number,
    precio_unitario_ultima_compra: string,
    precio_unitario_venta: string,
    margen: string
}

type InfoAnalisis = {
    productos: Array<ProductoAnalisis>,
    proveedores: Array<OpcionSelect>,
    categorias: Array<OpcionSelect>
}

type FiltrosAnalisis = {
    descripcion: string,
    id_categoria: number,
    id_proveedor_compra: number
}

const StockAnalisisDeMargen: React.FC = () => {
    const [datos, setDatos] = useState<InfoAnalisis>(
        {productos: [], proveedores: [], categorias: []})
    const [muestra, setMuestra] = useState<Array<ProductoAnalisis>>([])
    const [filtros, setFiltros] = useState<FiltrosAnalisis>(
        {descripcion: '', id_categoria: 0, id_proveedor_compra: 0})
    const [pagina, setPagina] = useState<number>(1)
    const [detalleCompra, setDetalleCompra] = useState<number | undefined>(undefined)
    const navigate = useNavigate()
    const {pedidoJson} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'stock/analisisdemargen', ok: setDatos})
    }, [pedidoJson])

    const filtrar = useCallback(() => {
        if (datos === undefined) return

        setPagina(1)
        setMuestra(datos.productos
            .filter(e => filtros.id_proveedor_compra === 0 ? true :
                e.id_proveedor_compra === filtros.id_proveedor_compra)
            .filter(e => filtros.id_categoria === 0 ? true : e.id_categoria === filtros.id_categoria)
            .filter(e => filtros.descripcion === '' ? true :
                e.desc_producto.toLocaleLowerCase().includes(filtros.descripcion.toLocaleLowerCase())))
    }, [filtros, datos])

    useEffect(() => {
        filtrar()
    }, [filtrar])

    return (
        <>
            <TituloPagina icono='fas fa-chart-pie' titulo='Análisis de Margen por Producto (Stock)'/>
            <BarraTarjeta>
                <div className='row g-2'>
                    <div className='col-xl-auto'>
                        <Boton toolbar titulo='Volver' className='btn-light' icono='fas fa-chevron-left'
                               onClick={() => navigate('../')}/>
                    </div>
                    <div className='col-xl-auto pt-1 d-none d-xl-block'>
                        <i className='fas fa-search text-primary'/>
                    </div>
                    <div className='col-xl'>
                        <div className='row g-2'>
                            <div className='col-md-4'>
                                <InputTexto value={filtros.descripcion} chico titulo='Descripción'
                                            onChange={v => setFiltros({...filtros, descripcion: v})}
                                            placeholder='Buscar por Producto'/>
                            </div>
                            <div className='col-md-4'>
                                <InputSelect chico titulo='Categoría' value={filtros.id_categoria}
                                             onChange={v => setFiltros({...filtros, id_categoria: v})}
                                             opciones={datos.categorias}
                                             textocero='(Todas)'/>
                            </div>
                            <div className='col-md-4'>
                                <InputSelect value={filtros.id_proveedor_compra} chico titulo='Proveedor'
                                             onChange={v => setFiltros({...filtros, id_proveedor_compra: v})}
                                             opciones={datos.proveedores} textocero='(Todos)'/>
                            </div>
                        </div>
                    </div>
                </div>
            </BarraTarjeta>
            <Tarjeta>
                <Tabla>
                    <thead>
                    <tr>
                        <th colSpan={8}>
                            <ControlPaginas registros={muestra.length} pagina={pagina} onChange={setPagina}/>
                        </th>
                    </tr>
                    <tr>
                        <th rowSpan={2} className='border-end' style={{width: '25%'}}>Producto</th>
                        <th colSpan={4} className='border-end' style={{width: '45%'}}>Última Compra</th>
                        <th rowSpan={2} className='border-end' style={{width: '10%'}}>Precio Unitario (Compra)</th>
                        <th rowSpan={2} className='border-end' style={{width: '10%'}}>Precio Unitario (Venta)</th>
                        <th rowSpan={2} style={{width: '10%'}}>Margen</th>
                    </tr>
                    <tr className='headerTabla'>
                        <th style={{width: '15%'}}>Proveedor</th>
                        <th style={{width: '10%'}}>Fecha</th>
                        <th style={{width: '10%'}}>Importe Total</th>
                        <th style={{width: '10%'}} className='border-end'>Cantidad de Unidades</th>
                    </tr>
                    </thead>
                    <tbody>
                    {muestra.length ? paginado(muestra, pagina).map((p, k) => (
                        <tr key={k}>
                            <td className='border-end'>{p.desc_producto}</td>
                            <td>
                                <div className='row g-2'>
                                    <div className='col-auto'>
                                        <BotonIcono icono='fas fa-magnifying-glass-plus'
                                                    onClick={() => setDetalleCompra(p.id_proveedor_compra)}/>
                                    </div>
                                    <div className='col'>{p.proveedor}</div>
                                </div>
                            </td>
                            <td>{p.ultima_compra}</td>
                            <td className='text-end'>{p.importe_total}</td>
                            <td className='text-end border-end'>{p.cantidad}</td>
                            <td className='text-end border-end'>{p.precio_unitario_ultima_compra}</td>
                            <td className='text-end border-end'>{p.precio_unitario_venta}</td>
                            <td className='text-end'>{p.margen}</td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={8} texto='No hay productos para mostrar'/>
                    )}
                    </tbody>
                </Tabla>
            </Tarjeta>
            {detalleCompra !== undefined && (
                <ProveedoresHistorialDetalle idCompra={detalleCompra}
                                             onCerrar={() => setDetalleCompra(undefined)}/>
            )}
        </>
    )
}

export default StockAnalisisDeMargen