import React from "react";
import {Bar} from "react-chartjs-2";
import {BarElement, CategoryScale, Chart as ChartJS, LinearScale} from 'chart.js'
import {coloresBordes, coloresGraficos} from "./configuracionGraficos";

type Props = {
    leyendas: Array<string>,
    datos: Array<number>
}

const GraficoCategoriasProductos: React.FC<Props> = ({leyendas, datos}) => {
    ChartJS.register(CategoryScale, LinearScale, BarElement)

    let lf: Array<string>
    let df: Array<number>
    if (leyendas.length > 5) {
        lf = leyendas.slice(0, 5).concat('Otros')
        df = datos.slice(0, 5).concat(datos.slice(5, datos.length).reduce((a, b) => a + b))
    } else {
        lf = leyendas
        df = datos
    }

    return (
        <div>
            <Bar data={{
                labels: lf,
                datasets: [
                    {
                        indexAxis: 'y',
                        backgroundColor: coloresGraficos,
                        borderColor: coloresBordes,
                        borderWidth: 1,
                        data: df
                    }
                ]
            }}
                 options={{
                     indexAxis: 'y',
                     plugins: {
                         legend: {
                             display: false
                         }
                     }
                 }}/>
        </div>
    )
}

export default GraficoCategoriasProductos