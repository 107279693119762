import React from "react";

type Props = {
    icono?: string,
    className?: string,
    onClick: React.MouseEventHandler<HTMLButtonElement>
    espacio?: boolean
}

const BotonIcono: React.FC<Props> = (
    {icono, className, onClick, espacio}
) => {
    if (className === undefined) className = 'btn-light text-primary'
    if (icono === undefined) icono = 'fas fa-external-link-alt'

    return (
        <button type='button' className={`btn ${className} btn-sm` + (espacio? ' me-2' : '')} onClick={onClick}>
            <i className={icono}/>
        </button>
    )
}

export default BotonIcono
